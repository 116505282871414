import { Component, Input, OnInit } from '@angular/core';
import { HeroMessageConfig } from '@app/shared/models/hero-message.model';

@Component({
  selector: 'app-shared-hero-message',
  templateUrl: './shared-hero-message.component.html',
  styleUrls: ['./shared-hero-message.component.scss']
})
export class SharedHeroMessageComponent implements OnInit {

  @Input() heroMessageConfig: HeroMessageConfig;

  constructor() { }

  ngOnInit(): void {
  }

  public getStyleConfigs(): HeroMessageConfig {
    return this.heroMessageConfig;
  }

}
