import { Component, OnInit, Input, Output } from '@angular/core';

enum Size {
  xs = 'fa-xs',
  sm = 'fa-sm',
  lg = 'fa-lg'
  // = "fa-2x",
  // = "fa-3x",
  // = "fa-4x",
  // = "fa-5x",
  // = "fa-6x",
  // = "fa-7x",
  // = "fa-8x",
  // = "fa-9x",
  // = "fa-10x",
}

@Component({
  selector: 'app-submit',
  templateUrl: './submit.component.html',
  styleUrls: ['./submit.component.scss']
})
export class SubmitComponent implements OnInit {
  private _buttonText: string | undefined;
  public ButtonText: string | undefined;

  constructor() {}

  ngOnInit() {
    this.ButtonText = this.buttonText;
  }

  get buttonText(): string {
    return this._buttonText;
  }
  @Input()
  set buttonText(text: string) {
    this._buttonText = text;
  }
}
