
<ng-container *ngIf="!showSpinner else spinner">
    <form class="floating-form">
        <div *ngIf="initiateIdvData">
            <p class="beginVerificationTitle" translate="">^begin-kyc.beginVerification</p>
            <iframe [src]="initiateIdvData?.idvUrl | safeUrl" frameborder="0" *ngIf="initiateIdvData?.idvUrl"  width="70%" height="80%" allow="camera;fullscreen;accelerometer;gyroscope;magnetometer" allowfullscreen ></iframe>
        </div>
    </form>
</ng-container>
<ng-template #spinner>
    <div class="kyc-spinner floating-form">
        <app-card-spinner>^begin-kyc.pageRedirect</app-card-spinner>
    </div>
</ng-template>