import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
    selector: 'input[numbersOnly]',
})
export class NumbersOnlyDirective {
    constructor(private _el: ElementRef, private renderer: Renderer2) {}

    ngAfterViewInit() {
        this.renderer.setAttribute(this._el.nativeElement, 'type', 'tel');
        this.renderer.setAttribute(this._el.nativeElement, 'pattern', '[0-9]*');
        this.renderer.setAttribute(this._el.nativeElement, 'inputmode', 'numeric');
    }

    @HostListener('input', ['$event'])
    onInput(event: Event) {
        this.inputHandler(event);
    }
    @HostListener('copy', ['$event']) onCopy(event: ClipboardEvent) {
        let value = (event.target as HTMLInputElement).value;
        if (!isNaN(Number(value))) {
            event.clipboardData?.setData('text/plain', value);
            event.preventDefault();
        }
    }

    @HostListener('paste', ['$event']) onPaste(event: ClipboardEvent) {
        let value = event.clipboardData?.getData('text/plain') || '';
        if (isNaN(Number(value))) event.preventDefault();
    }

    private inputHandler(event: Event) {
        // Handler for the inputs
        const initialValue = this._el.nativeElement.value;
        this._el.nativeElement.value = initialValue.replace(/[^0-9]*/g, '');
        if (initialValue !== this._el.nativeElement.value) {
            event.stopPropagation();
        }
    }
}
