import { Injectable } from '@angular/core';
import { CredentialsService } from '@app/core/authentication/credentials.service';
import * as moment from 'moment';
import { KycStatus } from '../enum/kyc-status.enum';
import { InitiateIdvResponse } from '../models/initiateIdv-response-model';
import { KycIntendedAccount } from '../models/kyc-intended-account.model';
import { KycJob } from '../models/kyc-job.model';
import { KycOccupationCategory } from '../models/kyc-occupation.model';
import { SaveAdditionalInfoRequest } from '../models/save-additional-info-request.model';
import { UpdateUserKycRequest } from '../models/update-userKyc-request.model';
import { KycHttpService } from './kyc-http.service';
import { UserKYC } from '../models/user-kyc.model';

@Injectable({
    providedIn: 'root',
})
export class KycService {
    public _currentKyc: UserKYC = null;

    constructor(private kycHttpService: KycHttpService, private credentials: CredentialsService) {}

    async getOccupationCategories(): Promise<KycOccupationCategory> {
        return await this.kycHttpService.getOccupationCategories();
    }
    async getIntendedUseOfAccountList(): Promise<KycIntendedAccount> {
        return await this.kycHttpService.getIntendedUseOfAccountList();
    }
    async getJobsByOccupationCategoryId(jobId: number): Promise<KycJob> {
        return await this.kycHttpService.getJobsByOccupationCategoryId(jobId);
    }
    async initiateIdv(proxyCardNumber: string): Promise<InitiateIdvResponse> {
        return await this.kycHttpService.initiateIdv(proxyCardNumber);
    }
    async saveAdditionalInformation(request: SaveAdditionalInfoRequest): Promise<any> {
        return await this.kycHttpService.saveAdditionalInformation(request);
    }
    async updateUserKycInfo(request: UpdateUserKycRequest): Promise<any> {
        return await this.kycHttpService.updateUserKycInfo(request);
    }
    get credentialsKYCStatus(): string {
        return this._currentKyc && this._currentKyc.kycStatus;
    }

    get credentialsAllowKycResubmit(): boolean {
        return this._currentKyc && this._currentKyc.allowResubmit;
    }

    get credentialsAdditionalInfoPresent(): boolean {
        return this._currentKyc && this._currentKyc.additionalInfoPresent;
    }

    get credentialsKycExpiration(): Date {
        return this._currentKyc && this._currentKyc.kycExpiration;
    }

    get isKYCExpired(): boolean {
        return moment().isAfter(this.credentialsKycExpiration);
    }

    public finishActivationBtn(enableKYC: boolean, activated: boolean, cancelledCard?: boolean): boolean {
        if (
            enableKYC &&
            !activated &&
            (this.credentialsKYCStatus === null ||
                this.credentialsKYCStatus === KycStatus.INCOMPLETE ||
                (this.credentialsKYCStatus === KycStatus.FAIL && this.credentialsAllowKycResubmit))
        ) {
            if (enableKYC && cancelledCard) {
                return false;
            }
            return true;
        } else {
            return false;
        }
    }

    public deleteBtn(enableKYC: boolean, activated: boolean, cancelledCard?: boolean): boolean {
        if (
            (this.credentialsKYCStatus === KycStatus.FAIL && !this.credentialsAllowKycResubmit && enableKYC && !activated) ||
            (enableKYC && cancelledCard)
        ) {
            return true;
        } else {
            return false;
        }
    }

    public viewDetailsBtn(enableKYC: boolean, activated: boolean, cancelledCard?: boolean): boolean {
        if (!(enableKYC && this.credentialsKYCStatus === KycStatus.FAIL && !this.credentialsAllowKycResubmit && !activated)) {
            if (enableKYC && cancelledCard) {
                return false;
            }
            return true;
        } else {
            return false;
        }
    }

    public onSetUserKYCInfo(userKyc: UserKYC) {
        this._currentKyc = userKyc;
    }
}
