import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { BHNTranslateService } from '@app/shared/services/bhn-translate.service';
@Component({
    selector: 'app-staging',
    templateUrl: './staging.component.html',
})
export class StagingComponent implements OnInit {
    constructor(private activatedRoute: ActivatedRoute, private BHNTranslateService: BHNTranslateService, private router: Router) {}

    ngOnInit() {
        const country = this.activatedRoute.snapshot.queryParamMap.get('country');
        const language = this.activatedRoute.snapshot.queryParamMap.get('lang');

        if (country && language) {
            this.BHNTranslateService.storeCountry(country);
            this.BHNTranslateService.storeLanguage(language);
            this.router.navigate(['home']);
        }
    }
}
