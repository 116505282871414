import { Injectable } from '@angular/core';
import { Route, Router } from '@angular/router';
import { FeatureSets } from '@app/features/feature-sets';
import { defaultsDeep } from 'lodash-es';
import { StagingComponent } from '../components/staging/staging.component';
import { Feature, FeatureSetReference, Shell } from '../models/application-config.model';

@Injectable({ providedIn: 'root' })
export class FeatureService {
    constructor(private router: Router) {}

    private defaultShell: Shell;
    private shells: Map<string, Shell>;
    private features: Map<string, Feature>;
    private featureLayout: string;

    public setfeatureLayout(feature: string) {
        this.featureLayout = feature;
    }

    public getfeatureLayout(): string {
        return this.featureLayout;
    }

    public has(feature: string): boolean {
        return this.features.has(feature);
    }

    public get(feature: string): Feature {
        return this.features.get(feature);
    }

    public getShell(shell: string): Shell {
        return this.shells.get(shell);
    }

    private shellChildRoutes = new Map<string, Route[]>();
    public getShellChildRoutes(shell: string): Route[] {
        const routes = [
            ...this.shellChildRoutes.get(shell), //
        ];
        return (
            routes &&
            routes.sort(
                // Sorting routes to put empty paths first and catch-all last
                (a, b) =>
                    (a.path === '' ? 1 : a.path === '**' ? 3 : 2) - //
                    (b.path === '' ? 1 : b.path === '**' ? 3 : 2),
            )
        );
    }

    public async applyFeatureSet(derivedFeatureSet: FeatureSetReference): Promise<void> {
        const featureSet = FeatureSets.get(derivedFeatureSet.base);
        this.shells = featureSet.shells;
        this.features = featureSet.features;
        this.defaultShell = derivedFeatureSet.shell?.default //
            ? this.shells.get(derivedFeatureSet.shell.default)
            : featureSet.defaultShell;
        derivedFeatureSet.features?.forEach((f) => {
            const feature = this.features.get(f.name);
            if (!feature) {
                throw Error(`Unknown feature '${f.name}' specified in set ${derivedFeatureSet.name}`);
            }
            if (!['add', 'omit', 'configure'].includes(f.action)) {
                throw Error(`Unknown action '${f.action}' specified in set ${derivedFeatureSet.name}`);
            }
            this.features.set(f.name, {
                ...feature,
                action:
                    f.action === 'add' || f.action === 'omit' //
                        ? f.action
                        : feature.action,
                shell: f.shell || feature.shell,
                configuration:
                    f.configuration?.['replace-all-configuration'] === true
                        ? f.configuration
                        : defaultsDeep(f.configuration, feature.configuration),
            });
            console.log(this.features.get(f.name));
        });
        this.shells.forEach((shell) => {
            this.shells.set(shell.name, {
                ...shell,
                configuration: defaultsDeep(derivedFeatureSet?.shell?.configuration?.[shell.name], shell.configuration),
            });
        });
        let rootRoutes = new Array<Route>();
        for (const feature of this.features.values()) {
            if (feature.action === 'omit') continue;
            let featureRoutes: Route[] = feature.routes;
            if (featureRoutes) {
                featureRoutes = featureRoutes.map((r) => ({
                    ...r,
                    path: feature.paths?.[r.path] || r.path,
                }));
                if (feature.shell === false) {
                    rootRoutes = [
                        ...rootRoutes, //
                        ...featureRoutes,
                    ];
                } else {
                    const shell = this.shells.get(feature.shell) || this.defaultShell;
                    if (!shell) {
                        throw Error(`invalid shell specified in feature set '${derivedFeatureSet?.name}': ${feature.shell}`);
                    }
                    const shellRoutes = this.shellChildRoutes.get(shell.name) || [];
                    this.shellChildRoutes.set(shell.name, [
                        ...shellRoutes, //
                        ...featureRoutes.filter((r) => (r.path !== '' || !r.redirectTo) && r.path !== '**'),
                    ]);
                    rootRoutes = [
                        ...featureRoutes.filter((r) => r.path === '' && r.redirectTo),
                        ...rootRoutes, //
                        ...featureRoutes.filter((r) => r.path === '**'),
                    ];
                }
            }
        }
        rootRoutes = [
            { path: 'staging', component: StagingComponent },
            ...rootRoutes.filter((route) => route.path === ''),
            ...rootRoutes.filter((route) => route.path !== '**'),
            ...Array.from(this.shells.values())
                .filter(
                    (shell) =>
                        this.shellChildRoutes.get(shell.name)?.length > 0 && //
                        shell.name !== this.defaultShell.name,
                )
                .map((shell) => shell.route),
            ...Array.from(this.shells.values())
                .filter(
                    (shell) =>
                        this.shellChildRoutes.get(shell.name)?.length > 0 && //
                        shell.name === this.defaultShell.name,
                )
                .map((shell) => shell.route),
            ...rootRoutes.filter((route) => route.path === '**'),
        ];
        this.router.resetConfig(rootRoutes);
    }
}
