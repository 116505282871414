import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CredentialsService } from '@app/core/authentication/credentials.service';
import { ENVIRONMENT } from '@app/core/services/environment.service';
import { InputSanitizerService } from '@app/core/services/input-sanitizer.service';
import { RmsSessionService } from '@app/services/auth/rms-session.service';
import { CodeRedemptionService } from '@app/services/card/code-redemption.service';
import { BHNTranslateService } from '@app/shared/services/bhn-translate.service';
import { CardDataService } from '@app/shared/services/card-data.service';
import { PdfViewerConfigService } from '@app/shared/services/pdf-viewer-config.service';
import { TranslateService } from '@ngx-translate/core';

enum PageStatus {
    Default,
    Processing,
    ValidationFailed,
    VirtualValidationFailed,
    ActivationError,
    InvalidTenant,
    BlockedProductCode,
    ProcessingError,
    GeoError,
}

@Component({
    selector: 'app-cardholder-agreement',
    templateUrl: './cardholder-agreement.component.html',
    styleUrls: ['./cardholder-agreement.component.scss'],
})
export class CardholderAgreementComponent implements OnInit {
    CHAForm: FormGroup;
    visibleKey = 'visible';
    invisibleKey = 'invisible';
    pdfScript: any;
    @Input() termsFileUrl: string;
    @Output() formValidityChange = new EventEmitter<boolean>();
    fileName: string;
    @ViewChild('termsLinkMessage', { static: true }) termsLinkMessage: ElementRef;

    PageStatus = PageStatus;
    status: PageStatus;

    constructor(
        private formBuilder: FormBuilder,
        private cardDataService: CardDataService,
        private credentials: CredentialsService,
        private elementRef: ElementRef,
        @Inject(ENVIRONMENT) private environment,
        private translate: TranslateService,
        private bhnTranslateService: BHNTranslateService,
        private rmsSessionService: RmsSessionService,
        private pdfConfig: PdfViewerConfigService,
        private renderer: Renderer2,
        private inputSanitizerService: InputSanitizerService,
        private codeRedemptionService: CodeRedemptionService,
        private router: Router,
    ) {
        this.attachPdfScriptToDoc();
    }

    ngOnInit(): void {
        this.CHAForm = this.formBuilder.group({
            termsAcceptedEsign: new FormControl(false, Validators.requiredTrue),
            termsAcceptedCard: new FormControl(false, Validators.requiredTrue),
        });

        this.CHAForm.valueChanges.subscribe(() => {
            this.emitFormValidity();
        });

        if (this.codeRedemptionService.virtualCardTermsFileName) {
            this.termsFileUrl = this.environment.baseS3URL + '/Cardholder_Agreement/' + this.codeRedemptionService.virtualCardTermsFileName;
            this.fileName = this.codeRedemptionService.virtualCardTermsFileName;
            const termsLink = `<a href="${this.termsFileUrl}" target="_blank">${this.translate.instant(
                'redeem.address.termsLinkText',
            )}</a>`;

            (this.termsLinkMessage.nativeElement as HTMLSpanElement).innerHTML = this.translate.instant('redeem.address.termsLinkMessage', {
                termsLink,
            });
        }
    }

    emitFormValidity() {
        this.formValidityChange.emit(this.CHAForm.valid);
    }

    termsAcceptedErrorVisibility(): string {
        let eSignError = false;
        let cardError = false;

        if (!this.CHAForm.controls.termsAcceptedEsign.valid && !this.CHAForm.controls.termsAcceptedEsign.untouched) {
            eSignError = true;
            return this.visibleKey;
        }

        if (!this.CHAForm.controls.termsAcceptedCard.valid && !this.CHAForm.controls.termsAcceptedCard.untouched) {
            cardError = true;
            return this.visibleKey;
        }

        if (!eSignError && !cardError) {
            return this.invisibleKey;
        }
    }

    ngAfterViewInit(): void {
        this.renderPdf(this.fileName);
    }

    exportToFile(action: string) {
        this.cardDataService.getDownloadFile(this.termsFileUrl);
    }

    renderPdf(fileName: string) {
        this.pdfConfig.pdfReady$.subscribe(() => {
            const canvas: HTMLElement = document.querySelector('#adobe-dc-view');
            canvas.style.display = 'block';
            this.pdfConfig.previewFile('adobe-dc-view', this.termsFileUrl, fileName);
        });
    }

    attachPdfScriptToDoc() {
        this.pdfScript = this.renderer.createElement('script');
        this.pdfScript.src = `https://acrobatservices.adobe.com/view-sdk/viewer.js`;
        this.renderer.appendChild(document.head, this.pdfScript);
    }
}
