<div class="container-fluid pt-4 pb-4 country-language-model">
    <div class="row">
        <div class="col-12">
            <h2 class="header-text" id="language-dialog-label" editable translate>^country-language-modal.header</h2>
        </div>
    </div>
    <hr />
    <div class="row">
        <div class="col-12 col-md-6">
            <label id="country" class="sub-text" editable translate aria-label="Country">^country-language-modal.country</label>
            <div dropdown #dropdownCountry="bs-dropdown">
                <button
                    id="countryButton"
                    aria-haspopup="listbox"
                    aria-expanded="true"
                    type="button"
                    aria-labelledby="Country button"
                    dropdownToggle
                    class="dropdown-shell w-100 text-left bg-transparent"
                >
                    <div class="m-3" [attr.aria-label]="'Country ' + ('^country-language.' + this.selectedCountry | translate)">
                        <img class="mr-2 mb-1" [src]="'../../../assets/images/' + this.selectedCountry + '.svg'" alt="" />
                        <span class="dropdown-text"> {{ '^country-language.' + this.selectedCountry | translate }}</span>
                        <i class="fa fa-angle-down fa-lg icon-color float-right mt-1"></i>
                    </div>
                </button>

                <div
                    *dropdownMenu
                    class="dropdown-menu"
                    id="list"
                    role="listbox"
                    tabindex="-1"
                    [attr.aria-activedescendant]="selectedCountry"
                >
                    <div
                        role="option"
                        [id]="country.countryCode"
                        class="dropdown-item"
                        *ngFor="let country of countrySettingList"
                        (click)="countryChange(country.countryCode, country.defaultLanguage)"
                        [attr.aria-label]="'Country ' + ('^country-language.' + country.countryCode | translate)"
                        tabindex="0"
                        (keydown.enter)="countryChange(country.countryCode, country.defaultLanguage); dropdownCountry.hide()"
                    >
                        <img [src]="'../../../assets/images/' + country.countryCode + '.svg'" alt="" />
                        <span> {{ '^country-language.' + country.countryCode | translate }}</span>
                    </div>
                </div>
            </div>
            <div class="pb-3"></div>
            <span id="language" class="sub-text" editable translate>^country-language-modal.language</span>
            <div *ngFor="let country of countrySettingList" (focusin)="dropdownCountry.hide()">
                <div *ngIf="country.countryCode === this.selectedCountry" role="radiogroup" aria-labelledby="language">
                    <div *ngFor="let language of country.languages; let i = index" class="country">
                        <div class="d-flex">
                            <div [class]="language === this.selectedLanguage ? 'language-bar-selected' : 'language-bar'"></div>
                            <div
                                role="radio"
                                [attr.aria-checked]="language === this.selectedLanguage ? true : false"
                                [class]="language === this.selectedLanguage ? 'language-container-selected' : 'language-container'"
                                *ngIf="i === 0 || i === 1"
                                (click)="languageChange(language)"
                                tabindex="0"
                                (keydown.enter)="languageChange(language)"
                            >
                                <div class="lang-pad" [attr.aria-label]="'^country-language.' + language | translate">
                                    {{ '^country-language.' + language | translate }}
                                </div>
                            </div>
                            <div
                                [class]="language === this.selectedLanguage ? 'language-container-selected' : 'language-container'"
                                *ngIf="i > 1"
                            >
                                <div dropdown #dropdown="bs-dropdown">
                                    <button
                                        dropdownToggle
                                        id="moreLanguage"
                                        role="button"
                                        aria-haspopup="listbox"
                                        aria-expanded="true"
                                        aria-labelledby="Country button"
                                        data-toggle="dropdown"
                                        class="dropdown-shell-language w-100 text-left border-0 bg-transparent"
                                    >
                                        <div class="m-3">
                                            <span class="dropdown-text">
                                                {{
                                                    (this.selectedLanguage === country.languages[0] ||
                                                    this.selectedLanguage === country.languages[1]
                                                        ? '^country-language-modal.moreLanguage'
                                                        : '^country-language.' + this.selectedLanguage
                                                    ) | translate
                                                }}</span
                                            >
                                            <i class="fa fa-angle-down fa-lg icon-color float-right mt-1"></i>
                                        </div>
                                    </button>
                                    <div
                                        *dropdownMenu
                                        class="dropdown-menu"
                                        id="list"
                                        role="listbox"
                                        tabindex="-1"
                                        [attr.aria-activedescendant]="language"
                                    >
                                        <div
                                            role="option"
                                            [id]="language"
                                            class="dropdown-item"
                                            (click)="languageChange(language)"
                                            tabindex="0"
                                            (keydown.enter)="languageChange(language); dropdown.hide()"
                                        >
                                            <span>{{ '^country-language.' + language | translate }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="pb-1"></div>
                    </div>
                </div>
            </div>
            <div class="pb-2"></div>
        </div>
        <div class="col-12 col-md-6">
            <h3 id="countrySubTextHeader" class="sub-text-header" editable translate>^country-language-modal.countrySubTextHeader</h3>
            <p id="countrySubText" class="sub-text" editable translate>^country-language-modal.countrySubText</p>
            <div class="pb-1"></div>
            <h3 id="languageSubTextHeader" class="sub-text-header" editable translate>^country-language-modal.languageSubTextHeader</h3>
            <p id="languageSubText" class="sub-text" editable translate>^country-language-modal.languageSubText</p>
        </div>
    </div>
    <div class="row pt-1 pb-1" *ngIf="showCountryChangeWarning()">
        <div class="col-12 d-flex">
            <div>
                <img class="mr-2 change-country-icon" [src]="'../../../assets/images/icon-info.svg'" />
            </div>
            <div>
                <span id="countryChangeWarning" class="sub-text font-weight-bold align-middle" editable translate
                    >^country-language-modal.countryChangeWarning</span
                >
            </div>
        </div>
    </div>
    <div [hidden]="!showTranslationAssistanceLink" class="row pt-1 pb-1">
        <div class="col-12 col-md-6">
            <span editable [innerHTML]="'^country-language-modal.translationAssistance' | translate"></span>
        </div>
    </div>
    <div class="row pt-1 pb-1">
        <div class="col-12 col-md-6">
            <button type="button" class="btn btn-block btn-primary" (click)="submit()">
                <span id="doneButton" editable translate> ^country-language-modal.doneButton </span>
            </button>
        </div>
    </div>
    <div class="row pt-1 pb-1">
        <div class="col-12 col-md-6">
            <button type="button" class="btn btn-block btn-secondary" (click)="close()">
                <span id="cancelButton" editable translate> ^country-language-modal.cancelButton </span>
            </button>
        </div>
    </div>
</div>
