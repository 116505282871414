import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { CredentialsService } from '@app/core/authentication/credentials.service';

@Injectable({
    providedIn: 'root',
})
export class NavigationService {
    private previousUrl: string | null = null;
    private currentUrl: string | null = null;

    constructor(private router: Router, private credentials: CredentialsService) {
        this.currentUrl = this.router.url;
        this.handleBackButton();
    }

    private handleBackButton() {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                if (event.navigationTrigger === 'popstate') {
                    if (event.url === '/login/user' && this.credentials.isAuthenticated()) {
                        this.router.navigate(['/home']);
                    }
                    this.previousUrl = this.currentUrl;
                    this.currentUrl = event.url;
                } else {
                    this.previousUrl = this.currentUrl;
                    this.currentUrl = event.url;
                }
            }
        });
    }
}
