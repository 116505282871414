export enum CardNetwork {
    Visa = 'visa',
    Mastercard = 'mastercard',
    Amex = 'amex',
    Discover = 'discover',
    Other = 'other',
}

// eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
export const CardNetworkByValue = {
    visa: CardNetwork.Visa,
    mastercard: CardNetwork.Mastercard,
    amex: CardNetwork.Amex,
    discover: CardNetwork.Discover,
    other: CardNetwork.Other,
}
