<div class="custom-banner-wrapper" [ngClass]="{ 'left-align': bannerInfo?.module === 'stepper' }">
    <div
        class="custom-banner-container"
        [ngClass]="{ 'banner-success': !bannerInfo.fullWidth, 'fullWidth': bannerInfo.fullWidth }"
        *ngIf="bannerInfo && !dismissBanner && bannerInfo.module === 'kycbanner'"
    >
        <div class="custom-banner-header">
            <img
                *ngIf="bannerInfo.type === BANNER_TYPE.Error"
                [src]="bannerInfo.customLogo ? bannerInfo.customLogo : '../../../../assets/images/Icons_alert_basic_white.svg'"
            />
            <img
                *ngIf="bannerInfo.type === BANNER_TYPE.Warning"
                [src]="bannerInfo.customLogo ? bannerInfo.customLogo : '../../../../assets/images/Icons_alert_basic_white.svg'"
            />
            <img
                *ngIf="bannerInfo.type === BANNER_TYPE.Info"
                [src]="bannerInfo.customLogo ? bannerInfo.customLogo : '../../../../assets/images/icon-success.svg'"
            />
            <img
                *ngIf="bannerInfo.type === BANNER_TYPE.Success"
                [src]="bannerInfo.customLogo ? bannerInfo.customLogo : '../../../../assets/images/icon-success.svg'"
            />
            <img
                *ngIf="bannerInfo.type === BANNER_TYPE.Default"
                [ngClass]="{ 'd-none': !bannerInfo.customLogo }"
                [src]="bannerInfo.customLogo"
            />
        </div>
        <div class="custom-banner-body" [ngClass]="{ 'fullWidth': !(bannerInfo.additionalLink || bannerInfo.button_text) }">
            <ng-container>
                {{ bannerInfo.primaryText | translate }}
                <div class="custom-banner-body-error-message" *ngIf="bannerInfo.secondaryText">
                    {{ bannerInfo.secondaryText | translate }}
                </div>
            </ng-container>
        </div>
        <div class="custom-banner-footer" *ngIf="bannerInfo.additionalLink || bannerInfo.button_text">
            <span class="custom-banner-tips" (click)="onAdditionalLinkClick()" *ngIf="bannerInfo.additionalLink">
                {{ bannerInfo.additionalLink | translate }}
                <i class="fa fa-refresh fa-spin" *ngIf="showSpinnerTips"></i>
                <i class="fa fa-angle-right fa-lg icon-color" *ngIf="!showSpinnerTips"></i>
            </span>
            <button
                class="custom-banner-finish-activation"
                (click)="onPrimaryButtonClick()"
                [disabled]="showSpinnerActivation"
                *ngIf="bannerInfo.button_text"
            >
                {{ bannerInfo.button_text | translate }}
                <i class="fa fa-refresh fa-spin ml-1" *ngIf="showSpinnerActivation"></i>
            </button>
        </div>
        <div class="dismissBanner" *ngIf="bannerInfo.cancelable" (click)="onDismissBanner()">
            <i class="fa fa-close"></i>
        </div>
    </div>

    <div
        class="custom-banner-container stepper"
        *ngIf="
            bannerInfo && !dismissBanner && bannerInfo.module === 'stepper' && bannerInfo.isNoRegistration && bannerInfo.isAddressNotPresent
        "
    >
        <div class="register-card-online-wrapper">
            <div class="register-card-online__stepper">
                <i [class]="bannerInfo.fontAwesomeIcon"></i>
                <h1 class="stepper-border"></h1>
                <span class="dot"></span>
            </div>
            <div class="register-card-online__actions">
                <div class="register-card-online__actions_active">{{ bannerInfo.primaryText | translate }}</div>
                <div class="register-card-online__actions_register">
                    <a (click)="onRegCardForShopping()"
                        >{{ bannerInfo.secondaryText | translate }}
                        <app-custom-tooltip [tooltipConfigs]="tooltipConfigs"></app-custom-tooltip>
                    </a>
                    <!-- DO NOT CHANGE THIS CLASS -->
                    <i class="fa fa-info-circle ml-2" (click)="onToggleRegCardHelp(toggleRegCardIcon)"> </i>
                </div>
            </div>
        </div>
    </div>
    <div class="custom-banner-container stepper" *ngIf="bannerInfo && bannerInfo.module === 'stepper' && bannerInfo.isnotify">
        <span
            class="custom-banner-container__expired-card"
            id="cardExpiredError"
            editable
            [innerHTML]="'^card-details.expiredCard' | translate | safeHtml"
        ></span>
    </div>
    <div
        class="custom-banner-container fullWidth notify-banner"  *ngIf="bannerInfo && !dismissBanner && bannerInfo.module === 'cardactivationbanner'">
        <div class="custom-banner-header notify-banner-icon">
            <img
                *ngIf="bannerInfo.type === BANNER_TYPE.Success"
                [src]="bannerInfo.customLogo ? bannerInfo.customLogo : '../../../../assets/images/icon-success.svg'"
            />
        </div>
        <div class="custom-banner-body fullWidth notify-banner-text" >
            <span
                id="cardActivationBannerMessage"
                editable
                [innerHTML]="'^custom-banner.card-details.cardActivationMessage' | translate | safeHtml"
            ></span>
        </div>
        <div class="dismissBanner" *ngIf="bannerInfo.cancelable" (click)="onDismissBanner()">
            <i class="fa fa-close mt-21px" ></i>
        </div>
    </div>

    <div
    class="custom-banner-container fullWidth notify-banner"  *ngIf="bannerInfo && !dismissBanner && bannerInfo.module === 'rlSelfServiceConfirmationBanner'">
    <div class="custom-banner-header notify-banner-icon">
        <img
            *ngIf="bannerInfo.type === BANNER_TYPE.Success"
            [src]="bannerInfo.customLogo ? bannerInfo.customLogo : '../../../../assets/images/icon-success.svg'"
        />
    </div>
    <div class="custom-banner-body fullWidth notify-banner-text" >
        <span 
            id="rlSelfServiceBannerMessage"
            editable
            [innerHTML]="'^custom-banner.card-details.rlSelfServiceBannerMessage' | translate | safeHtml"
        ></span>
    </div>
    <div class="dismissBanner" *ngIf="bannerInfo.cancelable" (click)="onDismissBanner()">
        <i class="fa fa-close mt-21px" ></i>
    </div>
</div>
</div>
