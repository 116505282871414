import { Injectable, Inject } from '@angular/core';
import { BehaviorSubject, combineLatest, NEVER, Observable, of, Subject } from 'rxjs';
import { CardHttpService } from '@app/services/card/http/card-http.service';
import { filter, map, switchMap, shareReplay, distinctUntilChanged, skip } from 'rxjs/operators';
import { CardInformation } from '@app/services/card/models/card-information.model';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { CredentialsService } from '@app/core/authentication/credentials.service';
import { ProductInformation } from '@app/services/card/models/product-information.model';
import { CardBundle } from '@app/services/card/models/card-bundle';
import { SESSION_STORAGE } from '@app/core/services/session-storage.service';
import { TranslateService } from '@ngx-translate/core';
import { CardholderFee } from '@app/services/card/models/cardholder-fee.model';
import { ENVIRONMENT } from '@app/core/services/environment.service';
import { KycStatus } from '../enum/kyc-status.enum';
import { KycService } from './kyc.service';
import { RmsSessionService } from '@app/services/auth/rms-session.service';
import { FlowName, FlowType } from '../enum/rms.enum';

const CARD_DATA_KEY = 'cdb';
const PROXYNUMBER_KEY = 'PXYK';

@Injectable({
    providedIn: 'root',
})
export class CardDataService {
    private readonly cards$$ = new BehaviorSubject<CardInformation[]>([]);
    private readonly selected$$ = new BehaviorSubject<CardInformation>(null);
    private readonly showHidden$$ = new BehaviorSubject<boolean>(false);
    private readonly cards = new Map<string, CardInformation>();
    private readonly products = new Map<string, ProductInformation>();
    private pendingCardActivation: CardBundle;

    public cardDataLoaded = new Subject<CardInformation>();
    public cardsDataLoaded = new BehaviorSubject<boolean>(false);
    public isCardActivationBanner = new BehaviorSubject<boolean>(false);
    public isRLSelfServiceBanner = new BehaviorSubject<boolean>(false);

    public newCard = false;
    private cardBalCount = 5;

    constructor(
        private credentials: CredentialsService,
        private cardHttp: CardHttpService,
        private router: Router,
        private route: ActivatedRoute,
        translate: TranslateService,
        @Inject(SESSION_STORAGE) private sessionStorage: Storage,
        @Inject(ENVIRONMENT) private environment: any,
        private kycService: KycService,
        private rmsSessionService: RmsSessionService,
    ) {
        combineLatest([this.proxyCardNumber$, this.cards$]).subscribe(([proxyCardNumber]) => {
            this.selectCard(proxyCardNumber);
        });
        this.credentials
            .asObservable()
            .pipe(skip(1))
            .subscribe((current) => {
                if (!current) this.clear();
            });

        translate.onLangChange
            .pipe(
                switchMap(async () => {
                    if (this.credentials.isAuthenticated() && this.products.size > 0) {
                        // const rmsSessionId = await this.onGetRMSsessionData();
                        return this.cardHttp.getProductText(Array.from(this.products.keys()));
                    } else {
                        return NEVER;
                    }
                }),
            )
            .subscribe((productTexts: ProductInformation[]) => {
                for (const objVal of Object.keys(productTexts)) {
                    const productText = productTexts[objVal];
                    const product = this.products.get(productText?.productIdentifier);
                    this.products.set(product?.productIdentifier, {
                        ...product,
                        productName: productText?.productName,
                        productDetail: productText?.productDetail,
                        disclaimer: productText?.disclaimer,
                        fees: productText?.fees
                            ? productText?.fees.map(
                                (fee) =>
                                    new CardholderFee({
                                        ...product.fees.find((f) => f.id === fee.id),
                                        description: fee.description,
                                    }),
                            )
                            : [],
                    });
                }
                for (const card of this.cards.values()) {
                    this.cards.set(card.proxyCardNumber, {
                        ...card,
                        product: this.products.get(card.productIdentifier),
                    });
                }
                this.emitCards();
                this.emitSelected();
            });
    }

    private proxyCardNumber$ = this.router.events.pipe(
        filter((e) => e instanceof NavigationEnd),
        map(() => {
            let r = this.route;
            while (r.firstChild) {
                r = r.firstChild;
            }
            return r;
        }),
        filter((r: ActivatedRoute) => r.outlet === 'primary'),
        map((r: ActivatedRoute) => {
            while (r) {
                let identifier = '';
                if (r.snapshot.paramMap.has('identifier')) {
                    identifier = r.snapshot.paramMap.get('identifier');
                }
                if (r.snapshot.queryParamMap.has('identifier')) {
                    identifier = r.snapshot.queryParamMap.get('identifier');
                }

                if (identifier) {
                    const card = Array.from(this.cards.values()).filter(
                        (item) => item.identifier === identifier || item.proxyCardNumber === identifier,
                    )[0];
                    return card.proxyCardNumber;
                }
                r = r.parent;
            }
            return this.getProxyCardNumberFromSession();
        }),
        distinctUntilChanged(),
    );

    readonly cards$ = combineLatest([this.cards$$, this.showHidden$$]).pipe(
        map(([cards, showHidden]) => cards.filter((card) => showHidden || !card.isHidden)),
        shareReplay(1),
    );

    readonly selected$ = this.selected$$.pipe(shareReplay(1));

    get snapshot(): {
        selected: CardInformation;
        cards: CardInformation[];
    } {
        return {
            selected: this.selected$$.value,
            cards: this.cards$$.value,
        };
    }

    readonly previous$ = combineLatest([this.cards$, this.selected$]).pipe(
        map(([cards, selected]) => {
            if (selected === cards[0]) {
                return cards[cards.length - 1];
            } else {
                return cards[cards.indexOf(selected) - 1];
            }
        }),
        shareReplay(1),
    );

    readonly next$ = combineLatest([this.cards$, this.selected$]).pipe(
        map(([cards, selected]) => {
            if (selected === cards[cards.length - 1]) {
                return cards[0];
            } else {
                return cards[cards.indexOf(selected) + 1];
            }
        }),
        shareReplay(1),
    );

    get pending$(): Observable<CardBundle> {
        const response = of(this.pendingCardActivation);
        this.pendingCardActivation = null;
        return response;
    }

    get showHidden(): boolean {
        return this.showHidden$$.value;
    }

    set showHidden(value: boolean) {
        this.showHidden$$.next(value);
    }

    setPending(bundle: CardBundle) {
        this.pendingCardActivation = bundle;
    }

    clear(): void {
        this.products.clear();
        this.cards.clear();
        this.emitCards();
        this.emitSelected();
        this.sessionStorage.removeItem(CARD_DATA_KEY);
    }

    async load(cardBundle?: CardBundle): Promise<void> {
        if (!this.credentials.isAuthenticated()) {
            throw Error('not-authenticated');
        }

        if (!cardBundle && this.credentials.current.isRegisteredEndUser()) {
            cardBundle = await this.cardHttp.getCardBundle({
                userUuid: this.credentials.current.userUuid,
                rmsSessionId: await this.onGetRMSsessionData(),
            });
        }

        if (!cardBundle && this.credentials.current.isAnonymousCardholder()) {
            cardBundle = await this.cardHttp.getCardBundle({
                proxyCardNumber: this.credentials.current.proxyCardNumber,
                rmsSessionId: await this.onGetRMSsessionData(),
            });
        }

        if (!cardBundle) {
            cardBundle = this.getFromSessionStorage();
        }

        if (cardBundle) {
            for (const product of cardBundle.products || []) {
                const maritzEYProducts = ['EYDCPD', 'EYDCRD'];
                product.showAsPoints = maritzEYProducts.indexOf(product.productCode) > -1;
                this.products.set(product.productIdentifier, product);
            }
            for (const card of this.cards.values()) {
                this.cards.set(card.proxyCardNumber, {
                    ...card,
                    product: undefined,
                });
            }
            for (const bundleCard of cardBundle?.cards) {
                const tempMap = new Map();

                for (const bundleCard of cardBundle?.cards) {
                    tempMap.set(bundleCard.proxyCardNumber, {
                        ...this.cards.get(bundleCard.proxyCardNumber),
                        ...bundleCard,
                    });
                }
                
                for (const [key, value] of this.cards) {
                    if (!tempMap.has(key)) {
                        tempMap.set(key, value); // Preserve existing order
                    }
                }
                
                // Clear the existing Map and refill it (avoiding reassignment)
                this.cards.clear();
                tempMap.forEach((value, key) => this.cards.set(key, value));
                

                // this.cards.set(bundleCard.proxyCardNumber, {
                //     ...this.cards.get(bundleCard.proxyCardNumber),
                //     ...bundleCard,
                // });
            }
            this.saveToSessionStorage({
                cards: Array.from(this.cards.values()),
                products: Array.from(this.products.values()),
            });

            for (const card of this.cards.values()) {
                this.cards.set(card.proxyCardNumber, {
                    ...card,
                    product: this.products.get(card.productIdentifier),
                });
            }

            this.emitCards();
            this.emitSelected();
        }
    }

    get(proxyCardNumber: string): CardInformation {
        return this.cards.get(proxyCardNumber);
    }

    get getShowCardBalCount(): number {
        return this.cardBalCount;
    }
    async getCardBalance(card: CardInformation, rmsId: any) {
        return await this.getBalance(card.proxyCardNumber, rmsId);
    }

    getCardByIdentifier(identifier: string): CardInformation {
        return Array.from(this.cards.values()).filter((item) => item.identifier === identifier || item.proxyCardNumber === identifier)[0];
    }

    getIdentifierByProxyCardNumber(proxyCardNumber: string): string {
        return this.get(proxyCardNumber).identifier;
    }

    private selectCard(proxyCardNumber: string) {
        const card = this.cards.get(proxyCardNumber);
        if (this.selected$$.value !== card) {
            this.selected$$.next(card);
        }
    }

    private getFromSessionStorage(): CardBundle {
        const cardString = this.sessionStorage.getItem(CARD_DATA_KEY);
        if (!cardString) {
            return;
        }
        return JSON.parse(decodeURIComponent(escape(atob(cardString)))) as CardBundle; // Unicode support
    }

    private saveToSessionStorage(bundle: CardBundle) {
        const bundleString = JSON.stringify(bundle);
        this.sessionStorage.setItem(CARD_DATA_KEY, btoa(unescape(encodeURIComponent(bundleString)))); // Unicode support
    }

    async removeCard(proxyCardNumber: string) {
        if (!this.credentials.isAuthenticated() || !this.credentials.current.isRegisteredEndUser()) {
            throw Error('no-current-user');
        }
        const userUuid = this.credentials.current.userUuid;
        const rmsSessionId = await this.onGetRMSsessionData();
        await this.cardHttp.removeUserCard({ proxyCardNumber, userUuid }, rmsSessionId);
        this.cards.set(proxyCardNumber, {
            ...this.cards.get(proxyCardNumber),
            isHidden: true,
        });

        const cardBundle = this.getFromSessionStorage();
        cardBundle.cards.find((x) => x.proxyCardNumber === proxyCardNumber).isHidden = true;
        this.saveToSessionStorage(cardBundle);

        this.emitCards();
        this.selectCard(undefined);
    }

    async updateCard(cardData: Partial<CardInformation>) {
        let card = this.cards.get(cardData.proxyCardNumber);
        if (!card) card = this.selected$$.value;
        if (!card) {
            throw Error('Unable to identify card for update');
        }
        this.cards.set(card.proxyCardNumber, {
            ...card,
            ...cardData,
        });
        this.saveToSessionStorage({
            cards: Array.from(this.cards.values()),
            products: Array.from(this.products.values()),
        });
        this.emitCards();
        if (this.selected$$.value) {
            this.selectCard(this.selected$$.value.proxyCardNumber);
        }
    }

    async refreshBalance(proxyCardNumber?: string, rmsId?: any) {
        if (!proxyCardNumber && this.selected$$.value && !this.selected$$.value.isHidden) {
            proxyCardNumber = this.selected$$.value.proxyCardNumber;
        }
        if (!proxyCardNumber) {
            throw Error('Unable to identify card for balance update');
        }
        this.getBalance(proxyCardNumber, rmsId);
    }

    async getBalance(proxyCardNumber: string, rmsId?: any) {
        const balance = await this.cardHttp.getBalance(proxyCardNumber, rmsId);
        this.cards.set(proxyCardNumber, {
            ...this.cards.get(proxyCardNumber),
            currentBalance: {
                value: balance.balance,
                currency: balance.currency,
                lastUpdate: new Date(),
            },
        });
        this.emitCards();
        this.emitSelected();
    }

    private emitCards() {
        this.cards$$.next(Array.from(this.cards.values()));
        this.cardsDataLoaded.next(true);
    }

    private emitSelected() {
        if (!this.selected$$.value) return;
        this.selectCard(this.selected$$.value.proxyCardNumber);
        this.cardDataLoaded.next(this.selected$$.value);
    }

    saveProxyCardNumberToSession(proxyCardNumber) {
        this.sessionStorage.setItem(PROXYNUMBER_KEY, proxyCardNumber);
    }

    getProxyCardNumberFromSession() {
        return this.sessionStorage.getItem(PROXYNUMBER_KEY);
    }

    handleCardPageRedirect(card: CardInformation, isDigitalRetrival = false, isShowIdentifier = false) {
        this.saveProxyCardNumberToSession(null);
        if (card) {
            // Remove when we switch over to new card detail page
            if (this.environment.useNewCardDetail === true) {
                this.saveProxyCardNumberToSession(card.proxyCardNumber);
                if (card.product && card.product.pageurl) {
                    if (isShowIdentifier) {
                        this.router.navigate([`page/${card.product.pageurl}/${card.identifier}`]);
                    } else {
                        this.router.navigate([`page/${card.product.pageurl}`]);
                    }
                } else {
                    isDigitalRetrival
                        ? this.router.navigate(['card-details', card.proxyCardNumber])
                        : this.router.navigate(['card-details']);
                }
            } else {
                isDigitalRetrival
                    ? this.router.navigate(['card-details', card.proxyCardNumber])
                    : this.router.navigate(['card-features', card.proxyCardNumber]);
            }
        }
    }

    isSlimmedVersion(card?: CardInformation) {
        card = card ? card : this.snapshot.selected;
        if (!card) {
            return;
        }
        return !card?.activated && card?.enableKYC && this.kycService._currentKyc?.kycStatus !== KycStatus.PASS;
    }

    getDownloadFile(termsFileUrl?: string) {
        this.cardHttp.getDownloadFile(termsFileUrl);
    }

    async onGetRMSsessionData() {
        const flowType = {
            type: FlowType.OTHER,
            name: FlowName.CARDS_DATA,
        };
        return this.rmsSessionService?.rmsSessionData?.rmsId ||
            (await this.rmsSessionService.getRmsData(flowType))?.rmsId;
    }

    adobePushEvent(card?: CardInformation, userUuid?: string) {
        const { proxyCardNumber, currentBalance:{ currency, value} } = card || {};
        (window as any).pushEventPayload('load', 'web.webinteraction.onload', 'ViewCardDetails', this.router.url, 'viewed', location.origin + this.router.url,
            {
                currency: currency,
                balance: value,
                proxyCardNumber: proxyCardNumber,
                userUuid: userUuid
            });
    }
}
