import { SelfMappingObject, Field } from '@app/shared/models/self-mapping-object';
import { FeeType } from '@app/shared/enum/fee-type.enum';

export class CardholderFee extends SelfMappingObject<CardholderFee> {
    @Field() id: number;
    @Field() feeType: FeeType;
    @Field() description: string;
    @Field() amountCents: number;
    @Field() amountPercent: number;
}
