import { ContentEditorFileUploadService } from './../../services/content-editor-file-upload.service';
import { Component, OnInit, ViewContainerRef } from '@angular/core';

@Component({
    selector: 'app-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent implements OnInit {
    imageObj: File;
    imageUrl: string;

    constructor(private imageUploadService: ContentEditorFileUploadService) {
        console.log('upload comp. loaded');
    }

    onImagePicked(event: Event): void {
        const FILE = (event.target as HTMLInputElement).files[0];
        this.imageObj = FILE;
    }

    onImageUpload() {
        const imageForm = new FormData();
        imageForm.append('image', this.imageObj);
        // this.imageUploadService.imageUpload(imageForm).subscribe(res => {
        //     this.imageUrl = res['image'];
        // });
    }
    ngOnInit() {}
}
