import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: 'input[numbersAndSlashOnly]'
})
export class NumbersAndSlashOnlyDirective {

  constructor(private _el: ElementRef) { }

  @HostListener('input', ['$event'])
  onKeypress(event) {
      this.inputHandler(event);
  }


  private inputHandler(event) {
    // Handler for the inputs
    
    const initialValue = this._el.nativeElement.value;
    this._el.nativeElement.value = initialValue.replace(/[^0-9]*/g, '');

    if(initialValue.includes('/')){
      if(initialValue.split('').filter(x=>x==='/').length >1){
        this._el.nativeElement.value = initialValue.split('').map((x,i)=>{
          if(i==2 || (i!==2 && x!=='/')) return x
        }).join('');
      }else{

        if(initialValue.split('').indexOf('/') == 2) this._el.nativeElement.value = initialValue.replace(/[^0-9/]*/g, ''); 
        else this._el.nativeElement.value = initialValue.replace(/[^0-9]*/g, '');
          
      }
    }
    if (initialValue !== this._el.nativeElement.value) {
        event.stopPropagation();
    }
}

}
