import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CommonHttpService } from '../http/common-http.service';
import { AddressModel } from '../models/address.model';

@Injectable({
    providedIn: 'root',
})
export class AddressService {
    enteredAddress: AddressModel;
    validatedAddress: AddressModel;
    private readonly addressValidation$$ = new BehaviorSubject<string>(null);
    readonly addressValidation$ = this.addressValidation$$.pipe();

    constructor(private commonHttp: CommonHttpService) {}

    get addressValidation(): string {
        return this.addressValidation$$.value;
    }

    set addressValidation(value: string) {
        this.addressValidation$$.next(value);
    }

    async verifyAddress(): Promise<boolean> {
        try {
            this.validatedAddress = await this.commonHttp.verifyAddress(this.enteredAddress);
            // melissa data wrapper is concatenating some values and returning them as address line 2 for some reason
            // dumb check to prevent duplicate lines
            const concat = this.validatedAddress.city + ' ' + this.validatedAddress.region + ' ' + this.validatedAddress.postalCode;
            if (this.validatedAddress.addressLine2 === concat) {
                this.validatedAddress.addressLine2 = null;
            }
            let result = true;
            if (
                this.validatedAddress.addressLine1 !== null &&
                (this.enteredAddress.addressLine1 !== this.validatedAddress.addressLine1 ||
                    this.enteredAddress.addressLine2 != this.validatedAddress.addressLine2 ||
                    this.enteredAddress.city !== this.validatedAddress.city ||
                    this.enteredAddress.postalCode !== this.validatedAddress.postalCode ||
                    this.enteredAddress.country.isoCode2 !== this.validatedAddress.country.isoCode2 ||
                    this.enteredAddress.region !== this.validatedAddress.region)
            ) {
                result = false;
            }
            return result;
        } catch (e) {
            // if error occurs we don't want to prevent user from moving forward with whatever they are doing.
            return true;
        }
    }

    public getAutoCompleteAddressList(addressLine1: string, countryIsoCode: string): Observable<AddressModel[]> {
        return this.commonHttp.getAutoCompleteAddressList(addressLine1, countryIsoCode);
    }
    async getmaskedData(proxyCardNumber: string): Promise<any> {
        return await this.commonHttp.getMaskedData(proxyCardNumber);
    }
}
