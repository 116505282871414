import {
    ContentEditorModel,
    ContentEditorRequestModel,
    InitializeContentModel,
    Culture,
    ResourceFilterOptionModel,
    FilterValue,
    ResourceFilterModel,
} from '@app/shared/models/content-editor.model';
import { NGXLogger } from 'ngx-logger';
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseResponse } from '@app/shared/models/base-response-model';
import { ENVIRONMENT } from '@app/core/services/environment.service';
import { Country } from '../models/country-model';
import { Region } from '../models/region-model';
import { tap, map, catchError } from 'rxjs/operators';
import { firstValueFrom, Observable, of } from 'rxjs';
import { ProhibitedCountryListModel } from '../models/prohibited-country-list.model';
import { CountrySettingModel } from '../models/country-setting.model';
import * as moment from 'moment';
import { AddressModel } from '../models/address.model';
import { AmericanAddress } from '../models/american-address.model';
import { InternationalAddress } from '../models/international-address.model';
import { ApplicationSettings } from '../models/application-config.model';
import { NotificationBannerModel } from '../models/notification-banner.model';
import { MessageChannelType } from '../enum/message-channel-types.enum';
import { ContactUsCountryModel } from '../models/contactus_countries.model';
import { IContactUsReasons } from '../models/contactus_reasons.model';
import { DeepLinkResponseModel } from '../models/deep-link-response.model';
import { ContactUsCountryPaginationModel } from '../models/contactus_countries_pagination.model';
import { UserKYC } from '../models/user-kyc.model';

@Injectable({
    providedIn: 'root',
})
export class CommonHttpService {
    constructor(private http: HttpClient, private logger: NGXLogger, @Inject(ENVIRONMENT) private environment) {}

    getCountryList(param: any): Observable<Country[]> {
        type CountryResponse = BaseResponse & { countries: Country[] };
        return this.http.post<CountryResponse>(this.environment.nodeBaseURL + 'common/getCountryList', param).pipe(
            map((response) => {
                if (response.success) {
                    return response.countries;
                } else {
                    this.logger.error('Server error getting country list.', response.errors);
                    throw Error('error-getting-country-list');
                }
            }),
        );
    }

    getRegionList(country: Partial<Country>): Observable<Region[]> {
        type RegionResponse = BaseResponse & { regions: Region[] };
        return this.http.post<RegionResponse>(this.environment.nodeBaseURL + 'common/getRegionList', country).pipe(
            map((response) => {
                if (response.success) {
                    return response.regions;
                } else {
                    this.logger.error('Server error getting region list.', response.errors);
                    throw Error('error-getting-region-list');
                }
            }),
        );
    }

    async getCountrySetting(): Promise<CountrySettingModel[]> {
        const apiRequest$ = this.http.post<BaseResponse>(this.environment.nodeBaseURL + 'common/getCountrySetting', {});
        const response = await firstValueFrom(apiRequest$);
        if (response.success) {
            return response.result;
        } else {
            throw Error('error-getting-country-settings');
        }
    }

    /*Content Editor Specific*/

    getContentEditorData(requestModel: ContentEditorRequestModel): Observable<ContentEditorModel[]> {
        return this.http.post(this.environment.nodeBaseURL + 'resource/getContentEditorData', requestModel).pipe(
            map((res: any) => {
                return res.resource.map(
                    (r) =>
                        new ContentEditorModel({
                            cultureName: r.isoCode,
                            name: r.name,
                            value: r.value.replace(/©20[0-9]{2}/g, '©' + moment().format('YYYY')),
                            setName: r.setName,
                            filter: r.filterList,
                        }),
                );
            }),
            catchError((error) => {
                this.logger.error('Error:' + error.data);
                const responseModel: ContentEditorModel[] = [];
                return of(responseModel);
            }),
        );
    }

    saveContentEditorData(requestModel: ContentEditorModel[]) {
        const model = {
            resource: requestModel.map((x) => {
                return {
                    isoCode: x.cultureName,
                    setName: x.setName,
                    name: x.name,
                    value: x.value,
                    filterList: x.filter,
                };
            }),
        };
        const apiRequest$ = this.http.post(this.environment.nodeBaseURL + 'resource/saveContentEditorData', model);
        const response = firstValueFrom(apiRequest$);
    }

    uploadFile(formData: FormData) {
        const apiRequest$ = this.http.post(this.environment.nodeBaseURL + 'resource/upload', formData);
        return firstValueFrom(apiRequest$);
    }

    initContentEditor(isoCode: string): Observable<InitializeContentModel> {
        return this.http.get(this.environment.nodeBaseURL + 'resource/initContentEditor?isoCode=' + isoCode).pipe(
            map((response: any) => {
                return new InitializeContentModel({
                    selectedFilterList: response.currentFilters,
                    connectionInformationModel: response.connectionInformation,
                });
            }),
            catchError((error) => {
                this.logger.error('Error:' + error.data);
                const responseModel: InitializeContentModel = null;
                return of(responseModel);
            }),
        );
    }

    getSegments(isoCode: string): Observable<ResourceFilterOptionModel[]> {
        return this.http.get(this.environment.nodeBaseURL + 'resource/getSegments?isoCode=' + isoCode).pipe(
            map((response: any) => {
                return response.segmentList as ResourceFilterOptionModel[];
            }),
            catchError((error) => {
                this.logger.error('Error:' + error.data);
                const responseModel: ResourceFilterOptionModel[] = [];
                return of(responseModel);
            }),
        );
    }

    getCurrentSegment(isoCode: string): Observable<FilterValue[]> {
        return this.http.get(this.environment.nodeBaseURL + 'resource/getCurrentSegment?isoCode=' + isoCode).pipe(
            map((response: any) => {
                return response.currentSegment as FilterValue[];
            }),
            catchError((error) => {
                this.logger.error('Error:' + error.data);
                const responseModel: FilterValue[] = [];
                return of(responseModel);
            }),
        );
    }

    async getAppSettings(url: string): Promise<ApplicationSettings> {
        return await firstValueFrom(
            this.http.post<ApplicationSettings>(this.environment.nodeBaseURL + 'common/getAppSettings?url=' + url, ''),
        );
    }

    async getResourceFilterTypeList(isoCode): Promise<ResourceFilterModel[]> {
        const date = new Date();
        const apiRequest$ = this.http.get<BaseResponse & { resourceFilterTypeList: ResourceFilterModel[] }>(
            this.environment.nodeBaseURL + 'resource/getResourceFilterTypeList?isoCode=' + isoCode + '&timestamp=' + date.getTime(),
        );
        const response = await firstValueFrom(apiRequest$);

        if (!response.success) {
            this.logger.error('Server error getting resource filter type list', response.errors);
            throw Error('error-getting-resource-filter-type');
        }

        return response.resourceFilterTypeList;
    }

    async getProductCodeId(clientProgramId: number, productCode?: string): Promise<number> {
        const apiRequest$ = this.http.get<BaseResponse & { productClientProgramId: number }>(
            this.environment.nodeBaseURL + `resource/getProductCodeId?clientProgramId=${clientProgramId}&productCode=${productCode}`,
        );
        const response = await firstValueFrom(apiRequest$);

        return response.success ? response.productClientProgramId : -1;
    }

    async getProhibitedCountryList(): Promise<ProhibitedCountryListModel> {
        const apiRequest$ = this.http.get<BaseResponse & ProhibitedCountryListModel>(
            this.environment.nodeBaseURL + 'common/getProhibitedCountryList',
            {},
        );
        const response = await firstValueFrom(apiRequest$);
        if (response.success) {
            return response;
        } else {
            throw Error('error-getting-prohibited-countries');
        }
    }

    async getTenantServiceNumber(): Promise<string> {
        const apiRequest$ = this.http.get<BaseResponse & { phoneNumber: string }>(
            this.environment.nodeBaseURL + 'common/getTenantPhoneNumber',
            {},
        );
        const response = await firstValueFrom(apiRequest$);

        return response.phoneNumber;
    }
    async getMaskedData(proxyCardNumber: string): Promise<any> {
        const apiRequest$ = this.http.get<BaseResponse>(
            this.environment.nodeBaseURL + `card/getMaskedDemographics?proxyCardNumber=${proxyCardNumber}`,
        );
        const response = await firstValueFrom(apiRequest$);
        if (response.success) {
            return response;
        }
    }

    getAutoCompleteAddressList(addressLine1: string, countryIsoCode: string): Observable<AddressModel[]> {
        type AddressResponse = BaseResponse & { addressList: AddressModel[] };
        return this.http
            .post<AddressResponse>(this.environment.nodeBaseURL + 'common/autoCompleteInternationalAddress', {
                'address1': addressLine1,
                'country': countryIsoCode,
            })
            .pipe(
                tap((response) => {
                    if (!response.success) {
                        throw Error('error-getting-autocomplete-list');
                    }
                }),
                map((response) => {
                    return response.addressList as AddressModel[];
                }),
            );
    }
    async verifyAddress(address: AddressModel): Promise<AddressModel> {
        try {
            const responseAddress = new AddressModel();
            if (address.country.isoCode2 === 'US') {
                const postalCodeSplit = address.postalCode.split('-');
                const apiRequest$ = this.http.post<BaseResponse & AmericanAddress>(
                    this.environment.nodeBaseURL + 'common/verifyAmericanAddress',
                    {
                        address1: address.addressLine1,
                        address2: address.addressLine2,
                        city: address.city,
                        state: address.region,
                        postalCode5: postalCodeSplit[0],
                        postalCode4: postalCodeSplit.length > 1 ? postalCodeSplit[1] : null,
                        country: address.country.isoCode2,
                    },
                );
                const response = await firstValueFrom(apiRequest$);
                responseAddress.addressLine1 = response.americanAddress.address1;
                responseAddress.addressLine2 = response.americanAddress.address2 ? response.americanAddress.address2 : null;
                responseAddress.city = response.americanAddress.city;
                responseAddress.postalCode = response.americanAddress.postalCode5 + '-' + response.americanAddress.postalCode4;
                responseAddress.region = response.americanAddress.state;
                responseAddress.country = {
                    isoCode2: 'US',
                    isoNumber: 1,
                    countryConfiguration: null,
                    isoCode3: 'USA',
                    phonecode: null,
                    name: response.americanAddress.country,
                    shippingAllowed: false,
                };
            } else {
                const apiRequest$ = this.http.post<BaseResponse & InternationalAddress>(
                    this.environment.nodeBaseURL + 'common/verifyInternationalAddress',
                    {
                        streetAddress1: address.addressLine1,
                        streetAddress2: address.addressLine2,
                        streetAddress3: null,
                        streetAddress4: null,
                        city: address.city,
                        postalCode: address.postalCode,
                        state: address.region,
                        country: address.country.isoCode2,
                    },
                );
                const response = await firstValueFrom(apiRequest$);
                responseAddress.addressLine1 = response.internationAddress.streetAddress1;
                responseAddress.addressLine2 = response.internationAddress.streetAddress2
                    ? response.internationAddress.streetAddress2
                    : null;
                responseAddress.city = response.internationAddress.city;
                responseAddress.postalCode = response.internationAddress.postalCode;
                responseAddress.region = response.internationAddress.administrativeArea;
                responseAddress.country = {
                    isoCode2: response.internationAddress.countryISO3166_1_Alpha2,
                    isoNumber: Number(response.internationAddress.countryISO3166_1_Numeric),
                    countryConfiguration: null,
                    phonecode: null,
                    isoCode3: response.internationAddress.countryISO3166_1_Alpha3,
                    name: response.internationAddress.countryName,
                    shippingAllowed: false,
                };
            }
            return responseAddress;
        } catch (e) {
            throw e;
        }
    }

    async getNotificationBanners(isoCode: string): Promise<NotificationBannerModel[]> {
        const apiRequest$ = this.http.post<BaseResponse & NotificationBannerModel[]>(
            this.environment.nodeBaseURL + 'notification/getNotificationBanners',
            { isoCode },
        );
        const response = await firstValueFrom(apiRequest$);

        if (!response.success) {
            this.logger.error('Server error getting notification banner details', response.errors);
            throw Error('error-getting-notification-banner-details');
        }

        return response.bannerDetails;
    }
    async getUserKycInfo(): Promise<any> {
        const apiRequest$ = this.http.post<BaseResponse>(this.environment.nodeBaseURL + 'login/getUserKycInfo', '');
        const response = await firstValueFrom(apiRequest$);

        if (!response.success) {
            this.logger.error('Server error updating the credential Token', response.errors);
            throw Error('error-updating-token');
        }
        return response;
    }
    async generateDeepLink(requestor: string, account: string, verification: string, action: string) {
        const apiRequest$ = this.http.post<DeepLinkResponseModel>(this.environment.nodeBaseURL + 'common/generateDeepLink', {
            requestor,
            account,
            verification,
            action,
        });
        const response = await firstValueFrom(apiRequest$);
        if (!response.success) {
            this.logger.error('Server error generating a deep link', response.errors);
            throw Error('error-generating-deep-link');
        }
        return response;
    }

    async sendSMS(phoneNumber: string, smsBody: string) {
        const apiRequest$ = this.http.post<BaseResponse>(this.environment.nodeBaseURL + 'common/sendMessage', {
            message: smsBody,
            userId: phoneNumber, // Need to check with David on this Payload
            channelType: MessageChannelType.SMS,
        });
        const response = await firstValueFrom(apiRequest$);
        if (!response.success) {
            this.logger.error('Server error sending the SMS', response.errors);
            throw Error('error-sending-sms');
        }
        return response;
    }

    async getCountactUsCountries(limit: number): Promise<ContactUsCountryPaginationModel> {
        const apiRequest$ = this.http.get<BaseResponse & ContactUsCountryPaginationModel>(
            this.environment.nodeBaseURL + `common/getContactUsCountries?limit=${limit}`,
        );
        const response = await firstValueFrom(apiRequest$);
        if (response.success) {
            return response.result;
        } else {
            throw Error('error-getting-contactus-countries');
        }
    }

    async getContactUsReasons(typeKey: string) {
        const date = new Date().getTime();
        const apiRequest$ = this.http.get<BaseResponse & IContactUsReasons>(
            this.environment.nodeBaseURL + 'common/getContactUsReasons?typeKey=' + typeKey + '&date=' + date,
        );
        const response = await firstValueFrom(apiRequest$);
        if (response.success) {
            return response.result;
        } else {
            throw Error('error-getting-contactus-reasons');
        }
    }

    async submitContactUsForm(formData: FormData) {
        return await firstValueFrom(this.http.post<BaseResponse>(this.environment.nodeBaseURL + 'common/submitContactUsForm', formData));
    }

    async getFAQComponents(): Promise<any> {
        return await firstValueFrom(this.http.get<any>(this.environment.nodeBaseURL + 'common/getFAQComponents'));
    }
}
