export class CardDetailStyleOptions {
    constructor() {
        this.fontFamily = '';
        this.buttonCorner = 'none';
        this.primaryAccentColor = '';
        this.secondaryAccentColor = '';
        this.primaryTextColor = '';
    }

    fontFamily: string;
    buttonCorner: string;
    primaryAccentColor: string;
    secondaryAccentColor: string;
    primaryTextColor: string;
}
