import { Component, OnInit, forwardRef, Input, HostListener, ChangeDetectorRef, EventEmitter, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { Subscription, Observable } from 'rxjs';

@Component({
    selector: 'app-toggle-switch',
    templateUrl: './toggle-switch.component.html',
    styleUrls: ['./toggle-switch.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ToggleSwitchComponent),
            multi: true,
        },
    ],
})
export class ToggleSwitchComponent implements ControlValueAccessor {
    private _checked: boolean;
    private _disabled: boolean;
    private _label: string;
    private _beforeChange: Subscription;

    @Input() beforeChange: Observable<boolean>;

    constructor(private cdr: ChangeDetectorRef) {}

    @Input()
    set checked(val: boolean) {
        this._checked = val !== false;
    }

    get checked() {
        return this._checked;
    }

    @Input()
    set disabled(val: boolean) {
        this._disabled = val !== false;
    }

    get disabled() {
        return this._disabled;
    }

    @Input()
    set label(val: string) {
        this._label = val;
    }

    get label() {
        return this._label;
    }

    /**
     * Emits changed value
     */
    @Output() change = new EventEmitter<boolean>();

    /**
     * Emits DOM event
     */
    @Output() changeEvent = new EventEmitter<MouseEvent>();

    /**
     * Emits changed value
     */
    @Output() valueChange = new EventEmitter<boolean>();

    onClick(event: MouseEvent) {
        if (this.disabled) {
            return;
        }
        this.checked = !this.checked;

        // Component events
        this.change.emit(this.checked);
        this.valueChange.emit(this.checked);
        this.changeEvent.emit(event);

        // value accessor callbacks
        this.onChangeCallback(this.checked);
        this.onTouchedCallback(this.checked);
        this.cdr.markForCheck();
    }

    @HostListener('click', ['$event'])
    @HostListener('keydown.enter', ['$event'])
    onToggle(event: MouseEvent) {
        if (this.disabled) {
            return;
        }
        if (this.beforeChange) {
            this._beforeChange = this.beforeChange.subscribe((confirm: boolean) => {
                if (confirm) {
                    this.onClick(event);
                }
            });
        } else {
            this.onClick(event);
        }
    }

    writeValue(val: any): void {
        if (val !== this.checked) {
            this.checked = !!val;
        }

        this.onChangeCallback(this.checked);
        if (this.cdr) {
            this.cdr.markForCheck();
        }
    }

    registerOnChange(fn: any) {
        this.onChangeCallback = fn;
    }

    registerOnTouched(fn: any) {}

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    private onChangeCallback = (fn: any) => {};
    private onTouchedCallback = (v: any) => {};
}
