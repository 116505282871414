import { AfterViewChecked, Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { ThemeService } from '../services/theme.service';

type DisableFunction = () => boolean;

@Directive({
    selector: '[disableField]',
})
export class DisableFieldDirective implements OnInit, AfterViewChecked {
    @Input() disableField: boolean | DisableFunction;
    buttonDisable = true;

    constructor(private el: ElementRef, private theme: ThemeService) {}

    ngOnInit(): void {
        if (this.theme.themeConfig['non-css-properties']['field-style'].isButtonStyleEnable) {
            this.buttonDisable = false;
        }
    }

    ngAfterViewChecked(): void {
        if (this.buttonDisable) {
            this.el.nativeElement.disabled = this.disableHandler();
        }
    }

    @HostListener('hover', ['$event'])
    disableHandler(event = null): boolean {
        if (typeof this.disableField === 'function') {
            return this.disableField();
        }
        return this.disableField;
    }
}
