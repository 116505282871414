import { Injectable, Inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, debounceTime, shareReplay, auditTime } from 'rxjs/operators';
import { WINDOW } from './window.service';

export enum DEVICE_SIZE {
    MOBILE = 0,
    TABLET = 481,
    DESKTOP = 1025,
}

export enum BOOTSTRAP_SIZE {
    XS = 0,
    SM = 576,
    MD = 768,
    LG = 992,
    XL = 1200,
}

@Injectable({ providedIn: 'root' })
export class ResizeService {
    private bootstrapSize$$: BehaviorSubject<BOOTSTRAP_SIZE> = new BehaviorSubject<BOOTSTRAP_SIZE>(BOOTSTRAP_SIZE.XS);
    public bootstrapSize$ = this.bootstrapSize$$.asObservable().pipe(distinctUntilChanged());

    private deviceSize$$: BehaviorSubject<DEVICE_SIZE> = new BehaviorSubject<DEVICE_SIZE>(DEVICE_SIZE.MOBILE);
    public deviceSize$ = this.deviceSize$$.asObservable().pipe(distinctUntilChanged());

    private pixelSize$$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    public pixelSize$ = this.pixelSize$$.asObservable().pipe(distinctUntilChanged(), auditTime(500), shareReplay(1));

    public get currentPixelSize(): number {
        return this.pixelSize$$.value;
    }

    public get currentDeviceSize(): number {
        return this.deviceSize$$.value;
    }

    public get currentBootstrapSize(): number {
        return this.bootstrapSize$$.value;
    }

    constructor(@Inject(WINDOW) window) {
        this.onResize(window.innerWidth);
    }

    onResize(size: number) {
        switch (true) {
            case size >= DEVICE_SIZE.DESKTOP:
                this.deviceSize$$.next(DEVICE_SIZE.DESKTOP);
                break;
            case size >= DEVICE_SIZE.TABLET:
                this.deviceSize$$.next(DEVICE_SIZE.TABLET);
                break;
            default:
                this.deviceSize$$.next(DEVICE_SIZE.MOBILE);
        }
        switch (true) {
            case size >= BOOTSTRAP_SIZE.XL:
                this.bootstrapSize$$.next(BOOTSTRAP_SIZE.XL);
                break;
            case size >= BOOTSTRAP_SIZE.LG:
                this.bootstrapSize$$.next(BOOTSTRAP_SIZE.LG);
                break;
            case size >= BOOTSTRAP_SIZE.MD:
                this.bootstrapSize$$.next(BOOTSTRAP_SIZE.MD);
                break;
            case size >= BOOTSTRAP_SIZE.SM:
                this.bootstrapSize$$.next(BOOTSTRAP_SIZE.SM);
                break;
            default:
                this.bootstrapSize$$.next(BOOTSTRAP_SIZE.XS);
        }
        this.pixelSize$$.next(size);
    }
}
