import { CardDetailStyleOptions } from './card-detail-style-options-model';

export class CardDetailModel {
    constructor() {
        this.isPublished = false;
        this.approvedMetaName = '';
        this.marketingcardimage = '';
        this.siteLogo = '';
        this.additionalImage = '';
        this.programDescription = '';
        this.styleOptions = new CardDetailStyleOptions();
        this.clienthtml = '';
        this.clientscript = '';
        this.exchangeURL = '';
    }
    cardName: string;

    isPublished: boolean;
    approvedMetaName: string;
    marketingcardimage: string;
    siteLogo: string;
    additionalImage: string;
    programDescription: string;
    styleOptions: CardDetailStyleOptions;
    clienthtml: string;
    clientscript: string;
    cardholderAgreementUrl: string;
    firstClientProgramId: number;
    terms: string;
    firstProductCode?: string;
    exchangeURL?: string;
    merchantfilterid?: string;
}
