import { Injectable } from '@angular/core';
import { eGiftRequestModel } from '@app/shared/models/eGift-request.model';
import { eGiftResponseModel } from '@app/shared/models/eGift-response.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { RegisterCardRequest } from '../card/models/register-card-request-model';
import { CardHttpService } from '../card/http/card-http.service';
import { CommonService } from '@app/shared/services/common.service';
import { Country } from '@app/shared/models/country-model';
import { NGXLogger } from 'ngx-logger';
import { CardDataService } from '@app/shared/services/card-data.service';
import { CardBundle } from './models/card-bundle';
import { CredentialsService } from '@app/core/authentication/credentials.service';

@Injectable({
    providedIn: 'root',
})
export class RegistrationService {
    private _eGiftResoveData$$: BehaviorSubject<eGiftResponseModel> = new BehaviorSubject(null);
    constructor(
        private cardHttpService: CardHttpService,
        private commonService: CommonService,
        private _logger: NGXLogger,
        private cardData: CardDataService,
        private credentials: CredentialsService,
    ) {}

    registerCard(requestModel: RegisterCardRequest, rmsId?: any): Promise<void> {
        return this.cardHttpService.registerCard(requestModel, rmsId);
    }
    async digitalRedeemCard(requestModel: RegisterCardRequest, rmsId: any): Promise<void> {
        const resp = await this.cardHttpService.digitalRedeemCard(requestModel, rmsId);
        this.setUserData(resp);
    }

    async digitalRedeem(requestModel: any, rmsId?: any): Promise<void> {
        const resp = requestModel.code
            ? await this.cardHttpService.redeemCode(requestModel, rmsId)
            : await this.cardHttpService.digitalRedeemCard(requestModel, rmsId);
        const codeProduct: eGiftResponseModel = {
            isCodeFlow: true,
            upc: '',
            success: true,
            proxyCardNumber: resp.cards[0].proxyCardNumber,
            product: resp.products[0],
        };
        this.seteGift$(codeProduct);
        this.setUserData(resp);
    }

    async getEGiftData(request: eGiftRequestModel): Promise<eGiftResponseModel> {
        try {
            const response = await this.cardHttpService.getEGiftData(request);
            this.seteGift$(response);
            return response;
        } catch (error) {
            this._logger.error('Begin sign up async error', error);
            return error;
        }
    }
    getCountries() {
        return this.commonService.getCountries();
    }
    getRegions(val: Country) {
        return this.commonService.getRegions(val);
    }
    geteGift$(): Observable<eGiftResponseModel> {
        return this._eGiftResoveData$$.asObservable();
    }
    seteGift$(value: eGiftResponseModel) {
        this._eGiftResoveData$$.next(value);
    }
    private setUserData(loginBundle: CardBundle) {
        if (!this.credentials.isAuthenticated() || !this.credentials.current.isRegisteredEndUser()) {
            this.cardData.clear();
        }
        this.cardData.load(loginBundle);
    }
}
