import { BHNTranslateService } from './../../shared/services/bhn-translate.service';
import { Inject, Injectable, Injector } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpResponse, HttpHeaders, HttpResponseBase } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import { CredentialsService } from '../authentication/credentials.service';
import { tap, finalize, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from '@app/shared/services/common.service';
import * as moment from 'moment';
import { ENVIRONMENT } from '@app/core/services/environment.service';
import { UnAuthErrorMessage } from '@app/shared/enum/unauthorized-errors.enum';

@Injectable({
    providedIn: 'root',
})
export class HttpRequestResponseInterceptorService implements HttpInterceptor {
    constructor(
        private inject: Injector,
        private credentials: CredentialsService,
        private router: Router,
        private translate: TranslateService,
        private bhnTranslateService: BHNTranslateService,
        @Inject(ENVIRONMENT) private environment,
    ) { }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        const started = Date.now();
        const loggerService = this.inject.get(NGXLogger);
        const commonService = this.inject.get(CommonService);
        let authorizationToken: string;
        let ok: string;

        if (commonService.tenantKey) {
            request = request.clone({
                setHeaders: {
                    'X-Tenant-Key': commonService.tenantKey,
                },
            });
        }

        if (commonService.sessionId) {
            request = request.clone({
                setHeaders: {
                    'X-Session-Id': commonService.sessionId,
                },
            });
        }

        if (this.translate.currentLang) {
            request = request.clone({
                setHeaders: {
                    'X-Selected-Language': this.bhnTranslateService.storedLanguageExist()
                        ? this.bhnTranslateService.getStoredLanguage()
                        : this.translate.currentLang,
                },
            });
        }

        // Get authorization token using credential service
        if (this.credentials.isAuthenticated()) {
            authorizationToken = this.credentials.current.token;
        }

        if (!!authorizationToken) {
            // Add bearer token
            request = request.clone({
                setHeaders: {
                    Authorization: 'Bearer ' + authorizationToken,
                },
            });
        }

        if (commonService.getReferrer()) {
            request = request.clone({
                setHeaders: {
                    'X-Referrer': commonService.getReferrer(),
                },
            });
        }
        /** To Set x-forwarded-for on the Local testing */
        request = request.clone({
            setHeaders: {
                'X-Local-Time': moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
                'Accept': 'application/json',
                ...(this.environment.customIP && { 'x-forwarded-for': this.environment.customIP }),
            },
            ...(!this.environment.customIP && { withCredentials: true }),
        });
        /** To Set x-forwarded-for on the Local testing */

        if (!request.url.endsWith('log')) {
            // const reqClone = Object.assign({}, request);
            const reqClone = JSON.parse(JSON.stringify(request));
            if (reqClone.body) {
                if (reqClone.body.cardNumber && reqClone.body.cardNumber.length > 6) {
                    reqClone.body.cardNumber = `${reqClone.body.cardNumber.substr(0, 6)}XXXXXX${reqClone.body.cardNumber.substr(
                        reqClone.body.cardNumber.length - 4,
                    )}`;
                }
                if (reqClone.body.cardnum && reqClone.body.cardnum.length > 6) {
                    reqClone.body.cardnum = `${reqClone.body.cardnum.substr(0, 6)}XXXXXX${reqClone.body.cardnum.substr(
                        reqClone.body.cardnum.length - 4,
                    )}`;
                }
            }

            if (request.url.includes('/assets/i18n/') === false) {
                loggerService.debug('FRONTEND Request: ' + request.url, reqClone);
            }
        }

        return next.handle(request).pipe(
            tap((response: HttpEvent<unknown>) => {
                if (response instanceof HttpErrorResponse) {
                    ok = 'failed';
                }
                if (response instanceof HttpResponse) {
                    ok = 'succeeded';
                    if (!request.url.endsWith('log') && request.url.includes('/assets/i18n/') === false) {
                        loggerService.debug('FRONTEND Response: ' + response.url, response);
                    }
                    const newToken = response.headers.get('X-Token');
                    if (newToken) {
                        this.credentials.set(newToken);
                    } else if (this.credentials.isAuthenticated()) {
                        const refreshedToken = response.headers.get('X-Token-Refresh');
                        if (refreshedToken) {
                            this.credentials.refresh(refreshedToken);
                        }
                    }

                    let newRes : any = response;
                    if (!newRes?.body?.success && newRes?.body?.errors?.includes('aws-waf-error')) {
                        this.credentials.clear();
                        this.credentials.onErrorModelPopup(newRes?.body?.errors[0]);
                    }
                }
            }),
            finalize(() => {
                if (!request.url.endsWith('log')) {
                    const elapsed = Date.now() - started;
                    const msg = {
                        method: request.method,
                        url: request.urlWithParams,
                        time: `${ok} in ${elapsed} ms.`,
                    };
                    if (commonService.sessionId) {
                        loggerService.setCustomHttpHeaders(new HttpHeaders({ 'X-Session-Id': commonService.sessionId }));
                    }
                    loggerService.info('FRONTEND Roundtrip Statistics', msg);
                }
            }),
            catchError((error) => {
                if (error.status === 401 || error.status === 403) {
                    // Redirect to login page, if auth token is presence
                    const {
                        error: { errors },
                    } = error;
                    const errorStatus = errors.toString();
                    if (
                        this.credentials &&
                        (errorStatus === UnAuthErrorMessage.InvalidTenant ||
                            errorStatus === UnAuthErrorMessage.DuplicateLogin ||
                            errorStatus === UnAuthErrorMessage.TokenMismatch ||
                            errorStatus === UnAuthErrorMessage.TokenExpired ||
                            errorStatus === UnAuthErrorMessage.SessionExpired)
                    ) {
                        this.credentials.clear();
                        this.credentials.onErrorModelPopup(errorStatus);
                    } else if (authorizationToken !== '' && authorizationToken !== undefined) {
                        this.router.navigate(['login']);
                    } else {
                        this.credentials.clear();
                    }
                    // if (authorizationToken !== '' && authorizationToken !== undefined) {
                    //     this.router.navigate(['login']);
                    // }
                }

                // Log error response
                if (!request.url.endsWith('log')) {
                    loggerService.error('FRONTEND RESPONSE ERROR', JSON.stringify(error, Object.getOwnPropertyNames(error)));
                }
                return throwError(error);
            }),
        );
    }
}
