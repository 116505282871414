import { AuthenticationGuard } from '@app/core/guards/authentication.guard';
import { CardDetailActivationGuard } from '@app/core/guards/card-detail-activation.guard';
import { CardDetailPreviewActivationGuard } from '@app/core/guards/card-detail-preview-activation.guard';
import { FluidCardDetailActivationGuard } from '@app/core/guards/fluid-card-detail-activation.guard';
import { RedirectionComponent } from '@app/shared/components/redirection/redirection.component';
import { FlowName, FlowType } from '@app/shared/enum/rms.enum';
import { Feature, Shell } from '@app/shared/models/application-config.model';
import { EgiftResolver } from '@app/shared/services/egift.resolver';
import { RmsSessionGeneratorResolver } from '@app/shared/services/rms-session-generator.resolver';

export const SetName = 'fluid';

export enum FluidShell {
    FluidShell = 'fluid-shell',
    FluidFloatingShell = 'fluid-floating-shell',
}

let shells = new Map<string, Shell>([
    [
        FluidShell.FluidShell,
        {
            route: {
                path: '',
                loadChildren: () => import('@app/features/fluid/shells/fluid/fluid-shell.module').then((m) => m.FluidShellModule),
            },
        },
    ],
    [
        FluidShell.FluidFloatingShell,
        {
            route: {
                path: '',
                loadChildren: () =>
                    import('@app/features/fluid/shells/fluid-floating/fluid-floating-shell.module').then((m) => m.FluidFloatingShellModule),
            },
        },
    ],
]);

shells = new Map(Array.from(shells.entries()).map(([name, shell]) => [name, { ...shell, name }]));

export enum FluidFeature {
    FluidProxyActivation = 'fluid-proxy-activation',
    FluidSignUp = 'fluid-sign-up',
    FluidSignIn = 'fluid-sign-in',
    FluidRegister = 'fluid-register',
    FluidVerificationCode = 'fluid-verification-code',
    FluidSetPin = 'fluid-set-pin',
    FluidCardDetails = 'fluid-card-details',
    FluidMyCards = 'fluid-my-cards',
    FluidForgotPassword = 'fluid-forgot-password',
    FluidContactUs = 'fluid-contact-us',
    FluidFaq = 'fluid-faq',
    FluidCardRetrievalPage = 'fluid-card-retrieval-page',
    FluidManageProfile = 'fluid-manage-profile',
}

const features = new Map<string, Feature>([
    [
        FluidFeature.FluidProxyActivation,
        {
            routes: [
                {
                    path: '',
                    pathMatch: 'full',
                    redirectTo: '/home',
                },
                {
                    path: 'home',
                    pathMatch: 'full',
                    component: RedirectionComponent,
                    data: { redirectTo: '/login/card/landing', redirectData: { fadeBackground: true } },
                },
                {
                    path: 'redeem',
                    pathMatch: 'full',
                    component: RedirectionComponent,
                    data: { redirectTo: '/login/card/landing', redirectData: { fadeBackground: true } },
                },
                {
                    path: 'login/card', //
                    loadChildren: () =>
                        import('@app/features/fluid/home/proxy-activation/fluid-proxy-activation.module').then(
                            (m) => m.FluidProxyActivationModule,
                        ),
                        data: { title: 'shell.pageTitle.createProfile', flowType :{
                            type: FlowType.OTHER,
                            name: FlowName.CARD_LOGIN } },
                },
                {
                    path: '**',
                    redirectTo: '/home',
                },
            ],
        },
    ],

    [
        FluidFeature.FluidSignIn,
        {
            routes: [
                {
                    path: 'sign-in', //
                    loadChildren: () => import('app/features/fluid/home/sign-in/fluid-sign-in.module').then((m) => m.FluidSignInModule),
                },
            ],
        },
    ],

    [
        FluidFeature.FluidSignUp,
        {
            routes: [
                {
                    path: 'create-profile', //
                    loadChildren: () => import('@app/features/fluid/home/sign-up/fluid-sign-up.module').then((m) => m.FluidSignUpModule),
                },
            ],
        },
    ],

    [
        FluidFeature.FluidRegister,
        {
            routes: [
                {
                    path: 'register', //
                    loadChildren: () =>
                        import('@app/features/fluid/home/register/fluid-register.module').then((m) => m.FluidRegisterModule),
                    resolve: { eGiftResolveData: EgiftResolver },
                    runGuardsAndResolvers: 'always',
                },
            ],
        },
    ],

    [
        FluidFeature.FluidVerificationCode,
        {
            routes: [
                {
                    path: 'verify', //
                    loadChildren: () =>
                        import('@app/features/fluid/home/verification-code/fluid-verification-code.module').then(
                            (m) => m.FluidVerificationCodeModule,
                        ),
                },
            ],
        },
    ],
    [
        FluidFeature.FluidSetPin,
        {
            routes: [
                {
                    path: 'set-pin', //
                    loadChildren: () => import('@app/features/fluid/home/set-pin/fluid-set-pin.module').then((m) => m.FluidSetPinModule),
                },
            ],
        },
    ],
    [
        FluidFeature.FluidCardDetails,
        {
            shell: 'fluid-shell',
            routes: [
                {
                    path: 'card-details', //
                    loadChildren: () => import('@app/features/fluid/card/fluid-card.module').then((m) => m.FluidCardModule),
                    canActivate: [FluidCardDetailActivationGuard],
                },
                {
                    path: 'site',
                    redirectTo: 'page',
                },
                {
                    path: 'page/:name', //
                    loadChildren: () => import('@app/features/fluid/card/fluid-card.module').then((m) => m.FluidCardModule),
                    canActivate: [FluidCardDetailActivationGuard],
                },
                {
                    path: 'page-preview/:name',
                    loadChildren: () => import('@app/features/fluid/card/fluid-card.module').then((m) => m.FluidCardModule),
                    canActivate: [CardDetailPreviewActivationGuard],
                },
            ],
        },
    ],
    [
        FluidFeature.FluidMyCards,
        {
            shell: 'fluid-shell',
            routes: [
                {
                    path: 'my-cards', //
                    loadChildren: () => import('@app/features/fluid/user/my-cards/fluid-my-cards.module').then((m) => m.FluidMyCardsModule),
                    canActivate: [AuthenticationGuard],
                    data: { signInURL: 'sign-in' },
                },
            ],
        },
    ],
    [
        FluidFeature.FluidForgotPassword,
        {
            routes: [
                {
                    path: 'reset-password', //
                    loadChildren: () =>
                        import('@app/features/fluid/home/forgot-password/fluid-forgot-password.module').then(
                            (m) => m.FluidForgotPasswordModule,
                        ),
                },
            ],
        },
    ],
    [
        FluidFeature.FluidContactUs,
        {
            shell: 'fluid-shell',
            routes: [
                {
                    path: 'contactus', //
                    loadChildren: () =>
                        import('@app/features/fluid/home/contact-us/fluid-contact-us.module').then((m) => m.FluidContactUsModule),
                },
            ],
        },
    ],
    [
        FluidFeature.FluidFaq,
        {
            shell: 'fluid-shell',
            routes: [
                {
                    path: 'faq', //
                    loadChildren: () => import('@app/features/fluid/home/faq/fluid-faq.module').then((m) => m.FluidFaqModule),
                },
            ],
        },
    ],
    [
        FluidFeature.FluidCardRetrievalPage,
        {
            routes: [
                {
                    path: 'login/code-redeemed', //
                    loadChildren: () =>
                        import('@app/features/fluid/home/card-retrieval/fluid-card-retrieval.module').then(
                            (m) => m.FluidCardRetrievalModule,
                        ),
                },
            ],
        },
    ],
    [
        FluidFeature.FluidManageProfile,
        {
            shell: 'fluid-shell',
            routes: [
                {
                    path: 'manage-profile', //
                    loadChildren: () =>
                        import('@app/features/fluid/user/manage-profile/fluid-manage-profile.module').then(
                            (m) => m.FluidManageProfileModule,
                        ),
                    canActivate: [AuthenticationGuard],
                    data: { signInURL: 'sign-in' },
                },
            ],
        },
    ],
]);

export default new Map([
    [
        SetName,
        {
            defaultShell: shells.get(FluidShell.FluidFloatingShell),
            shells,
            features,
        },
    ],
]);
