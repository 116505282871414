import { Injectable } from '@angular/core';
import { UserHttpService } from './http/user-http.service';
import { UserProfile } from './models/user-profile-model';
import { UserCardAlert, CardAlertSetup ,PaginationRequest} from './models/user-card-alert-model';
import { UpdateUserProfile } from './models/update-user-profile-model';

@Injectable({
    providedIn: 'root',
})
export class MyAlertsService {
    constructor(private userHttpService: UserHttpService) {}

    getCurrentUserProfile(): Promise<UserProfile> {
        return this.userHttpService.getCurrentUserProfile();
    }

    saveCurrentUserProfile(request: UpdateUserProfile) {
        return this.userHttpService.updateUser(request);
    }

    saveUserCardAlerts(userCardAlerts: UserCardAlert[]): Promise<boolean> {
        return this.userHttpService.saveUserCardAlerts(userCardAlerts);
    }

    getUserCardAlerts(request: PaginationRequest,rmsId?: any): Promise<UserCardAlert[]> {
        return this.userHttpService.getUserCardAlerts(request,rmsId);
    }

    getCardAlertSetup(request: CardAlertSetup, rmsID: string): Promise<UserCardAlert[]> {
        return this.userHttpService.getCardAlertSetup(request, rmsID);
    }
}
