import { Component } from '@angular/core';

@Component({
    selector: 'forgot-password-toast-template',
    template: `<div class="forgot-password-toast-container">
        <div class="padding" aria-hidden="true">
            <svg width="36" height="36" xmlns="http://www.w3.org/2000/svg">
                <g stroke="#FFF" stroke-width="1.5" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                    <path d="M35 1 13.565 27.609l-7.392-7.391" />
                    <path
                        d="M27.274 18.73c.217.955.335 1.945.335 2.965C27.61 29.044 21.65 35 14.305 35 6.953 35 1 29.044 1 21.695 1 14.347 6.953 8.391 14.305 8.391c2.12 0 4.128.498 5.913 1.384"
                    />
                </g>
            </svg>
        </div>

        <div>{{ '^forgot-password.toast-success-message' | translate }}</div>
    </div>`,
    styleUrls: ['forgot-password-template.component.scss'],
})
export class ForgotPasswordToastTemplateComponent {}
