import { Country } from './country-model';

export class AddressModel {
    constructor() {
        this.addressLine1 = null;
        this.addressLine2 = null;
        this.city = null;
        this.postalCode = null;
        this.region = null;
        this.country = null;
    }
    addressLine1: string;
    addressLine2?: string;
    city: string;
    postalCode: string;
    region: string;
    country: Country;
}
