import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CredentialsService, RoleEnum } from '@app/core/authentication/credentials.service';
import { SESSION_STORAGE } from '@app/core/services/session-storage.service';
import { CardBundle } from '@app/services/card/models/card-bundle';
import { CardInformation } from '@app/services/card/models/card-information.model';
import { KycStatus } from '../enum/kyc-status.enum';
import { InitiateIdvResponse } from '../models/initiateIdv-response-model';
import { BHNTranslateService } from './bhn-translate.service';
import { CardDataService } from './card-data.service';
import { KycService } from './kyc.service';
import { CommonService } from './common.service';

export const KYC_OBJ_KEY = 'kycDataObj';

export interface KycProcessFlowInterface {
    card: CardInformation;
    initiateIdv: InitiateIdvResponse;
    cardBundle: CardBundle;
    allowRedirectionforHeaderLogin: boolean;
    allowRedirectionforForgetPassword: boolean;
}
@Injectable({
    providedIn: 'root',
})
export class KycProcessFlowService implements KycProcessFlowInterface {
    public card: CardInformation;
    public initiateIdv: InitiateIdvResponse;
    public cardBundle: CardBundle;
    public allowRedirectionforHeaderLogin: boolean;
    public allowRedirectionforForgetPassword: boolean;

    constructor(
        private router: Router,
        private credentials: CredentialsService,
        private cardData: CardDataService,
        private bhnTranslateService: BHNTranslateService,
        private kycService: KycService,
        @Inject(SESSION_STORAGE) private sessionStorage: Storage,
        private commonService: CommonService,
    ) {
        const kycDataObj: KycProcessFlowInterface = JSON.parse(this.sessionStorage.getItem(KYC_OBJ_KEY));
        if (kycDataObj) {
            this.card = kycDataObj.card;
            this.initiateIdv = kycDataObj.initiateIdv;
            this.cardBundle = kycDataObj.cardBundle;
            this.allowRedirectionforHeaderLogin = kycDataObj.allowRedirectionforHeaderLogin;
            this.allowRedirectionforForgetPassword = kycDataObj.allowRedirectionforForgetPassword;
        }
    }

    async beginKYCFlow(): Promise<void> {
        await this.storeKycDataToSession();
        if (this.credentials.current.userUuid && this.credentials.current.hasRole(RoleEnum.registeredEndUser)) {
            await this.onProcessUserKYCInfo();
            await this.deciderFlow();
        } else {
            await this.navigateToCreateProfile();
        }
    }

    async storeKycDataToSession(): Promise<void> {
        const kycDataObj: KycProcessFlowInterface = {
            card: this.card,
            initiateIdv: this.initiateIdv,
            cardBundle: this.cardBundle,
            allowRedirectionforHeaderLogin: this.allowRedirectionforHeaderLogin,
            allowRedirectionforForgetPassword: this.allowRedirectionforForgetPassword,
        };

        this.sessionStorage.setItem(KYC_OBJ_KEY, JSON.stringify(kycDataObj));
    }

    async navigateToAdditionalinfo(): Promise<void> {
        this.router.navigateByUrl('additional-info');
    }

    async navigateToInitiateIDV(): Promise<void> {
        this.router.navigateByUrl('initiate-idv');
    }

    async navigateToCreateProfile(): Promise<void> {
        this.router.navigateByUrl('create-profile');
    }

    async navigateToKYCError(): Promise<void> {
        this.router.navigateByUrl('kyc-error');
    }

    async navigateToMycards(): Promise<void> {
        this.setUserData(this.cardBundle);
        this.router.navigateByUrl('my-cards');
        this.rest();
    }

    async navigateToCardDetails(): Promise<void> {
        this.setUserData(this.cardBundle);
        this.cardData.handleCardPageRedirect(this.card);
        this.rest();
    }

    get isLeaveKycFlow(): boolean {
        return this.enableKYC ? false : true;
    }

    get enableKYC(): boolean {
        return this.card && this.card.enableKYC ? true : false;
    }

    async deciderFlow(): Promise<void> {
        if (
            (this.kycService.credentialsKYCStatus === null &&
                this.kycService.credentialsAdditionalInfoPresent === null &&
                this.kycService.credentialsAllowKycResubmit === null) ||
            !this.kycService.credentialsAdditionalInfoPresent
        ) {
            this.navigateToAdditionalinfo();
        } else if (this.kycService.credentialsAdditionalInfoPresent && this.kycService.credentialsKYCStatus === KycStatus.INCOMPLETE) {
            this.cardData.load(this.cardBundle);
            this.initiateIdv = await this.kycService.initiateIdv(this.card.proxyCardNumber);
            await this.storeKycDataToSession();
            await this.navigateToInitiateIDV();
        } else if (this.kycService.credentialsKYCStatus === KycStatus.FAIL && !this.kycService.credentialsAllowKycResubmit) {
            this.navigateToMycards();
        } else if (this.kycService.credentialsKYCStatus === KycStatus.FAIL && this.kycService.credentialsAllowKycResubmit) {
            this.navigateToCardDetails();
        } else if (
            (this.kycService.credentialsKYCStatus === KycStatus.PASS && !this.kycService.isKYCExpired) ||
            this.kycService.credentialsKYCStatus !== KycStatus.PASS
        ) {
            this.navigateToCardDetails();
        } else if (this.kycService.credentialsKYCStatus === KycStatus.PASS && this.kycService.isKYCExpired) {
            this.navigateToAdditionalinfo();
        } else {
            this.navigateToMycards();
        }
    }

    async finishActivationDeciderFlow() {
        if (
            (this.kycService.credentialsKYCStatus === null &&
                this.kycService.credentialsAdditionalInfoPresent === null &&
                this.kycService.credentialsAllowKycResubmit === null) ||
            !this.kycService.credentialsAdditionalInfoPresent
        ) {
            this.navigateToAdditionalinfo();
        } else if (
            (this.kycService.credentialsAdditionalInfoPresent && this.kycService.credentialsKYCStatus === KycStatus.INCOMPLETE) ||
            (this.kycService.credentialsAdditionalInfoPresent &&
                this.kycService.credentialsKYCStatus === KycStatus.FAIL &&
                this.kycService.credentialsAllowKycResubmit)
        ) {
            this.initiateIdv = await this.kycService.initiateIdv(this.card.proxyCardNumber);
            await this.navigateToInitiateIDV();
        }
        await this.storeKycDataToSession();
    }

    private setUserData(loginBundle: CardBundle) {
        this.cardData.load(loginBundle);
        const currentLanguage = this.bhnTranslateService.getStoredLanguage();
        if (this.credentials.current.language && this.credentials.current.language !== currentLanguage) {
            this.bhnTranslateService.storeLanguage(this.credentials.current.language);
        }
    }
    public rest() {
        this.card = null;
        this.initiateIdv = null;
        this.sessionStorage.removeItem(KYC_OBJ_KEY);
        this.cardBundle = null;
    }

    private async onProcessUserKYCInfo() {
        const USER_KYC = await this.commonService.getUserKycInfo();
        this.kycService.onSetUserKYCInfo(USER_KYC);
    }
}
