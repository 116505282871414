import { Component, HostBinding, TemplateRef } from '@angular/core';
import { Toast } from '@app/shared/models/toast.model';
import { ToastService } from '@app/shared/services/toast-service';
import { isNumber } from 'lodash';

@Component({
    selector: 'app-toasts',
    templateUrl: 'toast-container.component.html',
    styleUrls: ['toast-container.component.scss'],
})
@HostBinding('[class.ngb-toasts]')
export class ToastsContainerComponent {
    constructor(public toastService: ToastService) {}

    isTemplate(toast: Toast): boolean {
        return toast.textOrTpl instanceof TemplateRef;
    }

    applyClasses(className: string, type: string) {
        if (type && className) {
            return `fluid theme-color ${type} ${className}`;
        } else if (className) {
            return className;
        } else {
            return `fluid theme-color ${type}`;
        }
    }

    applyPositions(toast: Toast) {
        if (toast.xpos && toast.ypos) {
            if (isNumber(toast.xpos) && isNumber(toast.ypos)) {
                return { 'left': toast.xpos + 'px', 'top': toast.ypos + 'px' };
            } else {
                return { 'left': toast.xpos, 'top': toast.ypos };
            }
        } else {
            return { 'left': '40%', 'top': '20%' };
        }
    }
}
