import { Directive, EventEmitter, Input, Output, HostBinding, HostListener } from '@angular/core';
import { SortDirection } from '@app/services/card/transactions.service';

const rotate: { [key: string]: SortDirection } = { asc: 'desc', desc: 'asc' };

export interface SortEvent {
    column: string;
    direction: SortDirection;
    force?: boolean;
}

@Directive({
    selector: 'th[sortable]',
    host: {
        '[class.asc]': 'direction === "asc" && active',
        '[class.desc]': 'direction === "desc" && active',
        '(click)': 'rotate()',
    },
})
export class SortableHeaderDirective {
    @Input() sortable: string;
    @Input() direction: SortDirection = 'asc';
    @Input() active: boolean;
    @Output() sort = new EventEmitter<SortEvent>();

    rotate() {
        this.direction = rotate[this.direction];
        this.sort.emit({ column: this.sortable, direction: this.direction });
    }
}
