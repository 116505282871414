export interface Culture {
    name: string;
    isoCode: string;
}
export interface FilterValue {
    resourceFilterId: number;
    resourceFilterTypeId: number;
    resourceFilterTypeTypeKey?: string;
}
export class ContentEditorModel {
    public name: string;
    public setName: string;
    public value: string;
    public cultureName: string;
    public filter: Array<FilterValue>;
    constructor(init?: Partial<ContentEditorModel>) {
        Object.assign(this, init);
    }
}

export class InitializeContentModel {
    selectedFilterList: ResourceFilterModel[];
    connectionInformationModel: ConnectionInformationModel;
    constructor(init?: Partial<InitializeContentModel>) {
        Object.assign(this, init);
    }
}

export class ContentEditorRequestModel {
    public setName: string;
    public isoCode: string;
    public filterList: Array<FilterValue>;
    public overrideFilterListWithCurrent: boolean;
    public includeShell: boolean;
}

export class ConnectionInformationModel {
    webServer: string;
    dataSource: string;
    initialCatalog: string;
}

export class ResourceFilterModel {
    resourceFilterId: number;
    resourceFilterTypeId: number;
    name: string;
    typeKey: string;
}

export class ResourceFilterOptionModel {
    resourceFilterTypeId: number;
    typeKey: string;
    name: string;
    description: string;
    resourceFilterList: ResourceFilterModel[];
}
