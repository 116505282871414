import { TranslateDirective, TranslateService } from '@ngx-translate/core';
import { ElementRef, ChangeDetectorRef, forwardRef, Inject, Directive, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ContentEditorService } from '../services/content-editor.service';

@Directive({
    selector: '[translate],[ngx-translate]',
})
export class BHNTranslateDirective extends TranslateDirective {
    private elm: ElementRef;

    // @HostBinding('class.loading-translation')
    // private loadingTranslation: boolean;

    @Input() translationKeyPrefix: string;

    constructor(
        @Inject(forwardRef(() => TranslateService)) translateService: TranslateService,
        @Inject(forwardRef(() => ElementRef)) element: ElementRef,
        @Inject(forwardRef(() => ChangeDetectorRef)) _ref: ChangeDetectorRef,
        private router: Router,
        private route: ActivatedRoute,
        private ceService: ContentEditorService,
    ) {
        super(translateService, element, _ref);
        this.elm = element;
    }

    checkNodes(forceUpdate = false, translations?: any) {
        super.checkNodes(forceUpdate, translations);
    }

    setContent(node: any, content: string): void {
        if (this.isDefined(node.textContent)) {
            node.textContent = content;
        } else {
            node.data = content;
        }
        super.setContent(node, content);
        // this.loadingTranslation = false;
    }

    updateValue(key: string, node: any, translations: any) {
        if (key) {
            if (
                (node.lastKey === key && this.lastParams === this.currentParams) ||
                this.elm.nativeElement.getAttribute('modified') === 'true' ||
                this.elm.nativeElement.getAttribute('contenteditable') === 'true'
            ) {
                return;
            }
            if (!key.includes('shell')) {
                const newKeyPrefix = this.translationKeyPrefix
                    ? this.translationKeyPrefix && this.translationKeyPrefix?.concat('.')
                    : this.hasOwnProperty('translationKeyPrefix')
                    ? ''
                    : this.route.snapshot?.data.translationKeyPrefix?.concat('.') ??
                      this.router.url.substr(1).split('/')[0].split('?')[0].concat('.');

                if (!key.includes(newKeyPrefix) && !key.startsWith('^')) {
                    if (node && node.lastKey) {
                        if (node.lastKey.startsWith('^')) return;
                    }
                    key = newKeyPrefix.concat(key);
                    node.originalContent = key;
                }
            }

            if (!this.ceService.translationKeyMap.has(this.elm.nativeElement.id)) {
                this.ceService.translationKeyMap.set(this.elm.nativeElement.id, key);
            }
            // node.textContent = ''; // Easy way to hide untranslated tokens
            // this.loadingTranslation = true;
            super.updateValue(key, node, translations);
        }
    }

    private isDefined(value: any): boolean {
        return typeof value !== 'undefined' && value !== null;
    }
}
