import { ContentEditorService } from '@app/shared/services/content-editor.service';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BHNTranslateService } from '@app/shared/services/bhn-translate.service';
import { CountrySettingModel } from '@app/shared/models/country-setting.model';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';
import { Subject, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-country-language',
    templateUrl: './country-language.component.html',
    styleUrls: ['./country-language.component.scss'],
})
export class CountryLanguageComponent implements OnInit, OnDestroy {
    @ViewChild('dropdown') languageDropdown: BsDropdownDirective;
    @ViewChild('dropdownCountry') countryDropdown: BsDropdownDirective;
    @Input() showTranslationAssistanceLink: boolean;
    countrySettingList: CountrySettingModel[];
    selectedCountry: string;
    selectedLanguage: string;
    selectedURL: string;
    private destroy$: Subject<any> = new Subject();
    constructor(
        public activeModal: NgbActiveModal,
        private BHNTranslateService: BHNTranslateService,
        private router: Router,
        protected translate: TranslateService,
    ) {}

    ngOnInit(): void {
        this.selectedCountry = this.BHNTranslateService.getStoredCountry();
        this.selectedLanguage = this.BHNTranslateService.getStoredLanguage();
        (async () => {
            this.countrySettingList = await this.BHNTranslateService.getCountrySetting();

            // Sort country alphabetically and put default language first
            this.countrySettingList = this.countrySettingList.sort((a, b) => {
                return a.countryCode > b.countryCode ? 1 : -1;
            });
            this.countrySettingList.map((x) => {
                x.languages.sort((a, b) => {
                    return a === x.defaultLanguage ? -1 : b === x.defaultLanguage ? 1 : 0;
                });
            });

            const currentCountryModel: CountrySettingModel = this.countrySettingList.filter(
                (x) => x.countryCode === this.selectedCountry,
            )[0];
            this.selectedURL = currentCountryModel ? currentCountryModel.url : '';
        })();
        setTimeout(() => {
            this.countryDropdown.hide();
        }, 0);
    }

    public close(): void {
        this.activeModal.close();
    }

    public countryChange(countryCode: string, defaultLanguage: string): void {
        this.selectedCountry = countryCode;
        this.selectedLanguage = defaultLanguage;
        this.countryDropdown.hide();
    }

    public languageChange(language: string): void {
        this.selectedLanguage = language;
    }

    public showCountryChangeWarning(): boolean {
        if (this.countrySettingList) {
            const countryModel: CountrySettingModel = this.countrySettingList.filter((x) => x.countryCode === this.selectedCountry)[0];
            return countryModel && this.selectedURL !== countryModel.url;
        } else {
            return false;
        }
    }

    public async submit() {
        const countryModel: CountrySettingModel = this.countrySettingList.filter((x) => x.countryCode === this.selectedCountry)[0];

        if (countryModel && this.selectedURL !== countryModel.url) {
            window.open(`//${countryModel.url}/home?country=${this.selectedCountry}&lang=${this.selectedLanguage}`);
        } else {
            this.BHNTranslateService.storeCountry(this.selectedCountry);
            this.BHNTranslateService.storeLanguage(this.selectedLanguage);
            this.activeModal.close();
            timer(0)
                .pipe(takeUntil(this.destroy$))
                .subscribe(() => this.redirectTo(this.router.url));
        }
    }

    private redirectTo(uri): void {
        this.router.navigateByUrl('/staging', { skipLocationChange: true }).then(() => this.router.navigateByUrl(uri));
    }
    ngOnDestroy(): void {
        this.destroy$.next(null);
        this.destroy$.complete();
    }
}
