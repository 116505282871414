import { NgModule, Optional, SkipSelf, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { AuthenticationGuard } from './guards/authentication.guard';
import { RouteReuseStrategy } from '@angular/router';
import { RouteReusableStrategy } from './route-reusable-strategy';
// import { EnvironmentProvider } from '@app/core/services/environment.service';
import { SessionStorageProvider } from '@app/core/services/session-storage.service';
import { ErrorHandlerLogService } from './services/error-handler-log.service';
import { ActivationGuard } from './guards/activation.guard';
import { ResizeService } from './services/screen-resize.service';
import { CardDetailActivationGuard } from './guards/card-detail-activation.guard';
import { CardDetailPreviewActivationGuard } from './guards/card-detail-preview-activation.guard';
import { FluidCardDetailActivationGuard } from './guards/fluid-card-detail-activation.guard';
import { InputSanitizerService } from './services/input-sanitizer.service';

@NgModule({
    imports: [CommonModule, HttpClientModule],
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        // Import guard
        if (parentModule) {
            throw new Error(`${parentModule} has already been loaded. Import Core module in the AppModule only.`);
        }
    }
    static forRoot(): ModuleWithProviders<CoreModule> {
        return {
            ngModule: CoreModule,
            providers: [
                AuthenticationGuard,
                ActivationGuard,
                {
                    provide: RouteReuseStrategy,
                    useClass: RouteReusableStrategy,
                },
                SessionStorageProvider,
                // EnvironmentProvider,
                ErrorHandlerLogService,
                ResizeService,
                CardDetailActivationGuard,
                CardDetailPreviewActivationGuard,
                FluidCardDetailActivationGuard,
                InputSanitizerService,
            ],
        };
    }
}
