<div class="row" [ngClass]="cardDetailsService.isCustomPage && cardDetailsService.isStyleOption && cardDetailsService.fromCardDetails ? 'customTheme' : ''">
    <div class="col alertCard-w" *ngIf="user">
        <form [formGroup]="personalInformationFormGroup" >
            <div class="bb-1 m-0">
                <h3 translate> setup-alerts-view.contactInfo </h3>
            </div>
            <div class="bb-1 d-flex mb-1 mt-1 ml-1">
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 28 28">
                    <g fill="none" fill-rule="evenodd">
                        <g class="cancel" stroke="#00843D" stroke-linecap="round" stroke-linejoin="round">
                            <path d="M15.072 20.696c0 1.63-1.336 2.954-2.983 2.954H4.137c-1.647 0-2.982-1.324-2.982-2.954V4.939C1.155 2.764 2.935 1 5.131 1h5.964c2.196 0 3.977 1.764 3.977 3.94v15.756h0z"/><path d="M13.083 18.94h-9.94v-14h9.94zM6.125 2.97h3.976">
                            </path>
                        </g>
                        <circle cx="1" cy="1" r="1" transform="translate(7.5 20.038)" fill="#00843D"/>
                    </g>
                </svg>
                <div class="phoneInput" *ngIf="userProfileObj.editPhone" for="phoneNumber">
                    <input
                        minlength="10"
                        maxlength="15"
                        numbersOnly
                        id="phoneNumber"
                        autocomplete="tel"
                        class="form-control"
                        formControlName="phoneNumber"
                        aria-describedby="SuccessSavingPhone"
                        [attr.aria-label]="'setup-alerts-view.phoneTextBox' | translate"
                        #phoneInput
                    />
                </div>
                <span class="phonelabel" *ngIf="!userProfileObj.editPhone">{{ personalInformationFormGroup.get('phoneNumber').value }}</span>
                <button
                    class="btn p-0"
                    *ngIf="!userProfileObj.editPhone"
                    (click)="toggleEditPhone()"
                    [attr.aria-label]="'setup-alerts-view.editPhone' | translate"
                >
                    <div class="edit mt-1" translate> setup-alerts-view.edit</div>
                </button>
                <ng-container *ngIf="userProfileObj.editPhone && userProfileObj.phoneStatus == PageStatus.Processing" >
                    <div class="card-spinner px-3"></div>
                </ng-container>
                <ng-container *ngIf="userProfileObj.phoneStatus != PageStatus.Processing" >
                    <button
                        *ngIf="userProfileObj.editPhone"
                        class="btn ml-2 mr-1 mt-1 p-0 px-1"
                        (click)="savePhone()"
                        [attr.aria-label]="'setup-alerts-view.updatePhone' | translate"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 18 18">
                            <g stroke="#00843D" stroke-width="0">
                                <path class="update-email-or-phone" style="fill:#00843D;" d="M16.145,2.571c-0.272-0.273-0.718-0.273-0.99,0L6.92,10.804l-4.241-4.27   c-0.272-0.274-0.715-0.274-0.989,0L0.204,8.019c-0.272,0.271-0.272,0.717,0,0.99l6.217,6.258c0.272,0.271,0.715,0.271,0.99,0   L17.63,5.047c0.276-0.273,0.276-0.72,0-0.994L16.145,2.571z"/>
                            </g>
                        </svg>
                    </button>
                    
                    <button
                        *ngIf="userProfileObj.editPhone"
                        class="btn p-0 pr-1 bg-white" 
                        [attr.aria-label]="'setup-alerts-view.cancelPhone' | translate"
                        (click)="toggleEditPhone()"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 32 32">
                            <g transform="translate(-179.000000, -212.000000) translate(170.000000, 204.000000)">
                                <g class="cancel" stroke="#00843D" stroke-width="4">
                                    <path d="M0 0L19.653 19.651M19.652 0L0 19.65" 
                                    transform="translate(30.500212, 29.500513) rotate(-180.000000) translate(-30.500212, -29.500513) translate(16.000212, 15.000513) translate(4.673079, 4.674950)"></path>
                                </g>
                            </g>
                        </svg>
                    </button>
                </ng-container>
            </div>
            <div class="bb-1 d-flex mb-1 mt-1" *ngIf="user">
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="5 5 26 26">
                    <g fill="none" fill-rule="evenodd">
                        <path fill-rule="nonzero" d="M0 0H32V32H0z"/>
                        <g class="cancel" stroke="#00843D" stroke-linecap="square" transform="translate(7 8.8)">
                            <path d="M2.4 3L9 9 15.6 3"/>
                            <rect width="18" height="14.4" rx="3"/>
                            <path d="M2.4 11.4L4.8 8.4M15.6 11.4L13.2 8.4"/>
                        </g>
                    </g>
                </svg>
                <div class="emailInput" *ngIf="userProfileObj.editEmail" for="emailId">
                    <input
                        type="text"
                        class="form-control"
                        id="emailId"
                        autocomplete="email"
                        formControlName="email"
                        aria-describedby="invalidEmail"
                        [attr.aria-label]="'setup-alerts-view.emailTextBox' | translate"
                        #emailInput
                    />
                </div>
                <span class="emaillabel"*ngIf="!userProfileObj.editEmail">{{ personalInformationFormGroup.get('email').value }}</span>
               
                <i class="fa fa-info-circle ml-2 mt-2" aria-hidden="true"  *ngIf="!userProfileObj.editEmail"  
                (click)="onToggleRegCardHelp(toggleRegCardIcon); $event.stopPropagation()"></i>
                 <app-custom-tooltip [tooltipConfigs]="tooltipConfigs"></app-custom-tooltip>
              
                <ng-container *ngIf="userProfileObj.editEmail && userProfileObj.emailStatus == PageStatus.Processing" >
                    <div class="card-spinner px-3"></div>
                </ng-container>
                <ng-container *ngIf="userProfileObj.emailStatus != PageStatus.Processing" >
                    <button
                        *ngIf="userProfileObj.editEmail"
                        class="btn ml-2 mr-1 mt-1 p-0 px-1"
                        (click)="saveEmail()"
                        [attr.aria-label]="'setup-alerts-view.updateEmail' | translate"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 18 18">
                            <g stroke="#00843D" stroke-width="0">
                                <path class="update-email-or-phone" style="fill:#00843D;" d="M16.145,2.571c-0.272-0.273-0.718-0.273-0.99,0L6.92,10.804l-4.241-4.27   c-0.272-0.274-0.715-0.274-0.989,0L0.204,8.019c-0.272,0.271-0.272,0.717,0,0.99l6.217,6.258c0.272,0.271,0.715,0.271,0.99,0   L17.63,5.047c0.276-0.273,0.276-0.72,0-0.994L16.145,2.571z"/>
                            </g>
                        </svg>
                    </button>
                    
                    <button
                        *ngIf="userProfileObj.editEmail"
                        class="btn p-0 pr-1 bg-white" 
                        (click)="toggleEditEmail()"
                        [attr.aria-label]="'setup-alerts-view.cancelEmail' | translate"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 32 32">
                            <g transform="translate(-179.000000, -212.000000) translate(170.000000, 204.000000)">
                                <g class="cancel" stroke="#00843D" stroke-width="4">
                                    <path d="M0 0L19.653 19.651M19.652 0L0 19.65" 
                                    transform="translate(30.500212, 29.500513) rotate(-180.000000) translate(-30.500212, -29.500513) translate(16.000212, 15.000513) translate(4.673079, 4.674950)"></path>
                                </g>
                            </g>
                        </svg>
                    </button>
                </ng-container>
            </div> 
            <div class="mb-4">
                <app-field-error
                    *ngIf="userProfileObj.editEmail && emailAddressInvalid()"
                    [type]="'invalidInputFormat'"
                    [parentForm]="personalInformationFormGroup"
                    [errorMessage]="'setup-alerts-view.invalidEmail'"
                    [id]="'invalidEmail'"
                ></app-field-error>
                <ng-container *ngIf="userProfileObj.editEmail && emailAddressInvalid()"> <br/> </ng-container>
                <app-field-error
                    *ngIf="userProfileObj.editPhone && phoneNumberInvalid()"
                    [type]="'invalidInputFormat'"
                    [parentForm]="personalInformationFormGroup"
                    [errorMessage]="'setup-alerts-view.invalidPhoneFormat'"
                    [id]="'invalidPhoneFormat'"
                ></app-field-error>
                <ng-container *ngIf="userProfileObj.editPhone && phoneNumberInvalid()"> <br/> </ng-container> 
                <app-field-error
                    *ngIf="userProfileObj.emailStatus == PageStatus.SuccessSavingEmail"
                    [type]="'successEmail'"
                    [fieldName]="'email'"
                    [parentForm]="personalInformationFormGroup"
                    [errorMessage]="'setup-alerts-view.emailUpdateSuccessMessage'"
                    [id]="'successEmail'"
                ></app-field-error>
               
                <app-field-error
                    *ngIf="userProfileObj.phoneStatus == PageStatus.SuccessSavingPhone"
                    [type]="'successPhone'"
                    [fieldName]="'phoneNumber'"
                    [parentForm]="personalInformationFormGroup"
                    [errorMessage]="'setup-alerts-view.phoneUpdateSuccessMessage'"
                    [id]="'successPhone'"
                ></app-field-error>

                <app-field-error
                    *ngIf="userProfileObj.phoneStatus == PageStatus.SuccessRemovingPhone"
                    [type]="'successPhone'"
                    [fieldName]="'phoneNumber'"
                    [parentForm]="personalInformationFormGroup"
                    [errorMessage]="'setup-alerts-view.phoneRemovalSuccessMessage'"
                    [id]="'successPhone'"
                ></app-field-error>
                
                <app-field-error
                    *ngIf="userProfileObj.emailStatus === PageStatus.ErrorSavingContactInfo || userProfileObj.phoneStatus === PageStatus.ErrorSavingContactInfo "
                    [type]="'customFieldError'"
                    [parentForm]="personalInformationFormGroup"
                    [errorMessage]="'setup-alerts-view.contactInfoFailureMessage'"
                    [id]="'errorContactInfo'"
                ></app-field-error>

            </div>
        </form>
    </div>
    <!-- <div *ngIf="!user" class="card-spinner d-flex flex-fill">  </div> -->
</div>