import { Injectable } from '@angular/core';

import { CardHttpService } from '../../services/card/http/card-http.service';
import { ValidateVirtualCodeRequest } from './models/validate-virtual-code-request-model';
import { VirtualCodeQuoteInformation } from './models/virtual-code-quote-information-model';
import { RedeemCodeRequest, ValidateCountryCodeModel } from './models/redeem-code-request-model';
import { CardBundle } from './models/card-bundle';
import { ValidateVirtualCodeResponseModel } from './models/validate-virtual-code-response-model';
import { BaseResponse } from '@app/shared/models/base-response-model';

@Injectable({
    providedIn: 'root',
})
export class CodeRedemptionService {
    virtualCodeQuoteInformation: VirtualCodeQuoteInformation;
    isNoRegistration: boolean;
    isE2P:boolean;
    productCode: string;
    clientProgramId: number;
    virtualCardTermsFileName: string;
    reInitiateVirtualCode: boolean = false;
    redeemableCode: string;
    cardCurrency: string;

    constructor(private cardHttpService: CardHttpService) {}

    validateVirtualCode(requestModel: ValidateVirtualCodeRequest): Promise<ValidateVirtualCodeResponseModel> {
        return this.cardHttpService.validateVirtualCode(requestModel);
    }

    validateCountryCode(requestModel: ValidateCountryCodeModel): Promise<BaseResponse> {
        return this.cardHttpService.validateCountryCode(requestModel);
    }

    redeemCode(requestModel: RedeemCodeRequest, rmsSessionId: string): Promise<CardBundle> {
        return this.cardHttpService.redeemCode(requestModel, rmsSessionId);
    }

    clearVirtualCodeQuoteInformation(): void {
        this.virtualCodeQuoteInformation = undefined;
    }

    clearReInitiateVirtualCode(): void {
        this.reInitiateVirtualCode = false;
    }

    getCodeToRedeem(): string {
        this.redeemableCode = sessionStorage.getItem(`activationCode`);
        return this.redeemableCode;
    }

    setCodeToRedeem(code: string) {
        sessionStorage.setItem(`activationCode`, code);
    }
}
