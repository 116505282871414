import { BHNTranslateService } from './../../services/bhn-translate.service';
import { Component, forwardRef, OnInit, Input } from '@angular/core';
import {
    FormControl,
    FormGroup,
    Validators,
    ControlValueAccessor,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    AbstractControl,
    ValidationErrors,
} from '@angular/forms';
import { BaseComponent } from '@app/base/base.component';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ProfileComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => ProfileComponent),
            multi: true,
        },
    ],
})
export class ProfileComponent extends BaseComponent implements OnInit, ControlValueAccessor {
    @Input() currentPage: string;
    profileFormGroup: FormGroup;
    visibleKey = 'visible text-danger show-on-content-edit-visible';
    invisibleKey = 'invisible text-danger show-on-content-edit-visible';
    usernameNotUnique = false;
    unmaskedPassword = false;
    passwordPattern = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\\W_])(?=.{8,20}$)')
    currentPageManageProfile = false;
    currentUserName: string;
    emailAsUsername: boolean;
    public languages: string[];

    constructor(private bhnTranslateService: BHNTranslateService) {
        super();
        this.bhnTranslateService.getDefaultCountrySetting().then((x) => (this.languages = x.languages));
    }

    ngOnInit() {
        this.currentPageManageProfile = this.currentPage === 'manageProfile';
        this.emailAsUsername = true; // coming from config table

        this.profileFormGroup = new FormGroup(
            {
                username: new FormControl(null, {
                    validators: [Validators.required],
                    updateOn: 'blur',
                }),
                firstName: new FormControl(null, [Validators.required, Validators.pattern(new RegExp('^[a-zA-Z ]+$'))]),
                lastName: new FormControl(null, [Validators.required, Validators.pattern(new RegExp('^[a-zA-Z ]+$'))]),
                password: new FormControl(null, Validators.pattern(this.passwordPattern)),
                confirmPassword: new FormControl(null),
                email: new FormControl(null, {
                    updateOn: 'blur',
                    validators: [Validators.required, Validators.email, Validators.pattern(new RegExp('\\S+@\\S+(?=\\.).*'))],
                }),
                confirmEmail: new FormControl(null, { updateOn: 'blur', validators: Validators.required }),
                language: new FormControl(null, Validators.required),
            },
            [this.emailMatchValidator, this.passwordMatchValidator],
        );

        this.profileFormGroup.get('email').valueChanges.subscribe(() => {
            const control = this.profileFormGroup.get('email');
            if (control.value !== control.value.trim()) {
                control.setValue(control.value.trim());
            }
        });

        this.profileFormGroup.get('confirmEmail').valueChanges.subscribe(() => {
            const control = this.profileFormGroup.get('confirmEmail');
            if (control.value !== control.value.trim()) {
                control.setValue(control.value.trim());
            }
        });

        if (this.emailAsUsername) {
            this.profileFormGroup.removeControl('username');
        }

        if (!this.currentPageManageProfile) {
            this.profileFormGroup.get('password').setValidators([Validators.required, Validators.pattern(this.passwordPattern)]);
            this.profileFormGroup.get('confirmPassword').setValidators(Validators.required);
        }
    }

    writeValue(val: any): void {
        if (val) {
            if (this.currentPageManageProfile) {
                if (!this.emailAsUsername) {
                    this.currentUserName = val.username;
                }
            }
            this.profileFormGroup.setValue(val, { emitEvent: false });
        }
    }

    registerOnChange(fn: any): void {
        this.profileFormGroup.valueChanges.subscribe(fn);
    }

    registerOnTouched(fn: any): void {}

    validate(control: AbstractControl): ValidationErrors | null {
        if (control.touched) {
            this.markControlsAsTouched();
        }
        return this.profileFormGroup.valid ? null : { invalidForm: { valid: false, message: 'invalid' } };
    }

    private markControlsAsTouched() {
        for (const controlName in this.profileFormGroup.controls) {
            if (this.profileFormGroup.controls[controlName] !== undefined) {
                this.profileFormGroup.controls[controlName].markAsTouched();
            }
        }
    }

    emailMatchValidator(group: FormGroup): { [s: string]: boolean } {
        const emailVal = group.controls.email.value;
        const confirmEmailVal = group.controls.confirmEmail.value;

        return emailVal === confirmEmailVal ? null : { emailsDontMatch: true };
    }

    passwordMatchValidator(group: FormGroup): { [s: string]: boolean } {
        const passVal = group.controls.password.value;
        const confirmPassVal = group.controls.confirmPassword.value;

        return passVal === confirmPassVal ? null : { passwordsDontMatch: true };
    }

    userNameErrorVisibility() {
        const showInvalidUsernameError = this.showInvalidUsernameError();

        return showInvalidUsernameError
            ? 'd-none'
            : !this.profileFormGroup.controls.username.valid &&
              !this.profileFormGroup.controls.username.untouched &&
              showInvalidUsernameError === false
            ? this.visibleKey
            : this.invisibleKey;
    }

    userNameNotValidErrorVisibility() {
        return this.showInvalidUsernameError() ? this.visibleKey : this.invisibleKey;
    }

    private showInvalidUsernameError() {
        return (
            !this.profileFormGroup.controls.username.valid &&
            this.profileFormGroup.controls.username.errors != null &&
            this.profileFormGroup.controls.username.errors.usernameInvalid === true &&
            !this.profileFormGroup.controls.username.untouched
        );
    }

    firstNameInvalid() {
        return (
            !this.profileFormGroup.controls.firstName.valid &&
            !this.profileFormGroup.controls.firstName.untouched &&
            this.profileFormGroup.controls.firstName.errors?.required
        );
    }

    firstNameInvalidPattern() {
        return (
            !this.profileFormGroup.controls.firstName.valid &&
            !this.profileFormGroup.controls.firstName.untouched &&
            this.profileFormGroup.controls.firstName.errors?.pattern
        );
    }

    lastNameInvalid() {
        return (
            !this.profileFormGroup.controls.lastName.valid &&
            !this.profileFormGroup.controls.lastName.untouched &&
            this.profileFormGroup.controls.lastName.errors?.required
        );
    }

    lastNameInvalidPattern() {
        return (
            !this.profileFormGroup.controls.lastName.valid &&
            !this.profileFormGroup.controls.lastName.untouched &&
            this.profileFormGroup.controls.lastName.errors?.pattern
        );
    }

    passwordInvalid() {
        return !this.profileFormGroup.controls.password.valid && !this.profileFormGroup.controls.password.untouched;
    }

    confirmPasswordInvalid() {
        return (
            (!this.profileFormGroup.controls.confirmPassword.valid ||
                (this.profileFormGroup.errors != null && this.profileFormGroup.errors.passwordsDontMatch === true)) &&
            !this.profileFormGroup.controls.confirmPassword.untouched &&
            !this.profileFormGroup.controls.password.untouched
        );
    }

    emailAddressInvalid() {
        return !this.profileFormGroup.controls.email.valid && !this.profileFormGroup.controls.email.untouched;
    }

    confirmEmailAddressInvalid() {
        return (
            (!this.profileFormGroup.controls.confirmEmail.valid ||
                (this.profileFormGroup.errors != null && this.profileFormGroup.errors.emailsDontMatch === true)) &&
            !this.profileFormGroup.controls.confirmEmail.untouched &&
            !this.profileFormGroup.controls.email.untouched
        );
    }

    confirmSecurityQuestionErrorVisibility() {
        return !this.profileFormGroup.controls.secretQuestion.valid && !this.profileFormGroup.controls.secretQuestion.untouched
            ? this.visibleKey
            : this.invisibleKey;
    }

    confirmSecurityAnswerInvalid() {
        return !this.profileFormGroup.controls.secretAnswer.valid && !this.profileFormGroup.controls.secretAnswer.untouched;
    }

    public changeLanguage() {
        const select = this.profileFormGroup.get('language') as FormControl;
        this.bhnTranslateService.storeLanguage(select.value);
        this.profileFormGroup.patchValue(select.value, {
            onlySelf: true,
        });
    }
}
