import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { CardDetailsService } from '@app/services/card/card-details.service';
import { CardDetailModel } from '@app/services/card/models/card-detail.model';
import { CardInformation } from '@app/services/card/models/card-information.model';
import { CardDataService } from '@app/shared/services/card-data.service';
import { CredentialsService } from '../authentication/credentials.service';

@Injectable()
export class FluidCardDetailActivationGuard implements CanActivate {
    constructor(
        private router: Router,
        private cardDetailsService: CardDetailsService,
        private cardService: CardDataService,
        private credentials: CredentialsService,
    ) {}

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        let canActivate = false;
        let card: CardInformation;
        let identifier = '';

        for (const child of route.firstChild.children) {
            identifier = child.paramMap.get('identifier');

            if (identifier) {
                card = this.cardService.getCardByIdentifier(identifier);
                break;
            }
        }

        if (!this.credentials.isAuthenticated() && identifier) {
            this.router.navigate(['/sign-in'], {
                queryParams: { redirect: state.url },
                replaceUrl: true,
            });
        }

        if (card) {
            if (route.params && route.params.name) {
                const cardDetailModel: CardDetailModel = await this.cardDetailsService.validatePublishedPage(route.params.name);

                if (cardDetailModel && cardDetailModel.isPublished && route.params.name === card.product.pageurl) {
                    canActivate = true;

                    // override styles
                    if (cardDetailModel.styleOptions) {
                        Object.keys(cardDetailModel.styleOptions).forEach((k) => {
                            document.documentElement.style.setProperty(`--card-detail-override-${k}`, cardDetailModel.styleOptions[k]);
                        });
                    }
                } else {
                    this.router.navigate(
                        [identifier ? '/sign-in' : '/home'],
                        identifier
                            ? {
                                  queryParams: { redirect: state.url },
                                  replaceUrl: true,
                              }
                            : {},
                    );
                }
            } else {
                canActivate = true;
                if (card.product && card.product.pageurl) {
                    this.router.navigate(['page', card.product.pageurl, card.identifier]);
                } else {
                    //clear out any card detail models from previous cards.
                    this.cardDetailsService.clear();
                }
            }
        } else {
            if (route.params && route.params.name) {
                const cardDetailModel: CardDetailModel = await this.cardDetailsService.validatePublishedPage(route.params.name);
                
                if(!card && identifier && 
                    window.location.pathname.indexOf(route.params.name) != -1 &&
                    window.location.pathname.indexOf(identifier) != -1) {
                    this.router.navigate(['my-cards']);
                } else {

                    if (cardDetailModel && cardDetailModel.isPublished) {
                        canActivate = true;

                        // override styles
                        if (cardDetailModel.styleOptions) {
                            Object.keys(cardDetailModel.styleOptions).forEach((k) => {
                                document.documentElement.style.setProperty(`--card-detail-override-${k}`, cardDetailModel.styleOptions[k]);
                            });
                        }
                    } else {
                        this.router.navigate(
                            [identifier ? '/sign-in' : '/home'],
                            identifier
                                ? {
                                    queryParams: { redirect: state.url },
                                    replaceUrl: true,
                                }
                                : {},
                        );
                    }
                }
            } else {
                if (this.credentials.isAuthenticated() && identifier && !card) {
                    this.router.navigate(['my-cards']);
                } else {
                    this.router.navigate(
                        [identifier ? '/sign-in' : '/home'],
                        identifier
                            ? {
                                  queryParams: { redirect: state.url },
                                  replaceUrl: true,
                              }
                            : {},
                    );
                }
            }
        }

        return canActivate;
    }
}
