import { ContentEditorFileUploadService } from './../../services/content-editor-file-upload.service';
import { TranslateService } from '@ngx-translate/core';
import { ContentEditorModel, FilterValue } from './../../models/content-editor.model';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { Component, OnInit, NgZone, Renderer2, ChangeDetectorRef, OnDestroy, HostListener } from '@angular/core';
import { ContentEditorService } from '@app/shared/services/content-editor.service';
import { FormGroup, FormControl, FormBuilder, FormArray } from '@angular/forms';
import { BHNTranslateService } from '@app/shared/services/bhn-translate.service';

declare let $: any;

@Component({
    selector: 'app-content-editor',
    templateUrl: './content-editor.component.html',
    styleUrls: ['./content-editor.component.scss'],
    // encapsulation: ViewEncapsulation.None,
})
export class ContentEditorComponent implements OnInit, OnDestroy {
    isEditMode: boolean;
    private editModeSubscription: Subscription;
    contentEditorFormGroup: FormGroup;
    languages: string[];

    constructor(
        public contentEditorService: ContentEditorService,
        private fileUploadService: ContentEditorFileUploadService,
        private router: Router,
        private ngZone: NgZone,
        private renderer: Renderer2,
        private _ref: ChangeDetectorRef,
        private translateService: TranslateService,
        private bhnTranslateService: BHNTranslateService,
        private formBuilder: FormBuilder,
    ) {
        this.editModeSubscription = this.contentEditorService.isInEditMode().subscribe((editing) => {
            this.isEditMode = editing;
        });

        this.bhnTranslateService.getDefaultCountrySetting().then((x) => (this.languages = x.languages));
    }

    ngOnInit() {
        this.contentEditorFormGroup = new FormGroup({
            culture: new FormControl(this.translateService.currentLang),
            segments: this.formBuilder.array([]),
        });

        // Initial Set up
        this.contentEditorService.initialSetUp(this.translateService.currentLang);

        // Build segments form array dynamically
        this.contentEditorService.segmentList.subscribe((data) => {
            if (data !== undefined && data !== null && data.length > 0) {
                const segmentFormArray = this.contentEditorFormGroup.controls.segments as FormArray;
                data.forEach((resourceFilterType) => {
                    const filterValue: FilterValue = {
                        resourceFilterId: -1,
                        resourceFilterTypeId: resourceFilterType.resourceFilterTypeId,
                        resourceFilterTypeTypeKey: resourceFilterType.typeKey,
                    };
                    segmentFormArray.push(this.formBuilder.group(filterValue));
                });
            }
        });

        this.translateService.onLangChange.subscribe(() => {
            this.contentEditorFormGroup.controls.culture.setValue(this.translateService.currentLang);
        });
    }

    ngOnDestroy() {
        this.editModeSubscription.unsubscribe();
    }

    contentEditorClick() {
        $('.tabstrip-vertical .tab-content-container .tab-wrapper:visible').hide();
        $('.side-instructions-contents').animate(
            {
                width: 'toggle',
            },
            400,
        );
    }

    onEditClick() {
        this.setSelectedFilterList();
        this.ngZone.runOutsideAngular(() => {
            $('[editable]').hallo(this.contentEditorService.editorOptions);
            $('.editableIcon').fadeIn(800);
            this.contentEditorService.setEditMode(true);
            // disable all the editable buttons
            $('[editable]').closest('button').prop('disabled', true);
            // to disable angular routerlink
            $('a[editable]').css('pointer-events', 'none');

            // show all
            $('[editable]').show();

            $('.show-on-content-edit-visible').removeClass('invisible');
            $('.show-on-content-edit-visible').addClass('visible');
            $('.show-on-content-edit-hidden').removeAttr('hidden');

            $('.side-instructions-title').trigger('click');
        });
    }

    onSaveClick() {
        // set the segments value from control to this filter list, might have a better way but will do for now
        this.setSelectedFilterList();
        this.contentEditorService.saveResourceData(this.buildDataModel());

        $('[editable]').hallo({ editable: false });

        $('.editableIcon').fadeOut(400);
        this.contentEditorService.setEditMode(false);

        $('[editable]').closest('button').prop('disabled', false);
        $('a[editable]').css('pointer-events', '');

        $('.show-on-content-edit-visible').removeClass('visible');
        $('.show-on-content-edit-visible').addClass('invisible');
        $('.show-on-content-edit-hidden').prop('hidden', true);

        $('.side-instructions-title').trigger('click');

        this.contentEditorService.selectedFilteredList = [];
    }

    onCancelClick(reload) {
        this.ngZone.runOutsideAngular(() => {
            $('[editable]').hallo({ editable: false });

            $('.editableIcon').fadeOut(400);
            this.contentEditorService.setEditMode(false);
            $('[editable]').closest('button').prop('disabled', false);
            $('a[editable]').css('pointer-events', '');

            // hide error messages
            $('.show-on-content-edit-visible').removeClass('visible');
            $('.show-on-content-edit-visible').addClass('invisible');
            $('.show-on-content-edit-hidden').prop('hidden', true);
        });
        if (reload) {
            // this.redirectTo(this.router.url);
            this._ref.markForCheck();
        }
        this.contentEditorService.selectedFilteredList = [];
    }

    // Hacky "HARD" refresh
    onRefreshClick() {
        this.refresh();
    }

    refresh() {
        this.bhnTranslateService.storeLanguage(this.contentEditorFormGroup.controls.culture.value);

        this.setSelectedFilterList();

        // Culture selected value
        this.translateService.use(this.contentEditorFormGroup.controls.culture.value);

        // Client program stuff for
        const clientProgramIdFilter = this.contentEditorService.selectedFilteredList.filter((x) => {
            return x.resourceFilterTypeTypeKey === 'clientProgram';
        });
        // ProductClientProgramId (product code)
        const productClientProgramIdFilter = this.contentEditorService.selectedFilteredList.filter((x) => {
            return x.resourceFilterTypeTypeKey === 'productClientProgram';
        });

        if (this.router.url.split('?')[0] === '/faq' && clientProgramIdFilter.length > 0) {
            this.router.navigateByUrl('~/', { skipLocationChange: true }).then(() =>
                this.router.navigate(['/faq'], {
                    queryParams: {
                        clientProgramId: clientProgramIdFilter[0].resourceFilterId,
                        productClientProgramId: productClientProgramIdFilter[0].resourceFilterId,
                    },
                }),
            );
        } else {
            this.redirectTo(this.router.url);
        }
    }

    // Yes this is Donkey
    // TODO: WE NEED TO STRIP OUT HALLOJS AND BUILD A STRAIGHT ANGULAR HTML5 EDITOR
    @HostListener('document:contentEditor-fileUpload', ['$event.detail'])
    public onContentEditorFileUpload(formData: FormData) {
        // console.log(formData.get('file'));
        this.fileUploadService.imageUpload(formData);
    }

    redirectTo(uri) {
        if (uri !== '') {
            this.router.navigateByUrl('~/', { skipLocationChange: true }).then(() => this.router.navigate([uri]));
        }
    }

    private buildDataModel() {
        const editableElements = document.querySelectorAll('[editable]');
        const contentEditorModelList = new Array<ContentEditorModel>();

        for (let i = 0, len = editableElements.length; i < len; i++) {
            const element = editableElements[i];

            if (element.id === null) {
                throw new Error('the following element is missing an "id" attribute: ' + element);
            }
            if (element.classList.contains('isModified')) {
                element.setAttribute('modified', 'true');
            }

            contentEditorModelList.push(
                new ContentEditorModel({
                    name: element.id,
                    setName: element.hasAttribute('editable-container-item') ? '/' : this.router.url.split('?')[0],
                    value: this.getElementValue(
                        element.firstChild !== undefined &&
                            element.firstChild !== null &&
                            element.firstChild.nodeName !== undefined &&
                            element.firstChild.nodeName !== null &&
                            element.firstChild.nodeName === 'IMG'
                            ? (element.firstChild as Element)
                            : element,
                    ),
                    cultureName: this.contentEditorFormGroup.controls.culture.value,
                    filter: this.contentEditorService.selectedFilteredList,
                }),
            );
        }

        // console.log(contentEditorModelList);
        return contentEditorModelList;
    }

    private getElementValue(elm: Element) {
        let elementValue: string;
        let tag: string;

        tag = elm.nodeName.toLowerCase();

        switch (tag) {
            case 'input':
                if (elm.getAttribute('type') === null || elm.getAttribute('type') === 'text') {
                    if (elm.getAttribute('value') !== '') {
                        elementValue = elm.getAttribute('value');
                        this.renderer.setProperty(elm, 'value', '');
                        this.renderer.setProperty(elm, 'placeholder', elementValue);
                    } else {
                        elementValue = elm.getAttribute('placeholder');
                    }
                }
                break;
            case 'img':
                elementValue = JSON.stringify({
                    src: elm.getAttribute('src').trim(),
                    width: elm.getAttribute('width'),
                    height: elm.getAttribute('height'),
                    alt: elm.getAttribute('alt'),
                });
                break;
            default:
                elementValue = elm.innerHTML.trim();
                break;
        }

        return elementValue.indexOf('src=""') === -1 ? elementValue : '';
    }

    private setSelectedFilterList(): void {
        // Segment selected values
        this.contentEditorService.selectedFilteredList = [];
        this.contentEditorService.selectedFilteredList = this.contentEditorFormGroup.controls.segments.value;
        this.contentEditorService.selectedFilteredList.forEach((x) => {
            x.resourceFilterId = Number(x.resourceFilterId); // Make sure selected value is number
            x.resourceFilterTypeId = Number(x.resourceFilterTypeId);
        });
    }
}
