import { NGXLogger } from 'ngx-logger';
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class ErrorHandlerLogService {
    constructor(private log: NGXLogger) {}
    logError(error: any) {
        if (error instanceof HttpErrorResponse) {
            this.log.error('There was an HTTP error.', error.message, {
                httpstatuscode: (error as HttpErrorResponse).status,
            });
        } else if (error instanceof TypeError) {
            this.log.error('There was a Type error.', { name: error.name, message: error.message, stack: error.stack });
        } else if (error instanceof Error) {
            this.log.error('There was a general error.', { name: error.name, message: error.message, stack: error.stack });
        } else {
            this.log.error('Nothing Thrown, but something happened!', error);
        }
    }
}
