import { Component, OnInit, Input } from '@angular/core';
import { AddressModel } from '@app/shared/models/address.model';
import { AddressService } from '@app/shared/services/address.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-address-verification-modal',
    templateUrl: './address-verification-modal.component.html',
    styleUrls: ['./address-verification-modal.component.scss'],
})
export class AddressVerificationModalComponent implements OnInit {
    enteredAddress: AddressModel;
    validatedAddress: AddressModel;

    constructor(private activeModal: NgbActiveModal, private addressService: AddressService) {}

    ngOnInit() {
        this.enteredAddress = this.addressService.enteredAddress;
        this.validatedAddress = this.addressService.validatedAddress;
    }

    dismissModal(returnValue) {
        this.activeModal.close(returnValue);
    }
}
