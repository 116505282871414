import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

import { DOCUMENT } from '@angular/common';
import { BHNModalService } from '@app/shared/services/bhn-modal.service';
import { WINDOW } from '@app/core/services/window.service';
import { ENVIRONMENT } from '@app/core/services/environment.service';
import { regExpEscape } from '@ng-bootstrap/ng-bootstrap/util/util';

@Injectable({
    providedIn: 'root',
})
export class ExternalLinkInterceptorService {
    constructor(
        @Inject(PLATFORM_ID) private platformId: object,
        @Inject(DOCUMENT) private documentRef: Document,
        @Inject(WINDOW) private windowRef: Window,
        @Inject(ENVIRONMENT) private environment,
        private modalService: BHNModalService,
    ) {}

    hookLinkClicks() {
        if (isPlatformBrowser(this.platformId)) {
            this.documentRef.onclick = this.interceptClicks.bind(this);
        }
    }

    interceptClicks(event: Event) {
        const link = event.target as HTMLAnchorElement;
        if (link.tagName === 'A' && link.href) {
            const url = link.href;

            const reEscapedHostname = window.location.hostname.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&');
            const localPattern = new RegExp(`^${window.location.protocol}\\/\\/${reEscapedHostname}:?\\d*\\/`);

            if (localPattern.test(url)) return;

            let trusted = false;

            if (this.environment && this.environment.trustedLinkPatterns) {
                trusted = this.environment.trustedLinkPatterns.reduce((found, pattern) => {
                    return found || pattern.test(url);
                }, false);
            }
            if (url && !trusted) {
                this.modalService
                    .showAlert(
                        '^external-link-modal.message',
                        '^external-link-modal.title', //
                        ['^external-link-modal.continue', '^external-link-modal.cancel'],
                    )
                    .then((result) => {
                        if (result === '^external-link-modal.continue') {
                            this.windowRef.open(url, '_blank', 'noreferrer');
                        }
                    });
                event.preventDefault();
            }
        }
    }
}
