import { Injectable, Inject } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { HttpClient } from '@angular/common/http';
import { CreateUserRequest } from '../models/create-profile-request-model';
import { UserProfile } from '../models/user-profile-model';
import { BaseResponse } from '@app/shared/models/base-response-model';
import { ENVIRONMENT } from '@app/core/services/environment.service';
import { CredentialsService } from '@app/core/authentication/credentials.service';
import { ForgotPasswordRequest } from '../models/forgot-password-request.model';
import { SavePasswordRequest } from '../models/save-password-request.model';
import { UserCardAlert, CardAlertSetup,PaginationRequest } from '../models/user-card-alert-model';
import { firstValueFrom } from 'rxjs';
import { UpdateUserProfile } from '../models/update-user-profile-model';

@Injectable({
    providedIn: 'root',
})
export class UserHttpService {
    constructor(
        private credentials: CredentialsService,
        private http: HttpClient,
        private logger: NGXLogger,
        @Inject(ENVIRONMENT) private environment: any,
    ) {}

    async createUser(request: CreateUserRequest, rmsId?: any): Promise<void> {
        const apiRequest$ = this.http.post<BaseResponse>(this.environment.nodeBaseURL + 'user/createUserProfile', request, {
            headers: {
                't-session-id': rmsId ? rmsId : '',
            },
        });
        const response = await firstValueFrom(apiRequest$);
        if (!response.success) {
            this.logger.error('Server error creating profile.', response.errors);
            if (response.errors.includes('username-already-in-use')) {
                throw Error('username-already-in-use');
            }
            if (response.errors.includes('email-already-in-use')) {
                throw Error('email-already-in-use');
            }
            throw Error('error-creating-profile');
        }
    }

    async getCurrentUserProfile(): Promise<UserProfile> {
        if (!this.credentials.isAuthenticated() || !this.credentials.current.isRegisteredEndUser()) {
            throw Error('not-logged-in');
        }
        const apiRequest$ = this.http.post<BaseResponse & { user: UserProfile }>(this.environment.nodeBaseURL + 'user/getUserProfile', {});
        const response = await firstValueFrom(apiRequest$);
        if (!response.success) {
            this.logger.error('Server error retrieving user profile.', response.errors);
            throw Error('error-retrieving-user-profile');
        }
        return response.user;
    }

    async updateUser(request: UpdateUserProfile): Promise<void> {
        const apiRequest$ = this.http.post<BaseResponse>(this.environment.nodeBaseURL + 'user/saveUserProfile', request);
        const response = await firstValueFrom(apiRequest$);
        if (!response.success) {
            this.logger.error('Server error updating user.', response.errors);
            if (response.errors.includes('username-already-in-use')) {
                throw Error('username-already-in-use');
            }
            if (response.errors.includes('email-already-in-use')) {
                throw Error('email-already-in-use');
            }
            throw Error('error-updating-user');
        }
    }

    async resetPassword(request: SavePasswordRequest): Promise<boolean> {
        const apiRequest$ = this.http.post<BaseResponse>(this.environment.nodeBaseURL + 'user/resetForgotPassword', {
            token: request.token,
            newPassword: request.newPassword,
        });
        const response = await firstValueFrom(apiRequest$);

        if (!response.success) {
            if (response.errors.includes('expired-nonexistent-token')) {
                throw Error('expired-nonexistent-token');
            } else {
                throw Error('reset-password-error');
            }
        }

        return response.success;
    }

    async resetPasswordLinkValidation(token: string, rmsID: string) {
        try {
            const apiRequest$ = this.http.get<BaseResponse>(
                this.environment.nodeBaseURL + 'user/validateResetForgotPasswordLink/?token=' + token,
                {
                    headers: {
                        't-session-id': rmsID ? rmsID : '',
                    },
                },
            );
            const response = await firstValueFrom(apiRequest$);
            return response.success;
        } catch (error) {
            this.logger.error('Reset password link invalid.', error);
        }
    }

    async getUserCardAlerts(request:PaginationRequest,rmsSessionId?: any): Promise<UserCardAlert[]> {
        if (!this.credentials.isAuthenticated() || !this.credentials.current.isRegisteredEndUser()) {
            throw Error('not-logged-in');
        }
        const apiRequest$ = this.http.post<BaseResponse & UserCardAlert[]>(
            this.environment.nodeBaseURL + 'card/getUserCardAlerts',
            request,
            {
                headers: {
                    't-session-id': rmsSessionId ? rmsSessionId : '',
                },
            },
        );
        const response = await firstValueFrom(apiRequest$);
        if (!response.success) {
            this.logger.error('Server error retrieving user card alerts.', response.errors);
            throw Error('error-retrieving-user-card-alerts');
        }
        return response;
    }

    async saveUserCardAlerts(userCardAlerts: UserCardAlert[]): Promise<boolean> {
        const apiRequest$ = this.http.post<BaseResponse>(this.environment.nodeBaseURL + 'card/saveCardAlertSettings', {
            alerts: userCardAlerts,
        });
        const response = await firstValueFrom(apiRequest$);

        if (!response.success) {
            this.logger.error('Server error saving user card alerts.', response.errors);
            throw Error('error-saving-user-card-alerts');
        }

        return response.success;
    }

    async checkEmailExists(email: string): Promise<boolean> {
        const apiRequest$ = this.http.get<BaseResponse>(this.environment.nodeBaseURL + 'user/checkEmailExists', {
            params: { email: email },
        });
        const response = await firstValueFrom(apiRequest$);
        if (!response.success) {
            this.logger.error('Server error retriving user email existence.', response.errors);
            throw Error('processing-error');
        }
        return response.userExists;
    }

    async getCardAlertSetup(request: CardAlertSetup, rmsID: string): Promise<UserCardAlert[]> {
        if (!this.credentials.isAuthenticated() || !this.credentials.current.isRegisteredEndUser()) {
            throw Error('not-logged-in');
        }
        const apiRequest$ = this.http.post<BaseResponse & { alerts: UserCardAlert[] }>(
            this.environment.nodeBaseURL + 'card/getUserCardAlertsWithPCN',
            request,
            {
                headers: {
                    't-session-id': rmsID ? rmsID : '',
                },
            },
        );
        const response = await firstValueFrom(apiRequest$);
        if (!response.success) {
            this.logger.error('Server error retrieving user card alerts.', response.errors);
            throw Error('error-retrieving-user-card-alerts');
        }
        return response.alerts;
    }
}
