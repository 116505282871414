<ng-container *ngIf="!tooltipConfigs?.isHTML">
    <span
        class="register-card__tooltip custom_border"
        *ngIf="tooltipConfigs?.toggleToolTip && !dataPatchMsg"
        [ngStyle]="{
            'top': tooltipConfigs.top,
            'left': tooltipConfigs.left,
            'right': tooltipConfigs.right,
            'position': tooltipConfigs.position,
            'display': tooltipConfigs.display,
            'margin-top': tooltipConfigs.marginTop,
            'margin-left': tooltipConfigs.marginLeft
        }"
        aria-hidden="true"
    >
        {{ tooltipConfigs.toolText | translate }}
    </span>
</ng-container>

<ng-container *ngIf="tooltipConfigs?.isHTML">
    <span
        class="register-card__tooltip custom_border"
        *ngIf="tooltipConfigs?.toggleToolTip && !dataPatchMsg"
        [ngStyle]="{
            'top': tooltipConfigs.top,
            'left': tooltipConfigs.left,
            'right': tooltipConfigs.right,
            'position': tooltipConfigs.position,
            'display': tooltipConfigs.display,
            'margin-top': tooltipConfigs.marginTop,
            'margin-left': tooltipConfigs.marginLeft
        }"
        aria-hidden="true"
        [innerHTML]="tooltipConfigs.toolText"
    >
    </span>
</ng-container>

<span
    id="{{ tooltipConfigs?.id }}"
    editable
    translate
    class="register-card__tooltip custom_border"
    *ngIf="tooltipConfigs?.toggleToolTip && dataPatchMsg"
    [ngStyle]="{
        'top': tooltipConfigs.top,
        'left': tooltipConfigs.left,
        'right': tooltipConfigs.right,
        'position': tooltipConfigs.position,
        'display': tooltipConfigs.display,
        'margin-top': tooltipConfigs.marginTop,
        'margin-left': tooltipConfigs.marginLeft
    }"
    aria-hidden="true"
>
    {{ tooltipConfigs?.id }}
</span>
