import { CommonHttpService } from '@app/shared/http/common-http.service';
import { Injectable, Inject } from '@angular/core';
import { ENVIRONMENT } from '@app/core/services/environment.service';

@Injectable({
    providedIn: 'root',
})
export class ContentEditorFileUploadService {
    constructor(private commonHttp: CommonHttpService, @Inject(ENVIRONMENT) private environment) {}

    // TODO: Spec. observable return type
    imageUpload(formData: FormData) {
        // console.log('image uploading');
        return this.commonHttp.uploadFile(formData).then((res: any) => {
            document.dispatchEvent(
                new CustomEvent('contentEditor-fileUpload-complete', {
                    detail: {
                        response: res,
                        url: this.environment.baseS3URL,
                    },
                }),
            );
        });
    }
}
