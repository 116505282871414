import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseComponent } from '@app/base/base.component';
import { CardInformation } from '@app/services/card/models/card-information.model';
import { CardDataService } from '@app/shared/services/card-data.service';
import { CommonHttpService } from '@app/shared/http/common-http.service';
import { MPC, ACTION } from '@app/shared/constants/common.constants';
import { firstValueFrom } from 'rxjs/internal/firstValueFrom';
import { TranslateService } from '@ngx-translate/core';
import { DeepLinkResponseModel } from '@app/shared/models/deep-link-response.model';
import { AddToWalletService } from '@app/shared/services/add-to-wallet.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { RegistrationService } from '@app/services/card/registration.service';
import {  CommonService, Countries } from '@app/shared/services/common.service';

export enum State {
    EnterPhoneNumber,
    InvalidPhoneNumber,
    ProcessingError,
    SMSError,
    Success,
    Processing,
}
@Component({
    selector: 'app-send-me-the-app',
    templateUrl: './send-me-the-app.component.html',
    styleUrls: ['./send-me-the-app.component.scss'],
})
export class SendMeTheAppComponent extends BaseComponent implements OnInit {
    State = State;
    state: State;
    sendMeAppFormGroup: FormGroup;
    sendSmsButtonText: string = '^fluid-card-details.send-sms-link-btn';
    card?: CardInformation;
    validPhoneEntered: any = null;
    countryCodes: any 
    countryCodesmodel:any;
    displayWalletHelp: boolean = true;
    showSpinner: boolean;
    countries: Countries;

    constructor(
        public cardData: CardDataService,
        public commonHttpService: CommonHttpService,
        public commonService: CommonService,
        private translate: TranslateService,
        private addToWalletService: AddToWalletService,
        private deviceDetector: DeviceDetectorService,
        private registrationService: RegistrationService,

    ) {
        super();
        this.subscriptions = [
            this.cardData.selected$.subscribe((card) => {
                this.card = card;
            }),
        ];
    }

    ngOnInit(): void {
        this.state = State.EnterPhoneNumber;
        this.sendMeAppFormGroup = new FormGroup({
            phoneNumber: new FormControl(null, [
                Validators.required,
                Validators.maxLength(12),
                Validators.minLength(9),
                Validators.pattern(/^(|[\d-+()\ ]{9,12})$/),
            ]),
            countryCode: new FormControl(null, [ Validators.required]),
        });
        this.commonService.clientProgramId = this.card?.product?.clientProgramId;
        this.commonService.productCode = this.card?.product?.productCode;
        this.countries = this.registrationService.getCountries();
        this.countries.$.subscribe((value) => {
            this.countryCodes = value.filter((x) => x.phonecode != null);    
        });
        this.countryCodesmodel="+61";
}

    describedBy(): string {
        switch (this.state) {
            case State.EnterPhoneNumber:
                return '^fluid-card-details.phone-number-placeholder';
            case State.InvalidPhoneNumber:
                return '^fluid-card-details.invalid-phone-number';
            case State.SMSError:
                return '^fluid-card-details.sms-error-msg';
            case State.ProcessingError:
                return '^fluid-card-details.processing-error-msg';
            default:
                return '^fluid-card-details.phone-number-placeholder';
        }
    }

    async onSubmit() {
        if (
            this.sendMeAppFormGroup.valid &&
            this.sendMeAppFormGroup.controls.phoneNumber.value &&
            this.sendMeAppFormGroup.controls.countryCode.value
        ) {
            this.validPhoneEntered = this.sendMeAppFormGroup.controls.phoneNumber.value;
            // if (this.validPhoneEntered.indexOf(0) === 0) this.validPhoneEntered = this.validPhoneEntered.slice(1);
            this.displayWalletHelp = false;
            if (this.isMobileOrTabletDevice()) this.addToWallet();
        } else {
            this.state = State.InvalidPhoneNumber;
        }
    }

    async sendSMS() {
        this.sendSmsButtonText = await firstValueFrom(this.translate.get('^fluid-card-details.sending-sms-btn-txt'));

        this.validPhoneEntered = this.sendMeAppFormGroup.controls.countryCode.value.slice(1) +  this.sendMeAppFormGroup.controls.phoneNumber.value;;
        try {
            this.state = State.Processing;
            const lastFourPhone =
                this.validPhoneEntered && this.validPhoneEntered.length > 4
                    ? this.validPhoneEntered.substring(this.validPhoneEntered.length - 4)
                    : '';
            let mpcMobileDeepLink: DeepLinkResponseModel = await this.commonHttpService.generateDeepLink(
                MPC,
                this.card.proxyCardNumber,
                lastFourPhone,
                ACTION,
            );
            let smsBody = await firstValueFrom(this.translate.get('^fluid-card-details.sendMeAppSMSbody'));
            smsBody += mpcMobileDeepLink.url;
            await this.commonHttpService.sendSMS(this.validPhoneEntered, smsBody);
            this.sendSmsButtonText = await firstValueFrom(this.translate.get('^fluid-card-details.sentBtn'));
            this.state = State.Success;
        } catch (error) {
            if (error.message === 'error-sending-sms') {
                this.state = State.SMSError;
            } else {
                this.state = State.ProcessingError;
            }
            this.sendSmsButtonText = await firstValueFrom(this.translate.get('^fluid-card-details.send-sms-link-btn'));
        }
    }

    async addToWallet() {
        this.showSpinner = true;
        const lastFourPhone =
            this.validPhoneEntered && this.validPhoneEntered.length > 4
                ? this.validPhoneEntered.substring(this.validPhoneEntered.length - 4)
                : '';
        this.addToWalletService
            .openLink(this.card.proxyCardNumber, lastFourPhone)
            .then(() => {
                this.showSpinner = false;
            })
            .catch(() => {
                this.showSpinner = false;
            });
    }

    isMobileOrTabletDevice() {
        return this.deviceDetector.isMobile() || this.deviceDetector.isTablet();
    }
}
