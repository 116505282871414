import { NGXLogger } from 'ngx-logger';
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { CardDataService } from '@app/shared/services/card-data.service';
import { CardActivationType } from '@app/shared/enum/card-activation-type.enum';
import { firstValueFrom } from 'rxjs';

@Injectable()
export class ActivationGuard implements CanActivate {
    constructor(
        private router: Router,
        private cardData: CardDataService,
        private log: NGXLogger,
    ) {}

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        const card = await firstValueFrom(this.cardData.selected$);

        // if (card.activated) {
        //     this.log.debug('[data-capture] Card already activated.');
        //     this.router.navigate(['/card-features', card.proxyCardNumber], { replaceUrl: true });
        //     return false;
        // }

        // if (card.activationType !== CardActivationType.DataCapture) {
        //     this.log.debug('[data-capture] Invalid card for data capture.');
        //     this.router.navigate(['/login/card'], { replaceUrl: true });
        //     return false;
        // }

        return true;

    }
}
