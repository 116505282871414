import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { SetFocusService } from '../services/set-focus.service';

@Directive({
    selector: '[appSetFocus]',
})
export class SetFocusDirective implements OnInit, OnDestroy {
    @Input() appSetFocus: string;
    private _onFocusChangeSub: Subscription;

    constructor(private el: ElementRef, private setFocus: SetFocusService) {}

    ngOnInit(): void {
        this._onFocusChangeSub = this.setFocus.onFocusChange$.subscribe((focusToId) => {
            if (this.appSetFocus === focusToId) {
                (this.el.nativeElement as HTMLElement).focus({ preventScroll: true });
            }
        });
    }

    ngOnDestroy(): void {
        if (this._onFocusChangeSub) {
            this._onFocusChangeSub.unsubscribe();
        }
    }
}
