import { Injectable, Inject } from '@angular/core';
import { WINDOW } from '@app/core/services/window.service';
import { ENVIRONMENT } from '@app/core/services/environment.service';
import { CardHttpService } from '@app/services/card/http/card-http.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddToWalletModalComponent } from '../components/modals/add-to-wallet-modal/add-to-wallet-modal.component';
import { Subject, takeUntil, timer } from 'rxjs';
import { CommonService } from './common.service';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable({ providedIn: 'root' })
export class AddToWalletService {
    private destroy$ = new Subject<void>();

    constructor(
        @Inject(WINDOW) private window: Window,
        @Inject(ENVIRONMENT) private environment: any,
        private cardHttpService: CardHttpService,
        private ngbModal: NgbModal,
        private commonService: CommonService,
        private deviceService: DeviceDetectorService,
    ) {}

    public async openLink(proxyCardNumber: string, cardLastFour: string) {
        const req = {
            proxyCardNumber: proxyCardNumber,
            lastFourPhoneNumber: cardLastFour,
        };
        const resp = await this.cardHttpService.generatePayloadDatawithToken(req);
        this.showAddToWalletModal(resp.token);
    }

    private showAddToWalletModal(token: string) {
        const modalRef = this.ngbModal.open(AddToWalletModalComponent, {
            backdrop: 'static',
            keyboard: false,
            centered: true,
            backdropClass: 'light-black-backdrop',
            windowClass: 'bhn-alert-modal',
        });

        timer(this.commonService.addToWalletTimer)
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                modalRef.close('timeout');
            });

        modalRef.result
            .then((result) => {
                this.destroy$.next();
                if (result === 'ok') {
                    const walletURL = `${this.environment.walletURL.replace('${token}', token)}`;
                    if (this.deviceService.browser.includes('Edge')) {
                        this.window.location.href = walletURL;
                    } else {
                        this.window.open(walletURL, '_blank');
                    }
                }
            })
            .catch(() => {
                this.destroy$.next();
            });
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
