import { Observable, Subject, BehaviorSubject } from 'rxjs';
import {
    ContentEditorModel,
    FilterValue,
    Culture,
    ContentEditorRequestModel,
    InitializeContentModel,
    ResourceFilterOptionModel,
    ConnectionInformationModel,
    ResourceFilterModel,
} from '@app/shared/models/content-editor.model';
import { Injectable, Inject, EventEmitter } from '@angular/core';
import { CommonHttpService } from '../http/common-http.service';
import { DOCUMENT } from '@angular/common';
import { ProductInformation } from '@app/services/card/models/product-information.model';
import { CardHttpService } from '@app/services/card/http/card-http.service';

export interface ContentEditorLangChangeEvent {
    lang: string;
    data: ContentEditorModel[];
    route: string;
}
export interface ContentEditorRouteChangeEvent {
    url: string;
    changed: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class ContentEditorService {
    private _translationKeyMap: Map<string, string>;
    private _resourceList: Array<ContentEditorModel>;
    private editSubject = new Subject<boolean>();
    private _currentLang: string;
    private _onContentEditorLanguageChange: EventEmitter<ContentEditorLangChangeEvent> = new EventEmitter<ContentEditorLangChangeEvent>();
    private _onContentEditorRouteChange: EventEmitter<ContentEditorRouteChangeEvent> = new EventEmitter<ContentEditorRouteChangeEvent>();
    public selectedFilteredList: FilterValue[] = [];
    public connectionInformation: ConnectionInformationModel;
    public segmentList = new BehaviorSubject<ResourceFilterOptionModel[]>([]);
    public translateRoute: string;
    public innerHtmlMap: Array<ContentEditorModel>;
    public content: any;

    public get onContentEditorRouteChangeSub(): EventEmitter<ContentEditorRouteChangeEvent> {
        return this._onContentEditorRouteChange;
    }
    public get onContentEditorLanguageChange(): EventEmitter<ContentEditorLangChangeEvent> {
        return this._onContentEditorLanguageChange;
    }
    get currentLang(): string {
        return this._currentLang;
    }

    set currentLang(currentLang: string) {
        this._currentLang = currentLang;
    }
    public get resourceList(): Array<ContentEditorModel> {
        return this._resourceList;
    }
    public set resourceList(v: Array<ContentEditorModel>) {
        this._resourceList = v;
    }
    public get translationKeyMap(): Map<string, string> {
        return this._translationKeyMap;
    }
    public set translationKeyMap(v: Map<string, string>) {
        this._translationKeyMap = v;
    }

    public editorOptions = {
        editable: true,
        toolbar: 'halloToolbarFixed',
        plugins: {
            halloformat: { formattings: { bold: true, italic: true, strikethrough: true, underline: true } },
            halloheadings: { formatBlocks: ['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'] },
            hallohtml: {},
            'hallo-image-insert-edit': {},
            // https://github.com/bergie/hallo/issues/167#issuecomment-19032398
            // halloimage: {
            //     searchUrl: g => {},
            //     uploadUrl: p => {
            //         console.log(p);
            //     },
            // },
            bescmslink: {},
            // bescmscolorpicker: {},
            hallojustify: {},
            hallolists: {},
            hallooverlay: {},
            halloreundo: {},
            hallotoolbarlinebreak: {},
        },
    };

    constructor(private commonHttp: CommonHttpService, private cardHttpService: CardHttpService, @Inject(DOCUMENT) private document: any) {
        this.selectedFilteredList = new Array<FilterValue>();
        this.translationKeyMap = new Map<string, string>();
        this.connectionInformation = {
            webServer: '',
            dataSource: '',
            initialCatalog: '',
        };
    }

    public setEditMode(editing: boolean) {
        this.editSubject.next(editing);
    }

    public isInEditMode(): Observable<boolean> {
        return this.editSubject.asObservable();
    }

    public saveResourceData(model: ContentEditorModel[]) {
        // console.log(model);

        // Update local item (mostly for testing) or we can wait for save then update if success... todo todiscusss....
        for (let i = 0, len = model.length; i < len; i++) {
            const item = this.resourceList.find((x) => model[i].name === x.name, model[i].name);

            const index = this.resourceList.indexOf(item);

            this.resourceList[index] = model[i];
        }

        // Save off to DB
        this.commonHttp.saveContentEditorData(model);
    }

    public getContentEditorData(requestModel: ContentEditorRequestModel): Observable<ContentEditorModel[]> {
        return this.commonHttp.getContentEditorData(requestModel);
    }

    public initContentEditor(isoCode: string): Observable<InitializeContentModel> {
        return this.commonHttp.initContentEditor(isoCode);
    }

    public getSegments(isoCode: string): Observable<ResourceFilterOptionModel[]> {
        return this.commonHttp.getSegments(isoCode);
    }

    public getCurrentSegment(isoCode: string): Observable<FilterValue[]> {
        return this.commonHttp.getCurrentSegment(isoCode);
    }

    public getResourceFilterTypeList(isoCode: string): Promise<ResourceFilterModel[]> {
        return this.commonHttp.getResourceFilterTypeList(isoCode);
    }

    public initialSetUp(isoCode: string) {
        this.initContentEditor(isoCode).subscribe((data) => {
            this.connectionInformation = data.connectionInformationModel;
            this.connectionInformation.webServer = this.document.location.hostname;
        });

        this.getSegments(isoCode).subscribe((data) => {
            this.segmentList.next(data);
        });
    }

    public getProductCodeId(clientProgramId: number, productCode?: string): Promise<number> {
        return this.commonHttp.getProductCodeId(clientProgramId, productCode);
    }
}
