import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
    template: '',
})
export class BaseComponent implements OnDestroy {
    public subscriptions: Subscription[] = [];
    ngOnDestroy(): void {
        if (this.subscriptions) {
            this.subscriptions.forEach(sub => {
                sub.unsubscribe();
            });
            this.subscriptions = [];
        }
    }
}
