import { Feature, Shell } from '@app/shared/models/application-config.model';
import FluidFeatureSet from './fluid/fluid-feature-set';
import OriginalFeatureSet from './original/original-feature-set';

export interface FeatureSet {
    defaultShell: Shell;
    shells: Map<string, Shell>;
    features: Map<string, Feature>;
}

export const FeatureSets = new Map([
    ...OriginalFeatureSet, //
    ...FluidFeatureSet,
]);
