import { NgModule, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { CommonService } from './shared/services/common.service';

export function init_app(commonService: CommonService) {
    return async (): Promise<void> => await commonService.loadAppSettings();
}

@NgModule({
    imports: [HttpClientModule],
    providers: [CommonService, { provide: APP_INITIALIZER, useFactory: init_app, deps: [CommonService], multi: true }],
})
export class AppConfigModule {}
