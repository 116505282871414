<form [formGroup]="loginForm" novalidate>
    <div class="form-group">
        <div class="row">
            <div class="col-sm">
                <div class="row">
                    <div class="col">
                        <label id="usernameText" for="usernameText" editable translate>user.username</label>
                    </div>
                </div>
                <div class="input-group">
                    <input
                        type="text"
                        class="form-control"
                        id="usernameText"
                        aria-describedby="forgotUserName"
                        required
                        formControlName="username"
                        autocomplete="username"
                    />
                </div>
                <small
                    [hidden]="usernameIsValid()"
                    class="text-danger show-on-content-edit-hidden"
                    id="forgotUserame"
                    role="alert"
                    editable
                    translate
                >
                    user.requiredUsername
                </small>
            </div>
        </div>
        <div class="pb-3"></div>
        <div class="row">
            <div class="col-sm">
                <div class="row">
                    <div class="col">
                        <label editable translate for="userPassword" id="forgotPasswordText" editable>user.password</label>
                    </div>
                    <div class="col text-right">
                        <a (click)="allowRedirection()" [routerLink]="['/forgot-password']" id="forgotPasswordLink" editable translate>user.forgotPassword</a>
                    </div>
                </div>
                <div class="input-group">
                    <input
                        type="password"
                        class="form-control"
                        aria-describedby="passwordInvalidText"
                        id="userPassword"
                        formControlName="password"
                        autocomplete="current-password"
                        required
                    />
                </div>
                <small
                    [hidden]="passwordIsValid()"
                    class="text-danger show-on-content-edit-hidden"
                    id="passwordInvalidText"
                    role="alert"
                    editable
                    translate
                >
                    user.requiredPassword
                </small>
            </div>
        </div>
    </div>
</form>
