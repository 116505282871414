import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({ template: '' })
export class RedirectionComponent {
    constructor(route: ActivatedRoute, router: Router) {
        if (route.snapshot.data.redirectTo) {
            router.navigate([route.snapshot.data.redirectTo], { queryParamsHandling: 'preserve', skipLocationChange: true });
        }
    }
}
