import { ContentEditorService } from '@app/shared/services/content-editor.service';
import { TranslateService, TranslatePipe } from '@ngx-translate/core';
import { ChangeDetectorRef, ElementRef, forwardRef, Inject, Pipe } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';

@Pipe({
    pure: false,
    name: 'translate',
})
export class BHNTranslatePipe extends TranslatePipe {
    private elmRef: ElementRef;
    private elm: HTMLElement;
    private ceService: ContentEditorService;
    constructor(
        @Inject(forwardRef(() => ContentEditorService)) contentEditorService: ContentEditorService,
        @Inject(forwardRef(() => TranslateService)) translateService: TranslateService,
        @Inject(forwardRef(() => ChangeDetectorRef)) _ref: ChangeDetectorRef,
        @Inject(forwardRef(() => ElementRef)) elementRef: ElementRef,
        private route: ActivatedRoute,
        private router: Router,
    ) {
        super(translateService, _ref);
        this.elmRef = elementRef;
        this.elm = elementRef.nativeElement;
        this.ceService = contentEditorService;
    }
    updateValue(key: string, interpolateParams?: object, translations?: any) {
        if (key) {
            if (!key.includes('shell') && !key.includes(' ')) {
                const newKeyPrefix =
                    this.elm instanceof HTMLElement && this.elm?.hasAttribute('translationKeyPrefix')
                        ? this.elm.getAttribute('translationKeyPrefix') && this.elm.getAttribute('translationKeyPrefix').concat('.')
                        : this.route.snapshot?.data.translationKeyPrefix?.concat('.') ?? //
                          this.router.url.substr(1).split('/')[0].split('?')[0].concat('.');

                if (!key.includes(newKeyPrefix) && !key.startsWith('^')) {
                    key = newKeyPrefix.concat(key);
                }
            }
            super.updateValue(key, interpolateParams, translations);
        }
    }

    transform(query: string, ...args: any[]): any {
        if (this.elmRef && this.elmRef.nativeElement.getAttribute && this.elmRef.nativeElement.getAttribute('modified') === 'true') {
            if (this.ceService.innerHtmlMap) {
                const ceModel = this.ceService.innerHtmlMap.find((r) => r.name === this.elmRef.nativeElement.id);
                if (ceModel && ceModel.value) {
                    if (args && args.length > 0) {
                        let innerHTMLhasDynamicParam = false;
                        for (const key in args) {
                            const innerObject = args[key];
                            for (const innerKey in innerObject) {
                                ceModel.value = ceModel.value.replace(`{{${innerKey}}}`, innerObject[innerKey]);
                                if (this.elmRef.nativeElement.innerHTML.includes(`{{${innerKey}}}`)) {
                                    innerHTMLhasDynamicParam = true;
                                }
                            }
                        }
                        if (innerHTMLhasDynamicParam) {
                            this.elmRef.nativeElement.innerHTML = ceModel.value;
                        }
                    }

                    return ceModel.value;
                }
            }
        }
        return super.transform(query, ...args);
    }
}
