<div [formGroup]="CHAForm" role="main" id="main" tabindex="-1" appSetFocus="main" class="cha-container">
    <div class="cha-container-wrapper">
        <div class="justify-content-center mt-4 mb-4">
            <div class="col activeCardAgreement">
                <p id="termsAgreementHeader" for="termsAndConditions">
                    <span id="addressAgreementHeader" editable [attr.aria-label]="'^cha.agreementHeader' | translate" translate
                        >^cha.agreementHeader</span
                    >
                </p>
                <button
                    type="button"
                    class="print-icon fa fa-file-pdf-o fa-2x mb-1 mr-2 border-0 bg-white f-right"
                    [attr.aria-label]="'address.exportPDF' | translate"
                    (click)="exportToFile('save')"
                ></button>
            </div>
            <div class="col">
                <span class="terms-link-message" #termsLinkMessage></span>
            </div>
            <div class="col">
                <div class="termsContent">
                    <div id="adobe-dc-view"></div>
                </div>
            </div>
        </div>
        <div>
            <div class="col-11">
                <label class="customCheckbox" translate>
                    ^cha.acceptTermsEsign
                    <input type="checkbox" formControlName="termsAcceptedEsign" />
                    <span class="checkmark fa"></span>
                </label>
                <label class="customCheckbox">
                    <input type="checkbox" formControlName="termsAcceptedCard" />
                    <span class="checkmark fa"></span>
                    <span translate editable id="acceptTermsCardDefault">^cha.acceptTermsCardDefault</span>
                </label>
            </div>

            <div class="col-11">
                <div [class]="termsAcceptedErrorVisibility()">
                    <small
                        class="text-danger"
                        id="addressTermsNotAccepted"
                        editable
                        [attr.aria-label]="'^cha.termsNotAccepted' | translate"
                        translate
                        >^cha.termsNotAccepted</small
                    >
                </div>
            </div>
        </div>
    </div>
</div>
