<footer class="border">
    <div>
        <ul id="footerList" class="d-flex flex-wrap justify-content-center list-unstyled m-0">
            <li class="footer-item text-center">
                <ng-container *ngIf="isExternalTnC; then external_tnc; else internal_tnc"> </ng-container>
                <ng-template #external_tnc>
                    <a
                        [href]="termsOfUse"
                        target="_blank"
                        id="shell.footer.termsOfUse"
                        editable
                        editable-container-item
                        [innerHTML]="'shell.footer.termsOfUse' | translate"
                    ></a>
                </ng-template>
                <ng-template #internal_tnc>
                    <a
                        [routerLink]="termsOfUse"
                        id="shell.footer.termsOfUse"
                        editable
                        editable-container-item
                        [innerHTML]="'shell.footer.termsOfUse' | translate"
                    ></a>
                </ng-template>
            </li>
            <li class="footer-item text-center">
                <a
                    routerLink="/registered-marks"
                    id="shell.footer.registeredMarks"
                    editable
                    editable-container-item
                    [innerHTML]="'shell.footer.registeredMarks' | translate"
                >
                </a>
            </li>
            <li class="footer-item text-center" *ngIf="privacyPolicyUrl">
                <a
                    [href]="privacyPolicyUrl"
                    target="_blank"
                    id="shell.footer.privacyStatement"
                    editable
                    editable-container-item
                    [innerHTML]="'shell.footer.privacyStatement' | translate"
                ></a>
            </li>
            <li class="footer-item text-center">
                <a
                    [href]="accessibilityStatement"
                    target="_blank"
                    id="shell.footer.accessibilityStatement"
                    editable
                    editable-container-item
                    [innerHTML]="'shell.footer.accessibilityStatement' | translate"
                ></a>
            </li>
            <li class="footer-item text-center">
                <a
                    routerLink="/prohibited-countries"
                    id="shell.footer.prohibitedCountries"
                    editable
                    editable-container-item
                    [innerHTML]="'shell.footer.prohibitedCountries' | translate"
                ></a>
            </li>
            <li class="footer-item text-center">
                <a
                    routerLink=""
                    (click)="goToFAQ()"
                    id="shell.footer.faq"
                    editable
                    editable-container-item
                    [innerHTML]="'shell.footer.faq' | translate"
                ></a>
            </li>
            <li class="footer-item text-center">
                <a
                    routerLink="/contactus"
                    id="shell.footer.contactUs"
                    editable
                    editable-container-item
                    [innerHTML]="'shell.footer.contactUs' | translate"
                ></a>
            </li>
            <li class="footer-item text-center" *ngIf="this.showTranslationAssistanceLink">
                <a
                    routerLink="/translationassistance"
                    id="shell.footer.translationAssistance"
                    editable
                    editable-container-item
                    [innerHTML]="'shell.footer.translationAssistance' | translate"
                ></a>
            </li>
            <li class="footer-item text-center">
                <div id="teconsent"></div>
            </li>
        </ul>
    </div>
    <hr />
    <div class="container-fluid">
        <div class="row">
            <div class="col-11 col-md-6 col-lg-4 col-xl-3 mx-auto">
                <div
                    class="row border rounded pb-2 language-container"
                    (click)="showLanguageModal()"
                    tabindex="0"
                    (keydown.enter)="showLanguageModal()"
                    role="button"
                >
                    <div class="col-10">
                        <div class="row">
                            <div class="col-xl-7 col-lg-6 col-md-6 col-sm-12">
                                <div class="pb-2"></div>
                                <span
                                    class="language-header-text"
                                    id="shell.footer.country"
                                    editable
                                    [innerHTML]="'shell.footer.country' | translate"
                                >
                                    {{ 'shell.footer.country' | translate }}
                                </span>
                                <div class="pb-1"></div>
                                <div>
                                    <img alt="" class="mr-2 mb-1" [src]="'../../../assets/images/' + this.getCurrentCountry() + '.svg'" />
                                    <span class="language-sub-text">{{ '^country-language.' + this.getCurrentCountry() | translate }}</span>
                                </div>
                            </div>
                            <div class="col-xl-5 col-lg-6 col-md-6 col-sm-12">
                                <div class="pb-2"></div>
                                <span class="language-header-text" id="shell.footer.language" editable>{{
                                    'shell.footer.language' | translate
                                }}</span>
                                <div class="pb-1"></div>
                                <span class="language-sub-text">{{ '^country-language.' + this.getStoredLanguage() | translate }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-2 mt-auto mb-auto">
                        <div class="row float-right">
                            <div class="col-12">
                                <i class="fa fa-angle-right fa-lg icon-color"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <section id="shell.footer.socialMediaSection" editable></section>
    <div class="row mx-auto pb-5">
        <div class="col-xl-2 col-1"></div>
        <div class="col-xl-8 col-md-10 col-12" style="word-wrap: break-word">
            <section>
                <div class="d-inline-block p-3 text-md-left text-center">
                    <div
                        class="footerFont"
                        id="shell.footer.footerCompliance"
                        editable
                        editable-container-item
                        [innerHTML]="'shell.footer.footerCompliance' | translate : { copyrightYear: currentYear }"
                    ></div>
                    <!-- <p class="footerFont" [innerHTML]="complianceKey$ | async | translate"></p> -->
                </div>
            </section>
        </div>
        <div class="col-2"></div>
    </div>
</footer>
