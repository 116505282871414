<div class="floating-form additional-info">
	<h1 class="form-header" translate>{{mainHeader}}</h1>
	<form [formGroup]="additionalInfoFormGroup" (ngSubmit)="submit()">
        <app-form-field-error-link [formGroup]="additionalInfoFormGroup" [visible]="state === State.ValidationFailed"></app-form-field-error-link>
            <input id="consent" type="checkbox" class="checkmark" formControlName="consent" [ngClass]="{'border-error-checkbox' : this.additionalInfoFormGroup.controls.consent.errors && submitted}"/>
            <p  class="consent-lable" [innerHTML]="subHeader | translate"></p>
            <div class="clearfix"></div>
            <app-field-error
                [type]="'errorOnly'"
                [parentForm]="additionalInfoFormGroup"
                fieldName="consent"
                [errorMessage]="'^additional-info.consentRequired'"
                [id]="'consent'"
            ></app-field-error>
		<div class="additional-info-row mt-4">
			<!-- Occupation Dropdown-->
			<div class="additional-info-column form-spacer">
				<label editable [translate]="'^additional-info.occupation'" for="occupation"></label>
				<div dropdown #occupationDropdown="bs-dropdown" tabindex="0" (keydown.enter)="occupationDropdown.show()" aria-describedby="occupationCategory">
					<div dropdownToggle role="button" data-toggle="dropdownCountry" class="dropdown-shell" [ngClass]="{'border-error' : this.additionalInfoFormGroup.controls.occupationCategory.errors && submitted}">
						<div class="m-3" [ngClass]="{'d-flex': this.additionalInfoFormGroup.controls.occupationCategory.value }"> <span *ngIf="this.additionalInfoFormGroup.controls.occupationCategory.value" class="dropdown-text" [ngClass]="{'dropdown-text-fixed-width': this.additionalInfoFormGroup.controls.occupationCategory.value }">{{
                            this.additionalInfoFormGroup.controls.occupationCategory.value
                        }}</span> <span *ngIf="!this.additionalInfoFormGroup.controls.occupationCategory.value" editable translate>^additional-info.select</span> <i class="fa fa-angle-down fa-lg icon-color float-right mt-1"></i> </div>
					</div>
					<div class="dropdown-menu-shell">
						<ul *dropdownMenu class="dropdown-menu">
							<li class="dropdown-item" *ngFor="let category of occupationCategoryList?.occupationCategoriesModel" tabindex="0" (click)="occupationSelected(category)" tabindex="0" (keydown.enter)="occupationSelected(category); hideDropdown(occupationDropdown)"> <span>{{ category.occupationcategoryname }}</span> </li>
						</ul>
					</div>
					<app-field-error [type]="'errorOnly'" [parentForm]="additionalInfoFormGroup" fieldName="occupationCategory" [errorMessage]="'^additional-info.occupationCategoryRequired'" [id]="'occupationCategory'"></app-field-error>
				</div>
			</div>
			<!-- Occupation Dropdown-->
			<!-- Job Dropdown-->
			<div class="additional-info-column">
				<label editable [translate]="'^additional-info.job'" for="job"></label>
				<div dropdown #jobDropdown="bs-dropdown" tabindex="0" (keydown.enter)="jobDropdown.show()" aria-describedby="job">
					<div dropdownToggle role="button" data-toggle="dropdownCountry" class="dropdown-shell" [ngClass]="{'border-error' : this.additionalInfoFormGroup.controls.job.errors && submitted}">
						<div class="m-3" [ngClass]="{'d-flex': this.additionalInfoFormGroup.controls.job.value }"> <span *ngIf="this.additionalInfoFormGroup.controls.job.value" class="dropdown-text" [ngClass]="{'dropdown-text-fixed-width': this.additionalInfoFormGroup.controls.job.value }">{{
                            this.additionalInfoFormGroup.controls.job.value
                        }}</span> <span *ngIf="!this.additionalInfoFormGroup.controls.job.value" editable translate>^additional-info.select</span> <i class="fa fa-angle-down fa-lg icon-color float-right mt-1"></i> </div>
					</div>
					<div class="dropdown-menu-shell">
						<ul *dropdownMenu class="dropdown-menu">
                            <ng-container *ngIf="jobbyOccupationCategoryID?.occupationModel.length else noJobAvailable">
							    <li class="dropdown-item" *ngFor="let job of jobbyOccupationCategoryID?.occupationModel" tabindex="0" (click)="jobSelected(job)" tabindex="0" (keydown.enter)="jobSelected(job); hideDropdown(jobDropdown)"> <span>{{ job.occupationname }}</span> </li>
                            </ng-container>
                            <ng-template #noJobAvailable>
                                <li class="dropdown-item"  tabindex="0"> <span>No Data Available</span> </li>
                            </ng-template>
						</ul>
					</div>
				</div>
				<app-field-error [type]="'errorOnly'" [parentForm]="additionalInfoFormGroup" fieldName="job" [errorMessage]="'^additional-info.jobRequired'" [id]="'job'"></app-field-error>
			</div>
		</div>
		<!-- Job Dropdown-->
		<!-- Account Intented Dropdown-->
		<div class="additional-info-row">
			<div class="additional-info-column form-spacer">
				<label editable [translate]="'^additional-info.intentedAccount'" for="intentedAccount"></label>
				<div dropdown #accountIntendedDropdown="bs-dropdown" tabindex="0" (keydown.enter)="accountIntendedDropdown.show()" aria-describedby="accountIntended">
					<div dropdownToggle role="button" data-toggle="dropdownCountry" class="dropdown-shell" [ngClass]="{'border-error' : this.additionalInfoFormGroup.controls.accountIntended.errors && submitted}">
						<div class="m-3" [ngClass]="{'d-flex': this.additionalInfoFormGroup.controls.accountIntended.value }"> <span *ngIf="this.additionalInfoFormGroup.controls.accountIntended.value" class="dropdown-text" [ngClass]="{'dropdown-text-fixed-width': this.additionalInfoFormGroup.controls.accountIntended.value }">{{
                            this.additionalInfoFormGroup.controls.accountIntended.value
                        }}</span> <span *ngIf="!this.additionalInfoFormGroup.controls.accountIntended.value" editable translate>^additional-info.select</span> <i class="fa fa-angle-down fa-lg icon-color float-right mt-1"></i> </div>
					</div>
					<div class="dropdown-menu-shell">
						<ul *dropdownMenu class="dropdown-menu">
							<li class="dropdown-item" *ngFor="let account of intendedUseOfAccountList?.accountIntendedUsageList" tabindex="0" (click)="accountSelected(account)" tabindex="0" (keydown.enter)="accountSelected(account); hideDropdown(accountIntendedDropdown)"> <span>{{ account.intendedusagename}}</span> </li>
						</ul>
					</div>
				</div>
				<app-field-error [type]="'errorOnly'" [parentForm]="additionalInfoFormGroup" fieldName="accountIntended" [errorMessage]="'^additional-info.intentedAccountRequired'" [id]="'accountIntended'"></app-field-error>
			</div>
			<!-- Account Intented Dropdown-->
			<div class="additional-info-column button">
				<button type="submit" class="btn btn-primary btn-block primary-button" [disabled]="state === State.Processing">
                    <span editable translate>^additional-info.next</span>
                    <i class="fa fa-refresh fa-spin ml-2" *ngIf="state === State.Processing"></i>
                </button>
			</div>
		</div>
        <p class="text-danger" *ngIf="isApiError" [innerHTML]="'^additional-info.apiErrorResponse' | translate"></p>
	</form>
</div>
