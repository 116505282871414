import { Injectable } from '@angular/core';

import { AuthenticationHttpService } from '@app/services/auth/http/authentication-http.service';
import { UserLoginRequest } from '@app/services/auth/models/user-login-request-model';
import { CardBundle } from '@app/services/card/models/card-bundle';
import { LegacyLoginBundle } from '@app/features/original/user/profile-migration/models/legacy-profile.model';
import { CredentialsService, RoleEnum } from '@app/core/authentication/credentials.service';
import { Router } from '@angular/router';
import { CardDataService } from '@app/shared/services/card-data.service';
import { BHNTranslateService } from '@app/shared/services/bhn-translate.service';
import { TranslateService } from '@ngx-translate/core';
import { CreateUserRequest } from '../user/models/create-profile-request-model';

@Injectable({
    providedIn: 'root',
})
export class UserLoginService {
    emailAddress: string;

    constructor(
        private authenticationHttpService: AuthenticationHttpService,
        private credentialService: CredentialsService,
        private router: Router,
        private cardData: CardDataService,
        private bhnTranslateService: BHNTranslateService,
        private translateService: TranslateService,
    ) {}

    async login(request: UserLoginRequest): Promise<CardBundle | LegacyLoginBundle> {
        const loginBundle = await this.authenticationHttpService.loginWithUser(request);

        if (!this.credentialService.current.hasRole(RoleEnum.verifyingUserLogin)) {
            this.setUserData(loginBundle as CardBundle);
        }

        return loginBundle;
    }

    refresh(): Promise<void> {
        return this.authenticationHttpService.refresh();
    }

    async verifyLoginVerificationCode(code: string, createUserRequest?: CreateUserRequest, rmsId?: any): Promise<CardBundle> {
        const loginBundle = await this.authenticationHttpService.verifyLoginVerificationCode(code, createUserRequest, rmsId);
        this.setUserData(loginBundle);
        return loginBundle;
    }

    resendLoginVerificationCode(RMS_ID: string): Promise<boolean> {
        return this.authenticationHttpService.resendLoginVerificationCode(RMS_ID);
    }

    private setUserData(loginBundle: CardBundle) {
        this.cardData.clear();
        this.cardData.load(loginBundle);
        const currentLanguage = this.bhnTranslateService.getStoredLanguage();
        if (this.credentialService.current.language && this.credentialService.current.language !== currentLanguage) {
            this.bhnTranslateService.storeLanguage(this.credentialService.current.language);
        }
    }

    async sendCode(): Promise<void> {
        await this.authenticationHttpService.sendCardRetrievalCode({ mode: 'Email' });
    }

    async logout(): Promise<void> {
        await this.authenticationHttpService.logout();
    }
}
