<div class="bhn-alert-modal">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title" translate>^address-verification.title</h1>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-6 col-sm-12" id="enteredAddressDiv">
                        <div>
                            <span class="address-title" translate> ^address-verification.enteredAddress </span>
                        </div>
                        <div>{{ enteredAddress.addressLine1 }}</div>
                        <div>{{ enteredAddress.addressLine2 }}</div>
                        <div>{{ enteredAddress.city }} {{ enteredAddress.region }} {{ enteredAddress.postalCode }}</div>
                        <div>{{ enteredAddress.country.name }}</div>
                    </div>
                    <div class="col-md-6 col-sm-12" id="validatedAddressDiv">
                        <div>
                            <span class="address-title" translate> ^address-verification.validatedAddress </span>
                        </div>
                        <div>{{ validatedAddress.addressLine1 }}</div>
                        <div>{{ validatedAddress.addressLine2 }}</div>
                        <div>{{ validatedAddress.city }} {{ validatedAddress.region }} {{ validatedAddress.postalCode }}</div>
                        <div>{{ validatedAddress.country.name }}</div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <div class="col-md-6">
                    <button type="button" class="btn btn-primary w-100" (click)="dismissModal('useEnteredAddress')" translate>
                        ^address-verification.useEnteredAddress
                    </button>
                </div>
                <div class="col-md-6">
                    <button type="button" class="btn btn-secondary w-100" (click)="dismissModal('useValidatedAddress')" translate>
                        ^address-verification.useValidatedAddress
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
