import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ThemeService {
    private _themeConfig: object;
    public get themeConfig(): object {
        return this._themeConfig;
    }
    constructor() {}

    loadTheme(theme: {}) {
        if (theme) {
            if (Object.entries(theme).length !== 0) {
                this.setTheme(theme);
            }
            this._themeConfig = theme;
        }
    }

    private setTheme(theme: {}) {
        this.applyNonCssProperties(theme['non-css-properties'] || {});
        Object.keys(theme).forEach((k) => {
            if (k !== 'non-css-properties') {
                document.documentElement.style.setProperty(`--${k}`, theme[k]);
            }
        });
    }

    private applyNonCssProperties(properties: { [key: string]: any }) {
        this.applyFavicon(properties['favicon-url']);
        this.applyFontDefinitions(properties['font-definitions']);
        this.applyGoogleAnalyticsTag(properties['google-analytics-tag']);
    }

    private applyFavicon(url: string) {
        const faviconElement: HTMLLinkElement = document.querySelector('head link[rel=icon]');
        faviconElement.href = url || 'assets/images/BHN_favicon.svg';
    }

    private applyFontDefinitions(fonts: { name: string; file: string }[]) {
        if (!fonts || fonts.length === 0) return;
        const styleElement = document.createElement('style');
        fonts.forEach((fontDef) => {
            styleElement.appendChild(
                document.createTextNode(` @font-face { font-family: "${fontDef.name}"; src: url('${fontDef.file}'); } `),
            );
        });
        if (styleElement.childNodes.length > 0) {
            document.head.prepend(styleElement);
        }
    }

    private applyGoogleAnalyticsTag(tag: string) {
        if (tag) {
            document.head.appendChild(document.createComment('Global site tag (gtag.js) - Google Analytics'));
            let scriptElement = document.createElement('script');
            scriptElement.async = true;
            scriptElement.src = `https://www.googletagmanager.com/gtag/js?id=${tag}`;
            document.head.appendChild(scriptElement);
            scriptElement = document.createElement('script');
            scriptElement.appendChild(
                document.createTextNode(`
window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());

gtag('config', '${tag}');
            `),
            );
            document.head.appendChild(scriptElement);
        }
    }
}
