import { Component, ElementRef, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BaseComponent } from '@app/base/base.component';
import { ReactiveFormService } from '@app/shared/services/reactive-form.service';

@Component({
    selector: 'app-form-field-error-link',
    templateUrl: './form-field-error-link.component.html',
    styleUrls: ['./form-field-error-link.component.scss'],
})
export class FormFieldErrorLinkComponent extends BaseComponent implements OnInit {
    @Input()
    private formGroup: FormGroup;
    @Input()
    private get visible(): boolean {
        return this._visible;
    }
    private set visible(value: boolean) {
        this._visible = value;
        this.determineVisibility();
        if (value) {
            this.focusFormErrorLink();
        }
    }
    @ViewChild('errorLink') errorLink: ElementRef;

    private _visible: boolean;
    public showError = false;

    constructor(private reactiveFormService: ReactiveFormService) {
        super();
    }

    ngOnInit(): void {
        this.subscriptions = [
            this.formGroup.valueChanges.subscribe(() => {
                this.determineVisibility();
            }),
        ];
    }

    determineVisibility(): void {
        let count = 0;
        // only need loop to run if link is set to show from parent
        if (this.visible) {
            for (const name of Object.keys(this.formGroup.controls)) {
                const control = this.formGroup.controls[name];
                if (control.invalid && control.touched) {
                    count++;
                    if (count > 1 && this.visible) {
                        this.showError = true;
                        return;
                    }
                }
            }
        }
        if (count === 0) {
            this.showError = false;
        }
    }

    focusInvalidControl(): void {
        this.reactiveFormService.focusInvalidControl(this.formGroup);
    }

    focusFormErrorLink(): void {
        // timeout needed cause link might not be visible yet, not sure if theres a better way.
        setTimeout(() => {
            if (this.showError && this.visible) {
                this.errorLink.nativeElement.focus();
            }
        }, 1);
    }
}
