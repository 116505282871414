import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { BasePageComponent } from '@app/base/base-page.component';
import { filter, map } from 'rxjs/operators';
import { CredentialsService } from '@app/core/authentication/credentials.service';
import * as moment from 'moment';
import { UserLoginService } from '@app/services/auth/user-login.service';
import { CommonService } from '@app/shared/services/common.service';

@Component({
    selector: 'app-inactivity-content',
    templateUrl: './original-inactivity-modal.component.html',
    styleUrls: ['./original-inactivity-modal.component.scss'],
})
export class OriginalInactivityModalComponent extends BasePageComponent implements OnInit {
    public ngModel: any;
    public loginExpiration$ = this.credentials.expiration.pipe(
        filter((seconds) => !!seconds),
        map((seconds) => moment().startOf('day').seconds(seconds).format('HH:mm:ss')),
    );

    constructor(
        public activeModal: NgbActiveModal,
        public router: Router,
        private credentials: CredentialsService,
        private userLoginService: UserLoginService,
        private commonService: CommonService,
    ) {
        super();
    }

    async ngOnInit() {}

    async reset_timer() {
        await this.userLoginService.refresh();
        await this.credentials.setIsInactive(false);
        // this.activeModal.close('inactivity-refresh-session');
    }

    showLogoutBtn() {
        return this.commonService.enableHeaderUserManagement;
    }

    async logout() {
        await this.userLoginService.logout();
        await this.credentials.setIsInactive(false);
        this.credentials.clear();
        // this.activeModal.close('inactivity-logout');
    }
}
