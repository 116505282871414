import { Component, OnInit, forwardRef, Input } from '@angular/core';
import {
    FormGroup,
    AbstractControl,
    ValidationErrors,
    Validators,
    FormControl,
    ControlValueAccessor,
    NG_VALUE_ACCESSOR,
    NG_VALIDATORS,
    ValidatorFn,
} from '@angular/forms';
import { Observable, OperatorFunction } from 'rxjs';
import { debounceTime, map, switchMap } from 'rxjs/operators';

import { BaseComponent } from '@app/base/base.component';
import { AddressModel } from '@app/shared/models/address.model';
import { Country, CountryConfiguration } from '@app/shared/models/country-model';
import { Region } from '@app/shared/models/region-model';
import { AddressService } from '@app/shared/services/address.service';
import { BHNTranslateService } from '@app/shared/services/bhn-translate.service';
import { ModalResult } from '@app/shared/models/modal-result.model';
import { CommonService, Countries, Regions } from '@app/shared/services/common.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AddressVerificationModalComponent } from '../modals/address-verification-modal/address-verification-modal.component';
import { CardDetailsService } from '@app/services/card/card-details.service';
import { InputSanitizerService } from '@app/core/services/input-sanitizer.service';
import { CustomTooltipConfigs } from '@app/shared/models/custom-tooltip-configs.model';
import { CodeRedemptionService } from '@app/services/card/code-redemption.service';
import { DeviceDetectorService } from 'ngx-device-detector';

enum PageStatus {
    Default,
    Loading,
    Processing,
}
@Component({
    selector: 'app-address',
    templateUrl: './address.component.html',
    styleUrls: ['./address.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AddressComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => AddressComponent),
            multi: true,
        },
    ],
})
export class AddressComponent extends BaseComponent implements OnInit, ControlValueAccessor {
    @Input() showPhoneNumber = true;
    @Input() forShipping = false;
    @Input() requireRegistration = true;
    @Input() cardDetails: any;
    @Input() fromRequestPlasticCard = false;
    @Input() fromRegisterOnline = false;
    addressForm: FormGroup;
    regionList: Region[];
    countryList: Country[];
    requestPlasticPage = 'requestPlastic';
    countries: Countries;
    regions: Regions;
    visibleKey = 'visible text-danger show-on-content-edit-visible';
    invisibleKey = 'invisible text-danger show-on-content-edit-visible';
    countryConfiguration: CountryConfiguration;
    defaultCountryConfiguration: CountryConfiguration = {
        addressLine1Label: 'defaultAddressLine1',
        addressLine2Label: 'defaultAddressLine2',
        cityLabel: 'defaultCity',
        phoneNumberLabel: 'defaultPhoneNumber',
        postalCodeLabel: 'defaultPostalCode',
        stateprovinceLabel: 'defaultStateProvince',
        showStateProvince: true,
    };
    autoCompleteList: Map<string, AddressModel>;
    getMaskedDataResp: any;
    status: PageStatus;
    PageStatus = PageStatus;
    public tooltipConfigs: CustomTooltipConfigs;
    public toggleRegCardIcon = false;
    showToolTipE2P: boolean = false;
    isMobileFlag: boolean = false;
    default: string;
    getValue: any;

    constructor(
        private commonService: CommonService,
        private bhnTranslateService: BHNTranslateService,
        private modalService: NgbModal,
        private addressService: AddressService,
        public cardDetailsService: CardDetailsService,
        private inputSanitizerService: InputSanitizerService,
        private codeRedemptionService: CodeRedemptionService,
        private deviceDetector: DeviceDetectorService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.countryConfiguration = this.defaultCountryConfiguration;
        const isoCode = this.bhnTranslateService.getStoredLanguage();
        if (isoCode === 'en-US') {
            this.default = 'Select country';
        } else if (isoCode === 'es-MX') {
            this.default = 'Seleccionar país';
        } else if (isoCode === 'fr-CA') {
            this.default = 'Sélectionnez un pays';
        }

        this.createAddressForm();
        if (this.requireRegistration) this.addressForm.controls['country'].setValue(this.default, { onlySelf: true });
        if (this.fromRequestPlasticCard) {
            if (this.cardDetails && this.cardDetails.proxyCardNumber) {
                this.getMasekdData();
            }
        }
        if (this.codeRedemptionService.isE2P) {
            this.showToolTipE2P = true;
        }

        if (this.requireRegistration) {
           
            this.commonService.clientProgramId = (this.cardDetails?.product.clientProgramId)?(this.cardDetails?.product.clientProgramId):this.codeRedemptionService.clientProgramId;
            this.commonService.productCode =(this.cardDetails?.product.productCode)?(this.cardDetails?.product.productCode):this.codeRedemptionService.productCode;
            this.countries = this.commonService.getCountries();

            // iphone fix attempt #1
            this.subscriptions = [
                this.countries.$.subscribe((value) => {
                    const country = this.bhnTranslateService.getStoredCountry(true);
                    let countryObject = value.find((x) => x.isoCode2 === country);
                    if (!countryObject) {
                        countryObject = value[0];
                    }
                    this.countryList = value;
                    this.getValue = this.countryList;
                    if (this.countryList && this.countryList.length == 1) {
                        this.addressForm.controls.country.setValue(this.countryList[0]);
                    }
                    // this.addressForm.controls.country.setValue(countryObject);
                    this.configureAddressForm(countryObject.countryConfiguration);
                    this.regions = this.commonService.getRegions(countryObject);
                    this.regions.$.subscribe((value) => {
                        this.regionList = value;
                    });
                }),
                this.addressForm.controls.country.valueChanges.subscribe((value) => {
                    this.configureAddressForm(value.countryConfiguration);
                    this.regions = this.commonService.getRegions(value);
                    this.regions.$.subscribe((value) => {
                        this.regionList = value;
                    });
                    this.addressForm.controls.region.setValue(null);
                    this.addressForm.controls.region.markAsUntouched();
                }),
                this.addressService.addressValidation$.pipe(debounceTime(200)).subscribe(async (value) => {
                    if (value === 'validate') {
                        await this.verifyAddress();
                    }
                }),
            ];
        }
        this.isMobileFlag = this.deviceDetector.isMobile() || this.deviceDetector.isTablet();
    }
    async getMasekdData() {
        this.status = PageStatus.Loading;
        try {
            this.getMaskedDataResp = await this.addressService.getmaskedData(this.cardDetails.proxyCardNumber);
            if (this.getMaskedDataResp) {
                this.status = PageStatus.Default;
                this.addressForm.controls.firstName.setValue(this.getMaskedDataResp.firstName);
                this.addressForm.controls.lastName.setValue(this.getMaskedDataResp.lastName);
                this.addressForm.controls.addressLine1.setValue(this.getMaskedDataResp.addressLine1);
                this.addressForm.controls.addressLine2.setValue(this.getMaskedDataResp.addressLine2);
                this.addressForm.controls.city.setValue(this.getMaskedDataResp.city);
                this.addressForm.controls.postalCode.setValue(this.getMaskedDataResp.postalCode);
                this.addressForm.controls.country.setValue(this.getMaskedDataResp.countryName);
                this.addressForm.controls.region.setValue(this.getMaskedDataResp.stateName);
            }
        } catch (error) {
            this.status = PageStatus.Default;
        }
    }

    createAddressForm() {
        if (this.requireRegistration) {
            this.addressForm = new FormGroup({
                firstName: new FormControl(
                    {
                        value: this.cardDetails?.firstName ? this.cardDetails.firstName : null,
                        disabled: this.cardDetails?.firstName ? true : false,
                    },
                    { validators: [Validators.required, this.inputSanitizerService.sanitizeInput()] },
                ),
                lastName: new FormControl(
                    {
                        value: this.cardDetails?.lastName ? this.cardDetails.lastName : null,
                        disabled: this.cardDetails?.lastName ? true : false,
                    },
                    { validators: [Validators.required, this.inputSanitizerService.sanitizeInput()] },
                ),
                addressLine1: new FormControl(null, { validators: [Validators.required, this.inputSanitizerService.sanitizeInput()] }),
                addressLine2: new FormControl(null, this.inputSanitizerService.sanitizeInput()),
                postalCode: new FormControl(null, { validators: [Validators.required, this.inputSanitizerService.sanitizeInput()] }),
                region: new FormControl(null, Validators.required),
                city: new FormControl(null, { validators: [Validators.required, this.inputSanitizerService.sanitizeInput()] }),
                country: new FormControl(null, Validators.required),
            });
            let phoneNumberField =
                this.commonService.enableA2P && this.cardDetails?.product?.isNoRegistration && this.cardDetails?.phone != ''
                    ? { value: this.cardDetails?.phone, disabled: true }
                    : null;

            if (this.showPhoneNumber) {
                this.addressForm.registerControl(
                    'phoneNumber',
                    new FormControl(phoneNumberField, [
                        Validators.required,
                        Validators.pattern(/^(|[\d-+()\ ]{10,30})$/),
                        this.phoneValidator(),
                    ]),
                );
            }
        } else {
            this.addressForm = new FormGroup({
                firstName: new FormControl(null, { validators: [Validators.required, this.inputSanitizerService.sanitizeInput()] }),
                lastName: new FormControl(null, { validators: [Validators.required, this.inputSanitizerService.sanitizeInput()] }),
            });
            if (this.commonService?.dispalyPhoneNumber()) {
                this.addressForm.registerControl(
                    'phoneNumber',
                    new FormControl(
                        null,
                        this.commonService?.getPhoneNumberOptional()
                            ? [Validators.pattern(/^(|[\d-+()\ ]{0,10})$/), this.phoneValidator()]
                            : [Validators.required, Validators.pattern(/^(|[\d-+()\ ]{10,30})$/), this.phoneValidator()],
                    ),
                );
            } else {
                this.addressForm.registerControl('phoneNumber', new FormControl(null, Validators.pattern(/^(|[\d-+()\ ]{10,30})$/)));
            }
        }
    }

    configureAddressForm(config: CountryConfiguration): void {
        if (config) {
            this.countryConfiguration = config;
            if (config.showStateProvince) {
                this.addressForm.controls.region.setValidators(Validators.required);
            } else {
                this.addressForm.controls.region.clearValidators();
                this.addressForm.controls.region.setErrors(null);
            }
        } else {
            this.countryConfiguration = this.defaultCountryConfiguration;
        }
    }

    get country(): FormControl {
        return this.addressForm.get('country') as FormControl;
    }

    get region(): FormControl {
        return this.addressForm.get('region') as FormControl;
    }

    writeValue(val: unknown): void {
        if (val) {
            this.addressForm.setValue(val, { emitEvent: false });
        }
    }

    registerOnChange(fn: unknown): void {
        this.addressForm.valueChanges.subscribe(fn);
    }

    registerOnTouched(): void {
        //
    }

    validate(control: AbstractControl): ValidationErrors | null {
        if (control.touched) {
            this.markControlsAsTouched();
        }
        return this.addressForm.valid ? null : { invalidForm: { valid: false, message: 'invalid' } };
    }

    firstNameInvalid(): boolean {
        return !this.addressForm.controls.firstName.valid && !this.addressForm.controls.firstName.untouched;
    }

    lastNameInvalid(): boolean {
        return !this.addressForm.controls.lastName.valid && !this.addressForm.controls.lastName.untouched;
    }

    phoneNumberInvalid(): boolean {
        return !this.addressForm.controls.phoneNumber.valid && !this.addressForm.controls.phoneNumber.untouched;
    }

    addressInvalid(): boolean {
        return !this.addressForm.controls.addressLine1.valid && !this.addressForm.controls.addressLine1.untouched;
    }

    address2Invalid(): boolean {
        return !this.addressForm.controls.addressLine2.valid && !this.addressForm.controls.addressLine2.untouched;
    }

    countryErrorVisibility(): string {
        return !this.addressForm.controls.country.valid && !this.addressForm.controls.country.untouched
            ? this.visibleKey
            : this.invisibleKey;
    }

    countryInvalid(): boolean {
        return !this.addressForm.controls.country.valid && !this.addressForm.controls.country.untouched;
    }

    provinceInvalid(): boolean {
        return !this.addressForm.controls.region.valid && !this.addressForm.controls.region.untouched;
    }
    regionErrorVisibility(): string {
        return !this.addressForm.controls.region.valid && !this.addressForm.controls.region.untouched ? this.visibleKey : this.invisibleKey;
    }

    postalCodeInvalid(): boolean {
        return !this.addressForm.controls.postalCode.valid && !this.addressForm.controls.postalCode.untouched;
    }

    cityInvalid(): boolean {
        return !this.addressForm.controls.city.valid && !this.addressForm.controls.city.untouched;
    }

    phoneValidator(): ValidatorFn {
        return function validate(control: AbstractControl) {
            if (control.value) {
                return control.value.replace(/[^0-9]/g, '').length >= 10 ? null : { phoneNumberLengthInvalid: true };
            } else {
                return null;
            }
        };
    }

    search: OperatorFunction<string, string[]> = (text$: Observable<string>) =>
        text$.pipe(
            debounceTime(200),
            switchMap((searchText) => {
                if (searchText.length > 3) {
                    const countryIso = this.country.value.isoCode2;
                    return this.addressService.getAutoCompleteAddressList(searchText, countryIso).pipe(
                        map((response) => {
                            return this.buildAddressList(response);
                        }),
                    );
                } else {
                    return [];
                }
            }),
        );

    // Search list selection from dropdown - Event fired with selectedItem.
    public selectedSearchItem(event): void {
        event.preventDefault();
        const address = this.autoCompleteList.get(event.item);
        this.setAddressFormFromAutocomplete(address);
    }

    private markControlsAsTouched(): void {
        for (const controlName in this.addressForm.controls) {
            if (this.addressForm.controls[controlName] !== undefined) {
                this.addressForm.controls[controlName].markAsTouched();
            }
        }
    }

    private buildAddressList(addressArray: AddressModel[]): string[] {
        const resultList = [];
        this.autoCompleteList = new Map<string, AddressModel>();

        addressArray.forEach((address) => {
            const addressString = address.addressLine1 + ' ' + address.city + ', ' + address.region + ' ' + address.postalCode;
            resultList.push(addressString);
            this.autoCompleteList.set(addressString, address);
        });

        return resultList;
    }

    private setAddressFormFromAutocomplete(address: AddressModel) {
        const selectedRegion = this.regionList.find((region) => region.abbreviation === address.region);
        const selectedCountry = this.countryList.find((country) => country.name === address.country.name);
        this.addressForm.controls.addressLine1.setValue(address.addressLine1);
        this.addressForm.controls.addressLine2.setValue(address.addressLine2 || null);
        this.addressForm.controls.city.setValue(address.city);
        this.addressForm.controls.postalCode.setValue(address.postalCode);
        this.addressForm.controls.country.setValue(selectedCountry);
        this.addressForm.controls.region.setValue(selectedRegion.abbreviation);
    }

    private async verifyAddress() {
        const addressForm = this.addressForm.controls;
        this.addressService.enteredAddress = {
            addressLine1: addressForm.addressLine1.value,
            addressLine2: addressForm.addressLine2.value !== '' ? addressForm.addressLine2.value : null,
            postalCode: addressForm.postalCode.value,
            region: addressForm.region.value,
            city: addressForm.city.value,
            country: addressForm.country.value,
        };
        if (!this.fromRequestPlasticCard) {
            const verified = await this.addressService.verifyAddress();

            if (!verified) {
                const modalRef: NgbModalRef = this.modalService.open(AddressVerificationModalComponent, {
                    backdrop: 'static',
                    keyboard: false,
                    container: 'app-address',
                });

                const modalResult: ModalResult = await modalRef.result;

                if (modalResult === 'useValidatedAddress') {
                    addressForm.addressLine1.setValue(this.addressService.validatedAddress.addressLine1);
                    addressForm.addressLine2.setValue(this.addressService.validatedAddress.addressLine2);
                    addressForm.postalCode.setValue(this.addressService.validatedAddress.postalCode);
                    addressForm.city.setValue(this.addressService.validatedAddress.city);
                }
            }
        }
        this.addressService.addressValidation = 'validated';
    }
    public onToggleRegCardHelp(_value: boolean) {
        this.toggleRegCardIcon = !_value;
        this.tooltipConfigs = {
            toggleToolTip: this.toggleRegCardIcon,
            toolText: '^address.usernameLengthRestrictionError',
            id: 'toolTipusernameLengthRestriction',
        };
        this.tooltipConfigs.marginTop = this.isMobileFlag ? '50px' : '';
        this.tooltipConfigs.marginLeft = this.isMobileFlag ? '-250px' : '';
    }
}
