<div class="modal-header">
    <div class="modal-title">
        <span editable id="add-to-wallet-heading" [innerHTML]="heading || '^card-details.add-to-wallet.heading' | translate"></span>
    </div>
</div>
<div class="modal-body">
    <div class="alert-message" editable id="add-to-wallet-message" [innerHTML]="message || '^card-details.add-to-wallet.message' | translate"></div>
</div>
<div class="modal-footer">
    <button class="btn btn-primary" (click)="activeModal.close('ok')">
        <span editable translate>^card-details.add-to-wallet.ok</span>
    </button>
    <button class="btn btn-secondary" (click)="activeModal.dismiss('close')">
        <span editable translate>^card-details.add-to-wallet.cancel</span>
    </button>
</div>
