import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CredentialsService } from '@app/core/authentication/credentials.service';
import { CanComponentDeactivate } from '@app/core/guards/can-deactivate.guard';
import { KycStatus } from '@app/shared/enum/kyc-status.enum';
import { InitiateIdvResponse } from '@app/shared/models/initiateIdv-response-model';
import { UpdateUserKycRequest } from '@app/shared/models/update-userKyc-request.model';
import { BHNModalService } from '@app/shared/services/bhn-modal.service';
import { KycProcessFlowService } from '@app/shared/services/kyc-process-flow.service';
import { KycService } from '@app/shared/services/kyc.service';

@Component({
    selector: 'app-shared-initiate-idv',
    templateUrl: './initiate-idv.component.html',
    styleUrls: ['./initiate-idv.component.scss'],
})
export class InitiateIdvComponent implements OnInit, OnDestroy, CanComponentDeactivate {
    initiateIdvData: InitiateIdvResponse;
    allowToLeavetheKYCFlow: boolean;
    showSpinner: boolean;
    constructor(
        private kycProcessFlowService: KycProcessFlowService,
        private bhnModalService: BHNModalService,
        private kycService: KycService,
        private credentials: CredentialsService,
        private router: Router,
    ) {}

    async ngOnInit() {
        this.allowToLeavetheKYCFlow = false;
        this.showSpinner = false;
        this.initiateIdvData = this.kycProcessFlowService.initiateIdv;
        window.addEventListener('message', this.receiveMessage, false);
    }
    async canDeactivate(): Promise<boolean> {
        if (!this.kycProcessFlowService.isLeaveKycFlow && !this.allowToLeavetheKYCFlow) {
            const result = await this.bhnModalService.showAlert(
                '^kyc-exit-modal.lawDescription',
                '^kyc-exit-modal.title',
                ['^kyc-exit-modal.stay', '^kyc-exit-modal.discardChanges'],
                true,
                {
                    windowClass: 'bhn-alert-modal-fluid',
                    backdrop: 'static',
                    centered: true,
                },
            );
            if (result === '^kyc-exit-modal.discardChanges') {
                this.kycProcessFlowService.rest();
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    }

    receiveMessage = async (event) => {
        if (event) {
            const data = window.JSON.parse(event.data);
            if (typeof data !== 'undefined' && typeof data.payload !== 'undefined' && typeof data.payload.value !== 'undefined') {
                // console.log('auth token:', data.authorizationToken);
                // console.log('transaction reference:', data.transactionReference);
                // console.log('customer internal reference:', data.customerInternalReference);
                // console.log('event type:', data.eventType);
                // console.log('date-time:', data.dateTime);
                // console.log('event value:', data.payload.value);
                // console.log('event metainfo:', data.payload.metainfo);

                if (data.payload.value === 'success') {
                    this.allowToLeavetheKYCFlow = true;
                    this.showSpinner = true;
                    const req: UpdateUserKycRequest = {
                        userUuid: this.credentials.current.userUuid,
                        kycStatus: KycStatus.INITIATED,
                        proxyCardNumber: this.kycProcessFlowService.card.proxyCardNumber,
                    };
                    await this.kycService.updateUserKycInfo(req);
                    await this.kycProcessFlowService.beginKYCFlow();
                }
                if (data.payload.value === 'error') {
                    this.allowToLeavetheKYCFlow = true;
                    this.initiateIdvData = null;
                    this.kycProcessFlowService.navigateToKYCError();
                }
            }
        }
    };

    ngOnDestroy(): void {
        window.removeEventListener('message', this.receiveMessage, false);
    }
}
