<div class="notification-banner-wrapper" *ngIf="notificationBanners"
    [ngStyle]="{ 'bottom': isCookieAccepted() ? '0' : cookieConsentHeight + 'px' }">
    <div class="notification-banner-container" *ngFor="let item of notificationBanners; let i = index">
        <div class="banner-header">
            <h3>{{ item.bannerName }}</h3>
            <i class="fa fa-window-close" aria-hidden="true" (click)="dismissBanner(item)"
                *ngIf="item.configuration.isDismissable"></i>
        </div>
        <p class="description" [innerHTML]="item.description | safeHtml"></p>
    </div>
</div>