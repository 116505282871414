import { Inject, Injectable } from '@angular/core';
import { SESSION_STORAGE } from '@app/core/services/session-storage.service';
import { CommonHttpService } from '../http/common-http.service';
import { BaseResponse } from '../models/base-response-model';
import { ContactUsCountryModel } from '../models/contactus_countries.model';
import { ContactUsCountryPaginationModel } from '../models/contactus_countries_pagination.model';
import { IContactUsReasons } from '../models/contactus_reasons.model';

import { CardDataService } from './card-data.service';

const CONTACTUS_PROXYNUMBER_KEY = 'PXCU';
@Injectable({
    providedIn: 'root',
})
export class ContactusService {
    public proxyCardNumber: string;
    constructor(
        private cardData: CardDataService,
        @Inject(SESSION_STORAGE) private sessionStorage: Storage,
        private commonHttp: CommonHttpService,
    ) {}

    setProxyNumber(proxyCardNumber: string) {
        this.sessionStorage.setItem(CONTACTUS_PROXYNUMBER_KEY, proxyCardNumber);
    }

    getProxyNumber(): string {
        return this.sessionStorage.getItem(CONTACTUS_PROXYNUMBER_KEY);
    }

    removeProxyNumber() {
        this.sessionStorage.removeItem(CONTACTUS_PROXYNUMBER_KEY);
    }

    async getCountactUsCountries(limit: number): Promise<ContactUsCountryPaginationModel> {
        return await this.commonHttp.getCountactUsCountries(limit);
    }

    async getContactUsReasons(type: string): Promise<IContactUsReasons[]> {
        return await this.commonHttp.getContactUsReasons(type);
    }

    submitContactUsForm(formData: FormData): Promise<BaseResponse> {
        return this.commonHttp.submitContactUsForm(formData);
    }
}
