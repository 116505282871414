import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SetFocusService {
    private onFocusChange$$: BehaviorSubject<string> = new BehaviorSubject('navigation');
    public readonly onFocusChange$: Observable<string> = this.onFocusChange$$.asObservable();

    constructor() {}

    setFocusTo(id: string): void {
        this.onFocusChange$$.next(id);
    }
}
