import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { ENVIRONMENT } from '@app/core/services/environment.service';
import { UnmaskedCardInformation } from '@app/services/card/models/get-unmasked-card-information-response-model';
import { TransactionHistoryRequest } from '@app/services/card/models/transaction-history-request-model';
import { TransactionsForRange } from '@app/services/card/models/transaction.model';
import { BaseResponse } from '@app/shared/models/base-response-model';
import * as moment from 'moment';
import { NGXLogger } from 'ngx-logger';
import { firstValueFrom, Observable } from 'rxjs';
import { AdListModel } from '../models/ad.model';
import { CardAddress } from '../models/card-address.model';
import { CardDetailModel } from '../models/card-detail.model';
import { CustomLinkListModel } from '../models/custom-link-model';
import { MerchantCategoriesModel } from '../models/merchant-categories.model';
import { MerchantFilterModel } from '../models/merchant-filter-model';
import { CardInformation } from '../models/card-information.model';

@Injectable({
    providedIn: 'root',
})
export class CardDetailsHttpService {
    constructor(private http: HttpClient, private logger: NGXLogger, @Inject(ENVIRONMENT) private environment) { }

    async validatePublishedPage(pageUrl: string): Promise<CardDetailModel> {
        const apiRequest$ = this.http.post<CardDetailModel>(this.environment.nodeBaseURL + 'carddetail/validatePublishedPage', {
            pageUrl,
        });
        return firstValueFrom(apiRequest$);
    }

    async validatePreviewPage(pageUrl: string): Promise<CardDetailModel> {
        const apiRequest$ = this.http.post<CardDetailModel>(this.environment.nodeBaseURL + 'carddetail/validatePreviewPage', {
            pageUrl,
        });
        return firstValueFrom(apiRequest$);
    }

    async getUnmaskedCardInformation(proxyCardNumber: string, rmsSessionId?: string): Promise<UnmaskedCardInformation> {
        const apiRequest$ = this.http.post<BaseResponse & UnmaskedCardInformation>(
            this.environment.nodeBaseURL + 'card/getUnmaskedCardInfo',
            {
                proxyCardNumber,
            },
            {
                headers: {
                    't-session-id': rmsSessionId ? rmsSessionId : '',
                },
            },
        );
        const response = await firstValueFrom(apiRequest$);
        if (!response.success) {
            this.logger.error('Server error getting unmasked card information.', response.errors);
            throw Error('error-getting-unmasked-card-information');
        }
        return response;
    }

    async getMerchantFilter(pageUrl: string, clientProgramId: number, preview: boolean): Promise<MerchantFilterModel> {
        const apiRequest$ = this.http.post<MerchantFilterModel>(this.environment.nodeBaseURL + 'carddetail/getMerchantFilter', {
            pageUrl,
            clientProgramId,
            preview,
        });
        return firstValueFrom(apiRequest$);
    }

    public getMerchantCategories(): Observable<MerchantCategoriesModel> {
        return this.http.get<MerchantCategoriesModel>(this.environment.nodeBaseURL + 'carddetail/getMerchantCategories');
    }

    async getTermsConditions(termsConditionsName: string, bankIssuer: string): Promise<MerchantFilterModel> {
        const apiRequest$ = this.http.post<MerchantFilterModel>(this.environment.nodeBaseURL + 'carddetail/getTermsConditions', {
            termsConditionsName,
            bankIssuer
        });
        return firstValueFrom(apiRequest$);
    }

    async getCustomLinks(clientProgramId: number, productCode?: string): Promise<CustomLinkListModel> {
        const apiRequest$ = this.http.get<CustomLinkListModel>(
            this.environment.nodeBaseURL + `carddetail/getCustomLinks?clientProgramId=${clientProgramId}&productCode=${productCode}`,
        );
        return firstValueFrom(apiRequest$);
    }

    async getAds(pageUrl: string, clientProgramId: number): Promise<AdListModel> {
        let params;
        const currentDate = moment.utc().local().format();
        if (pageUrl) {
            params = { pageUrl, currentDate };
        } else if (clientProgramId) {
            params = { clientProgramId, currentDate };
        }
        const apiRequest$ = this.http.post<AdListModel>(this.environment.nodeBaseURL + 'carddetail/getAds', params);
        return firstValueFrom(apiRequest$);
    }

    async getTransactionHistory(request: TransactionHistoryRequest, rmsSessionId?: any): Promise<TransactionsForRange> {
        const apiRequest$ = this.http.post<BaseResponse & { transactionsForRange: TransactionsForRange }>(
            this.environment.nodeBaseURL + 'card/getTransactions',
            request,
            {
                headers: {
                    't-session-id': rmsSessionId ? rmsSessionId : '',
                },
            },
        );
        const response = await firstValueFrom(apiRequest$);
        if (!response.success) {
            this.logger.error('Error getting transaction history.', response.errors);
            throw Error('error-getting-transactions');
        }

        return response.transactionsForRange;
    }

    async getCardInfo(proxyCardNumber: string): Promise<any> {
        const apiRequest$ = this.http.post<BaseResponse & any>(this.environment.nodeBaseURL + 'card/getCardInfo', {
            proxyCardNumber,
        });
        const response = await firstValueFrom(apiRequest$);
        if (!response.success) {
            this.logger.error('Server error getting card information.', response.errors);
            throw Error('error-getting-card-information');
        }
        return response;
    }
}
