import { ThemeService } from './theme.service';
import { Observable, of, BehaviorSubject } from 'rxjs';

import { Country, CountryConfiguration } from '../models/country-model';
import { Region } from '../models/region-model';
import { CommonHttpService } from '../http/common-http.service';
import { shareReplay, catchError, distinct, tap, delay, distinctUntilChanged, take } from 'rxjs/operators';
import { Inject, Injectable } from '@angular/core';
import { Culture } from '../models/content-editor.model';
import { ProhibitedCountryListModel } from '../models/prohibited-country-list.model';
import { SESSION_STORAGE } from '@app/core/services/session-storage.service';
import { FeatureService } from './feature.service';
import { AppConfig } from '../models/application-config.model';
import { CookieConsentService } from './cookie-consent.service';
import { NotificationBannerModel } from '../models/notification-banner.model';
import { NGXLogger } from 'ngx-logger';
import { ContactUsCountryModel } from '../models/contactus_countries.model';
import { UserKYC } from '../models/user-kyc.model';
import { CardDetailsService } from '@app/services/card/card-details.service';
import { CardDetailModel } from '@app/services/card/models/card-detail.model';

const REFERRER_KEY = 'referrer';

export interface Countries {
    error$: Observable<boolean>;
    $: Observable<Country[]>;
}

export interface Regions {
    error$: Observable<boolean>;
    $: Observable<Region[]>;
}

@Injectable({
    providedIn: 'root',
})
export class CommonService {
    defaultCountryConfiguration: CountryConfiguration = {
        addressLine1Label: 'defaultAddressLine1',
        addressLine2Label: 'defaultAddressLine2',
        cityLabel: 'defaultCity',
        phoneNumberLabel: 'defaultPhoneNumber',
        postalCodeLabel: 'defaultPostalCode',
        stateprovinceLabel: 'defaultStateProvince',
        showStateProvince: true,
    };

    constructor(
        private cardDetailsService: CardDetailsService,
        private featureService: FeatureService,
        private commonHttp: CommonHttpService,
        private themeService: ThemeService,
        private cookieConsentService: CookieConsentService,
        private logger: NGXLogger,
        @Inject(SESSION_STORAGE) private sessionStorage: Storage,
    ) {}

    private _sessionId: string;
    public get sessionId(): string {
        return this._sessionId;
    }

    public set sessionId(v: string) {
        this._sessionId = v;
    }

    private _tenantKey: string;
    public get tenantKey(): string {
        return this._tenantKey;
    }

    private _appConfig: AppConfig;
    public get appConfig(): AppConfig {
        return this._appConfig;
    }

    private _themeConfig: {};
    public get themeObj(): {} {
        return this._themeConfig;
    }

    showErrorTxt = new BehaviorSubject<boolean>(false);
    private countriesError$$ = new BehaviorSubject<boolean>(false);
    private countriesError$ = this.countriesError$$.asObservable();
    clientProgramId: number;
    productCode: string; 
    private regions = new Map<Country, Regions>();
    public noRegisterationConfig: any;
    public enableA2P: boolean;
    public enableMycards: boolean;
    public enableHeaderUserManagement: boolean;
    public showErrorOnTimedOut: boolean;
    public clientConfigId: string;
    public addToWalletTimer: number;
    public fromAddress: string;
    public hmDomain: string;
    public siteUrl: string;

    getCountries(): Countries {
        const countries$$ =  this.commonHttp.getCountryList({productCode:this.productCode, cpid: this.clientProgramId }).pipe(
            tap(() => this.countriesError$$.next(false)),
            catchError(() => {
                this.countriesError$$.next(true);
                return of<Country[]>();
            }),
        );
        return {
            $: countries$$.pipe(shareReplay(1), delay(0)),
            error$: this.countriesError$,
        };
    }

    private sessionTimedOut$$ = new BehaviorSubject<boolean>(false);

    public getIsSessionTimedOut(): Observable<boolean> {
        return this.sessionTimedOut$$.asObservable().pipe(distinctUntilChanged());
    }

    public setIsSessionTimedOut(newValue: boolean): void {
        this.sessionTimedOut$$.next(newValue);
    }

    getRegions(country: Country): Regions {
        if (!country) return;
        if (!this.regions.has(country)) {
            const regionsError$$ = new BehaviorSubject<boolean>(false);
            const regions$$ = this.commonHttp.getRegionList(country).pipe(
                tap(() => regionsError$$.next(false)),
                catchError(() => {
                    regionsError$$.next(true);
                    return of<Region[]>();
                }),
            );
            this.regions.set(country, {
                $: regions$$.pipe(shareReplay(1), delay(0)),
                error$: regionsError$$.pipe(distinct()),
            });
        }
        return this.regions.get(country);
    }

    async loadAppSettings(): Promise<void> {
        try {
            const appSettings = await this.commonHttp.getAppSettings(document.location.host);
            this._tenantKey = appSettings.tenantKey;
            if (appSettings.themeConfig) {
                this.themeService.loadTheme(appSettings.themeConfig);
                this._themeConfig = appSettings.themeConfig;
            }
            this.featureService.setfeatureLayout(appSettings.featureSet.name);
            this.sessionId = this.sessionStorage.getItem('session') ? atob(this.sessionStorage.getItem('session')) : appSettings.sessionId;
            this.sessionStorage.setItem('session', btoa(this.sessionId));
            this._appConfig = appSettings.appConfig;
            this.applyGoogleTagManager(appSettings.googleTagManagerContainerId);
            this.cookieConsentService.cookieConsentUrl = appSettings.cookieConsentUrl;
            this.featureService.applyFeatureSet(appSettings.featureSet);
            this.noRegisterationConfig = appSettings?.noRegisterationConfig ? appSettings?.noRegisterationConfig : null;
            this.enableA2P = appSettings?.enableA2P;
            this.enableMycards = appSettings?.enableMycards;
            this.enableHeaderUserManagement = appSettings?.enableHeaderUserManagement;
            this.showErrorOnTimedOut = appSettings?.showErrorOnTimedOut;
            this.clientConfigId = appSettings?.clientConfigId ? appSettings?.clientConfigId : null;
            this.addToWalletTimer = appSettings?.a2w_timer || 600000;
            this.fromAddress = appSettings?.fromAddress;
            this.hmDomain = appSettings?.hmDomain;
            this.siteUrl = appSettings?.siteUrl;
        } catch (error) {
            this.logger.error(`FAILED GETTING APPSETTINGS: ${error.message}`, error);
            console.error('FAILED GETTING APPSETTINGS: ' + error.message);
            throw error;
        }
    }

    async getProhibitedCountryList(): Promise<ProhibitedCountryListModel> {
        return await this.commonHttp.getProhibitedCountryList();
    }

    async getTenantServiceNumber(): Promise<string> {
        return await this.commonHttp.getTenantServiceNumber();
    }

    public getReferrer(): string {
        return this.sessionStorage.getItem(REFERRER_KEY);
    }

    public setReferrer(referrer: string): void {
        this.sessionStorage.setItem(REFERRER_KEY, referrer);
    }

    private applyGoogleTagManager(gtmId: string) {
        if (gtmId) {
            const scriptElement = document.createElement('script');
            document.head.appendChild(document.createComment(' Google Tag Manager '));
            scriptElement.appendChild(
                document.createTextNode(
                    `
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${gtmId}');
                `.trim(),
                ),
            );
            document.head.appendChild(scriptElement);
            document.head.appendChild(document.createComment(' End Google Tag Manager '));
        }
    }

    async getNotificationBanners(isoCode: string): Promise<NotificationBannerModel[]> {
        return await this.commonHttp.getNotificationBanners(isoCode);
    }
    async getUserKycInfo(): Promise<UserKYC> {
        return await this.commonHttp.getUserKycInfo();``
    }

    /**
     * No Registeration Configuration
     *  */

    public displayBanner(): boolean {
        if (
            typeof this.noRegisterationConfig?.cardDetailsBanner === 'undefined' ||
            this.noRegisterationConfig?.cardDetailsBanner === null
        ) {
            return true;
        } else {
            return this.noRegisterationConfig?.cardDetailsBanner;
        }
    }
    public displayMerchant(): boolean {
        if (typeof this.noRegisterationConfig?.displayMerchant === 'undefined' || this.noRegisterationConfig?.displayMerchant === null) {
            return true;
        } else {
            return this.noRegisterationConfig?.displayMerchant;
        }
    }

    public getCustomBannerContent(): any {
        if (
            typeof this.noRegisterationConfig?.customBannerContent === 'undefined' ||
            this.noRegisterationConfig?.customBannerContent === null
        ) {
            return null;
        } else {
            return this.noRegisterationConfig?.customBannerContent;
        }
    }

    public dispalyPhoneNumber(): boolean {
        if (typeof this.noRegisterationConfig?.phoneNumber === 'undefined' || this.noRegisterationConfig?.phoneNumber === null) {
            return true;
        } else {
            return this.noRegisterationConfig?.phoneNumber;
        }
    }

    public getCustomMasterCardPhoneNumber(): string {
        return this.noRegisterationConfig?.masterCardPhoneNumber;
    }

    public isValidURL(URL: string) {
        var urlPattern = new RegExp(
            '^(https?:\\/\\/)?' + // validate protocol
                '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
                '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
                '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
                '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
                '(\\#[-a-z\\d_]*)?$',
            'i', // validate fragment locator
        );
        return !!urlPattern.test(URL);
    }

    /**
     * No Registeration Configuration
     *  */

    public getPhoneNumberOptional(): boolean {
        return !!this.noRegisterationConfig?.phoneNumberOptional;
    }

    async getFAQComponents(): Promise<any> {
        return await this.commonHttp.getFAQComponents();
    }
}
