import { Component } from '@angular/core';
import { BHNModalService } from '@app/shared/services/bhn-modal.service';
import { CardDataService } from '@app/shared/services/card-data.service';
import { KycProcessFlowService } from '@app/shared/services/kyc-process-flow.service';

@Component({
    selector: 'app-kyc-error',
    templateUrl: './kyc-error.component.html',
    styleUrls: ['./kyc-error.component.scss'],
})
export class KycErrorComponent {

    public showSpinner = false;
    public allowToLeavetheKYCFlow = true;

    constructor(private kycProcessFlowService: KycProcessFlowService, private cardData: CardDataService,  private bhnModalService: BHNModalService) {}

    async canDeactivate(): Promise<boolean> {
        if (!this.kycProcessFlowService.isLeaveKycFlow && this.allowToLeavetheKYCFlow) {
            const result = await this.bhnModalService.showAlert(
                '^kyc-exit-modal.lawDescription',
                '^kyc-exit-modal.title',
                ['^kyc-exit-modal.stay', '^kyc-exit-modal.discardChanges'],
                true,
                {
                    windowClass: 'bhn-alert-modal-fluid',
                    backdrop: 'static',
                    centered: true,
                },
            );
            if (result === '^kyc-exit-modal.discardChanges') {
                this.kycProcessFlowService.rest();
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    }

    async onReInitIDV() {
        this.allowToLeavetheKYCFlow = false;
        this.showSpinner = true;
        this.kycProcessFlowService.card = this.kycProcessFlowService.card ? this.kycProcessFlowService.card : this.cardData.snapshot.selected;
        this.kycProcessFlowService.beginKYCFlow();
    }
}
