<div class="modal-body row">
    <div class="col-lg-5 col-md-6 col-sm-12 px-7x addWallet-help" *ngIf="displayWalletHelp">
        <div class="modal-title send-app-header" id="addCardtoPhoneWalletHeader" editable translate>addCardtoPhoneWalletHeader</div>
        <div class="mt-1 text-center">
            <div class="fs-20 mt-57" id="addCardtoPhoneWalletTitle" editable translate>addCardtoPhoneWalletTitle</div>
            <div class="fs-16 text-left ol-align" id="addCardtoPhoneWalletDesc" editable></div> 
            <!--[innerHTML]="'^fluid-card-details.add-card-to-phone-wallet-instruction' | translate"> </div-->
        </div>
    </div>
    <div class="col-lg-1 col-md-1 col-sm-12 addWallet-help" *ngIf="displayWalletHelp">
        <h1 class="text-center center-partition">OR</h1>
    </div>

    <div *ngIf="!validPhoneEntered || this.isMobileOrTabletDevice()" class="col-lg-6 col-md-5 col-sm-12 phone-number-section">
        <h2 class="modal-title send-app-header" translate="^fluid-card-details.send-me-the-app-header"></h2>
        <div class="spacer"></div>

        <form [formGroup]="sendMeAppFormGroup" class="send-me-app-modal-content">
            <div class="phone-row">
                <label id="phone-number-label" class="fs-20" for="phoneNumberTextbox" translate editable
                    >^fluid-card-details.phone-number-label</label
                >
            </div>

            <div class="col-md-12 col-lg-12 col-sm-12 d-flex justify-content-center">
                <select formControlName="countryCode" class="form-control country-code" [ngModel]="countryCodesmodel">
                    <option *ngFor="let countryCode of countryCodes" >{{ countryCode.phonecode }}</option>
                </select>

                    <input
                        numbersOnly
                        class="form-control phone-number"
                        [attr.aria-describedby]="describedBy()"
                        formControlName="phoneNumber"
                        inputmode="numeric"
                        maxlength="12"
                        minlength="9"
                        required
                        id="phoneNumberTextbox"
                        translate
                        autocomplete="off"
                        [placeholder]="'^fluid-card-details.phone-number-placeholder' | translate"
                        #phoneInput
                    />
            </div>
            <div class="phone-row validation-text">
                <app-field-error
                    [type]="'required'"
                    [parentForm]="this.sendMeAppFormGroup"
                    fieldName="phoneNumber"
                    [errorMessage]="'^fluid-card-details.phoneRequired'"
                    [id]="'^fluid-card-details.phoneRequired'"
                >
                </app-field-error>
                <app-field-error
                    [type]="'invalidPhoneNumber'"
                    [state]="this.state"
                    [parentForm]="this.sendMeAppFormGroup"
                    fieldName="phoneNumber"
                    [errorMessage]="'^fluid-card-details.invalid-phone-number'"
                    [id]="'^fluid-card-details.invalid-phone-number'"
                >
                </app-field-error>
            </div>
            <button
                id="proxy-form.next-button"
                class="btn btn-primary primary-button"
                translate
                editable
                [disabled]="state === State.Processing"
                (click)="onSubmit()"
            >
                <svg
                    enable-background="new 0 0 32 32"
                    id="plane"
                    class="btn-submit-icon"
                    height="17px"
                    width="17px"
                    version="1.1"
                    viewBox="0 0 32 32"
                    xml:space="preserve"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                    <path
                        d="M31.543,0.16C31.377,0.053,31.188,0,31,0c-0.193,0-0.387,0.055-0.555,0.168l-30,20  c-0.309,0.205-0.479,0.566-0.439,0.936c0.038,0.369,0.278,0.688,0.623,0.824l7.824,3.131l3.679,6.438  c0.176,0.309,0.503,0.5,0.857,0.504c0.004,0,0.007,0,0.011,0c0.351,0,0.677-0.186,0.857-0.486l2.077-3.463l9.695,3.877  C25.748,31.977,25.873,32,26,32c0.17,0,0.338-0.043,0.49-0.129c0.264-0.148,0.445-0.408,0.496-0.707l5-30  C32.051,0.771,31.877,0.377,31.543,0.16z M3.136,20.777L26.311,5.326L9.461,23.363c-0.089-0.053-0.168-0.123-0.266-0.162  L3.136,20.777z M10.189,24.066c-0.002-0.004-0.005-0.006-0.007-0.01L29.125,3.781L12.976,28.943L10.189,24.066z M25.217,29.609  l-8.541-3.416c-0.203-0.08-0.414-0.107-0.623-0.119L29.205,5.686L25.217,29.609z"
                        fill="#FFFFFF"
                        id="paperplane"
                    ></path>
                </svg>
                ^fluid-card-details.sendMeAppBtn
            </button>
        </form>
    </div> 

    <div class="send-app-options-section" *ngIf="validPhoneEntered && !this.isMobileOrTabletDevice()">
        <div class="send-app-option">
            <button class="btn btn-primary primary-button" translate editable (click)="addToWallet()">
                ^fluid-card-details.get-qr-code-btn
                <i class="fa fa-refresh fa-spin ml-2" *ngIf="showSpinner"></i>
            </button>
            <p class="option-message" translate editable>^fluid-card-details.get-qr-code-msg</p>
        </div>
        <div class="separator">OR</div>
        <div class="send-app-option">
            <button class="btn btn-primary primary-button" translate editable [disabled]="state === State.Processing" (click)="sendSMS()">
                {{ sendSmsButtonText }}
            </button>
            <p *ngIf="this.state === State.SMSError || this.state === State.ProcessingError" class="sms-error-container">
                <app-field-error
                    [type]="'smsError'"
                    [state]="this.state"
                    [errorMessage]="'^fluid-card-details.sms-error-msg'"
                    [id]="'^fluid-card-details.sms-error-msg'"
                >
                </app-field-error>
                <app-field-error
                    [type]="'processingError'"
                    [state]="this.state"
                    [errorMessage]="'^fluid-card-details.processing-error-msg'"
                    [id]="'^fluid-card-details.processing-error-msg'"
                >
                </app-field-error>
            </p>

            <p class="option-message" translate editable>^fluid-card-details.send-sms-link-msg</p>
            <small class="sms-disclaimer-msg" editable translate>^fluid-card-details.send-me-the-app-disclaimer-msg</small>
        </div> 
    </div>
</div>
