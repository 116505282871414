import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { CustomTooltipConfigs } from '@app/shared/models/custom-tooltip-configs.model';

@Component({
    selector: 'app-custom-tooltip',
    templateUrl: './custom-tooltip.component.html',
    styleUrls: ['./custom-tooltip.component.scss'],
})
export class CustomTooltipComponent implements OnInit {
    @Input() public tooltipConfigs: CustomTooltipConfigs;
    @Input() dataPatchMsg=false;

    constructor() {}

    ngOnInit(): void {}
}
