import { Injectable, Inject } from '@angular/core';
import jwtDecode from 'jwt-decode';
import * as moment from 'moment';
import { SESSION_STORAGE } from '@app/core/services/session-storage.service';
import { Observable, BehaviorSubject, Subject, Subscription, interval } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { Router } from '@angular/router';
import { FilterValue } from '@app/shared/models/content-editor.model';
import { CommonService } from '@app/shared/services/common.service';
import { BHNModalService } from '@app/shared/services/bhn-modal.service';

const CREDENTIALS_KEY = 'credentials';
const DISPLAY_NAME_KEY = 'displayName';
const PROXYNUMBER_KEY = 'PXYK';
const HIDDEN_EXPIRATION_PADDING = 5;

interface JwtPayload {
    sub: any;
    displayName?: string;
    roles: string[];
    iat: number;
    exp: number;
    language?: string;
}

export enum RoleEnum {
    bhnAdmin = 'bhn-admin',
    clientAdmin = 'client-admin',
    registeredEndUser = 'registered-end-user',
    anonymousCardholder = 'anonymous-cardholder',
    activating = 'activating',
    anonymousRedeeming = 'anonymous-redeeming',
    anonymousRetrieving = 'anonymous-retrieving',
    verifyingUserLogin = 'verifying-user-login',
    verifyingCreateUser = 'verifying-create-user',
}

export class Credentials {
    readonly userUuid: string;
    readonly displayName: string;
    readonly proxyCardNumber: string;
    readonly retrievalCode: string;
    readonly roles: ReadonlyArray<string>;
    readonly filterValue: Array<FilterValue>;
    readonly language: string;
    protected _token: string;

    protected _expires: number;

    constructor(token: string, displayName?: string) {
        this._token = token;
        const decodedJwt: JwtPayload = jwtDecode(token);
        this.roles = decodedJwt.roles;
        this._expires = decodedJwt.exp;
        this.filterValue = new Array<FilterValue>();

        if (decodedJwt.sub) {
            this.retrievalCode = decodedJwt.sub.retrievalCode ? decodedJwt.sub.retrievalCode : null;
            this.proxyCardNumber = decodedJwt.sub.proxyCardNumber ? decodedJwt.sub.proxyCardNumber : null;
            this.userUuid = decodedJwt.sub.userUuid ? decodedJwt.sub.userUuid : null;
        }

        this.displayName = decodedJwt.displayName || displayName;
        this.language = decodedJwt.language;
    }

    hasRole(role: RoleEnum): boolean {
        return this.roles.includes(role);
    }

    isAdmin(): boolean {
        return this.roles.includes(RoleEnum.bhnAdmin) || this.roles.includes(RoleEnum.clientAdmin);
    }

    isRegisteredEndUser(): boolean {
        return this.roles.includes(RoleEnum.registeredEndUser);
    }

    isAnonymousCardholder(): boolean {
        return this.roles.includes(RoleEnum.anonymousCardholder);
    }

    isActivating(): boolean {
        return this.roles.includes(RoleEnum.activating);
    }

    isAnonymousRedeeming(): boolean {
        return this.roles.includes(RoleEnum.anonymousRedeeming);
    }

    isAnonymousRetrieving(): boolean {
        return this.roles.includes(RoleEnum.anonymousRetrieving);
    }

    isExpired(): boolean {
        return jwtHasExpired(this.token);
    }

    canBeStored(): boolean {
        return this.isAdmin() || this.isAnonymousCardholder() || this.isRegisteredEndUser();
    }

    get expires(): number {
        return this._expires;
    }

    get token(): string {
        return this._token;
    }

    set token(token: string) {
        const decodedJwt: JwtPayload = jwtDecode(token);
        if (
            decodedJwt.sub === null ||
            (this.isAnonymousCardholder() && this.proxyCardNumber !== decodedJwt.sub.proxyCardNumber) ||
            (this.isActivating() && this.proxyCardNumber !== decodedJwt.sub.proxyCardNumber) ||
            (this.isAnonymousRetrieving() && this.retrievalCode !== decodedJwt.sub.retrievalCode) ||
            (this.isRegisteredEndUser() && this.userUuid !== decodedJwt.sub.userUuid)
        ) {
            throw Error('New token user does not match current credentials');
        }
        this._token = token;
        this._expires = decodedJwt.exp;
    }
}

@Injectable({ providedIn: 'root' })
export class CredentialsService {
    private _current: Credentials = null;
    private readonly credentials = new BehaviorSubject<Credentials>(null);
    private isInactive: BehaviorSubject<boolean>;
    private readonly _expiration = new Subject<number>();
    private expirationWatcher: Subscription = null;

    private haveRemainingTop = false;
    private remainingTop = 0;
    private remainingTopPercentage = 0;
    private remaining = 0;

    constructor(
        @Inject(SESSION_STORAGE) private sessionStorage: Storage,
        private router: Router,
        private commonService: CommonService,
        private modalService: BHNModalService,
    ) {
        this.isInactive = new BehaviorSubject<boolean>(false);
    }

    asObservable(): Observable<Credentials> {
        return this.credentials.asObservable();
    }

    onErrorModelPopup = async (errorStatusMsg: string) => {
        const transKey = errorStatusMsg == 'aws-waf-error' ? errorStatusMsg : `^invalid-login.${errorStatusMsg}`;
        const result = await this.modalService.showAlert(transKey, '', undefined, true, {
            windowClass: 'bhn-alert-modal',
            backdrop: 'static',
        });
    };

    loadFromStorage(): void {
        const token = this.sessionStorage.getItem(CREDENTIALS_KEY);
        if (token !== null) {
            try {
                if (!jwtHasExpired(token)) {
                    const displayName: string = this.sessionStorage.getItem(DISPLAY_NAME_KEY);
                    const credentials = new Credentials(token, displayName);
                    if (credentials.canBeStored()) {
                        this._current = credentials;
                        this.credentials.next(this._current);
                        this.startExpirationWatcher();
                    }
                } else {
                    this.sessionStorage.removeItem(CREDENTIALS_KEY);
                }
            } catch {
                this.sessionStorage.removeItem(CREDENTIALS_KEY);
            }
        }
    }

    set(token: string): void {
        this._current = new Credentials(token);
        this.credentials.next(this._current);
        if (this._current.canBeStored()) {
            this.sessionStorage.setItem(CREDENTIALS_KEY, this._current.token);
            if (this._current.displayName) {
                this.sessionStorage.setItem(DISPLAY_NAME_KEY, this._current.displayName);
            } else {
                this.sessionStorage.removeItem(DISPLAY_NAME_KEY);
            }
        }
        this.startExpirationWatcher();
    }

    refresh(token: string): void {
        this._current.token = token;
        if (this._current.canBeStored()) {
            this.sessionStorage.setItem(CREDENTIALS_KEY, this._current.token);
        }
    }

    clear(): void {
        this._current = null;
        this.credentials.next(null);
        this.router.navigate(['/home']).then(() => {
            this.sessionStorage.removeItem(CREDENTIALS_KEY);
            this.sessionStorage.removeItem(DISPLAY_NAME_KEY);
            this.sessionStorage.removeItem(PROXYNUMBER_KEY);
        });
    }

    get expiration(): Observable<number> {
        return this._expiration.pipe(distinctUntilChanged());
    }

    getIsInactive(): Observable<boolean> {
        return this.isInactive.asObservable().pipe(distinctUntilChanged());
    }

    setIsInactive(newValue: boolean): void {
        this.isInactive.next(newValue);
    }

    get current(): Credentials {
        return this._current;
    }

    isAuthenticated(): boolean {
        return !!this._current;
    }

    private startExpirationWatcher() {
        if (this.expirationWatcher) {
            this.expirationWatcher.unsubscribe();
            this.expirationWatcher = null;
        }
        if (this._current) {
            // this.expirationWatcher = timer(
            //     (this._current.expires - this.environment.loginExpireWarningSeconds - HIDDEN_EXPIRATION_PADDING) * 1000,
            //     1,
            // ).subscribe(() => {
            this.expirationWatcher = interval(1000).subscribe(() => {
                if (!this._current) {
                    this.expirationWatcher.unsubscribe();
                    return;
                }
                this.remaining = this._current.expires - HIDDEN_EXPIRATION_PADDING - Math.round(Date.now() / 1000);

                // Wait until 35% of burn down then Pop the inactivity Modal.
                if (!this.haveRemainingTop) {
                    this.remainingTop = this.remaining;
                    // TODO: Place Inactivity timer in an Environment file
                    this.remainingTopPercentage = Math.round(this.remainingTop * (35 / 100)); // Set to 35% of inactivity timer.
                    this.haveRemainingTop = true;
                }
                if (this.remaining > 0) {
                    this._expiration.next(this.remaining);

                    // console.log('remaining:' + this.remaining + ' 25% remaining:' + this.remainingTopPercentage);
                    if (this.isInactive) {
                        if (this.remaining < this.remainingTopPercentage) {
                            this.setIsInactive(true);
                        }
                    }
                } else {
                    this._expiration.next(0);
                    this.expirationWatcher.unsubscribe();
                    this.commonService.setIsSessionTimedOut(true);
                    this.setIsInactive(false);
                    this.clear();
                }
            });
        }
    }
}

function jwtHasExpired(jwt: string): boolean {
    const decodedJwt: JwtPayload = jwtDecode(jwt);
    return decodedJwt.exp <= moment().unix();
}
