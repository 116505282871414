export const FIELD_LIST_META_KEY = Symbol();

export abstract class SelfMappingObject<T> {
    constructor(obj?: Partial<T>) {
        if (obj) {
            const fields = Reflect.get(this, FIELD_LIST_META_KEY) || [];
            if (fields.length === 0) {
                throw Error(
                    `Type ${this.constructor.name} does not define any fields. SelfMappingObject cannot do its job!`,
                );
            }
            Object.keys(obj).forEach(field => {
                if (fields.includes(field)) {
                    this[field] = obj[field];
                }
            });
        }
    }
}

export function Field() {
    return (target: any, propertyName: string) => {
        const fieldList: string[] = [...(Reflect.get(target, FIELD_LIST_META_KEY) || []), propertyName];
        // console.log(`defining fieldList: ${fieldList}`);
        Reflect.set(target, FIELD_LIST_META_KEY, fieldList);
    };
}
