export enum FlowType {
    CARD = 'cards',
    LOGIN = 'login',
    ALERT = 'alert',
    PIN = 'pin',
    REGISTER = 'register',
    PCN = 'card_activation',
    ACTIVATION = 'activation',
    REQUEST = 'request',
    TRANSACTION = 'transaction',
    ACH = 'ach',
    PROXY = 'proxy',
    VERIFICATION = 'verification',
    ADDRESS = 'address',
    EGIFT = 'egift',
    REDEEM = 'redeem',
    RMS = 'rms',
    PROFILE = 'signup',
    FORGET = 'password',
    DATA = 'data',
    RETRIVE = 'retrive',
    PHYSICAL = 'PHYSICAL_ACTIVATION',
    OTHER = 'other',
}

export enum FlowName {
    CARD_DETAILS = 'card_details',
    SET_PIN = 'set_pin',
    LOGIN_USER = 'login_user',
    VERIFICATION_CODE = 'verification_code',
    PCN_CODE = 'pcn_code',
    MY_CARDS = 'my_cards',
    ACTIVATION_DATA = 'activation_data',
    CARD_ACTIVATED = 'card_activated',
    REGISTER_CARD = 'register_card',
    REQUEST_PLASTIC = 'request_plastic',
    SET_RESET_PIN = 'set-reset-pin',
    TRANSACTION_HISTORY = 'transaction_history',
    ACH_TRANSFER = 'ach_transfer',
    CARD_LOGIN = 'card_login',
    PROXY_ACTIVATION = 'proxy_activation',
    ALERT_SETTINGS = 'alert_Settings',
    REDEMPTION = 'redemption',
    CONTACT_US = 'contact_us',
    CARDS_DATA = 'cards_data',
    EGIFT_TYPE = 'egift_type',
    REDEEM_CODE = 'redeem_code',
    RMS_SESSION_GENERATOR = 'rms_session_generator',
    ALERT_CARD = 'alert_card',
    ALERT_SETUP = 'alert_setup',
    REGISTER_PAGE = 'register_page',
    CREATE_PROFILE = 'create_profile',
    FORGET_PASSWORD = 'forget_password',
    DATA_CAPTURE = 'data_capture',
    VIRTUAL_REDEEM_ADDRESS = 'virtual-redeem_address',
    CARD_RETRIVAL_DATA = 'card_retrival_data',
    VALIDATE_RESET_PASSWORD_LINK = 'validate_reset_password_link',
    GET_RL = 'get_rl',
    CARD_EXPIRED_NOTIFY = 'card_expired_notify',
}
