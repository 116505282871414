<form
    [formGroup]="addressForm"
    [ngClass]="
        cardDetailsService.isCustomPage && cardDetailsService.isStyleOption && cardDetailsService.fromCardDetails ? 'customTheme' : ''
    "
>
    <div class="table-spinner" *ngIf="status === PageStatus.Loading"></div>
    <div class="row" *ngIf="requireRegistration && countryList && countryList.length > 0">
        <div class="col-md-6 col-sm-12">
            <div class="col-md-12">
                <label class="h6" for="addCountry" editable translate>^address.defaultCountry</label>
                <select
                    *ngIf="!getMaskedDataResp && countryList && countryList.length >= 2"
                    id="addCountry"
                    class="custom-select form-control"
                    aria-describedby="addressCountriesError"
                    formControlName="country"
                    required
                    autocomplete="country"
                >
                    <option hidden disabled [value]="">{{ default }}</option>
                    <ng-container *ngFor="let country of countries?.$ | async">
                        <option [ngValue]="country" *ngIf="country.shippingAllowed || !forShipping">
                            {{ country.name }}
                        </option>
                    </ng-container>
                </select>
                <div *ngIf="!getMaskedDataResp && countryList && countryList.length == 1" class="form-control maskedCountry">
                    {{ getValue[0].name }}
                </div>
                <div *ngIf="getMaskedDataResp" class="form-control maskedCountry">{{ this.getMaskedDataResp.countryName }}</div>
                <small
                    [hidden]="(countries.error$ | async) !== true"
                    class="text-danger show-on-content-edit-hidden"
                    role="alert"
                    id="addressCountriesError"
                    editable
                    translate
                >
                    ^address.countriesError
                </small>
                <small
                    [hidden]="((countries.error$ | async) !== true && country.touched && country.errors?.required) !== true"
                    class="text-danger show-on-content-edit-hidden"
                    id="addressCountriesError"
                    role="alert"
                    editable
                    translate
                >
                    ^address.countryRequired
                </small>
            </div>
        </div>
    </div>
    <div class="row" [class.columnViewContentCentered]="!requireRegistration">
        <div class="col-md-6 col-sm-12 mt-3">
            <div class="col-md-12">
                <label class="h6" for="addFirstName" editable translate>^address.defaultFirstName</label>
                <input
                    type="text"
                    id="addFirstName"
                    class="form-control"
                    formControlName="firstName"
                    aria-describedby="addressFirstNameRequired"
                    required
                    autocomplete="given-name"
                    [readonly]="getMaskedDataResp"
                />
                <small
                    [hidden]="(addressForm.get('firstName').touched && addressForm.get('firstName').invalid) !== true"
                    class="text-danger show-on-content-edit-hidden"
                    id="addressFirstNameRequired"
                    role="alert"
                    editable
                    translate
                >
                    ^address.firstNameRequired
                </small>
            </div>
        </div>
        <div class="col-md-6 col-sm-12 mt-3" *ngIf="!showToolTipE2P">
            <div class="col-md-12">
                <label class="h6" for="addLastName" id="addressLastName" editable translate>^address.defaultLastName</label>
                <div class="d-flex">
                    <input
                        type="text"
                        id="addLastName"
                        class="form-control"
                        formControlName="lastName"
                        aria-describedby="addressLastNameRequired"
                        required
                        autocomplete="family-name"
                        [readonly]="getMaskedDataResp"
                    />
                </div>
                <small
                    [hidden]="(addressForm.get('lastName').touched && addressForm.get('lastName').invalid) !== true"
                    class="text-danger show-on-content-edit-hidden"
                    id="addressLastNameRequired"
                    role="alert"
                    editable
                    translate
                >
                    ^address.lastNameRequired
                </small>
            </div>
        </div>
        <div class="col-md-6 col-sm-12 mt-3 pr-0" *ngIf="showToolTipE2P">
            <div class="col-md-12 pr-0">
                <label class="h6" for="addLastName" id="addressLastName" editable translate>^address.defaultLastName</label>
                <div class="d-flex">
                    <input
                        type="text"
                        id="addLastName"
                        class="form-control"
                        formControlName="lastName"
                        aria-describedby="addressLastNameRequired"
                        required
                        autocomplete="family-name"
                        [readonly]="getMaskedDataResp"
                    />
                    <i
                        *ngIf="showToolTipE2P && !getMaskedDataResp && !fromRegisterOnline"
                        class="fa fa-info-circle mt-3 pl-2 pr-2"
                        aria-hidden="true"
                        (click)="onToggleRegCardHelp(toggleRegCardIcon); $event.stopPropagation()"
                    ></i>
                    <app-custom-tooltip [dataPatchMsg]="true" [tooltipConfigs]="tooltipConfigs"></app-custom-tooltip>
                </div>
                <small
                    [hidden]="(addressForm.get('lastName').touched && addressForm.get('lastName').invalid) !== true"
                    class="text-danger show-on-content-edit-hidden"
                    id="addressLastNameRequired"
                    role="alert"
                    editable
                    translate
                >
                    ^address.lastNameRequired
                </small>
            </div>
        </div>
    </div>
    <div class="row"></div>
    <div class="row" *ngIf="requireRegistration">
        <div class="col-md-6 col-sm-12 mt-3">
            <div class="col-md-12">
                <label
                    class="h6"
                    for="addLine1"
                    id="addressLine1"
                    editable
                    [translate]="'^address.' + countryConfiguration.addressLine1Label"
                ></label>
                <input
                    type="text"
                    id="addLine1"
                    aria-describedby="addressLine1Required"
                    class="form-control"
                    formControlName="addressLine1"
                    required
                    autocomplete="address-line1"
                    (selectItem)="selectedSearchItem($event)"
                    [ngbTypeahead]="search"
                    [readonly]="getMaskedDataResp"
                />
                <small
                    [hidden]="(addressForm.get('addressLine1').touched && addressForm.get('addressLine1').invalid) !== true"
                    class="text-danger show-on-content-edit-hidden"
                    id="addressLine1Required"
                    role="alert"
                    editable
                    translate
                >
                    ^address.addressLine1Required
                </small>
            </div>
        </div>
        <div class="col-md-6 col-sm-12 mt-3">
            <div class="col-md-12">
                <label class="h6" for="addLine2" editable [translate]="'^address.' + countryConfiguration.addressLine2Label"></label>
                <input
                    type="text"
                    id="addLine2"
                    formControlName="addressLine2"
                    class="form-control"
                    autocomplete="address-line2"
                    [readonly]="getMaskedDataResp"
                />
            </div>
            <small
                [hidden]="(addressForm.get('addressLine2').touched && addressForm.get('addressLine2').invalid) !== true"
                class="text-danger show-on-content-edit-hidden"
                id="addressLine2Invalid"
                role="alert"
                editable
                translate
            >
                ^address.addressLine2Invalid
            </small>
        </div>
    </div>
    <div class="row" [class.columnViewContentCentered]="!requireRegistration">
        <div class="col-md-6 col-sm-12 mt-3" *ngIf="requireRegistration">
            <div class="col-md-12">
                <label class="h6" for="addCity" editable [translate]="'^address.' + countryConfiguration.cityLabel"></label>
                <input
                    type="text"
                    id="addCity"
                    aria-describedby="cityRequired"
                    class="form-control"
                    formControlName="city"
                    autocomplete="address-level2"
                    required
                    [readonly]="getMaskedDataResp"
                />
                <small
                    [hidden]="(addressForm.get('city').touched && addressForm.get('city').invalid) !== true"
                    class="text-danger show-on-content-edit-hidden"
                    id="cityRequired"
                    role="alert"
                    editable
                    translate
                >
                    ^address.cityRequired
                </small>
            </div>
        </div>
        <div *ngIf="countryConfiguration.showStateProvince && requireRegistration" class="col-md-6 col-sm-12 mt-3">
            <div class="col-md-12">
                <label
                    class="h6"
                    for="addRegion"
                    id="region"
                    editable
                    [translate]="'^address.' + countryConfiguration.stateprovinceLabel"
                ></label>
                <select
                    *ngIf="!getMaskedDataResp"
                    id="addRegion"
                    aria-describedby="regionError"
                    class="form-control"
                    formControlName="region"
                    class="form-control"
                    required
                    autocomplete="address-level1"
                >
                    <ng-container *ngFor="let region of regions?.$ | async">
                        <option [value]="region.abbreviation" *ngIf="region.shippingAllowed || !forShipping">
                            {{ region.name }}
                        </option>
                    </ng-container>
                </select>
                <div *ngIf="getMaskedDataResp" class="form-control maskedCountry">{{ this.getMaskedDataResp.stateName }}</div>
                <small
                    [hidden]="(regions?.error$ | async) !== true"
                    class="text-danger show-on-content-edit-hidden"
                    id="regionError"
                    role="alert"
                    editable
                    translate
                    >^address.regionError</small
                >
                <small
                    [hidden]="((regions?.error$ | async) !== true && region.touched && region.errors?.required) !== true"
                    class="text-danger show-on-content-edit-hidden"
                    id="regionError"
                    role="alert"
                    editable
                    translate="^address.regionRequired"
                ></small>
            </div>
        </div>
        <div class="col-md-6 col-sm-12 mt-3" *ngIf="requireRegistration">
            <div class="col-md-12">
                <label
                    for="addZIPCode"
                    class="h6"
                    id="postalCode"
                    editable
                    [translate]="'^address.' + countryConfiguration.postalCodeLabel"
                ></label>
                <input
                    id="addZIPCode"
                    type="text"
                    aria-describedby="postalCodeRequired"
                    class="form-control"
                    formControlName="postalCode"
                    required
                    autocomplete="postal-code"
                    [readonly]="getMaskedDataResp"
                />
                <small
                    [hidden]="(addressForm.get('postalCode').touched && addressForm.get('postalCode').invalid) !== true"
                    class="text-danger show-on-content-edit-hidden"
                    id="postalCodeRequired"
                    editable
                    role="alert"
                    translate
                >
                    ^address.postalCodeRequired
                </small>
            </div>
        </div>
        <div class="col-md-6 col-sm-12 mt-3" *ngIf="showPhoneNumber">
            <div class="col-md-12">
                <!-- [attr.aria-label]="'^address.' + countryConfiguration.phoneNumberLabel | translate" -->
                <label
                    class="h6"
                    for="addPhoneNumber"
                    id="phoneNumber"
                    editable
                    [translate]="'^address.' + countryConfiguration.phoneNumberLabel"
                ></label>
                <input
                    id="addPhoneNumber"
                    [attr.minlength]="requireRegistration ? 10 : 10"
                    [attr.maxlength]="requireRegistration ? 15 : 10"
                    aria-describedby="invalidPhonenumber"
                    class="form-control"
                    formControlName="phoneNumber"
                    numbersOnly
                />

                <small
                    [hidden]="(addressForm.get('phoneNumber').touched && addressForm.get('phoneNumber').invalid) !== true"
                    class="text-danger show-on-content-edit-hidden"
                    id="invalidPhonenumber"
                    role="alert"
                    editable
                    translate
                >
                    ^address.invalidPhonenumber
                </small>
            </div>
        </div>
    </div>
    <div class="row"></div>
</form>
