import { NumbersOnlyDirective } from './directives/numbers-only.directive';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { SubmitComponent } from './components/buttons/submit.component';
import { AlertModalComponent } from './components/modals/alert-modal.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { ProfileComponent } from './components/profile/profile.component';
import { LoginComponent } from './components/login/login.component';
import { AddressComponent } from './components/address/address.component';
import { ContentEditorComponent } from './components/contentEditor/content-editor.component';
import { FileUploadComponent } from './components/contentEditor/file-upload.component';
import { ToggleSwitchComponent } from './components/buttons/toggle-switch/toggle-switch.component';
import { CountryLanguageComponent } from './components/country-language/country-language.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AddressVerificationModalComponent } from './components/modals/address-verification-modal/address-verification-modal.component';
import { BHNTranslateDirective } from './directives/bhn-translate.directive';
import { EditableDirective } from './directives/editable.directive';
import { BHNSafeHtmlPipe } from './pipes/bhn-safe-html.pipe';
import { BHNTranslatePipe } from './pipes/bhn-translate.pipe';
import { DateLocalePipe } from './pipes/date-locale.pipe';
import { NumberCurrencyPipe } from './pipes/number-currency.pipe';
import { SetFocusDirective } from './directives/set-focus.directive';
import { RedirectionComponent } from './components/redirection/redirection.component';
import { DisableFieldDirective } from './directives/disable-field.directive';
import { ToastsContainerComponent } from './components/toast/toasts-container.component';
import { SortableHeaderDirective } from './directives/sortable.directive';
import { FieldErrorComponent } from './components/field-error/field-error.component';
import { ForgotPasswordToastTemplateComponent } from './components/toast/forgot-password/forgot-password-template.component';
import { SkipToContentComponent } from './components/skip-to-content/skip-to-content.component';
import { CardSpinnerComponent } from './components/card-spinner/card-spinner.component';
import { FluidAddressVerificationModalComponent } from './components/modals/fluid-address-verification-modal/fluid-address-verification-modal.component';
import { NotificationBannerComponent } from './components/notification-banner/notification-banner.component';
import { FooterComponent } from './components/footer/footer.component';
import { AdditionalInfoComponent } from './components/additional-info/additional-info.component';
import { FormFieldErrorLinkComponent } from './components/form-field-error-link/form-field-error-link.component';
import { InitiateIdvComponent } from './components/initiate-idv/initiate-idv.component';
import { BhnSafeResourceUrlPipe } from './pipes/bhn-safe-resource-url.pipe';
import { CustomBannerComponent } from './components/custom-banner/custom-banner.component';
import { KycErrorComponent } from './components/kyc-error/kyc-error.component';
import { SharedHeroMessageComponent } from './components/shared-hero-message/shared-hero-message.component';
import { NumbersAndSlashOnlyDirective } from './directives/numbers-and-slash-only.directive';
import { ContactUsFormComponent } from './components/contact-us-form/contact-us-form.component';
import { CustomTooltipComponent } from './components/custom-tooltip/custom-tooltip.component';
import { TimedOutErrorPageComponent } from './components/timedout-error-page/timedout-error-page.component';
import { AlertContactInfoComponent } from './components/alert-contact-info/alert-contact-info.component';
import { CurrencyPipe } from '@angular/common';
import { TrimPipe } from './pipes/trim.pipe';
import { AddToWalletModalComponent } from './components/modals/add-to-wallet-modal/add-to-wallet-modal.component';
import { VideoHoisitingModalComponent } from './components/modals/video-hoisiting-modal/video-hoisiting-modal.component';
import { CardholderAgreementComponent } from './components/cardholder-agreement/cardholder-agreement.component';

@NgModule({
    declarations: [
        SubmitComponent,
        AlertModalComponent,
        ProgressBarComponent,
        ProfileComponent,
        LoginComponent,
        AddressComponent,
        ContentEditorComponent,
        FileUploadComponent,
        ToggleSwitchComponent,
        CountryLanguageComponent,
        NumbersOnlyDirective,
        AddressVerificationModalComponent,
        BHNTranslateDirective,
        BHNTranslatePipe,
        BHNSafeHtmlPipe,
        EditableDirective,
        NumberCurrencyPipe,
        DateLocalePipe,
        SetFocusDirective,
        RedirectionComponent,
        DisableFieldDirective,
        ToastsContainerComponent,
        SortableHeaderDirective,
        FieldErrorComponent,
        ForgotPasswordToastTemplateComponent,
        SkipToContentComponent,
        CardSpinnerComponent,
        FluidAddressVerificationModalComponent,
        NotificationBannerComponent,
        FooterComponent,
        AdditionalInfoComponent,
        FormFieldErrorLinkComponent,
        InitiateIdvComponent,
        BhnSafeResourceUrlPipe,
        CustomBannerComponent,
        KycErrorComponent,
        SharedHeroMessageComponent,
        NumbersAndSlashOnlyDirective,
        ContactUsFormComponent,
        CustomTooltipComponent,
        TimedOutErrorPageComponent,
        AlertContactInfoComponent,
        TrimPipe,
        AddToWalletModalComponent,
        VideoHoisitingModalComponent,
        CardholderAgreementComponent,
    ],
    imports: [
        CommonModule, //
        RouterModule,
        ReactiveFormsModule,
        NgbModule,
        BsDropdownModule.forRoot(),
    ],
    exports: [
        SubmitComponent,
        AlertModalComponent,
        ProgressBarComponent,
        ProfileComponent,
        LoginComponent,
        AddressComponent,
        ContentEditorComponent,
        ReactiveFormsModule,
        ToggleSwitchComponent,
        NumbersOnlyDirective,
        AddressVerificationModalComponent,
        BHNTranslateDirective,
        BHNTranslatePipe,
        EditableDirective,
        BHNSafeHtmlPipe,
        NumberCurrencyPipe,
        DateLocalePipe,
        CountryLanguageComponent,
        SetFocusDirective,
        RedirectionComponent,
        DisableFieldDirective,
        ToastsContainerComponent,
        SortableHeaderDirective,
        FieldErrorComponent,
        ForgotPasswordToastTemplateComponent,
        SkipToContentComponent,
        CardSpinnerComponent,
        NotificationBannerComponent,
        FooterComponent,
        AdditionalInfoComponent,
        FormFieldErrorLinkComponent,
        InitiateIdvComponent,
        CustomBannerComponent,
        SharedHeroMessageComponent,
        NumbersAndSlashOnlyDirective,
        NgbModule,
        ContactUsFormComponent,
        CustomTooltipComponent,
        TimedOutErrorPageComponent,
        AlertContactInfoComponent,
        TrimPipe,
        CardholderAgreementComponent,
    ],
    providers: [NumberCurrencyPipe, CurrencyPipe, TrimPipe],
})
export class SharedModule {}
