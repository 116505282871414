import { BaseComponent } from '@app/base/base.component';
import { NGXLogger } from 'ngx-logger';
import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  template: ''
})
export class BasePageComponent extends BaseComponent {
}
