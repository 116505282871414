import { Observable, BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { CardDetailModel } from './models/card-detail.model';
import { CardDetailsHttpService } from './http/card-details-http.service';
import { UnmaskedCardInformation } from './models/get-unmasked-card-information-response-model';
import { MerchantFilterModel } from './models/merchant-filter-model';
import { MerchantCategoriesModel } from './models/merchant-categories.model';
import { CustomLinkListModel } from './models/custom-link-model';
import { AdListModel } from './models/ad.model';
import { CardInformation } from './models/card-information.model';

@Injectable({
    providedIn: 'root',
})
export class CardDetailsService {
    constructor(protected cardDetailsHttpService: CardDetailsHttpService) {}

    fromCardDetails = false;
    private cardDetailModel$$: BehaviorSubject<CardDetailModel> = new BehaviorSubject<CardDetailModel>(null);
    public cardDetailModel$: Observable<CardDetailModel> = this.cardDetailModel$$.asObservable();

    private merchantFilter$$: BehaviorSubject<MerchantFilterModel> = new BehaviorSubject<MerchantFilterModel>(null);
    public merchantFilter$: Observable<MerchantFilterModel> = this.merchantFilter$$.asObservable();
    public getCardInformation: CardInformation | any;
    public issuer: string;

    get snapshot(): {
        cardDetailModel: CardDetailModel;
        merchantFilter: MerchantFilterModel;
    } {
        return {
            cardDetailModel: this.cardDetailModel$$.value,
            merchantFilter: this.merchantFilter$$.value,
        };
    }

    get isCustomPage(): boolean {
        return !!this.snapshot.cardDetailModel;
    }

    get isStyleOption(): boolean {
        return !!this.snapshot.cardDetailModel.styleOptions;
    }

    clear(): void {
        this.cardDetailModel$$.next(null);
        this.merchantFilter$$.next(null);
    }

    getUnmaskedCardInformation(proxyCardNumber: string, rmsSessionId?: string): Promise<UnmaskedCardInformation> {
        return this.cardDetailsHttpService.getUnmaskedCardInformation(proxyCardNumber, rmsSessionId);
    }

    async validatePublishedPage(pageurl: string): Promise<CardDetailModel> {
        const cardDetailModel = await this.cardDetailsHttpService.validatePublishedPage(pageurl);

        // set Observable model
        if (cardDetailModel && cardDetailModel.isPublished) {
            this.cardDetailModel$$.next(cardDetailModel);
        }

        return cardDetailModel;
    }

    async validatePreviewPage(pageurl: string): Promise<CardDetailModel> {
        let cardDetailModel = new CardDetailModel();
        try {
            cardDetailModel = await this.cardDetailsHttpService.validatePreviewPage(pageurl);

            // set Observable model
            if (cardDetailModel) {
                this.cardDetailModel$$.next(cardDetailModel);
            }
        } catch (error) {
            cardDetailModel = null;
        }

        return cardDetailModel;
    }

    async getMerchantFilter(pageUrl: string, clientProgramId?: number, preview?: boolean): Promise<MerchantFilterModel> {
        const merchantFilterModel = await this.cardDetailsHttpService.getMerchantFilter(pageUrl, clientProgramId, preview);

        if (merchantFilterModel && merchantFilterModel.merchantfilterId) {
            this.merchantFilter$$.next(merchantFilterModel);
        }
        return merchantFilterModel;
    }

    public getMerchantCategories(): Observable<MerchantCategoriesModel> {
        return this.cardDetailsHttpService.getMerchantCategories();
    }

    async getTermsConditions(termsConditionsName: string, bankIssuer: string): Promise<MerchantFilterModel> {
        return await this.cardDetailsHttpService.getTermsConditions(termsConditionsName, bankIssuer);
    }

    async getCustomLinks(clientProgramId?: number, productCode?: string): Promise<CustomLinkListModel> {
        return await this.cardDetailsHttpService.getCustomLinks(clientProgramId, productCode);
    }

    async getAds(pageUrl: string, clientProgramId: number): Promise<AdListModel> {
        return await this.cardDetailsHttpService.getAds(pageUrl, clientProgramId);
    }

    async getCardInfo(proxyCardNumber: string): Promise<any> {
        return (this.getCardInformation = await this.cardDetailsHttpService.getCardInfo(proxyCardNumber));
    }
}
