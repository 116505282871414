import { Pipe, PipeTransform } from '@angular/core';
import { BHNTranslateService } from '../services/bhn-translate.service';

@Pipe({ name: 'dateLocale' })
export class DateLocalePipe implements PipeTransform {
    defaultOptions: any = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        timeZone: 'UTC',
    };
    constructor(private translate: BHNTranslateService) {}

    transform(value: any, options?: any) {
        try {
            const locale = this.translate.getStoredLanguage();
            if (!options) {
                options = this.defaultOptions;
            }
            const formatter = new Intl.DateTimeFormat(locale, options);

            if (value instanceof Date) {
                return formatter.format(value);
            } else if (typeof value === 'string') {
                const date = new Date(value);

                return formatter.format(date);
            } else {
                return value;
            }
        } catch {
            return value;
        }
    }
}
