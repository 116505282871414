/* eslint-disable @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match */

import { ValueProvider, InjectionToken } from '@angular/core';

export const SESSION_STORAGE = new InjectionToken('SessionStorage');

export const SessionStorageProvider: ValueProvider = {
    provide: SESSION_STORAGE,
    useValue: sessionStorage,
};

export const mockSessionStorage: Storage = {
    getItem: () => null,
    setItem: () => {},
    length: 0,
    clear: () => {},
    removeItem: () => {},
    key: () => '',
};

export const SessionStorageMock: ValueProvider = {
    provide: SESSION_STORAGE,
    useValue: mockSessionStorage,
};
