import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';
import { CommonHttpService } from '../http/common-http.service';
import { CountrySettingModel } from '../models/country-setting.model';
import { CookieConsentService } from './cookie-consent.service';

@Injectable({
    providedIn: 'root',
})
export class BHNTranslateService {
    private localStorageLanguageKey = 'storedLanguage';
    private localStorageCountryKey = 'storedCountry';
    private defaultLanguage = 'en-US';
    private defaultCountry = 'US';
    private countrySettingList: CountrySettingModel[];

    constructor(
        private commonHttp: CommonHttpService,
        protected translate: TranslateService,
        private router: Router,
        private cookieConsentService: CookieConsentService,
    ) {}

    storedLanguageExist(): boolean {
        if (localStorage.getItem(this.localStorageLanguageKey)) {
            return true;
        } else {
            return false;
        }
    }

    storeLanguage(language: string): void {
        localStorage.setItem(this.localStorageLanguageKey, language);
        this.translate.use(language).pipe(first());
    }

    getStoredLanguage(returnDefault: boolean = true): string {
        let language = localStorage.getItem(this.localStorageLanguageKey);
        if (returnDefault && language === null) {
            language = this.defaultLanguage;
        }

        return language;
    }

    storeCountry(countryCode: string): void {
        localStorage.setItem(this.localStorageCountryKey, countryCode);
    }

    getStoredCountry(returnDefault: boolean = true): string {
        let country = localStorage.getItem(this.localStorageCountryKey);
        if (returnDefault && country === null) {
            country = this.defaultCountry;
        }

        return country;
    }

    async getCountrySetting(): Promise<CountrySettingModel[]> {
        if (!this.countrySettingList) {
            this.countrySettingList = await this.commonHttp.getCountrySetting();
            const defaultCountrySetting = await this.getDefaultCountrySetting();

            if (defaultCountrySetting) {
                this.defaultLanguage = defaultCountrySetting.defaultLanguage;
                this.defaultCountry = defaultCountrySetting.countryCode;
            }
        }
        return this.countrySettingList;
    }

    async getDefaultCountrySetting(): Promise<CountrySettingModel> {
        if (!this.countrySettingList) {
            this.countrySettingList = await this.commonHttp.getCountrySetting();
        }
        return this.countrySettingList.length === 1
            ? this.countrySettingList[0]
            : this.countrySettingList.filter((x) => x.defaultCountry)[0]
            ? this.countrySettingList.filter((x) => x.defaultCountry)[0]
            : this.countrySettingList.filter((x) => x.countryCode === 'US')[0]
            ? this.countrySettingList.filter((x) => x.countryCode === 'US')[0]
            : this.countrySettingList[0];
    }
}
