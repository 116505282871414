import { DomSanitizer } from '@angular/platform-browser';
import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

export interface ModalButton {
    label: string;
    className: string;
    returnValue: string;
    focus: boolean;
}

@Component({
    template: `
        <div class="modal-header">
            <h2 *ngIf="title" class="modal-title" [innerHTML]="titleText | translate: titleTokens | safeHtml" aria-level="2" role="heading"></h2>
            <button
                *ngIf="closeButton"
                type="button"
                class="close"
                data-dismiss="modal"
                [attr.aria-label]="'^modal.general.close' | translate"
                (click)="dismissModal('x-button')"
                tabindex="0"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div *ngIf="!errType" class="alert-message" [innerHTML]="messageText | translate: messageTokens | safeHtml"></div>
            <div *ngIf="errType" class="alert-message" id="{{message}}" editable translate>^invalid-login.aws-waf-error</div>
        </div>
        <div class="modal-footer">
            <button
                *ngFor="let button of buttons"
                [class]="button.className"
                (click)="dismissModal(button.returnValue)"
                ngbAutofocus="button.focus"
            >
                {{ button.label | translate }}
            </button>
        </div>
    `,
})
export class AlertModalComponent {
    @Input() message: string | [string, { [key: string]: string }];
    @Input() title: string | [string, { [key: string]: string }];
    @Input() buttons: Array<ModalButton>;
    @Input() closeButton = true;
    @Input() errType: boolean = false;

    get titleText(): string {
        if (typeof this.title === 'string') {
            return this.title;
        } else {
            return this.title[0];
        }
    }

    get titleTokens(): { [key: string]: string } {
        if (typeof this.title === 'string') {
            return;
        } else {
            return this.title[1];
        }
    }

    get messageText(): string {
        if (typeof this.message === 'string') {
            return this.message;
        } else {
            return this.message[0];
        }
    }

    get messageTokens(): { [key: string]: string } {
        if (typeof this.message === 'string') {
            return;
        } else {
            return this.message[1];
        }
    }

    constructor(private activeModal: NgbActiveModal, private domSanitizer: DomSanitizer) {}

    dismissModal(returnValue) {
        this.activeModal.close(returnValue);
    }
}
