import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from '@app/base/base.component';
import { CredentialsService } from '@app/core/authentication/credentials.service';
import { CanComponentDeactivate } from '@app/core/guards/can-deactivate.guard';
import { KycStatus } from '@app/shared/enum/kyc-status.enum';
import { AccountIntendedUsageListEntity, KycIntendedAccount } from '@app/shared/models/kyc-intended-account.model';
import { KycJob, OccupationModelEntity } from '@app/shared/models/kyc-job.model';
import { KycOccupationCategory, OccupationCategoriesModelEntity } from '@app/shared/models/kyc-occupation.model';
import { SaveAdditionalInfoRequest } from '@app/shared/models/save-additional-info-request.model';
import { BHNModalService } from '@app/shared/services/bhn-modal.service';
import { KycProcessFlowService } from '@app/shared/services/kyc-process-flow.service';
import { KycService } from '@app/shared/services/kyc.service';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';

enum State {
    Default,
    Processing,
    ValidationFailed,
    Error,
}

@Component({
    selector: 'app-shared-additional-info',
    templateUrl: './additional-info.component.html',
    styleUrls: ['./additional-info.component.scss'],
})
export class AdditionalInfoComponent extends BaseComponent implements OnInit, CanComponentDeactivate {
    mainHeader: string;
    subHeader: string;
    occupationCategoryList: KycOccupationCategory;
    intendedUseOfAccountList: KycIntendedAccount;
    jobbyOccupationCategoryID: KycJob;
    State = State;
    state: State = this.State.Default;
    submitted: boolean;
    additionalInfoFormGroup: FormGroup;
    isApiError: boolean;
    occupationcategorySelectedList: OccupationCategoriesModelEntity;
    jobSelectedList: OccupationModelEntity;
    accountIntendedSelectedList: AccountIntendedUsageListEntity;
    allowToLeavetheKYCFlow: boolean;
    constructor(
        private kycService: KycService,
        private kycProcessFlowService: KycProcessFlowService,
        private credentials: CredentialsService,
        private route: ActivatedRoute,
        private bhnModalService: BHNModalService,
    ) {
        super();
    }

    async canDeactivate(): Promise<boolean> {
        if (!this.kycProcessFlowService.isLeaveKycFlow && !this.allowToLeavetheKYCFlow) {
            const result = await this.bhnModalService.showAlert(
                '^kyc-exit-modal.lawDescription',
                '^kyc-exit-modal.title',
                ['^kyc-exit-modal.stay', '^kyc-exit-modal.discardChanges'],
                true,
                {
                    windowClass: 'bhn-alert-modal-fluid',
                    backdrop: 'static',
                    centered: true,
                },
            );
            if (result === '^kyc-exit-modal.discardChanges') {
                this.kycProcessFlowService.rest();
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    }

    async ngOnInit() {
        this.allowToLeavetheKYCFlow = false;
        this.mainHeader = this.route.snapshot.data.mainHeader;
        this.subHeader = this.route.snapshot.data.subHeader;
        this.submitted = false;
        this.additionalInfoFormGroup = new FormGroup({
            occupationCategory: new FormControl(null, Validators.required),
            job: new FormControl(null, Validators.required),
            accountIntended: new FormControl(null, Validators.required),
            consent: new FormControl(false, Validators.requiredTrue),
        });
        this.occupationCategoryList = await this.kycService.getOccupationCategories();
        this.intendedUseOfAccountList = await this.kycService.getIntendedUseOfAccountList();
    }

    async occupationSelected(category: OccupationCategoriesModelEntity) {
        this.occupationcategorySelectedList = category;
        this.additionalInfoFormGroup.controls.occupationCategory.setValue(category.occupationcategoryname);
        this.additionalInfoFormGroup.controls.job.setValue(null);
        this.jobbyOccupationCategoryID = await this.kycService.getJobsByOccupationCategoryId(category.occupationcategoryid);
    }
    public jobSelected(value: OccupationModelEntity): void {
        this.jobSelectedList = value;
        this.additionalInfoFormGroup.controls.job.setValue(value.occupationname);
    }
    public accountSelected(value: AccountIntendedUsageListEntity): void {
        this.accountIntendedSelectedList = value;
        this.additionalInfoFormGroup.controls.accountIntended.setValue(value.intendedusagename);
    }
    hideDropdown(dropdownRef: BsDropdownDirective): void {
        setTimeout(() => {
            dropdownRef.hide();
        }, 1);
    }

    async submit() {
        this.submitted = true;
        try {
            if (!this.additionalInfoFormGroup.valid) {
                this.additionalInfoFormGroup.markAllAsTouched();
                this.state = State.ValidationFailed;
                return;
            }
            this.state = State.Processing;
            const request: SaveAdditionalInfoRequest = {
                occupationCategoryId: this.occupationcategorySelectedList ? this.occupationcategorySelectedList.occupationcategoryid : null,
                occupationId: this.jobSelectedList ? this.jobSelectedList.occupationid : null,
                accountIntendedUseageId: this.accountIntendedSelectedList ? this.accountIntendedSelectedList.intendedusageid : null,
                kycStatus: KycStatus.INCOMPLETE,
            };
            await this.kycService.saveAdditionalInformation(request);
            this.kycProcessFlowService.beginKYCFlow();
            this.allowToLeavetheKYCFlow = true;
        } catch (error) {
            this.allowToLeavetheKYCFlow = false;
            this.isApiError = true;
            this.state = State.ValidationFailed;
            console.log(error);
        }
    }
}
