<div class="my-5" #contactUsFormDiv>
    <h3 id="headerText" editable translate [attr.aria-label]="'headerText' | translate">contactusForm.headerText</h3>
    <form [formGroup]="contactusForm" (submit)="submit()">
        <!-- firstname and lastname row -->
        <div class="row customContactFormRow">
            <div class="col-md-5 my-3">
                <label class="h6 mr-1" for="firstname" editable translate>contactusForm.firstname</label>

                <div class="input-group-append">
                    <input
                        aria-describedby="firstnameInvalid"
                        type="text"
                        class="form-control"
                        id="firstname"
                        required
                        formControlName="firstname"
                        [readonly]="credentials?.isAuthenticated() && !!userData"
                    />
                </div>

                <app-field-error
                    [type]="'required'"
                    [parentForm]="contactusForm"
                    fieldName="firstname"
                    [errorMessage]="'contactusForm.requiredField'"
                    [id]="'firstname'"
                ></app-field-error>
                <app-field-error
                    [type]="'pattern'"
                    [parentForm]="contactusForm"
                    fieldName="firstname"
                    [errorMessage]="'contactusForm.invalidFirstName'"
                    [id]="'firstname'"
                ></app-field-error>
            </div>

            <div class="col-md-5 my-3">
                <label class="h6 mr-1" for="lastname" editable translate>contactusForm.lastname</label>

                <div class="input-group-append">
                    <input
                        type="text"
                        required
                        aria-describedby="lastnameInvalid"
                        id="lastname"
                        class="form-control"
                        formControlName="lastname"
                        required
                        [readonly]="credentials?.isAuthenticated() && !!userData"
                    />
                </div>

                <app-field-error
                    [type]="'required'"
                    [parentForm]="contactusForm"
                    fieldName="lastname"
                    [errorMessage]="'contactusForm.requiredField'"
                    [id]="'lastname'"
                ></app-field-error>
                <app-field-error
                    [type]="'pattern'"
                    [parentForm]="contactusForm"
                    fieldName="lastname"
                    [errorMessage]="'contactusForm.invalidLastName'"
                    [id]="'lastname'"
                ></app-field-error>
            </div>
        </div>

        <!-- email and telephone row -->
        <div class="row customContactFormRow">
            <div class="col-md-5 my-3">
                <label class="h6 mr-1" for="emailAddress" editable translate>contactusForm.emailAddress</label>

                <div class="input-group-append">
                    <input
                        aria-describedby="emailAddressInvalid"
                        type="email"
                        class="form-control"
                        id="emailAddress"
                        required
                        formControlName="emailAddress"
                        email
                        [readonly]="credentials?.isAuthenticated() && !!userData"
                    />
                </div>

                <app-field-error
                    [type]="'required'"
                    [parentForm]="contactusForm"
                    fieldName="emailAddress"
                    [errorMessage]="'contactusForm.requiredField'"
                    [id]="'emailAddressInvalid'"
                ></app-field-error>
                <app-field-error
                    [type]="'pattern'"
                    [parentForm]="contactusForm"
                    fieldName="emailAddress"
                    [errorMessage]="'contactusForm.invalidEmail'"
                    [id]="'emailAddressInvalid'"
                ></app-field-error>
                <!-- <app-field-error
                    [type]="'customFieldError'"
                    [parentForm]="contactusForm"
                    fieldName="emailAddress"
                    [errorMessage]="'contactusForm.invalidEmail'"
                    [id]="'emailAddressInvalid'"
                    *ngIf="
                        !contactusForm.get('emailAddress').valid &&
                        !contactusForm.get('emailAddress').untouched &&
                        contactusForm.get('emailAddress').errors?.email
                    "
                ></app-field-error> -->
            </div>

            <div class="col-md-5 my-3">
                <label class="h6 mr-1" for="telephoneNumber" *ngIf="credentials?.isAuthenticated()" editable translate>contactusForm.telephoneNumber</label>
                <label class="h6 mr-1" for="telephoneNumber" *ngIf="!credentials?.isAuthenticated()" editable translate>contactusForm.telephoneNumberReq</label>

                <div class="input-group-append">
                    <input
                        type="text"
                        minlength="10"
                        maxlength="15"
                        aria-describedby="telephoneNumberInvalid"
                        id="telephoneNumber"
                        class="form-control"
                        formControlName="telephoneNumber"
                        numbersOnly
                        *ngIf="credentials?.isAuthenticated()"
                        [readonly]="credentials?.isAuthenticated() && !!userData && !!userData.phone"
                    />
                    <input
                        type="text"
                        minlength="10"
                        maxlength="15"
                        required
                        aria-describedby="telephoneNumberInvalid"
                        id="telephoneNumber"
                        class="form-control"
                        formControlName="telephoneNumber"
                        numbersOnly
                        *ngIf="!credentials?.isAuthenticated()"
                        [readonly]="credentials?.isAuthenticated() && !!userData && !!userData.phone"
                    />
                </div>
                <app-field-error
                    [type]="'required'"
                    [parentForm]="contactusForm"
                    fieldName="telephoneNumber"
                    *ngIf="!credentials?.isAuthenticated()"
                    [errorMessage]="'contactusForm.requiredField'"
                    [id]="'phoneNumberInvalid'"
                ></app-field-error>
                
                <app-field-error
                    [type]="'customFieldError'"
                    [parentForm]="contactusForm"
                    fieldName="telephoneNumber"
                    [errorMessage]="'contactusForm.invalidPhoneFormat'"
                    [id]="'telephoneNumberInvalid'"
                    *ngIf="telephoneNumberValidations()"
                ></app-field-error>
            </div>
        </div>

        <!-- card details and reason row -->
        <div class="row customContactFormRow">
            <!-- Reason -->
            <div class="col-md-5 my-3" *ngIf="contactUsReasons">
                <div class="dropdownWrapper">
                    <label editable [translate]="'contactusForm.reason'"></label>
                    <div
                        dropdown
                        #reasonDropdown="bs-dropdown"
                        tabindex="0"
                        (keydown.enter)="reasonDropdown.show()"
                        aria-describedby="reasonRequired"
                    >
                        <div
                            dropdownToggle
                            role="button"
                            class="dropdown-shell"
                            [ngClass]="{ 'border-error': contactusForm.controls.reason.errors && submitted && !formSubmissionSuccessful }"
                        >
                            <div class="m-3">
                                <span *ngIf="contactusForm.controls.reason.value" class="dropdown-text">
                                    {{ contactusForm.controls.reason.value }}
                                </span>
                                <span *ngIf="!contactusForm.controls.reason.value" editable translate>contactusForm.selectReason</span>
                                <i class="fa fa-angle-down fa-lg icon-color float-right mt-1"></i>
                            </div>
                        </div>
                        <div class="dropdown-menu-shell">
                            <ul *dropdownMenu class="dropdown-menu">
                                <li
                                    class="dropdown-item"
                                    *ngFor="let reason of contactUsReasons"
                                    tabindex="0"
                                    (click)="reasonSelected(reason)"
                                    (keydown.enter)="reasonSelected(reason); hideDropdown(reasonDropdown)"
                                >
                                    <span>{{ reason.reasonName }}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <app-field-error
                    [type]="'errorOnly'"
                    [parentForm]="contactusForm"
                    fieldName="reason"
                    [errorMessage]="'contactusForm.requiredField'"
                    [id]="'reasonRequired'"
                ></app-field-error>
            </div>

            <!-- Card details -->
            <div class="col-md-5 my-3">
                <div class="dropdownWrapper" *ngIf="cards && cards?.length > 0">
                    <label editable [translate]="'contactusForm.proxyCardNumber'"></label>
                    <div
                        dropdown
                        #cardNumberDropdown="bs-dropdown"
                        [class.dropdownDisabled]="cards.length === 1"
                        tabindex="0"
                        (keydown.enter)="cardNumberDropdown.show()"
                        aria-describedby="cardNumberRequired"
                    >
                        <div
                            dropdownToggle
                            role="button"
                            class="dropdown-shell"
                            [ngClass]="{ 'border-error': contactusForm.controls.cardNumber.errors && submitted }"
                        >
                            <div class="m-3">
                                <span *ngIf="contactusForm.controls.cardNumber.value" class="dropdown-text">
                                    {{ maskCardNumber(selectedCard?.proxyCardNumber) }}
                                </span>
                                <span *ngIf="!contactusForm.controls.cardNumber.value" editable translate
                                    >contactusForm.selectProxyCardNumber</span
                                >
                                <i class="fa fa-angle-down fa-lg icon-color float-right mt-1"></i>
                            </div>
                        </div>
                        <div class="dropdown-menu-shell">
                            <ul *dropdownMenu class="dropdown-menu">
                                <li
                                    class="dropdown-item"
                                    *ngFor="let card of cards"
                                    tabindex="0"
                                    tabindex="0"
                                    (click)="cardNumberSelected(card)"
                                    (keydown.enter)="cardNumberSelected(card); hideDropdown(cardNumberDropdown)"
                                >
                                    <span>{{ maskCardNumber(card.proxyCardNumber) }}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <app-field-error
                    [type]="'errorOnly'"
                    [parentForm]="contactusForm"
                    fieldName="cardNumber"
                    [errorMessage]="'contactusForm.requiredField'"
                    [id]="'cardNumberRequired'"
                    *ngIf="cards && cards?.length > 0"
                ></app-field-error>
            </div>
        </div>

        <!-- Enquiry text area row -->
        <div class="row customContactFormRow mt-3">
            <div
                class="col-md-12 my-2"
                [ngClass]="{ 'enquiryPlaceHolder': !contactusForm.controls.enquiryText.value }"
                [attr.data-content]="('contactusForm.enquiryTextAreaPlaceholder' | translate) + ' | '"
            >
                <textarea
                    class="form-control"
                    formControlName="enquiryText"
                    id="enquiryText"
                    rows="5"
                    maxlength="2048"
                    aria-describedby="enquiryTextInvalid"
                >
                    <!-- placeholder="{{ ('contactusForm.enquiryTextAreaPlaceholder' | translate) + ' | ' }}" -->
                </textarea>

                <app-field-error
                    [type]="'required'"
                    [parentForm]="contactusForm"
                    fieldName="enquiryText"
                    [errorMessage]="'contactusForm.requiredField'"
                    [id]="'enquiryTextInvalid'"
                ></app-field-error>
                <app-field-error
                    [type]="'customFieldError'"
                    [parentForm]="contactusForm"
                    fieldName="enquiryText"
                    [errorMessage]="'contactusForm.enquiryTextLimitExceeded'"
                    [id]="'enquiryTextInvalid'"
                    *ngIf="contactusForm.controls.enquiryText.errors?.maxlength"
                ></app-field-error>
            </div>
        </div>

        <!-- Upload additional files area -->
        <div class="row customContactFormRow">
            <div class="col-md-5 my-3">
                <div class="uploadFileWrapper" (click)="docs.click()">
                    <input
                        type="file"
                        accept="application/pdf,image/jpeg,image/png"
                        (change)="onFilechange($event)"
                        multiple
                        hidden
                        #docs
                    />
                    <div class="d-flex justify-content-center buttonWrapper">
                        <p>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M0.75 14.85C0.948912 14.85 1.13968 14.929 1.28033 15.0697C1.42098 15.2103 1.5 15.4011 1.5 15.6V19.35C1.5 19.7478 1.65804 20.1294 1.93934 20.4107C2.22064 20.692 2.60218 20.85 3 20.85H21C21.3978 20.85 21.7794 20.692 22.0607 20.4107C22.342 20.1294 22.5 19.7478 22.5 19.35V15.6C22.5 15.4011 22.579 15.2103 22.7197 15.0697C22.8603 14.929 23.0511 14.85 23.25 14.85C23.4489 14.85 23.6397 14.929 23.7803 15.0697C23.921 15.2103 24 15.4011 24 15.6V19.35C24 20.1457 23.6839 20.9087 23.1213 21.4713C22.5587 22.0339 21.7956 22.35 21 22.35H3C2.20435 22.35 1.44129 22.0339 0.87868 21.4713C0.316071 20.9087 0 20.1457 0 19.35V15.6C0 15.4011 0.0790176 15.2103 0.21967 15.0697C0.360322 14.929 0.551088 14.85 0.75 14.85V14.85Z"
                                    fill="black"
                                />

                                <path
                                    d="M11.469 1.719C11.5386 1.64915 11.6214 1.59374 11.7125 1.55593C11.8036 1.51812 11.9013 1.49866 12 1.49866C12.0986 1.49866 12.1963 1.51812 12.2874 1.55593C12.3785 1.59374 12.4613 1.64915 12.531 1.719L17.031 6.219C17.1718 6.35983 17.2509 6.55083 17.2509 6.75C17.2509 6.94916 17.1718 7.14017 17.031 7.281C16.8901 7.42183 16.6991 7.50095 16.5 7.50095C16.3008 7.50095 16.1098 7.42183 15.969 7.281L12.75 4.0605V17.25C12.75 17.4489 12.671 17.6397 12.5303 17.7803C12.3896 17.921 12.1989 18 12 18C11.8011 18 11.6103 17.921 11.4696 17.7803C11.329 17.6397 11.25 17.4489 11.25 17.25V4.0605L8.03097 7.281C7.96124 7.35073 7.87846 7.40604 7.78735 7.44378C7.69624 7.48152 7.59859 7.50095 7.49997 7.50095C7.40136 7.50095 7.3037 7.48152 7.2126 7.44378C7.12149 7.40604 7.0387 7.35073 6.96897 7.281C6.89924 7.21127 6.84392 7.12848 6.80619 7.03737C6.76845 6.94626 6.74902 6.84861 6.74902 6.75C6.74902 6.65138 6.76845 6.55373 6.80619 6.46262C6.84392 6.37151 6.89924 6.28873 6.96897 6.219L11.469 1.719Z"
                                    fill="black"
                                />
                            </svg>

                            <span class="ml-4" translate>contactusForm.uploadAdditionalFiles</span>
                        </p>
                        <p translate>contactusForm.uploadAdditionalFilesTip</p>
                    </div>
                </div>

                <div class="d-flex flex-row chip" *ngIf="files?.length > 0">
                    <div *ngFor="let file of files; let i = index" class="fileNameWrapper">
                        <div class="uploadFileName">{{ file['name'] }}</div>
                        <i (click)="removeFile(i)" aria-hidden="true">&times;</i>
                    </div>
                </div>
            </div>
        </div>

        <!-- General error -->
        <span *ngIf="errorMessage" class="text-danger show-on-content-edit-hidden text-wrap" translate editable>{{ errorMessage }} </span>
        <span *ngIf="isEmailInvalid" class="text-danger show-on-content-edit-hidden text-wrap" translate editable> contactusForm.emailInvalidMessage </span>
        <span *ngIf="isPhoneInvalid" class="text-danger show-on-content-edit-hidden text-wrap" translate editable> contactusForm.countryCodeInvalidMessage </span>
        <span *ngIf="areBothInvalid" class="text-danger show-on-content-edit-hidden text-wrap" translate editable> contactusForm.bothInvalidMessage </span>
        <!-- Submit button -->
        <div class="row customContactFormRow">
            <div class="col-md-5 my-3 d-flex flex-column justify-content-center">
                <button
                    type="submit"
                    data-focus="true"
                    class="btn btn-block btn-primary submitButton d-flex justify-content-center"
                    [disabled]="status === PageStatus.Processing"
                >
                    <span editable translate> contactusForm.submitButton </span>
                    <i class="fa fa-refresh fa-spin ml-2" *ngIf="status === PageStatus.Processing"></i>
                </button>
                <span class="text-success text-center py-2" *ngIf="formSubmissionSuccessful && status === PageStatus.Completed" translate
                    >contactusForm.formSubmissionSuccessful</span
                >
            </div>
        </div>
    </form>
</div>
