import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { ENVIRONMENT } from '@app/core/services/environment.service';
import { CommonService } from '@app/shared/services/common.service';

declare const RMSWidget: any;
@Injectable({
    providedIn: 'root',
})
export class RmsSessionService {
    private loadScriptPromise: Promise<void> = null;
    public rmsSessionData: any;
    constructor(
        @Inject(DOCUMENT) private document: any,
        @Inject(ENVIRONMENT) private environment: any,
        private commonService: CommonService,
    ) {}

    async getRmsData(flow: any): Promise<any> {
        return new Promise((resolve) => {
            const clientConfigId = this.commonService.clientConfigId;
            const onInitialization = (data: any) => {
                resolve(data);
            };
            const config = {
                metaData: {
                    clientConfigId,
                    environment: this.environment.environmentKey === 'production' ? 'live' : 'test',
                    flow,
                },
                onInitialization,
            };
            const widget = new RMSWidget(config);
            widget.initialize();
        });
    }

    // async loadScript(): Promise<void> {
    //   if (this.loadScriptPromise) {
    //     return this.loadScriptPromise;
    //   } else {

    //     this.loadScriptPromise = new Promise((resolve, reject) => {
    //       const script = this.document.createElement('script');
    //       script.defer = true
    //       script.src = this.environment.rmsCDN;
    //       script.onerror = reject;
    //       script.onload = () => {
    //         resolve();
    //       };
    //       this.document.getElementsByTagName('head')[0].appendChild(script);
    //     });

    //     return this.loadScriptPromise;

    //   }
    // }
}
