import { TranslateService } from '@ngx-translate/core';
import { Component, Inject, OnInit, HostListener, AfterViewInit, AfterViewChecked } from '@angular/core';
import { ENVIRONMENT } from '@app/core/services/environment.service';
import { CardDataService } from './shared/services/card-data.service';
import { CredentialsService } from './core/authentication/credentials.service';
import { ActivatedRouteSnapshot, ActivationEnd, Router } from '@angular/router';
import { ResizeService } from './core/services/screen-resize.service';
import { CommonService } from './shared/services/common.service';
import { BHNTranslateService } from './shared/services/bhn-translate.service';
import { BaseComponent } from './base/base.component';
import { OriginalInactivityModalComponent } from './features/original/shells/original-shell/components/inactivity-modal/original-inactivity-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { UserLoginService } from './services/auth/user-login.service';
import { WebPackConfigService } from './shared/services/web-pack-config.service';
import { CookieConsentService } from './shared/services/cookie-consent.service';
import { NavigationService } from './shared/services/navigation.service';
declare var setupAnalyticsWidget: any;
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent extends BaseComponent implements OnInit, AfterViewChecked, AfterViewInit {
    private translate: TranslateService;
    private titleTrans: string = undefined;
    private scrollbarWidth: number;
    isAdmin: boolean;
    lastSetActivityTime: object;

    constructor(
        @Inject(ENVIRONMENT) public environment: any,
        translate: TranslateService,
        private credentials: CredentialsService,
        private cardData: CardDataService,
        private ngbModal: NgbModal,
        private userLoginService: UserLoginService,
        private resizeService: ResizeService,
        private bhnTranslateService: BHNTranslateService,
        private commonService: CommonService,
        private router: Router,
        private webPackConfigService: WebPackConfigService,
        private cookieConsentService: CookieConsentService,
        private navigationService: NavigationService,
    ) {
        super();
        this.translate = translate;
        this.translate.setDefaultLang(environment.defaultLang);

        if (window.document.referrer) {
            try {
                const referrer = new URL(window.document.referrer);
                if (referrer.host.toUpperCase() !== window.location.host.toUpperCase()) {
                    this.commonService.setReferrer(referrer.host);
                }
            } catch {
                // just in case
                if (window.document.referrer.toUpperCase() !== window.location.host.toUpperCase()) {
                    this.commonService.setReferrer(window.document.referrer);
                }
            }
        }

        const storedLanguage = this.bhnTranslateService.getStoredLanguage();

        if (storedLanguage) {
            this.translate.use(storedLanguage);
            this.translate.get('root.title').subscribe((val) => (this.titleTrans = val));
        }

        this.router.events.subscribe((event) => {
            if (event instanceof ActivationEnd) {
                const country = event.snapshot.queryParams['country'];
                const language = event.snapshot.queryParams['lang'];
                if (country) {
                    this.bhnTranslateService.storeCountry(country);
                }
                if (language) {
                    this.bhnTranslateService.storeLanguage(language);
                }
            }
        });
    }

    title = this.titleTrans;

    /** To Load script Dynamically */

    ngAfterViewInit(): void {        
        this.loadScript('./bhn_analytics.js')
          .then(() => {
            const setupConfig = {
              env: this.environment.environmentKey === 'production' ? 'production' : 'dev'
            };
            if (typeof setupAnalyticsWidget === 'function') {
              setupAnalyticsWidget(setupConfig);
            } else {
              console.error('setupAnalyticsWidget is not defined.');
            }
          })
          .catch(error => {
            console.error('Error loading the analytics script:', error);
          });
      }

      loadScript(src: string): Promise<void> {
        return new Promise((resolve, reject) => {
          const script = document.createElement('script');
          script.src = src;
          script.type = 'text/javascript';
          script.async = true;
          script.onload = () => resolve();
          script.onerror = (error: any) => reject(error);
          document.body.appendChild(script);
        });
      }

      /** To Load script Dynamically */

    async ngOnInit(): Promise<void> {
        this.credentials.loadFromStorage();
        if (this.credentials.isAuthenticated()) {
            await this.cardData.load();
        }

        this.credentials.asObservable().subscribe((credentials) => {
            this.isAdmin = !!credentials && credentials.isAdmin();
        });

        await this.validateLanguage();
        this.cookieConsentService.addCookieConsentScript();
        this.subscriptions = [
            // Inactivity Modal
            this.credentials.getIsInactive().subscribe((isInactive) => {
                if (isInactive) {
                    // check the time
                    const elapsed = moment.duration(moment().diff(this.lastSetActivityTime));
                    const minsSinceLastActivity = elapsed && Math.round(elapsed.asMinutes());
                    if (minsSinceLastActivity >= 10) this.showInactiveModal();
                    else {
                        this.userLoginService.refresh();
                        this.credentials.setIsInactive(false);
                    }
                } else {
                    this.ngbModal.dismissAll();
                }
            }),
        ];
    }

    @HostListener('window:resize', ['$event.target'])
    private onWindowResize(window: Window) {
        this.resizeService.onResize(window.innerWidth);
    }

    ngAfterViewChecked(): void {
        const scrollbarWidth = window.innerWidth - document.body.clientWidth;
        if (scrollbarWidth !== this.scrollbarWidth) {
            this.scrollbarWidth = scrollbarWidth;
            const halfScrollbarWidth = scrollbarWidth / 2;
            document.body.style.setProperty('--scrollbarWidth', `${scrollbarWidth}px`);
            document.body.style.setProperty('--halfScrollbarWidth', `${halfScrollbarWidth}px`);
        }
    }

    // @HostListener('window:mousemove', ['$event.target'])
    @HostListener('window:scroll', ['$event.target'])
    @HostListener('document:keydown.tab', ['$event.target'])
    @HostListener('document:keydown.shift.tab', ['$event.target'])
    @HostListener('document:click', ['$event.target'])
    @HostListener('window:resize', ['$event.target'])
    private onActivityEvent() {
        this.lastSetActivityTime = moment();
    }

    getResolvedUrl(route: ActivatedRouteSnapshot): string {
        return route.pathFromRoot.map((v) => v.url.map((segment) => segment.toString()).join('/')).join('/');
    }

    showInactiveModal() {
        this.ngbModal.dismissAll();
        const modalRef = this.ngbModal.open(OriginalInactivityModalComponent, {
            backdrop: 'static',
            keyboard: false,
            centered: true,
            backdropClass: 'light-black-backdrop',
            windowClass: 'bhn-alert-modal',
        });
        modalRef.componentInstance.name = 'InactiveModal';
    }

    async validateLanguage(): Promise<void> {
        // Get culture setting from local storage or from browser config
        let browserCulture = this.bhnTranslateService.getStoredLanguage(false) || this.translate.getBrowserCultureLang();

        let [browserLanguage, browserCountry] = browserCulture.split('-');

        let storedCountry = this.bhnTranslateService.getStoredCountry(false);
        if (storedCountry) {
            // set country from local storage if there is one
            browserCountry = storedCountry;
        }

        // Get tenant countries
        const countrySettings = await this.bhnTranslateService.getCountrySetting();

        // If there is a default country, browser country is not used
        const defaultCountrySetting = countrySettings.find((x) => x.defaultCountry === true);

        if (defaultCountrySetting && storedCountry === null) {
            browserCountry = defaultCountrySetting.countryCode;
        }

        let countrySetting = browserCountry && countrySettings.find((cs) => cs.countryCode === browserCountry.toUpperCase());

        if (!countrySetting) {
            countrySetting = countrySettings.find((cs) => !cs.languages.find((l) => l.toLowerCase() === browserCulture.toLowerCase()));
        }

        if (!countrySetting) {
            countrySetting = countrySettings.find((cs) => !cs.languages.find((l) => l.startsWith(`${browserLanguage.toLowerCase()}-`)));
        }

        if (!countrySetting) {
            countrySetting = countrySettings.find((x) => x.countryCode === 'US') || countrySettings[0];
            this.bhnTranslateService.storeCountry(countrySetting.countryCode);
            this.bhnTranslateService.storeLanguage(countrySetting.defaultLanguage);
            return;
        }

        this.bhnTranslateService.storeCountry(countrySetting.countryCode);

        let supportedLanguage = countrySetting.languages.find((l) => l.toLowerCase() === browserCulture.toLowerCase());
        if (supportedLanguage) {
            this.bhnTranslateService.storeLanguage(supportedLanguage);
            return;
        }

        if (countrySetting.defaultLanguage.startsWith(`${browserLanguage.toLowerCase()}-`)) {
            this.bhnTranslateService.storeLanguage(countrySetting.defaultLanguage);
            return;
        }

        supportedLanguage = countrySetting.languages.find((l) => l.startsWith(`${browserLanguage.toLowerCase()}-`));
        if (supportedLanguage) {
            this.bhnTranslateService.storeLanguage(supportedLanguage);
            return;
        }

        this.bhnTranslateService.storeLanguage(countrySetting.defaultLanguage);
    }
}
