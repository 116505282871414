import { ContentEditorRequestModel } from './../models/content-editor.model';
import { ContentEditorModel } from '@app/shared/models/content-editor.model';
import { ContentEditorService } from '@app/shared/services/content-editor.service';
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, ReplaySubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { BHNTranslateService } from './bhn-translate.service';

@Injectable()
export class ContentEditorResolver implements Resolve<ContentEditorModel[]> {
    subject = new ReplaySubject<ContentEditorModel[]>(1);

    constructor(private ceService: ContentEditorService, private bhnTranslateService: BHNTranslateService) {}
    resolve(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<ContentEditorModel[]> {
        let url = _state.url; //this.getResolvedUrl(_route);
        if (url) {
            url = url.replace('//', '/');
        }
        const contentEditorRequestModel = new ContentEditorRequestModel();
        contentEditorRequestModel.isoCode = this.bhnTranslateService.getStoredLanguage();
        contentEditorRequestModel.setName = url;
        contentEditorRequestModel.overrideFilterListWithCurrent = this.ceService.selectedFilteredList.length === 0;
        contentEditorRequestModel.filterList = this.ceService.selectedFilteredList;
        contentEditorRequestModel.includeShell = true;
        this.ceService.content = this.ceService.getContentEditorData(contentEditorRequestModel).pipe(take(1));
        return this.ceService.content;
    }
    getResolvedUrl(route: ActivatedRouteSnapshot): string {
        return route.pathFromRoot.map((v) => v.url.map((segment) => segment.toString()).join('/')).join('/');
    }
}
