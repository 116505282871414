<ng-container *ngIf="type && errorMessage">
    <ng-container [ngSwitch]="type">
        <ng-container *ngSwitchCase="'required'">
            <small
                *ngIf="!this.formField?.valid && !this.formField?.untouched && (formField.errors?.required || formField.errors?.sanitized)"
                class="fluid-text-danger show-on-content-edit-hidden"
                id="{{ id }}"
                role="alert"
                editable
                translate
                >{{ errorMessage }}
            </small>
        </ng-container>
        <ng-container *ngSwitchCase="'pattern'">
            <small
                *ngIf="!this.formField.valid && !this.formField.untouched && formField.errors?.pattern"
                class="fluid-text-danger show-on-content-edit-hidden"
                role="alert"
                id="{{ id }}"
                editable
                translate
                >{{ errorMessage }}
            </small>
        </ng-container>
        <ng-container *ngSwitchCase="'password'">
            <small
                *ngIf="!this.formField.valid && !this.formField.untouched && (formField.errors?.pattern || formField.errors?.required)"
                class="alert-assistive-text fluid-text-danger show-on-content-edit-hidden"
                role="alert"
                id="{{ id }}"
                editable
                translate
                >{{ errorMessage }}
            </small>
        </ng-container>
        <ng-container *ngSwitchCase="'errorOnly'">
            <small
                *ngIf="!this.formField.valid && !this.formField.untouched && formField.errors?.required"
                class="form-error show-on-content-edit-hidden"
                role="alert"
                id="{{ id }}"
                editable
                translate
                >{{ errorMessage }}
            </small>
        </ng-container>
        <ng-container *ngSwitchCase="'invalidPhoneNumber'">
            <small
                *ngIf="state === State.InvalidPhoneNumber && !(this.formField.errors?.required && this.formField.touched)"
                class="form-error show-on-content-edit-hidden"
                role="alert"
                id="{{ id }}"
                editable
                translate
                >{{ errorMessage }}
            </small>
        </ng-container>
        <ng-container *ngSwitchCase="'invalidInputFormat'">
            <small class="form-error show-on-content-edit-hidden" role="alert" id="{{ id }}" editable translate>{{ errorMessage }} </small>
        </ng-container>
        <ng-container *ngSwitchCase="'processingError'">
            <small
                *ngIf="state === State.ProcessingError"
                class="form-error show-on-content-edit-hidden"
                role="alert"
                id="{{ id }}"
                editable
                translate
                >{{ errorMessage }}
            </small>
        </ng-container>
        <ng-container *ngSwitchCase="'smsError'">
            <small
                *ngIf="state === State.SMSError"
                class="form-error show-on-content-edit-hidden"
                role="alert"
                id="{{ id }}"
                editable
                translate
                >{{ errorMessage }}
            </small>
        </ng-container>
        <ng-container *ngSwitchCase="'successEmail'">
            <small class="success-text show-on-content-edit-hidden" role="alert" id="{{ id }}" editable>
                {{ errorMessage | translate : { email: this.formField.value } }}
            </small>
        </ng-container>
        <ng-container *ngSwitchCase="'successPhone'">
            <small class="success-text show-on-content-edit-hidden" role="alert" id="{{ id }}" editable>
                {{ errorMessage | translate : { phone: this.formField.value } }} 
            </small>
        </ng-container>
        <ng-container *ngSwitchCase="'apiError'">
            <small class="form-error show-on-content-edit-hidden" role="alert" id="{{ id }}" editable translate>{{ errorMessage }} </small>
        </ng-container>
        <ng-container *ngSwitchCase="'customFieldError'">
            <small class="fluid-text-danger show-on-content-edit-hidden" role="alert" id="{{ id }}" editable translate
                >{{ errorMessage }}
            </small>
        </ng-container>
    </ng-container>
</ng-container>
