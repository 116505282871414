import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit {
  @Input() steps: [];
  @Input() stepIndex: number;

  constructor() {}

  ngOnInit() {}

  getStepItemState(stepItemIndex: number) {
    if (stepItemIndex < this.stepIndex) {
      return 'complete';
    } else if (stepItemIndex === this.stepIndex) {
      return 'active';
    } else {
      return '';
    }
  }
}
