import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { CardDetailsService } from '@app/services/card/card-details.service';
import { CardDetailModel } from '@app/services/card/models/card-detail.model';

@Injectable()
export class CardDetailPreviewActivationGuard implements CanActivate {
    constructor(private router: Router, private cardDetailsService: CardDetailsService) {}

    async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
        let canActivate = false;

        if (route.params && route.params.name) {
            const cardDetailModel: CardDetailModel = await this.cardDetailsService.validatePreviewPage(route.params.name);

            if (cardDetailModel) {
                // set card detail page values
                canActivate = true;

                // override styles
                if (cardDetailModel.styleOptions) {
                    Object.keys(cardDetailModel.styleOptions).forEach((k) => {
                        document.documentElement.style.setProperty(`--card-detail-override-${k}`, cardDetailModel.styleOptions[k]);
                    });
                }
            } else {
                // TODO: redirect back to landing page or normal card detail page
                this.router.navigate(['/home']);
            }
        }

        return canActivate;
    }
}
