// import { enableProdMode } from '@angular/core';
// import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

// import { AppModule } from './app/app.module';
// import { environment } from './environments/environment';
// import { AppInjector } from '@app/core/services/app-injector.service';

// if (environment.production || environment.stratus) {
//   enableProdMode();
// }

// platformBrowserDynamic()
//   .bootstrapModule(AppModule)
//   .then(moduleRef => {
//     if (AppInjector.getInjector() === undefined) {
//       AppInjector.setInjector(moduleRef.injector);
//     }
//   })
//   .catch(err => console.error(err));

import { enableProdMode, Provider } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { ENVIRONMENT, EnvironmentService, environmentFactory } from '@app/core/services/environment.service';
import { AppModule } from './app/app.module';

environmentFactory()
    .then((config) => {
        if (config.production) {
            enableProdMode();
        }
        EnvironmentService.setEnvironment(config);
        const environmentProvider: Provider = {
            provide: ENVIRONMENT,
            useValue: config,
        };

        platformBrowserDynamic([environmentProvider])
            .bootstrapModule(AppModule)
            .catch((err) => console.error(err));
    })
    .catch((err) => console.error('Failed to load environment configuration:', err));