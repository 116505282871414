<form [formGroup]="profileFormGroup">
    <div class="row mb-3" *ngIf="!emailAsUsername">
        <div class="col">
            <label class="h6" for="username" editable translate>username</label>
            <input class="form-control" aria-describedby="usernameError" required id="username" type="text" formControlName="username" />
            <small [class]="userNameErrorVisibility()" role="alert" id="usernameError" editable translate>usernameIsRequired</small>
            <small [class]="userNameNotValidErrorVisibility()" role="alert" id="usernameError" editable translate
                >usernameIsNotUniqued</small
            >
        </div>
    </div>

    <div class="row mb-3">
        <div class="col">
            <label class="h6" for="firstname" id="labelFirstname" editable translate>firstname</label>
            <input
                [class]="firstNameInvalid() ? 'form-control invalid-form' : 'form-control'"
                type="text"
                aria-describedby="firstNameIsRequired"
                id="firstname"
                formControlName="firstName"
                autocomplete="given-name"
            />

            <small
                *ngIf="firstNameInvalid()"
                role="alert"
                [class]="
                    firstNameInvalid()
                        ? 'd-inline-block text-danger show-on-content-edit-visible'
                        : 'd-none text-danger show-on-content-edit-visible'
                "
                id="firstNameIsRequired"
                editable
                translate
                >firstNameIsRequired</small
            >
            <small
                *ngIf="firstNameInvalidPattern()"
                [class]="
                    firstNameInvalidPattern()
                        ? 'd-inline-block text-danger show-on-content-edit-visible'
                        : 'd-none text-danger show-on-content-edit-visible'
                "
                role="alert"
                id="firstNameIsRequired"
                editable
                translate
                >firstNameInvalid
            </small>
        </div>
    </div>

    <div class="row mb-3">
        <div class="col">
            <label class="h6" for="lastname" id="labelLastname" editable translate>lastname</label>
            <input
                [class]="lastNameInvalid() ? 'form-control invalid-form' : 'form-control'"
                type="text"
                id="lastname"
                aria-describedby="lastNameIsRequired"
                formControlName="lastName"
                autocomplete="family-name"
            />
            <small
                *ngIf="lastNameInvalid()"
                role="alert"
                [class]="
                    lastNameInvalid()
                        ? 'd-inline-block text-danger show-on-content-edit-visible'
                        : 'd-none text-danger show-on-content-edit-visible'
                "
                id="lastNameIsRequired"
                editable
                translate
                >lastNameIsRequired</small
            >
            <small
                *ngIf="lastNameInvalidPattern()"
                [class]="
                    lastNameInvalidPattern()
                        ? 'd-inline-block text-danger show-on-content-edit-visible'
                        : 'd-none text-danger show-on-content-edit-visible'
                "
                role="alert"
                id="lastNameIsRequired"
                editable
                translate
                >lastNameInvalid
            </small>
        </div>
    </div>

    <div class="row mb-3">
        <div class="col">
            <label class="h6" for="emailAddress" id="labelEmailAddress" editable translate>emailAddress</label>
            <input
                [class]="emailAddressInvalid() ? 'form-control invalid-form' : 'form-control'"
                type="text"
                id="emailAddress"
                aria-describedby="invalidEmail"
                formControlName="email"
                autocomplete="email"
            />
            <small
                [class]="
                    emailAddressInvalid()
                        ? 'd-inline-block text-danger show-on-content-edit-visible'
                        : 'd-none text-danger show-on-content-edit-visible'
                "
                role="alert"
                id="invalidEmail"
                editable
                translate
                >invalidEmail</small
            >
        </div>
    </div>

    <div class="row mb-3">
        <div class="col">
            <label class="h6" for="confirmEmailAddress" id="labelConfirmEmailAddress" editable translate>confirmEmailAddress</label>
            <input
                [class]="confirmEmailAddressInvalid() ? 'form-control invalid-form' : 'form-control'"
                type="text"
                id="confirmEmailAddress"
                aria-describedby="invalidConfirmEmail"
                formControlName="confirmEmail"
                autocomplete="off"
            />
            <small
                [class]="
                    confirmEmailAddressInvalid()
                        ? 'd-inline-block text-danger show-on-content-edit-visible'
                        : 'd-none text-danger show-on-content-edit-visible'
                "
                role="alert"
                id="invalidConfirmEmail"
                editable
                translate
                >invalidConfirmEmail</small
            >
        </div>
    </div>

    <div class="row mb-3">
        <div class="col">
            <label class="h6" for="oPassword" id="labelNewPassword" editable translate [hidden]="!currentPageManageProfile">newPassword</label>
            <label class="h6" for="oPassword" id="labelPassword" editable translate [hidden]="currentPageManageProfile">password</label>
            <div class="input-group">
                <input
                    [type]="this.unmaskedPassword ? 'text' : 'password'"
                    id="oPassword"
                    [ngClass]="[passwordInvalid() ? 'form-control invalid-form' : 'form-control']"
                    aria-describedby="invalidPassword"
                    formControlName="password"
                    maxlength="20"
                    autocomplete="new-password"
                />
                <div
                    class="input-group-append"
                    *ngIf="profileFormGroup.value.password != null && profileFormGroup.value.password.length > 0"
                >
                    <div class="input-group-text appendItemBackgroundColor">
                        <i
                            [ngClass]="['fa', unmaskedPassword ? 'fa-eye-slash' : 'fa-eye']"
                            (click)="this.unmaskedPassword = !this.unmaskedPassword"
                        ></i>
                    </div>
                </div>
            </div>
            <small [ngClass]="[!passwordInvalid() ? 'd-inline-block' : 'd-none']" translate>passwordTooltip</small>
            <small
                [ngClass]="[passwordInvalid() ? 'd-inline-block' : 'd-none', 'text-danger', 'show-on-content-edit-visible']"
                id="invalidPassword"
                role="alert"
                editable
                translate
                >invalidPassword</small
            >
        </div>
    </div>

    <div class="row mb-3">
        <div class="col">
            <label class="h6" for="confirmPassword" id="labelConfirmPassword" editable translate>confirmPassword</label>
            <input
                [class]="confirmPasswordInvalid() ? 'form-control invalid-form' : 'form-control'"
                [type]="this.unmaskedPassword ? 'text' : 'password'"
                formControlName="confirmPassword"
                id="confirmPassword"
                aria-describedby="invalidConfirmPassword"
                autocomplete="off"
            />
            <small
                [class]="
                    confirmPasswordInvalid()
                        ? 'd-inline-block text-danger show-on-content-edit-visible'
                        : 'd-none text-danger show-on-content-edit-visible'
                "
                id="invalidConfirmPassword"
                editable
                role="alert"
                translate
                >invalidConfirmPassword</small
            >
        </div>
    </div>
    <div class="row">
        <div class="col">
            <label class="h6" for="language" id="labelLanguage" editable translate>language</label>
            <select
                class="custom-select"
                id="language"
                (change)="changeLanguage()"
                aria-describedby="languageInstructions"
                formControlName="language"
            >
                <option [value]="null" disabled translate>selectLanguage</option>
                <option *ngFor="let language of this.languages" [value]="language">
                    {{ '^country-language.' + language | translate }}
                </option>
            </select>
            <!-- <div ngbDropdown>
                <button class="btn btn-outline-primary" id="languageDropdown" ngbDropdownToggle translate>language</button>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                  <button ngbDropdownItem *ngFor="let culture of this.cultures" >{{culture.name}}</button>
                </div>
              </div> -->
            <small id="languageInstructions" translate>languageInstructions</small>
        </div>
    </div>
</form>
