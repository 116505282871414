import { NgModule } from '@angular/core';
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from './../environments/environment';
import { LoggerModule, NGXLoggerMonitor, NGXLogInterface, NGXLogger, NgxLoggerLevel } from 'ngx-logger';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ContentEditorResolver } from './shared/services/content-editor.resolver';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { HttpRequestResponseInterceptorService } from './core/services/http-request-response-interceptor.service';
import { ExternalLinkInterceptorService } from '@app/shared/services/external-link-interceptor.service';
import { WINDOW_PROVIDERS } from '@app/core/services/window.service';
import { BaseComponent } from './base/base.component';
import { BasePageComponent } from './base/base-page.component';
import { NgbModule, NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from './shared/shared.module';
import { AppConfigModule } from './appConfig.module';
import { JitCompilerModule } from '@codehint-ng/html-compiler';
import { RouterModule } from '@angular/router';

export function HttpLoaderFactory(http: HttpClient): TranslateLoader {
    return new TranslateHttpLoader(http);
}

// Using a custom 'loggerMonitor' so we can log the level 'name' in the db rather than a number.
export enum CustomNgxLoggerLevelSysLog {
    EMERG = 0,
    ALERT = 1,
    CRIT = 2,
    ERROR = 3,
    WARNING = 4,
    NOTICE = 5,
    INFO = 6,
    DEBUG = 7,
}
export declare class CustomNGXLogInterface extends NGXLogInterface {
    sysLogLevel: CustomNgxLoggerLevelSysLog;
}

export class CustomLoggerMonitor implements NGXLoggerMonitor {
    onLog(log: CustomNGXLogInterface): void {
        log.sysLogLevel = this.mapLevels(log.level);
    }
    mapLevels(level: NgxLoggerLevel): CustomNgxLoggerLevelSysLog {
        switch (level) {
            case NgxLoggerLevel.TRACE.valueOf():
            case NgxLoggerLevel.DEBUG.valueOf():
                return CustomNgxLoggerLevelSysLog.DEBUG.valueOf();
            case NgxLoggerLevel.INFO.valueOf():
            case NgxLoggerLevel.LOG.valueOf():
                return CustomNgxLoggerLevelSysLog.INFO.valueOf();
            case NgxLoggerLevel.WARN.valueOf():
                return CustomNgxLoggerLevelSysLog.WARNING.valueOf();
            case NgxLoggerLevel.WARN.valueOf():
                return CustomNgxLoggerLevelSysLog.WARNING.valueOf();
            case NgxLoggerLevel.ERROR.valueOf():
                return CustomNgxLoggerLevelSysLog.ERROR.valueOf();
            case NgxLoggerLevel.FATAL.valueOf():
                // going from ERROR skipping two and straight to full blown emergency!
                return CustomNgxLoggerLevelSysLog.EMERG.valueOf();
            default:
                break;
        }
    }
}

@NgModule({
    declarations: [
        AppComponent, //
        BaseComponent,
        BasePageComponent,
    ],
    imports: [
        NgbModule,
        BrowserAnimationsModule,
        NgbCollapseModule,
        AppConfigModule,
        CoreModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        JitCompilerModule,
        SharedModule,
        LoggerModule.forRoot(environment.logConfig()),
        RouterModule.forRoot([], { anchorScrolling: 'enabled', relativeLinkResolution: 'legacy', enableTracing: false }),
    ],
    providers: [
        // { provide: ErrorHandler, useClass: GlobalErrorHandler },
        { provide: HTTP_INTERCEPTORS, useClass: HttpRequestResponseInterceptorService, multi: true },
        ExternalLinkInterceptorService,
        WINDOW_PROVIDERS,
        ContentEditorResolver,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor(private logger: NGXLogger, private externalLinkInterceptor: ExternalLinkInterceptorService) {
        this.logger.registerMonitor(new CustomLoggerMonitor());
        this.externalLinkInterceptor.hookLinkClicks();
    }
}
