// import { ValueProvider, InjectionToken } from '@angular/core';
// import { environment } from '@env/environment';

// export const ENVIRONMENT = new InjectionToken('EnvironmentToken');

// export const EnvironmentProvider: ValueProvider = {
//   provide: ENVIRONMENT,
//   useValue: environment
// };

import { InjectionToken, Injectable } from '@angular/core';

export const ENVIRONMENT = new InjectionToken('EnvironmentToken');

@Injectable()
export class EnvironmentService {
    private static environment: any;

    constructor() {}

    static setEnvironment(config: any): void {
        EnvironmentService.environment = config;
    }

    static getEnvironment(): any {
        return this.environment;
    }
}

export function environmentFactory(): Promise<any> {
    return fetch('/assets/runtime/config.json', {
        headers: {
            'Accept': 'application/json',
        },
    })
        .then((response) => response.json())
        .catch((error) => {
            console.error('Failed to fetch environment configuration:', error);
            return {};
        });
}
