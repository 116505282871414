import { Component, ElementRef, Inject, Input, OnInit, ViewChild, Output, EventEmitter, OnDestroy } from '@angular/core';
import { ReactiveFormService } from '@app/shared/services/reactive-form.service';
import { FormGroup, FormBuilder, FormControlName, FormControl, Validators, ValidatorFn } from '@angular/forms';
import { UserProfile } from '@app/services/user/models/user-profile-model';
import { MyAlertsService } from '@app/services/user/my-alerts.service';
import { BHNModalService } from '@app/shared/services/bhn-modal.service';
import { CardDetailsService } from '@app/services/card/card-details.service';
import { CustomTooltipConfigs } from '@app/shared/models/custom-tooltip-configs.model';

enum PageStatus {
  Default,
  Loading,
  Processing,
  SuccessSavingEmail,
  SuccessSavingPhone,
  SuccessRemovingPhone,
  SuccessSavingLanguage,
  SuccessSavingAlerts,
  ValidationFailed,
  ErrorSavingAlerts,
  ErrorSavingContactInfo,
  UnsavedChanges,
  RMSError,
}

enum State {
  Default,
  Processing,
  PageExpired,
  Success,
  Error,
}

export interface userProfileObj {
  editEmail : boolean,
  editPhone : boolean,
  emailStatus : PageStatus,
  phoneStatus : PageStatus,
  userEmail : string,
  userPhone : string
}
@Component({
  selector: 'app-alert-contact-info',
  templateUrl: './alert-contact-info.component.html',
  styleUrls: ['./alert-contact-info.component.scss']
})
export class AlertContactInfoComponent implements OnInit, OnDestroy {
  @ViewChild('emailInput', { static: false }) emailInput: ElementRef;
  @ViewChild('phoneInput', { static: false }) phoneInput: ElementRef;
  @Output() userProfileFlag = new EventEmitter<{user: UserProfile, name:string, val:string}>();
  @Output() userProfile = new EventEmitter<UserProfile>();
  personalInformationFormGroup: FormGroup;
  user: UserProfile; 
  PageStatus = PageStatus;
  status: PageStatus;
  userProfileObj : userProfileObj = {
    editEmail : false,
    editPhone : false,
    emailStatus : PageStatus.Default,
    phoneStatus : PageStatus.Default,
    userEmail : '',
    userPhone : ''
}

  public tooltipConfigs: CustomTooltipConfigs;
    public toggleRegCardIcon = false;

  constructor(
    private formBuilder: FormBuilder,
    private alertsService: MyAlertsService,
    private bhnModalService: BHNModalService,
    public cardDetailsService: CardDetailsService,
  ) { }

  ngOnInit(): void {
    this.userDetails();
  }

  async userDetails() {
    this.user = await this.alertsService.getCurrentUserProfile();
    this.userProfile.emit(this.user);
    this.personalInformationFormGroup = this.formBuilder.group({
        email: [
            null,
            {
                updateOn: 'blur',
                validators: [Validators.required, Validators.email, Validators.pattern(new RegExp('\\S+@\\S+(?=\\.).*'))],
            },
        ],
        phoneNumber: [
            null,
            {
                updateOn: 'blur',
                validators: [Validators.minLength(10), Validators.maxLength(15), Validators.pattern(/^(|[\d-+()\ ]{10,15})$/)],
            },
        ],
      });
  
      this.personalInformationFormGroup.get('email').valueChanges.subscribe(() => {
          const control = this.personalInformationFormGroup.get('email');
          if (control.value !== control.value.trim()) {
              control.setValue(control.value.trim());
          }
      });

    this.personalInformationFormGroup.controls.email.setValue(this.user.email);
    this.personalInformationFormGroup.controls.phoneNumber.setValue(this.user.phone);
    this.userProfileObj.userEmail = this.user.email;
    this.userProfileObj.userPhone = this.user.phone;
  }


  toggleEditEmail(): void {
    this.userProfileObj.emailStatus = PageStatus.Default;
    this.userProfileObj.phoneStatus = PageStatus.Default;
    this.userProfileObj.editEmail = !this.userProfileObj.editEmail;
    this.personalInformationFormGroup.get('email').setValue(this.userProfileObj.userEmail);
    setTimeout(() => {
        this.emailInput?.nativeElement.focus();
    });
  }

  toggleEditPhone(): void {
      this.userProfileObj.phoneStatus = PageStatus.Default;
      this.userProfileObj.emailStatus = PageStatus.Default;
      this.userProfileObj.editPhone = !this.userProfileObj.editPhone;
      this.personalInformationFormGroup.get('phoneNumber').setValue(this.userProfileObj.userPhone || '');
      setTimeout(() => {
          this.phoneInput?.nativeElement.focus();
      });
  }

  phoneNumberInvalid() {
      return (
      !this.personalInformationFormGroup.controls.phoneNumber.valid &&
      !this.personalInformationFormGroup.controls.phoneNumber.untouched
      );
  }

  emailAddressInvalid() {
      return !this.personalInformationFormGroup.controls.email.valid && !this.personalInformationFormGroup.controls.email.untouched;
  }

  async saveEmail() {
    try {
        if (this.personalInformationFormGroup.get('email').valid) {
            const result = await this.bhnModalService.showAlert(
                'setup-alerts-view.emailUpdateWarningMessage',
                '', //
                ['setup-alerts-view.continueButton', 'setup-alerts-view.cancelButton'],
            );
            this.userProfileObj.emailStatus = PageStatus.Processing;

            if (result === 'setup-alerts-view.continueButton') {
                this.user.email = this.personalInformationFormGroup.get('email').value;
                this.user.username = this.personalInformationFormGroup.get('email').value;
                await this.alertsService.saveCurrentUserProfile(this.user);

                this.userProfileObj.editEmail = false;
                this.userProfileFlag.emit({user: this.user, name:'email', val:this.personalInformationFormGroup.get('email').value});
                this.userProfileObj.emailStatus = PageStatus.SuccessSavingEmail;
                this.userProfileObj.userEmail = this.user.email;
            } else {
                this.userProfileObj.emailStatus = PageStatus.Default;
            }
        }
    } catch (error) {
        this.userProfileObj.emailStatus = PageStatus.ErrorSavingContactInfo;
    }
  }

  async savePhone() {
    try {
        if (this.personalInformationFormGroup.get('phoneNumber').valid) {
            const phoneNumber = this.personalInformationFormGroup.get('phoneNumber').value;
            const phoneWarningMessage = phoneNumber ? 'setup-alerts-view.phoneUpdateWarningMessage' : 'setup-alerts-view.phoneRemovalWarningMessage';

            const result = await this.bhnModalService.showAlert([phoneWarningMessage, { phone: phoneNumber }], '', [
                'setup-alerts-view.continueButton',
                'setup-alerts-view.cancelButton',
            ]);
            this.userProfileObj.phoneStatus =  PageStatus.Processing;

            if (result === 'setup-alerts-view.continueButton') {
                this.user.phone = phoneNumber;
                await this.alertsService.saveCurrentUserProfile(this.user);
                this.userProfileObj.editPhone = false;
                this.userProfileFlag.emit({user: this.user, name:'phone', val:this.personalInformationFormGroup.get('phoneNumber').value});
                this.userProfileObj.phoneStatus =  phoneNumber ? PageStatus.SuccessSavingPhone : PageStatus.SuccessRemovingPhone;
                this.userProfileObj.userPhone = this.user.phone;
            } else {
                this.userProfileObj.phoneStatus =  PageStatus.Default;
            }
        }
    } catch (error) {
        this.userProfileObj.phoneStatus = PageStatus.ErrorSavingContactInfo;
    }
  }
  public onToggleRegCardHelp(_value: boolean) {
    this.toggleRegCardIcon = !_value;
    this.tooltipConfigs = {
        toggleToolTip: this.toggleRegCardIcon,
        toolText: '^card-details.myAlertEmailTooltip',
        top: '120px',
        left: '160px',
    };
}

  ngOnDestroy(): void {
    this.cardDetailsService.fromCardDetails = false;
  }
}
