<div class="modal-body">
    <button
                type="button"
                class="close"
                data-dismiss="modal"
                [attr.aria-label]="'^modal.general.close' | translate"
                (click)="dismissModal()"
                tabindex="0"
            >
                <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                    <g
                        stroke="#333"
                        stroke-width="2"
                        fill="none"
                        fill-rule="evenodd"
                        opacity=".88"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    >
                        <path d="m3.868 3.87 16.264 16.262M20.132 3.87 3.868 20.13" />
                    </g>
                </svg>
            </button>
    <h1 class="header" translate>^fluid-address-verification.header</h1>
    <form [formGroup]="form">
        <div class="addresses" ngbRadioGroup name="radioBasic">
            <div class="address-block mr-2">
                <label>
                    <div class="select-label">
                        <div>
                            <div [class]="radioSelect('useEnteredAddress')"></div>
                        </div>
                        <div class="address-labels">
                            <input type="radio" (click)="addressSelect('useEnteredAddress')" value="1" formControlName="selectedAddress" />
                            <span class="address-title" translate>^fluid-address-verification.enteredAddress</span>
                            <div class="address-line">{{ enteredAddress.addressLine1 }}</div>
                            <div class="address-line">{{ enteredAddress.addressLine2 }}</div>
                            <div class="address-line">
                                {{ enteredAddress.city }} {{ enteredAddress.region }} {{ enteredAddress.postalCode }}
                            </div>
                            <div class="address-line">{{ enteredAddress.country.name }}</div>
                        </div>
                    </div>
                </label>
            </div>
            <div class="address-block">
                <label>
                    <div class="select-label">
                        <div>
                            <div [class]="radioSelect('useValidatedAddress')"></div>
                        </div>
                        <div class="address-labels">
                            <input
                                type="radio"
                                (click)="addressSelect('useValidatedAddress')"
                                value="2"
                                formControlName="selectedAddress"
                            /><span class="address-title" translate>^fluid-address-verification.validatedAddress </span>
                            <div class="address-line">{{ validatedAddress.addressLine1 }}</div>
                            <div class="address-line">{{ validatedAddress.addressLine2 }}</div>
                            <div class="address-line">
                                {{ validatedAddress.city }} {{ validatedAddress.region }} {{ validatedAddress.postalCode }}
                            </div>
                            <div class="address-line">{{ validatedAddress.country.name }}</div>
                        </div>
                    </div></label
                >
            </div>
        </div>
        <div *ngIf="addressNotSelectedFlag" class="text-danger alert" translate>
            ^fluid-address-verification.addressNotSelected
        </div>
        <div class="modal-button-container">
            <button class="btn btn-primary modal-button" (click)="useAddress()" translate>
                ^fluid-address-verification.useThisAddress
            </button>
        </div>
    </form>
</div>
