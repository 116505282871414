/**
 * @author:Prashant Mishra
 * @description:This Component is created to implement the Skip content feature in header.
 */

import { Component, Input, OnInit } from '@angular/core';
import { SetFocusService } from '@app/shared/services/set-focus.service';

@Component({
    selector: 'app-skip-to-content',
    templateUrl: './skip-to-content.component.html',
    styleUrls: ['./skip-to-content.component.scss'],
})
export class SkipToContentComponent implements OnInit {
    @Input() idRef: string;
    @Input() idName: string;
    @Input() label: string;
    hrefPage: string;
    constructor(private setFocus: SetFocusService) {}

    ngOnInit(): void {
        this.hrefPage = `#${this.idRef}`;
    }

    anchorClickHandler(): void {
        this.setFocus.setFocusTo(this.idRef);
    }
}
