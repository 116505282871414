import { Injectable } from '@angular/core';
import * as CommonConstants from './../constants/common.constants';

@Injectable({
    providedIn: 'root',
})
export class CookieConsentService {
    public cookieConsentUrl: string;

    constructor() {}

    addCookieConsentScript(): void {
        const url: string = this.cookieConsentUrl ? this.cookieConsentUrl : CommonConstants.DEFAULT_COOKIE_CONSENT_URL;
        document.getElementById('consent_blackbar').innerHTML = '';
        Array.from(document.getElementsByTagName('script')).forEach((scriptTag) => {
            if (scriptTag.src.match(/trustarc/)) {
                scriptTag.remove();
            }
        });
        const scriptTag = document.createElement('script');
        scriptTag.async = true;
        scriptTag.crossOrigin = 'anonymous';
        scriptTag.src = url;
        document.head.appendChild(scriptTag);
    }
}
