import { Component, OnInit } from '@angular/core';
import { ContentEditorService } from '@app/shared/services/content-editor.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-add-to-wallet-modal',
    templateUrl: './add-to-wallet-modal.component.html',
    styleUrls: ['./add-to-wallet-modal.component.scss'],
})
export class AddToWalletModalComponent implements OnInit {
    public heading: string;
    public message: string;
    constructor(public activeModal: NgbActiveModal, private contentEditorService: ContentEditorService) {
        this.contentEditorService.resourceList.forEach((content) => {
            if (content.name === 'addToWalletHeading') {
                this.heading = content.value;
            }
            if (content.name === 'addToWalletMessage') {
                this.message = content.value;
            }
        });
    }

    ngOnInit(): void {}
}
