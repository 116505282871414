import { Component, Input, OnInit } from '@angular/core';
import { NotificationBannerModel } from '@app/shared/models/notification-banner.model';

@Component({
    selector: 'app-notification-banner',
    templateUrl: './notification-banner.component.html',
    styleUrls: ['./notification-banner.component.scss'],
})
export class NotificationBannerComponent implements OnInit {
    @Input() public notificationBanners: NotificationBannerModel[];
    cookieConsentHeight: any;
    constructor() {}

    ngOnInit(): void {}

    dismissBanner(item: any) {
        const index: number = this.notificationBanners.indexOf(item);
        if (index !== -1) {
            this.notificationBanners.splice(index, 1);
        }
    }

    public isCookieAccepted() {
        this.cookieConsentHeight = document.getElementById('consent_blackbar').clientHeight;

        const cookieKey = localStorage.getItem('truste.eu.cookie.notice_preferences');
        return cookieKey ? true : false;
    }
}
