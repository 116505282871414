import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CredentialsService } from '@app/core/authentication/credentials.service';
import { ENVIRONMENT } from '@app/core/services/environment.service';
import { NGXLogger } from 'ngx-logger';
import { firstValueFrom } from 'rxjs';
import { BaseResponse } from '../models/base-response-model';
import { InitiateIdvResponse } from '../models/initiateIdv-response-model';
import { KycIntendedAccount } from '../models/kyc-intended-account.model';
import { KycJob } from '../models/kyc-job.model';
import { KycOccupationCategory } from '../models/kyc-occupation.model';
import { SaveAdditionalInfoRequest } from '../models/save-additional-info-request.model';
import { UpdateUserKycRequest } from '../models/update-userKyc-request.model';

@Injectable({
    providedIn: 'root',
})
export class KycHttpService {
    constructor(
        private http: HttpClient,
        private logger: NGXLogger,
        @Inject(ENVIRONMENT) private environment,
        private credentials: CredentialsService,
    ) {}

    async getOccupationCategories(): Promise<KycOccupationCategory> {
        const apiRequest$ = this.http.get<BaseResponse & KycOccupationCategory>(
            this.environment.nodeBaseURL + 'user/getOccupationCategories',
        );
        const response = await firstValueFrom(apiRequest$);

        if (!response.success) {
            this.logger.error('Server error getting category list', response.errors);
            throw Error('error-getting-category-list');
        }

        return response;
    }

    async getIntendedUseOfAccountList(): Promise<KycIntendedAccount> {
        const apiRequest$ = this.http.get<BaseResponse & KycIntendedAccount>(
            this.environment.nodeBaseURL + 'user/getIntendedUseOfAccountList',
        );
        const response = await firstValueFrom(apiRequest$);

        if (!response.success) {
            this.logger.error('Server error getting intented account list', response.errors);
            throw Error('error-getting-inted-account-list');
        }

        return response;
    }

    async getJobsByOccupationCategoryId(jobId: number): Promise<KycJob> {
        const apiRequest$ = this.http.get<BaseResponse & KycJob>(
            this.environment.nodeBaseURL + 'user/getJobsByOccupationCategoryId?occupationCategoryId=' + jobId,
        );
        const response = await firstValueFrom(apiRequest$);

        if (!response.success) {
            this.logger.error('Server error getting job list', response.errors);
            throw Error('error-getting-job-list');
        }

        return response;
    }

    async initiateIdv(proxyCardNumber: string): Promise<InitiateIdvResponse> {
        const apiRequest$ = this.http.post<BaseResponse & InitiateIdvResponse>(this.environment.nodeBaseURL + 'user/initiateIdv', {
            userId: this.credentials.isAuthenticated() ? this.credentials.current.userUuid : null,
            proxyCardNumber,
        });
        const response = await firstValueFrom(apiRequest$);

        if (!response.success) {
            this.logger.error('Server error getting initiate idv', response.errors);
            throw Error('error-getting-initiate-idv');
        }

        return response;
    }
    async saveAdditionalInformation(request: SaveAdditionalInfoRequest): Promise<any> {
        const apiRequest$ = this.http.post<BaseResponse>(this.environment.nodeBaseURL + 'user/saveAdditionalInformation', request);
        const response = await firstValueFrom(apiRequest$);

        if (!response.success) {
            this.logger.error('Server error getting save-additional-info', response.errors);
            throw Error('error-getting-save-additional-info');
        }

        return response;
    }
    async updateUserKycInfo(request: UpdateUserKycRequest): Promise<any> {
        const apiRequest$ = this.http.post<BaseResponse>(this.environment.nodeBaseURL + 'user/updateUserKycInfo', request);
        const response = await firstValueFrom(apiRequest$);

        if (!response.success) {
            this.logger.error('Server error getting updateUserKycInfo', response.errors);
            throw Error('error-getting-updateUserKycInfo');
        }
        return response;
    }
}
