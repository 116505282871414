import { BaseComponent } from '@app/base/base.component';
import { Component, OnInit, OnDestroy, Inject, forwardRef } from '@angular/core';
import {
    FormGroup,
    FormBuilder,
    Validators,
    NG_VALUE_ACCESSOR,
    NG_VALIDATORS,
    ControlValueAccessor,
    AbstractControl,
    ValidationErrors,
} from '@angular/forms';

import { ENVIRONMENT } from '@app/core/services/environment.service';
import { KycProcessFlowService } from '@app/shared/services/kyc-process-flow.service';
import { InputSanitizerService } from '@app/core/services/input-sanitizer.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => LoginComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => LoginComponent),
            multi: true,
        },
    ],
})
export class LoginComponent extends BaseComponent implements OnInit, OnDestroy, ControlValueAccessor {
    version: string = this.environment.version;
    error: string | undefined;
    loginForm!: FormGroup;
    isLoading = false;

    constructor(
        private formBuilder: FormBuilder,
        @Inject(ENVIRONMENT) private environment: any,
        private kycProcessFlowService: KycProcessFlowService,
        private inputSanitizerService: InputSanitizerService,
    ) {
        super();
        this.createForm();
    }

    ngOnInit() {
        this.kycProcessFlowService.allowRedirectionforForgetPassword = false;
        this.createForm();
    }

    ngOnDestroy() {}

    writeValue(val: any): void {
        if (val) {
            this.loginForm.setValue(val, { emitEvent: false });
        }
    }

    registerOnChange(fn: any): void {
        this.loginForm.valueChanges.subscribe(fn);
    }

    registerOnTouched(fn: any): void {}

    validate(control: AbstractControl): ValidationErrors | null {
        if (control.touched) {
            this.markControlsAsTouched();
        }
        return this.loginForm.valid ? null : { invalidForm: { valid: false, message: 'invalid' } };
    }

    usernameIsValid(): boolean {
        return this.loginForm.controls.username.valid || this.loginForm.controls.username.untouched;
    }

    passwordIsValid(): boolean {
        return this.loginForm.controls.password.valid || this.loginForm.controls.password.untouched;
    }

    private markControlsAsTouched() {
        for (const controlName in this.loginForm.controls) {
            if (this.loginForm.controls[controlName] !== undefined) {
                this.loginForm.controls[controlName].markAsTouched();
            }
        }
    }

    private createForm() {
        this.loginForm = this.formBuilder.group({
            username: ['', { updateOn: 'blur', validators: [Validators.required, this.inputSanitizerService.sanitizeInput()] }],
            password: ['', [Validators.required]],
            remember: true,
        });
        this.loginForm.get('username').valueChanges.subscribe(() => {
            const control = this.loginForm.get('username');
            if (control.value !== control.value.trim()) {
                control.setValue(control.value.trim());
            }
        });
    }

    allowRedirection() {
        this.kycProcessFlowService.allowRedirectionforForgetPassword = true;
    }
}
