import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NGXLogger } from 'ngx-logger';

import { CardLoginRequest } from '../models/card-login-request-model';
import { ENVIRONMENT } from '@app/core/services/environment.service';
import { UserLoginRequest } from '../models/user-login-request-model';
import { BaseResponse } from '@app/shared/models/base-response-model';
import { CardBundle } from '@app/services/card/models/card-bundle';
import { ForgotPasswordRequest } from '@app/services/user/models/forgot-password-request.model';
import { CardRetrievalDataRequest } from '../models/card-retrieval-data-request.model';
import { CardRetrievalData } from '../models/card-retrieval-data.model';
import { SendCardRetrievalCodeRequest } from '../models/send-card-retrieval-code-request.model';
import { VerifyCardRetrievalCodeRequest } from '../models/verify-card-retrieval-code-request.model';
import { LegacyLoginBundle } from '@app/features/original/user/profile-migration/models/legacy-profile.model';
import { firstValueFrom } from 'rxjs';
import { ProxyLoginRequest } from '../models/proxy-login-request.model';
import { CreateUserRequest } from '@app/services/user/models/create-profile-request-model';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationHttpService {
    constructor(private http: HttpClient, private logger: NGXLogger, @Inject(ENVIRONMENT) private environment) {}

    async loginWithCard(request: CardLoginRequest): Promise<CardBundle> {
        const apiRequest$ = this.http.post<BaseResponse & CardBundle>(
            this.environment.nodeBaseURL + 'login/card',
            {
                cardNumber: request.cardNumber,
                expirationMonth: request.expirationMonth,
                expirationYear: request.expirationYear,
                securityCode: request.securityCode,
                acceptedTermsAndConditions: request.acceptedTermsAndConditions,
            },
            {
                headers: {
                    't-session-id': request.rmsSessionId ? request.rmsSessionId : '',
                },
            },
        );
        const response = await firstValueFrom(apiRequest$);
        if (!response.success) {
            if (response.errors && response.errors.includes('invalid-card-information')) {
                throw Error('invalid-card-information');
            }
            if (response.errors && response.errors.includes('invalid-virtual-card-information')) {
                throw Error('invalid-virtual-card-information');
            }
            if (response.errors && response.errors.includes('card-already-attached-to-a-profile')) {
                throw Error('card-already-attached-to-a-profile');
            }
            if (response.errors && response.errors.includes('blocked-product-code')) {
                throw Error('blocked-product-code');
            }
            if (response.errors && response.errors.includes('error-processing-card')) {
                throw Error('error-processing-card');
            }
            if (response.errors && response.errors.includes('processing-error')) {
                throw Error('processing-error');
            }
            if (response.errors && response.errors.includes('incorrect-card-information')) {
                throw Error(response.errors[0]);
            }
            if (response.errors && response.errors.includes('geo-check-failed')) {
                throw Error(response.errors[0]);
            }
            this.logger.error('Error logging in with card.', response.errors);
            throw Error('error-logging-in');
        }
        return response;
    }

    async loginWithUser(request: UserLoginRequest): Promise<CardBundle | LegacyLoginBundle> {
        const apiRequest$ = this.http.post<BaseResponse & CardBundle & LegacyLoginBundle>(
            this.environment.nodeBaseURL + 'login/user',
            {
                username: request.username,
                password: request.password,
                proxyCardNumber: request.proxyCardNumber,
            },
            {
                headers: {
                    't-session-id': request.rmsSessionId ? request.rmsSessionId : '',
                },
            },
        );
        const response = await firstValueFrom(apiRequest$);
        if (!response.success) {
            if (response.errors && response.errors.includes('profile-already-migrated')) {
                throw Error('profile-already-migrated');
            }
            if (response.errors && response.errors.includes('user-login-failed')) {
                throw Error('user-login-failed');
            }
            if (response.errors && response.errors.includes('processing-error')) {
                throw Error('processing-error');
            }
            this.logger.error(`Error logging in with user. Error: ${response.errors}`);
            throw Error('error-logging-in');
        }
        return response;
    }

    async loginWithProxy(request: ProxyLoginRequest): Promise<CardBundle> {
        const apiRequest$ = this.http.post<BaseResponse & CardBundle>(
            this.environment.nodeBaseURL + 'login/proxy',
            {
                proxyCardNumber: request.proxyCodeNumber,
                userUuid: request.userUuid,
            },
            {
                headers: {
                    't-session-id': request.rmsSessionId ? request.rmsSessionId : '',
                },
            },
        );
        const response = await firstValueFrom(apiRequest$);
        if (!response.success) {
            if (response.errors && response.errors.includes('invalid-card-information')) {
                throw Error('invalid-card-information');
            }
            if (response.errors && response.errors.includes('card-already-attached-to-a-profile')) {
                throw Error('card-already-attached-to-a-profile');
            }
            if (response.errors && response.errors.includes('blocked-product-code')) {
                throw Error('blocked-product-code');
            }
            if (response.errors && response.errors.includes('error-processing-card')) {
                throw Error('error-processing-card');
            }
            if (response.errors && response.errors.includes('incorrect-card-information')) {
                throw Error(response.errors[0]);
            }
            if (response.errors && response.errors.includes('card expired')) {
                throw Error(response.errors[0]);
            }
            if (response.errors && response.errors.includes('geo-check-failed')) {
                throw Error(response.errors[0]);
            }
            this.logger.error('Error logging in with card.', response.errors);
            throw Error('error-logging-in');
        }
        return response;
    }

    async sendForgotPasswordEmail(request: ForgotPasswordRequest): Promise<boolean> {
        try {
            const apiRequest$ = this.http.post<BaseResponse>(
                this.environment.nodeBaseURL + 'login/sendForgotPasswordEmail',
                { email: request.email },
                {
                    headers: {
                        't-session-id': request.rmsSessionId ? request.rmsSessionId : '',
                    },
                },
            );
            const response = await firstValueFrom(apiRequest$);
            if (!response.success) {
                this.logger.error('Server error triggering password reset.', response.errors[0]);
                throw Error('error-triggering-password-reset');
            }
            return true;
        } catch (error) {
            this.logger.error('Server error triggering password reset.', error);
        }
    }

    async refresh(): Promise<void> {
        const apiRequest$ = this.http.post<BaseResponse>(this.environment.nodeBaseURL + 'login/refresh', {});
        const response = await firstValueFrom(apiRequest$);
        if (!response.success) {
            this.logger.error('Server error triggering refesh.', response.errors);
            throw Error('error-triggering-refresh');
        }
    }

    async getCardRetrievalData(request: CardRetrievalDataRequest): Promise<CardRetrievalData & CardBundle> {
        const apiRequest$ = this.http.post<BaseResponse & CardRetrievalData & CardBundle>(
            this.environment.nodeBaseURL + 'login/getCardRetrievalData',
            {
                retrievalCode: request.retrievalCode,
            },
            {
                headers: {
                    't-session-id': request.rmsSessionId ? request.rmsSessionId : '',
                },
            },
        );
        const response = await firstValueFrom(apiRequest$);
        if (!response.success) {
            if (response.errors.includes('card-attached-to-profile')) {
                throw Error('card-attached-to-profile');
            }
            if (response.errors.includes('processing-error')) {
                throw Error('processing-error');
            }
            if (response.errors.includes('card-not-found')) {
                throw Error('card-not-found');
            }
            if (response.errors.includes('card-expired')) {
                throw Error('card-expired');
            }
            if (response.errors.includes('card-expired')) {
                throw Error('card-expired');
            }
            if (response.errors.includes('geo-check-failed')) {
                throw Error('geo-check-failed');
            }
            this.logger.error('Server error getting card retrieval data', response.errors);
            throw Error('error-getting-card-retrieval-data');
        }
        return response;
    }

    async sendCardRetrievalCode(request: SendCardRetrievalCodeRequest): Promise<void> {
        const apiRequest$ = this.http.post<BaseResponse>(this.environment.nodeBaseURL + 'login/sendCardRetrievalCode', request);
        const response = await firstValueFrom(apiRequest$);
        if (!response.success) {
            this.logger.error('Server error sending card retrieval code', response.errors);
            throw Error('error-sending-card-retrieval-code');
        }
    }

    async verifyCardRetrievalCode(request: VerifyCardRetrievalCodeRequest, rmsId?: any): Promise<CardBundle> {
        const apiRequest$ = this.http.post<BaseResponse & CardBundle>(
            this.environment.nodeBaseURL + 'login/verifyCardRetrievalCode',
            request,
            {
                headers: {
                    't-session-id': rmsId ? rmsId : '',
                },
            },
        );
        const response = await firstValueFrom(apiRequest$);
        if (!response.success) {
            if (response.errors.includes('code-not-valid')) {
                throw Error('code-not-valid');
            }
            this.logger.error('Error validating code.', response.errors);
            throw Error('error-validating-code');
        }
        return response;
    }

    async verifyLoginVerificationCode(code: string, createUserRequest?: CreateUserRequest, rmsId?: any): Promise<CardBundle> {
        try {
            const apiRequest$ = this.http.post<BaseResponse & CardBundle>(
                this.environment.nodeBaseURL + 'login/verifyUserVerificationCode',
                {
                    code,
                    createUserRequest: createUserRequest,
                },
                {
                    headers: {
                        't-session-id': rmsId ? rmsId : '',
                    },
                },
            );
            const response = await firstValueFrom(apiRequest$);
            if (!response.success) {
                throw Error('code-not-valid');
            }
            return response;
        } catch {
            throw Error('code-not-valid');
        }
    }

    async resendLoginVerificationCode(RMS_ID: string): Promise<boolean> {
        const apiRequest$ = this.http.post<BaseResponse>(
            this.environment.nodeBaseURL + 'login/resendUserVerificationCode',
            {},
            {
                headers: {
                    't-session-id': RMS_ID ? RMS_ID : '',
                },
            },
        );
        const response = await firstValueFrom(apiRequest$);
        if (!response.success) {
            throw Error('processing-error');
        }
        return response && response.success;
    }

    async logout(): Promise<boolean> {
        const apiRequest$ = this.http.post<BaseResponse>(this.environment.nodeBaseURL + 'login/logout', {});
        const response = await firstValueFrom(apiRequest$);
        if (!response.success) {
            throw Error('processing-error');
        }
        return response && response.success;
    }
}
