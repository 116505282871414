import { Injectable } from '@angular/core';
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot, Router } from '@angular/router';
import { RegistrationService } from '@app/services/card/registration.service';
import { from, Observable } from 'rxjs';
import { eGiftRequestModel } from '../models/eGift-request.model';
import { eGiftResponseModel } from '../models/eGift-response.model';
import { CommonService } from './common.service';
import { RmsSessionService } from '@app/services/auth/rms-session.service';
import { FlowName, FlowType } from '../enum/rms.enum';

@Injectable({
    providedIn: 'root',
})
export class EgiftResolver implements Resolve<eGiftResponseModel> {
    public rmsSessionData: any;

    constructor(private registrationService: RegistrationService, private router: Router, private rmsSessionService: RmsSessionService) { }

    async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<eGiftResponseModel> {
        try {
            const flowType = {
                type: FlowType.OTHER,
                name: FlowName.EGIFT_TYPE
            }
            this.rmsSessionData = await this.rmsSessionService.getRmsData(flowType).catch((error) => error);
            if (route.queryParams['eid'] && route.queryParams['tid']) {
                const request: eGiftRequestModel = {
                    eGiftId: route.queryParams['eid'],
                    tenantId: route.queryParams['tid'],
                    rmsSessionId: this.rmsSessionData?.rmsId ? this.rmsSessionData.rmsId : null,
                };
                return await this.registrationService.getEGiftData(request);
            } else {
                return;
            }
        } catch (error) { }
    }
}
