import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { NGXLogger } from 'ngx-logger';
import { firstValueFrom, map, Observable, of, catchError } from 'rxjs';

import { BaseResponse } from '@app/shared/models/base-response-model';
import { ENVIRONMENT } from '@app/core/services/environment.service';
import { UnmaskedCardInformation } from '../get-unmasked-card-information-response-model';
import { CardBalanceModel } from '../models/card-balance-model';
import { CardBundle } from '../models/card-bundle';
import { CardBundleRequest } from '../models/card-bundle-request-model';
import { CardRemovalRequest } from '../models/card-removal-request-model';
import { ActivateCardRequest } from '../models/data-capture-request-model';
import { PlasticRequest } from '../models/plastic-request';
import { RedeemCodeRequest, ValidateCountryCodeModel } from '../models/redeem-code-request-model';
import { RegisterCardRequest } from '../models/register-card-request-model';
import { SetPinRequest } from '../models/set-pin-request-model';
import { ValidateVirtualCodeRequest } from '../models/validate-virtual-code-request-model';
import { ValidateVirtualCodeResponseModel } from '../models/validate-virtual-code-response-model';
import { ProductInformation } from '@app/services/card/models/product-information.model';
import { TransactionHistoryRequest } from '@app/services/card/models/transaction-history-request-model';
import { TransactionsForRange } from '@app/services/card/models/transaction.model';
import { eGiftRequestModel } from '@app/shared/models/eGift-request.model';
import { eGiftResponseModel } from '@app/shared/models/eGift-response.model';
import { GetCardRetrievalLinkRequestModel, VerifyCardRetrievalLinkCodeRequestModel } from '../models/get-rl-model';
import { FeeTranslationModel } from '../models/fee-translation.model';

export enum ErrorCodes {
    CARD_ALREADY_REGISTERED = 'card-already-registered',
    MULTIPLE_COUNTRY_RECORDS_FOUND = 'multiple-country-records-found',
    ALREADY_REDEEMED = 'already-redeemed',
    NON_REDEEMABLE = 'non-redeemable-status',
    EXPIRED = 'expired',
    URC_SERVICE_NOT_AVAILABLE = 'unable-to-register-card-error-errors-error-code-service-is-not-available-message-the-service-is-not-currently-available',
    URC_CARDNOTFOUND = 'unable-to-register-card-error-errors-error-code-card-not-found-message-card-not-found',
    URC_INVALID_ADDRESS_LINE1_EXCEEDED_MAXLENGTH = 'unable-to-register-card-error-errors-error-code-invalid-address-address-line-1-message-invalid-address-line-1-exceeded-max-length',
    URC_INVALID_EMAIL_FORMAT = 'unable-to-register-card-error-errors-error-code-invalid-emailaddress-message-invalid-email-format',
    URC_INVALID_ADDRESS_POSTAL_CODE_EXCEEDED_MAXLENGTH = 'unable-to-register-card-error-errors-error-code-invalid-address-postal-code-message-invalid-postal-code-exceeded-max-length',
    URC_KEYSTONE_ERROR_IN_HTTP_REQUEST = 'unable-to-register-card-error-error-in-http-call-to-keystone-error-in-http-request',
    REDEMPTION_IN_PROGRESS = 'redemption-in-progress',
    ADDRESSLINE1_MAY_NOT_BE_EMPTY = 'address-line-1-may-not-be-empty',
    REDEMPTION_FAILED = 'redemption-failed',
    PROCESSING_ERROR = 'processing-error',
    CITY_MAY_NOT_BE_EMPTY = 'city-may-not-be-empty',
    POSTAL_MAY_NOT_BE_EMPTY = 'postal-code-may-not-be-empty',
    FIRSTNAME_MAY_NOT_BE_EMPTY = 'first-name-may-not-be-empty',
    LASTNAME_MAY_NOT_BE_EMPTY = 'last-name-may-not-be-empty',
    COUNTRY_MUSTBE_A_VALID_ISO_THREE_DIGIT_CODE = 'country-must-be-a-valid-iso-three-digit-country-code',
    PHONE_NUMBER_FROM_PROHIBITED_COUNTRY = 'Phone number from prohibited country',
}

@Injectable({
    providedIn: 'root',
})
export class CardHttpService {
    constructor(private http: HttpClient, private logger: NGXLogger, @Inject(ENVIRONMENT) private environment) {}

    async activateCard(request: ActivateCardRequest): Promise<CardBundle> {
        const apiRequest$ = this.http.post<BaseResponse & CardBundle>(this.environment.nodeBaseURL + 'card/activate', request, {
            headers: {
                't-session-id': request.rmsSessionId ? request.rmsSessionId : '',
            },
        });
        const response = await firstValueFrom(apiRequest$);
        if (!response.success) {
            if (response.errors.includes('incorrect-activation-code')) {
                throw Error('incorrect-activation-code');
            } else if (response.errors.includes('proxy-blocked')) {
                throw Error('proxy-blocked');
            } else {
                this.logger.error('Error activating card.', response.errors);
                throw Error('error-activating-card');
            }
        }
        return response;
    }

    async getBalance(proxyCardNumber: string, rmsId?: any): Promise<CardBalanceModel> {
        const apiRequest$ = this.http.post<BaseResponse & { balance: CardBalanceModel }>(
            this.environment.nodeBaseURL + 'card/getBalance',
            {
                proxyCardNumber,
            },
            {
                headers: {
                    't-session-id': rmsId ? rmsId : '',
                },
            },
        );
        const response = await firstValueFrom(apiRequest$);
        if (!response.success) {
            this.logger.error('Error getting card balance.', response.errors);
            throw Error('error-getting-card-balance');
        }
        return response.balance;
    }

    async setPin(request: SetPinRequest, rmsId?: any): Promise<void> {
        const apiRequest$ = this.http.post<BaseResponse>(this.environment.nodeBaseURL + 'card/setPin', request, {
            headers: {
                't-session-id': rmsId ? rmsId : '',
            },
        });
        const response = await firstValueFrom(apiRequest$);
        if (!response.success) {
            throw Error('error-changing-pin');
        }
    }

    async registerCard(request: RegisterCardRequest, rmsId?: any): Promise<void> {
        const apiRequest$ = this.http.post<BaseResponse>(this.environment.nodeBaseURL + 'card/register', request, {
            headers: {
                't-session-id': rmsId ? rmsId : '',
            },
        });
        const response = await firstValueFrom(apiRequest$);
        if (!response.success) {
            if (response.errors.includes('geo-check-failed')) {
                throw Error('geo-check-failed');
            } else {
                this.logger.error('Error registering card.', response.errors);
                throw Error('error-registering-card');
            }
        }
    }

    async digitalRedeemCard(request: RegisterCardRequest, rmsId?: any): Promise<CardBundle> {
        const apiRequest$ = this.http.post<CardBundle>(this.environment.nodeBaseURL + 'card/digitalRedeem', request, {
            headers: {
                't-session-id': rmsId ? rmsId : '',
            },
        });
        const response = await firstValueFrom(apiRequest$);
        if (!response) {
            this.logger.error('Error registering card.', response);
            throw Error('error-registering-card');
        }
        return response;
    }

    async getFeeSummary(request: TransactionHistoryRequest): Promise<TransactionsForRange> {
        const apiRequest$ = this.http.post<BaseResponse & { transactionsForRange: TransactionsForRange }>(
            this.environment.nodeBaseURL + 'card/getTransactions',
            request,
        );
        const response = await firstValueFrom(apiRequest$);
        if (!response.success) {
            this.logger.error('Error getting Fee Summary.', response.errors);
            throw Error('error-getting-fee summary');
        }
        return response.transactionsForRange;
    }

    async getBalances(request: TransactionHistoryRequest): Promise<TransactionsForRange> {
        const apiRequest$ = this.http.post<BaseResponse & { transactionsForRange: TransactionsForRange }>(
            this.environment.nodeBaseURL + 'card/getTransactions',
            request,
        );
        const response = await firstValueFrom(apiRequest$);
        if (!response.success) {
            this.logger.error('Error getting Balances for Transactions.', response.errors);
            throw Error('error-getting-Balances summary');
        }
        return response.transactionsForRange;
    }

    async getTransactionHistory(request: TransactionHistoryRequest): Promise<TransactionsForRange> {
        const apiRequest$ = this.http.post<BaseResponse & { transactionsForRange: TransactionsForRange }>(
            this.environment.nodeBaseURL + 'card/getTransactions',
            request,
        );
        const response = await firstValueFrom(apiRequest$);
        if (!response.success) {
            this.logger.error('Error getting transaction history.', response.errors);
            throw Error('error-getting-transactions');
        }

        return response.transactionsForRange;
    }

    async validateVirtualCode(request: ValidateVirtualCodeRequest): Promise<ValidateVirtualCodeResponseModel> {
        const apiRequest$ = this.http.post<BaseResponse & { virtualCardTerms: string; tenantUrl?: string; cardCurrency?: string }>(
            this.environment.nodeBaseURL + 'card/validateVirtualCode',
            request,
            {
                headers: {
                    't-session-id': request.rmsSessionId ? request.rmsSessionId : '',
                },
            },
        );
        const response = await firstValueFrom(apiRequest$);
        if (!response.success) {
            if (response.errors.includes('not-found')) {
                throw Error('invalid-code');
            } else if (response.errors.includes('already-redeemed')) {
                throw Error('already-redeemed');
            } else if (response.errors.includes('expired')) {
                throw Error('expired-code');
            } else if (response.errors.includes('error-processing-code')) {
                throw Error('error-processing-code');
            } else if (response.errors.includes('processing-error')) {
                throw Error('processing-error');
            } else if (response.errors.includes('error-activating-card')) {
                throw Error('error-activating-card');
            } else if (response.errors.includes('non-redeemable-status')) {
                throw Error('non-redeemable-status');
            } else if (response.errors.includes('cross-tenant-error')) {
                throw Error('cross-tenant-error');
            } else if (response.errors.includes('product-not-found')) {
                throw Error('product-not-found');
            } else if (response.errors.includes('Unauthorized')) {
                throw Error('Unauthorized');
            } else if (response.errors.includes('geo-check-failed')) {
                throw Error('geo-check-failed');
            } else {
                this.logger.error('Unknown Code Error.', response.errors);
                throw Error('unknown-code-error');
            }
        }
        return response;
    }

    async validateCountryCode(request: ValidateCountryCodeModel): Promise<BaseResponse> {
        const apiRequest$ = this.http.post<BaseResponse>(this.environment.nodeBaseURL + 'external/CheckProhibitedCountry', request);
        const response = await firstValueFrom(apiRequest$);
        return response;
    }

    async redeemCode(request: RedeemCodeRequest, rmsSessionId?: string): Promise<CardBundle> {
        const apiRequest$ = this.http.post<BaseResponse & CardBundle>(this.environment.nodeBaseURL + 'card/redeemCode', request, {
            headers: {
                't-session-id': rmsSessionId ? rmsSessionId : '',
            },
        });
        const response = await firstValueFrom(apiRequest$);
        if (!response.success) {
            if (response.errors.includes(ErrorCodes.PROCESSING_ERROR)) {
                throw Error(ErrorCodes.PROCESSING_ERROR);
            } else if (response.errors.includes(ErrorCodes.CARD_ALREADY_REGISTERED)) {
                throw Error(ErrorCodes.CARD_ALREADY_REGISTERED);
            } else if (response.errors.includes(ErrorCodes.MULTIPLE_COUNTRY_RECORDS_FOUND)) {
                throw Error(ErrorCodes.MULTIPLE_COUNTRY_RECORDS_FOUND);
            } else if (response.errors.includes(ErrorCodes.POSTAL_MAY_NOT_BE_EMPTY)) {
                throw Error(ErrorCodes.POSTAL_MAY_NOT_BE_EMPTY);
            } else if (response.errors.includes(ErrorCodes.CITY_MAY_NOT_BE_EMPTY)) {
                throw Error(ErrorCodes.CITY_MAY_NOT_BE_EMPTY);
            } else if (response.errors.includes(ErrorCodes.FIRSTNAME_MAY_NOT_BE_EMPTY)) {
                throw Error(ErrorCodes.FIRSTNAME_MAY_NOT_BE_EMPTY);
            } else if (response.errors.includes(ErrorCodes.NON_REDEEMABLE)) {
                throw Error(ErrorCodes.NON_REDEEMABLE);
            } else if (response.errors.includes(ErrorCodes.ADDRESSLINE1_MAY_NOT_BE_EMPTY)) {
                throw Error(ErrorCodes.ADDRESSLINE1_MAY_NOT_BE_EMPTY);
            } else if (response.errors.includes(ErrorCodes.LASTNAME_MAY_NOT_BE_EMPTY)) {
                throw Error(ErrorCodes.LASTNAME_MAY_NOT_BE_EMPTY);
            } else if (response.errors.includes(ErrorCodes.COUNTRY_MUSTBE_A_VALID_ISO_THREE_DIGIT_CODE)) {
                throw Error(ErrorCodes.COUNTRY_MUSTBE_A_VALID_ISO_THREE_DIGIT_CODE);
            } else if (response.errors.includes(ErrorCodes.ALREADY_REDEEMED)) {
                throw Error(ErrorCodes.ALREADY_REDEEMED);
            } else if (response.errors.includes(ErrorCodes.EXPIRED)) {
                throw Error(ErrorCodes.EXPIRED);
            } else if (response.errors.includes(ErrorCodes.REDEMPTION_FAILED)) {
                throw Error(ErrorCodes.REDEMPTION_FAILED);
            } else if (response.errors.includes(ErrorCodes.REDEMPTION_IN_PROGRESS)) {
                throw Error(ErrorCodes.REDEMPTION_IN_PROGRESS);
            } else if (response.errors.includes(ErrorCodes.URC_SERVICE_NOT_AVAILABLE)) {
                throw Error(ErrorCodes.URC_SERVICE_NOT_AVAILABLE);
            } else if (response.errors.includes(ErrorCodes.URC_CARDNOTFOUND)) {
                throw Error(ErrorCodes.URC_CARDNOTFOUND);
            } else if (response.errors.includes(ErrorCodes.URC_INVALID_ADDRESS_LINE1_EXCEEDED_MAXLENGTH)) {
                throw Error(ErrorCodes.URC_INVALID_ADDRESS_LINE1_EXCEEDED_MAXLENGTH);
            } else if (response.errors.includes(ErrorCodes.URC_INVALID_EMAIL_FORMAT)) {
                throw Error(ErrorCodes.URC_INVALID_EMAIL_FORMAT);
            } else if (response.errors.includes(ErrorCodes.URC_INVALID_ADDRESS_POSTAL_CODE_EXCEEDED_MAXLENGTH)) {
                throw Error(ErrorCodes.URC_INVALID_ADDRESS_POSTAL_CODE_EXCEEDED_MAXLENGTH);
            } else if (response.errors.includes(ErrorCodes.URC_KEYSTONE_ERROR_IN_HTTP_REQUEST)) {
                throw Error(ErrorCodes.URC_KEYSTONE_ERROR_IN_HTTP_REQUEST);
            } else {
                this.logger.error('Error redeeming code.', response.errors);
                throw Error('error-redeeming-code');
            }
        }
        return response;
    }

    async removeUserCard(request: CardRemovalRequest, rmsSessionId: any): Promise<void> {
        try {
            const apiRequest$ = this.http.post<BaseResponse>(this.environment.nodeBaseURL + 'card/removeUserCard', request, {
                headers: {
                    't-session-id': rmsSessionId ? rmsSessionId : '',
                },
            });
            const response = await firstValueFrom(apiRequest$);
            if (!response.success) {
                this.logger.error('Server error removing card.', response.errors);
                throw Error('error-removing-card');
            }
        } catch (error) {
            this.logger.error('Server error removing card.', error);
            return Promise.reject(new Error('error-removing-card'));
        }
    }

    async getCardBundle(request: CardBundleRequest): Promise<CardBundle> {
        const apiRequest$ = this.http.post<BaseResponse & CardBundle>(this.environment.nodeBaseURL + 'card/getCardBundle', request, {
            headers: {
                't-session-id': request.rmsSessionId ? request.rmsSessionId : '',
            },
        });
        const response = await firstValueFrom(apiRequest$);
        if (!response.success) {
            this.logger.error('Server error getting card bundle for current user.', response.errors);
            throw Error('error-getting-card-bundle-for-current-user');
        }
        return response;
    }

    async getUnmaskedCardInformation(proxyCardNumber: string, rmsSessionId?: string): Promise<UnmaskedCardInformation> {
        const apiRequest$ = this.http.post<BaseResponse & UnmaskedCardInformation>(
            this.environment.nodeBaseURL + 'card/getUnmaskedCardInfo',
            {
                proxyCardNumber,
            },
            {
                headers: {
                    't-session-id': rmsSessionId ? rmsSessionId : '',
                },
            },
        );
        const response = await firstValueFrom(apiRequest$);
        if (!response.success) {
            this.logger.error('Server error getting unmasked card information.', response.errors);
            throw Error('error-getting-unmasked-card-information');
        }
        return response;
    }

    async convertToPhysical(request: PlasticRequest, rmsSessionId?: any): Promise<CardBundle> {
        const apiRequest$ = this.http.post<BaseResponse & CardBundle>(this.environment.nodeBaseURL + 'card/convertToPhysical', request, {
            headers: {
                't-session-id': rmsSessionId ? rmsSessionId : '',
            },
        });
        const response = await firstValueFrom(apiRequest$);
        if (!response.success) {
            if (response.errors.includes('geo-check-failed')) {
                throw Error('geo-check-failed');
            } else {
                this.logger.error('Server error requesting physical card.', response.errors);
                throw Error('error-requesting-physical-card');
            }
        }
        return response;
    }

    async getProductText(productIdentifiers: string[]): Promise<ProductInformation[]> {
        const apiRequest$ = this.http.post<BaseResponse & { products: ProductInformation[] }>(
            this.environment.nodeBaseURL + 'card/getProductText',
            {
                productIdentifiers,
            },
        );
        const response = await firstValueFrom(apiRequest$);
        if (!response.success) {
            this.logger.error('Server error getting product text', response.errors);
            throw Error('error-getting-product-text');
        }
        return response.products;
    }

    async getMerchantList(clientProgramId: number, currentLang: string): Promise<any> {
        const apiRequest$ = this.http.get<BaseResponse & { result: any }>(
            this.environment.nodeBaseURL + 'card/getMerchantList?clientProgramId=' + clientProgramId + '&isoCode=' + currentLang,
        );
        const response = await firstValueFrom(apiRequest$);

        if (!response.success) {
            this.logger.error('Server error getting merchant list', response.errors);
            throw Error('error-getting-merchant-list');
        }

        return response;
    }

    async resendActivationCode(proxyCardNumber: string, rmsSessionId?: any): Promise<boolean> {
        const apiRequest$ = this.http.post<BaseResponse>(
            this.environment.nodeBaseURL + 'card/resendActivationCode',
            {
                proxyCardNumber,
            },
            {
                headers: {
                    't-session-id': rmsSessionId ? rmsSessionId : '',
                },
            },
        );
        const response = await firstValueFrom(apiRequest$);
        if (!response.success) {
            if (response.errors.includes('attempt-exceeded')) {
                throw Error('attempt-exceeded');
            } else {
                this.logger.error('Error resending activation code.', response.errors);
                throw Error('error-resending-activationcode');
            }
        }
        return response.success;
    }

    async getEGiftData(request: eGiftRequestModel): Promise<eGiftResponseModel> {
        try {
            const apiRequest$ = this.http.post<BaseResponse & eGiftResponseModel>(
                this.environment.nodeBaseURL + 'card/getEGiftData',
                {
                    eGiftId: request.eGiftId,
                    tenantId: request.tenantId,
                },
                {
                    headers: {
                        't-session-id': request.rmsSessionId ? request.rmsSessionId : '',
                    },
                },
            );
            const response = await firstValueFrom(apiRequest$);
            return response;
        } catch (error) {
            throw Error('error-retriving-eGift');
        }
    }

    getDownloadFile(termsFileUrl?: string) {
        let fileName = termsFileUrl.substring(termsFileUrl.lastIndexOf('/') + 1);
        let xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = function () {
            let a = document.createElement('a');
            a.href = window.URL.createObjectURL(xhr.response); // xhr.response is a blob
            a.download = fileName; // Set the file name.
            a.style.display = 'none';
            document.body.appendChild(a);
            a.click();
            // delete a;
        };
        xhr.open('GET', termsFileUrl);
        xhr.send();
    }

    async generatePayloadDatawithToken(request: any): Promise<any> {
        try {
            const apiRequest$ = this.http.post<BaseResponse & any>(
                this.environment.nodeBaseURL + 'card/generatePayloadDatawithToken',
                request,
            );
            const response = await firstValueFrom(apiRequest$);
            return response;
        } catch (error) {
            throw Error('error-generating-payload');
        }
    }

    async getCardRetrievalLink(request: GetCardRetrievalLinkRequestModel, RMS_ID: string): Promise<BaseResponse> {
        try {
            const apiRequest$ = this.http.post<BaseResponse & any>(this.environment.nodeBaseURL + 'card/getCardRetrievalLink', request, {
                headers: {
                    't-session-id': RMS_ID ? RMS_ID : '',
                },
            });
            const response = await firstValueFrom(apiRequest$);
            return response;
        } catch (error) {
            throw Error(error?.error?.errors?.toString());
        }
    }

    async verifyCardRetrievalLinkCode(request: VerifyCardRetrievalLinkCodeRequestModel, RMS_ID: string): Promise<CardBundle> {
        try {
            const apiRequest$ = this.http.post<BaseResponse & CardBundle>(
                this.environment.nodeBaseURL + 'card/verifyCardRetrievalLinkCode',
                request,
                {
                    headers: {
                        't-session-id': RMS_ID ? RMS_ID : '',
                    },
                },
            );
            const response = await firstValueFrom(apiRequest$);
            return response;
        } catch (error) {
            throw Error(error?.error?.errors?.toString());
        }
    }

    async resendRLVerificationCode(request: GetCardRetrievalLinkRequestModel, RMS_ID: string): Promise<BaseResponse> {
        try {
            const apiRequest$ = this.http.post<BaseResponse & any>(
                this.environment.nodeBaseURL + 'card/resendRLVerificationCode',
                request,
                {
                    headers: {
                        't-session-id': RMS_ID ? RMS_ID : '',
                    },
                },
            );
            const response = await firstValueFrom(apiRequest$);
            return response;
        } catch (error) {
            throw Error(error?.error?.errors?.toString());
        }
    }

    getFeeTranslation(request: FeeTranslationModel): Promise<BaseResponse> {
        try {
            const apiRequest$ = this.http.post<BaseResponse & any>(this.environment.nodeBaseURL + 'card/feeTranslation', request);
            const response = firstValueFrom(apiRequest$);
            return response;
        } catch (error) {
            throw Error(error?.error?.errors?.toString());
        }
    }
}
