// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import env from './.env';
import { NgxLoggerLevel } from 'ngx-logger';

export const environment = {
    environmentKey: 'development',
    production: false,
    stratus: false,
    version: env.npm_package_version + '-dev',
    nodeBaseURL: 'http://localhost:3030/api/',
    checkProhibitedCountryURL: 'https://hrt7yv0scj.execute-api.us-east-1.amazonaws.com/Production/CheckProhibitedCountryProd',
    baseS3URL: 'https://bes-fenton-devqa-yourcardhub.s3-us-west-2.amazonaws.com',
    //walletURL: 'https://apps.myprepaidcenter.com/provision-egift?requestor=MPC&account=${proxyCardNumber}&verification=${cardLastFour}',
    walletURL: 'https://apps.myprepaidcenter.com/provision-egift?requestor=MPC&token=${token}',
    defaultLang: 'en-US',
    loginExpireWarningSeconds: 15,
    showLoginExpirationTimer: false,
    logConfig() {
        return {
            serverLoggingUrl: 'http://localhost:3030/api/log',
            // enableSourceMaps: true,
            level: NgxLoggerLevel.TRACE,
            serverLogLevel: NgxLoggerLevel.OFF,
            disableConsoleLogging: true,
        };
    },
    trustedLinkPatterns: [
        /data:image/,
        /^https?\:\/\/localhost\:4200/,
        /^https?\:\/\/(.*?)yourcardhub.com/,
        /^https?\:\/\/[^\/\?\#]*myprepaidcenter\.com/,
        /^https?\:\/\/[^\/\?\#]*blackhawknetwork\.com/,
        /^https?\:\/\/[^\/\?\#]*intelispend-staging\.com/,
        /^https?\:\/\/bes-fenton-devqa-yourcardhub\.s3-us-west-2\.amazonaws\.com/,
        /^https?\:\/\/(.*?)myprepaidcenter.com/,
        /^https?\:\/\/(.*?)blackhawknetwork.com/,
        /^https?\:\/\/embed\.widencdn\.net\/download\/blackhawknetwork\//,
        /javascript:void/,
    ],
    useNewCardDetail: true,
    trustEVKey: '4c5bd5270c844831a15d0272b3b6fe77',
    trustEVURL: 'https://cdn-us.trustev.com/trustev.min.js',
    webPackConfig: false,
    customIP: '73.181.213.227, 64.252.73.64, 130.176.100.81',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.