import { AuthenticationGuard } from '@app/core/guards/authentication.guard';
import { CardDetailActivationGuard } from '@app/core/guards/card-detail-activation.guard';
import { CardDetailPreviewActivationGuard } from '@app/core/guards/card-detail-preview-activation.guard';
import { Feature, Shell } from '@app/shared/models/application-config.model';
import { ContentEditorResolver } from '@app/shared/services/content-editor.resolver';

const SetName = 'original';

export enum OriginalShell {
    OriginalShell = 'original-shell',
    TestShell = 'test-shell',
    KYCShell = 'kyc-shell',
}

let shells = new Map<string, Shell>([
    [
        OriginalShell.OriginalShell,
        {
            route: {
                path: '',
                loadChildren: () =>
                    import('@app/features/original/shells/original-shell/original-shell.module').then((m) => m.OriginalShellModule),
                resolve: { contentEditorData: ContentEditorResolver },
                runGuardsAndResolvers: 'always',
            },
        },
    ],
    [
        OriginalShell.TestShell,
        {
            route: {
                path: '',
                loadChildren: () => import('@app/features/original/shells/test-shell/test-shell.module').then((m) => m.TestShellModule),
            },
        },
    ],
    [
        OriginalShell.KYCShell,
        {
            route: {
                path: '',
                loadChildren: () => import('@app/features/original/shells/kyc-shell/kyc-shell.module').then((m) => m.KycShellModule),
            },
        },
    ],
]);

shells = new Map(Array.from(shells.entries()).map(([name, shell]) => [name, { ...shell, name }]));

export enum OriginalFeature {
    // from app module
    OriginalDiscontinuedMerchantsPage = 'original-discontinued-merchants-page',
    OriginalMerchantPage = 'original-merchant-page',
    Original10BackTocPage = 'original-10-back-toc-page',
    Original5BackTocCanadaPage = 'original-5-back-toc-canada-page',
    Original5BackTocPage = 'original-5-back-toc-page',
    OriginalVirtualRedeemPage = 'original-virtual-redeem-page',
    OriginalEgiftPage = 'original-egift-page',

    // from home module
    OriginalLandingPage = 'original-landing-page',
    OriginalContactUsPage = 'original-contact-us-page',
    OriginalTermsOfUsePage = 'original-terms-of-use-page',
    OriginalFaqPage = 'original-faq-page',
    OriginalProhibitedCountriesPage = 'original-prohibited-countries-page',
    OriginalRegisteredMarksPage = 'original-registered-marks-page',

    OriginalProxyActivationLandingPage = 'original-proxy-activation-landing-page',
    OriginalTranslationAssistancePage = 'original-translation-assistance-page',
    OriginalVerificationCode = 'original-verification-code',

    // from auth module
    OriginalCardLoginPage = 'original-card-login-page',
    OriginalUserLoginPage = 'original-user-login-page',
    OriginalCardLinkPage = 'original-card-link-page',
    OriginalCardRetrievalPage = 'original-card-retrieval-page',

    // from card module
    OriginalDataCapturePage = 'original-data-capture-page',
    OriginalRegistrationPage = 'original-registration-page',
    OriginalMyCardsPage = 'original-my-cards-page',
    OriginalRedeemPage = 'original-redeem-page',
    OriginalGetRL = 'original-get-rl-page',

    // from user module
    OriginalCreateProfilePage = 'original-create-profile-page',
    OriginalManageProfilePage = 'original-manage-profile-page',
    OriginalForgotPasswordPage = 'original-forgot-password-page',
    OriginalResetPasswordPage = 'original-reset-password-page',
    OriginalMyAlertsPage = 'original-my-alerts-page',
    OriginalAdditionalInfo = 'original-additional-info-page',
    OriginalInitiateIdv = 'original-initiate-idv',
    OriginalKYCError = 'original-kyc-error',

    // from card details module
    OriginalCardDetailsPage = 'original-card-details-page',
}

let features = new Map<string, Feature>([
    // from app module
    [
        OriginalFeature.OriginalDiscontinuedMerchantsPage,
        {
            routes: [
                {
                    path: 'content/discontinuedmerchants',
                    loadChildren: () =>
                        import('@app/features/original/card/discontinued-merchants/discontinued-merchants.module').then(
                            (m) => m.DiscontinuedMerchantsModule,
                        ),
                },
            ],
        },
    ],
    [
        OriginalFeature.OriginalMerchantPage,
        {
            routes: [
                {
                    path: 'merchant',
                    loadChildren: () => import('app/features/original/card/merchant/merchant.module').then((m) => m.MerchantModule),
                },
            ],
        },
    ],
    [
        OriginalFeature.Original10BackTocPage,
        {
            routes: [
                {
                    path: 'content/10backtermsandconditions',
                    loadChildren: () =>
                        import('@app/features/original/card/terms-and-conditions/original-terms-and-conditions.module').then(
                            (m) => m.OriginalTermsAndConditionsModule,
                        ),
                    data: { title: 'shell.pageTitle.content.10backTermsAndConditions' },
                },
            ],
        },
    ],
    [
        OriginalFeature.Original5BackTocCanadaPage,
        {
            routes: [
                {
                    path: 'content/5backtermsandconditionscanada',
                    loadChildren: () =>
                        import('@app/features/original/card/terms-and-conditions/original-terms-and-conditions.module').then(
                            (m) => m.OriginalTermsAndConditionsModule,
                        ),
                    data: { title: 'shell.pageTitle.content.5backTermsAndConditionsCanada' },
                },
            ],
        },
    ],
    [
        OriginalFeature.Original5BackTocPage,
        {
            routes: [
                {
                    path: 'content/5backtermsandconditions',
                    loadChildren: () =>
                        import('@app/features/original/card/terms-and-conditions/original-terms-and-conditions.module').then(
                            (m) => m.OriginalTermsAndConditionsModule,
                        ),
                    data: { title: 'shell.pageTitle.content.5backTermsAndConditions' },
                },
            ],
        },
    ],
    [
        OriginalFeature.OriginalVirtualRedeemPage,
        {
            routes: [
                {
                    path: 'virtual/redeem',
                    loadChildren: () =>
                        import('app/features/original/external/client-redeem/client-redeem.module').then((m) => m.ClientRedeemModule),
                },
            ],
        },
    ],
    [
        OriginalFeature.OriginalEgiftPage,
        {
            shell: false,
            routes: [
                {
                    path: 'egift',
                    loadChildren: () => import('app/features/original/external/egift/egift.module').then((m) => m.EgiftModule),
                },
            ],
        },
    ],

    // from Home module
    [
        OriginalFeature.OriginalLandingPage,
        {
            routes: [
                {
                    path: '',
                    pathMatch: 'full',
                    redirectTo: '/home',
                },
                {
                    path: 'home', //
                    loadChildren: () =>
                        import('@app/features/original/home/intentions-landing-page/original-intentions-landing-page.module').then(
                            (m) => m.OriginalIntentionsLandingPageModule,
                        ),
                },
                {
                    path: '**',
                    redirectTo: '/home',
                },
            ],
        },
    ],
    [
        OriginalFeature.OriginalContactUsPage,
        {
            routes: [
                {
                    path: 'contactus',
                    loadChildren: () =>
                        import('@app/features/original/home/contact-us/original-contact-us.module').then((m) => m.OriginalContactUsModule),
                },
            ],
        },
    ],
    [
        OriginalFeature.OriginalTermsOfUsePage,
        {
            routes: [
                {
                    path: 'termsofuse',
                    loadChildren: () =>
                        import('@app/features/original/home/terms-of-use/original-terms-of-use.module').then(
                            (m) => m.OriginalTermsOfUseModule,
                        ),
                },
            ],
        },
    ],
    [
        OriginalFeature.OriginalFaqPage,
        {
            routes: [
                {
                    path: 'faq', //
                    loadChildren: () => import('@app/features/original/home/faq/original-faq.module').then((m) => m.OriginalFaqModule),
                },
            ],
        },
    ],
    [
        OriginalFeature.OriginalProhibitedCountriesPage,
        {
            routes: [
                {
                    path: 'prohibited-countries',
                    loadChildren: () =>
                        import('@app/features/original/home/prohibited-countries/original-prohibited-countries.module').then(
                            (m) => m.OriginalProhibitedCountriesModule,
                        ),
                },
            ],
        },
    ],
    [
        OriginalFeature.OriginalRegisteredMarksPage,
        {
            routes: [
                {
                    path: 'registered-marks',
                    loadChildren: () =>
                        import('@app/features/original/home/registered-marks/original-registered-marks.module').then(
                            (m) => m.OriginalRegisteredMarksModule,
                        ),
                },
            ],
        },
    ],

    // from Auth module
    [
        OriginalFeature.OriginalCardLoginPage,
        {
            routes: [
                {
                    path: 'login/card',
                    loadChildren: () =>
                        import('@app/features/original/home/card-login/original-card-login.module').then((m) => m.OriginalCardLoginModule),
                },
            ],
        },
    ],
    [
        OriginalFeature.OriginalUserLoginPage,
        {
            routes: [
                {
                    path: 'login/user',
                    loadChildren: () =>
                        import('@app/features/original/home/user-login/original-user-login.module').then((m) => m.OriginalUserLoginModule),
                },
            ],
        },
    ],
    [
        OriginalFeature.OriginalCardLinkPage,
        {
            routes: [
                { path: 'login/code-redeemed', redirectTo: 'login/card-link' },
                {
                    path: 'login/card-link',
                    loadChildren: () =>
                        import('@app/features/original/home/card-link/original-card-link.module').then((m) => m.OriginalCardLinkModule),
                },
                {
                    path: 'login/card-link/:retrievalCode',
                    loadChildren: () =>
                        import('@app/features/original/home/card-link/original-card-link.module').then((m) => m.OriginalCardLinkModule),
                },
            ],
        },
    ],

    // from Card module
    [
        OriginalFeature.OriginalDataCapturePage,
        {
            routes: [
                {
                    path: 'data-capture',
                    loadChildren: () =>
                        import('@app/features/original/card/activation-data-capture/data-capture-page.module').then(
                            (m) => m.DataCapturePageModule,
                        ),
                    canActivate: [AuthenticationGuard],
                },
            ],
        },
    ],
    [
        OriginalFeature.OriginalMyCardsPage,
        {
            configuration: {
                extraNavButtons: [
                    {
                        textKey: 'addCard.txt',
                        route: '/login/card',
                        buttonClass: 'add-card',
                        iconClass: 'fa fa-plus',
                    },
                    {
                        textKey: 'redeemCode.txt',
                        route: '/redeem',
                        buttonClass: 'redeem-code',
                        iconClass: 'redeem-code-icon',
                    },
                ],
            },
            routes: [
                {
                    path: 'my-cards',
                    loadChildren: () => import('@app/features/original/card/my-cards/my-cards.module').then((m) => m.OriginalMyCardsModule),
                    canActivate: [AuthenticationGuard],
                },
            ],
        },
    ],
    [
        OriginalFeature.OriginalRedeemPage,
        {
            routes: [
                {
                    path: 'redeem',
                    loadChildren: () =>
                        import('@app/features/original/card/code-redemption/original-code-redemption.module').then(
                            (m) => m.OriginalCodeRedemptionModule,
                        ),
                },
            ],
        },
    ],

    [
        OriginalFeature.OriginalGetRL,
        {
            routes: [
                {
                    path: 'get-rl',
                    loadChildren: () =>
                        import('@app/features/original/card/get-rl/original-get-rl.module').then((m) => m.OriginalGetRLModule),
                },
            ],
        },
    ],

    [
        OriginalFeature.OriginalCreateProfilePage,
        {
            routes: [
                {
                    path: 'create-profile',
                    loadChildren: () =>
                        import('@app/features/original/user/create-profile/original-create-profile.module').then(
                            (m) => m.OriginalCreateProfileModule, //
                        ),
                },
            ],
        },
    ],
    [
        OriginalFeature.OriginalManageProfilePage,
        {
            routes: [
                {
                    path: 'manage-profile',
                    loadChildren: () =>
                        import('@app/features/original/user/manage-profile/original-manage-profile.module').then(
                            (m) => m.OriginalManageProfileModule, //
                        ),
                    canActivate: [AuthenticationGuard],
                },
            ],
        },
    ],
    [
        OriginalFeature.OriginalForgotPasswordPage,
        {
            routes: [
                {
                    path: 'forgot-password',
                    loadChildren: () =>
                        import('@app/features/original/user/forgot-password/original-forgot-password.module').then(
                            (m) => m.OriginalForgotPasswordModule, //
                        ),
                },
            ],
        },
    ],
    [
        OriginalFeature.OriginalResetPasswordPage,
        {
            routes: [
                {
                    path: 'reset-password',
                    loadChildren: () =>
                        import('@app/features/original/user/reset-password/original-reset-password.module').then(
                            (m) => m.OriginalResetPasswordModule, //
                        ),
                },
            ],
        },
    ],
    [
        OriginalFeature.OriginalMyAlertsPage,
        {
            routes: [
                {
                    path: 'my-alerts',
                    loadChildren: () =>
                        import('@app/features/original/user/my-alerts/original-my-alerts.module').then(
                            (m) => m.OriginalMyAlertsModule, //
                        ),
                    canActivate: [AuthenticationGuard],
                },
            ],
        },
    ],

    [
        OriginalFeature.OriginalCardDetailsPage,
        {
            routes: [
                {
                    path: 'card-details',
                    loadChildren: () =>
                        import('@app/features/original/card/card-detail/original-card-detail.module').then(
                            (m) => m.OriginalCardDetailModule, //
                        ),
                    canActivate: [AuthenticationGuard],
                },
                {
                    path: 'site',
                    redirectTo: 'page',
                },
                {
                    path: 'page/:name',
                    loadChildren: () =>
                        import('@app/features/original/card/card-detail/original-card-detail.module').then(
                            (m) => m.OriginalCardDetailModule, //
                        ),
                    canActivate: [CardDetailActivationGuard],
                },
                {
                    path: 'page-preview/:name',
                    loadChildren: () =>
                        import('@app/features/original/card/card-detail/original-card-detail.module').then(
                            (m) => m.OriginalCardDetailModule, //
                        ),
                    canActivate: [CardDetailPreviewActivationGuard],
                },
            ],
        },
    ],
    [
        OriginalFeature.OriginalTranslationAssistancePage,
        {
            action: 'omit',
            routes: [
                {
                    path: 'translationassistance',
                    loadChildren: () =>
                        import('@app/features/original/home/translation-assistance/original-translation-assistance.module').then(
                            (m) => m.OriginalTranslationAssistanceModule,
                        ),
                },
            ],
        },
    ],
    [
        OriginalFeature.OriginalProxyActivationLandingPage,
        {
            action: 'omit',
            routes: [
                {
                    path: '',
                    pathMatch: 'full',
                    redirectTo: '/home',
                },
                {
                    path: 'home', //
                    loadChildren: () =>
                        import('@app/features/original/home/proxy-activation/original-proxy-activation.module').then(
                            (m) => m.OriginalProxyActivationModule,
                        ),
                },
                {
                    path: '**',
                    redirectTo: '/home',
                },
            ],
        },
    ],

    [
        OriginalFeature.OriginalVerificationCode,
        {
            routes: [
                {
                    path: 'login/user/verify', //
                    loadChildren: () =>
                        import('@app/features/original/home/verification-code/original-verification-code.module').then(
                            (m) => m.OriginalVerificationCodeModule,
                        ),
                },
            ],
        },
    ],
    [
        OriginalFeature.OriginalAdditionalInfo,
        {
            shell: 'kyc-shell',
            routes: [
                {
                    path: 'additional-info',
                    loadChildren: () =>
                        import('@app/features/original/user/additional-info/original-additional-info.module').then(
                            (m) => m.OriginalAdditionalInfoModule,
                        ),
                },
            ],
        },
    ],
    [
        OriginalFeature.OriginalInitiateIdv,
        {
            shell: 'kyc-shell',
            routes: [
                {
                    path: 'initiate-idv',
                    loadChildren: () =>
                        import('@app/features/original/user/initiate-idv/original-initiate-idv.module').then(
                            (m) => m.OriginalInitiateIdvModule,
                        ),
                },
            ],
        },
    ],
    [
        OriginalFeature.OriginalKYCError,
        {
            shell: 'kyc-shell',
            routes: [
                {
                    path: 'kyc-error',
                    loadChildren: () =>
                        import('@app/features/original/user/kyc-error/original-kyc-error.module').then((m) => m.OriginalKycErrorModule),
                },
            ],
        },
    ],
]);

features = new Map(Array.from(features.entries()).map(([name, feature]) => [name, { ...feature, name }]));

export default new Map([
    [
        SetName,
        {
            defaultShell: shells.get(OriginalShell.OriginalShell),
            shells,
            features,
        },
    ],
]);
