import { Injectable, TemplateRef } from '@angular/core';
import { Toast } from '../models/toast.model';

@Injectable({ providedIn: 'root' })
export class ToastService {
    toasts: Toast[] = [];

    show(textOrTpl: string | TemplateRef<any>, options: any = {}): void {
        this.toasts.push({ textOrTpl, ...options });
    }

    remove(toast: Toast): void {
        this.toasts = this.toasts.filter(t => t !== toast);
    }
}
