import { Component, Input, OnInit } from '@angular/core';
import { CardDataService } from '@app/shared/services/card-data.service';
import { CardNetwork } from '@app/shared/enum/card-network.enum';
import { map, shareReplay } from 'rxjs/operators';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CountryLanguageComponent } from '@app/shared/components/country-language/country-language.component';
import { BHNTranslateService } from '@app/shared/services/bhn-translate.service';
import { CommonService } from '@app/shared/services/common.service';
import * as moment from 'moment';
import { BaseComponent } from '@app/base/base.component';

@Component({
    selector: 'app-shared-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export class FooterComponent extends BaseComponent {
    @Input() showTranslationAssistanceLink: boolean;
    country: string;
    currentYear: string;
    privacyPolicyUrl: string;
    termsOfUse: string;
    accessibilityStatement: string;
    isExternalTnC: boolean = false;
    constructor(
        private cardData: CardDataService,
        private router: Router,
        protected translate: TranslateService,
        private modalService: NgbModal,
        private BHNTranslateService: BHNTranslateService,
        private commonService: CommonService,
    ) {
        super();
        this.currentYear = moment().format('YYYY');
        this.onSetTnClink();
    }
    complianceKey$ = this.cardData.selected$.pipe(
        map((card) => {
            if (card) {
                switch (card.cardNetwork) {
                    case CardNetwork.Visa:
                        return 'shell.footer.visaFooterCompliance';
                    case CardNetwork.Mastercard:
                        return 'shell.footer.mastercardFooterCompliance';
                    case CardNetwork.Discover:
                        return 'shell.footer.discoverFooterCompliance';
                }
            }
            return '';
        }),
        shareReplay(1),
    );

    goToFAQ(): void {
        this.router.navigateByUrl('~/', { skipLocationChange: true }).then(() =>
            this.router.navigate(['faq'], {
                queryParams: { type: 'generic' },
            }),
        );
    }

    showLanguageModal(): void {
        const modalRef = this.modalService.open(CountryLanguageComponent, {
            size: 'lg',
            ariaLabelledBy: 'language-dialog-label',
        });
        const ci = modalRef.componentInstance as CountryLanguageComponent;
        ci.showTranslationAssistanceLink = this.showTranslationAssistanceLink;
    }

    getCurrentCountry(): string {
        return this.BHNTranslateService.getStoredCountry();
    }

    getStoredLanguage(): string {
        return this.BHNTranslateService.getStoredLanguage();
    }

    private onSetTnClink() {
        const selectedLanguage = this.getStoredLanguage();
        const selectedCountry = this.getCurrentCountry();

        const langSuffix = selectedLanguage === 'fr-CA' ? '_frCA' : '_enUS';

        let termsOfUseKey = `termsOfUse${langSuffix}`;
        if (selectedCountry === 'CA' && selectedLanguage === 'en-US') {
            termsOfUseKey = 'termsOfUse_caEN';
        }
        this.termsOfUse = this.commonService.appConfig?.[termsOfUseKey] ?? '/termsofuse';

        const privacyPolicyKey = `privacyPolicyUrl${langSuffix}`;
        this.privacyPolicyUrl =
            this.commonService.appConfig?.[privacyPolicyKey] ??
            this.commonService.appConfig?.privacyPolicyUrl ??
            'https://blackhawknetwork.com/privacy-policy/';

        const accessibilityKey = `accessibilityStatement${langSuffix}`;
        this.accessibilityStatement =
            this.commonService.appConfig?.[accessibilityKey] ?? 'https://blackhawknetwork.com/commitment-to-accessibility';

        this.isExternalTnC = this.commonService.isValidURL(this.termsOfUse);
    }
}
