import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import * as DOMPurify from 'dompurify';

@Injectable({
    providedIn: 'root',
})
export class InputSanitizerService {
    constructor() {}

    sanitizeInput() {
        return (control: FormControl) => {
            let valueToSanitize = control.value;
            if (typeof valueToSanitize === 'object') {
                valueToSanitize = JSON.stringify(valueToSanitize);
            }
            const sanitizedInput = DOMPurify.sanitize(valueToSanitize, { ALLOWED_TAGS: [], FORBID_TAGS: ['script', 'style'] });
            if (sanitizedInput !== valueToSanitize) {
                return { sanitized: true };
            }
            return null;
        };
    }
}
