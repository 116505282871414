import { NGXLogger } from 'ngx-logger';
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { CredentialsService } from '../authentication/credentials.service';

@Injectable()
export class AuthenticationGuard implements CanActivate {
    constructor(private router: Router, private credentials: CredentialsService, private log: NGXLogger) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (this.credentials.isAuthenticated()) {
            return true;
        }

        this.log.debug('Not authenticated, redirecting and adding redirect url...');

        const signInURL = route.data['signInURL'] ? route.data['signInURL'] : 'home';

        this.router.navigate([`${signInURL}`], {
            queryParams: { redirect: state.url },
            replaceUrl: true,
        });
        return false;
    }
}
