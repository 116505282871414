<form [formGroup]="contentEditorFormGroup" novalidate>
    <div class="side-instructions" role="complementary">
        <div class="side-instructions-wrapper" style="white-space: nowrap">
            <div class="side-instructions-contents" style="display: none">
                <div class="row tabstrip-vertical light">
                    <div class="col-md-12">
                        <h3 class="contentEditor-header">
                            <span>Content Editor</span>
                            <!-- <i ng-class="contentEditorService.data.showSpinner ? 'fa fa-spinner fa-spin' : ''"></i> -->
                        </h3>
                        <div class="row">
                            <div class="col">
                                <div
                                    formArrayName="segments"
                                    *ngFor="let segment of contentEditorService.segmentList.getValue(); let i = index"
                                >
                                    <span>{{ segment.name }}</span>
                                    <div [formGroupName]="i">
                                        <select class="form-control" formControlName="resourceFilterId" (change)="refresh()">
                                            <option [selected]="true" [value]="-1">Any</option>
                                            <option
                                                *ngFor="let resourceFilter of segment.resourceFilterList"
                                                [value]="resourceFilter.resourceFilterId"
                                            >
                                                {{ resourceFilter.name }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <label>Culture: </label><br />
                                <select class="form-control" formControlName="culture" (change)="refresh()">
                                    <option *ngFor="let language of languages" [value]="language">
                                        {{ '^country-language.' + language | translate }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <label>Jump to Page:</label><br />
                                <select class="form-control" (change)="redirectTo($any($event.target).value)">
                                    <option aria-label="Select One..." [selected]="true" value="">Select One...</option>
                                    <option aria-label="Home" value="/home">Home</option>
                                    <option aria-label="User Login" value="/login/user">User Login</option>
                                    <option aria-label="Create Profile" value="create-profile">Create Profile</option>
                                    <option aria-label="Manage Profile" value="manage-profile">Manage Profile</option>
                                </select>
                            </div>
                        </div>
                        <div ng-show="contentEditorService.connectionInformation != undefined" style="margin-top: 10px">
                            <h4>Server Information:</h4>
                            <label>Web Server:&nbsp;{{ contentEditorService.connectionInformation.webServer }}</label
                            ><br />
                            <label>Database Server:&nbsp;{{ contentEditorService.connectionInformation.dataSource }}</label
                            ><br />
                            <label>Database:&nbsp;{{ contentEditorService.connectionInformation.initialCatalog }}</label
                            ><br />
                        </div>
                        <div class="row mb-2">
                            <span *ngIf="!isEditMode" class="col-12">
                                <button id="editButton" type="button" class="btn btn-primary" (click)="onEditClick()">Edit Content</button>
                                <span class="mr-2"></span>
                                <button id="refreshButton" type="button" class="btn btn-primary" (click)="onRefreshClick()">Refresh</button>
                            </span>
                            <span *ngIf="isEditMode">
                                <button
                                    id="sSaveButton"
                                    type="button"
                                    class="btn btn-primary justify-content-center"
                                    (click)="onSaveClick()"
                                >
                                    Save Changes
                                </button>
                                <span class="mr-2"></span>
                                <button
                                    id="cancelButton"
                                    type="button"
                                    class="btn btn-primary justify-content-center"
                                    (click)="onCancelClick(true)"
                                >
                                    Cancel Changes
                                </button>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="side-instructions-title light" style="position: relative" (click)="contentEditorClick()">Content Editor</div>
        </div>
    </div>
</form>
