import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CardDataService } from '@app/shared/services/card-data.service';
import { map, take } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Observable, of, pipe, Subscription } from 'rxjs';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';
import { Credentials, CredentialsService } from '@app/core/authentication/credentials.service';
import { CardInformation } from '@app/services/card/models/card-information.model';
import { UserProfile } from '@app/services/user/models/user-profile-model';
import { IContactUsReasons } from '@app/shared/models/contactus_reasons.model';
import { ContactusService } from '@app/shared/services/contact-us.service';
import { ContactUsUserProfile } from '@app/services/user/models/contactus-user-profile.model';
import { RmsSessionService } from '@app/services/auth/rms-session.service';
import { FlowName, FlowType } from '@app/shared/enum/rms.enum';
import { ValidateCountryCodeModel } from '@app/services/card/models/redeem-code-request-model';
import { CodeRedemptionService } from '@app/services/card/code-redemption.service';
import { FeatureService } from '@app/shared/services/feature.service';
import { InputSanitizerService } from '@app/core/services/input-sanitizer.service';

enum PageStatus {
    Idle,
    Processing,
    Completed,
    Error,
}

@Component({
    selector: 'app-contact-us-form',
    templateUrl: './contact-us-form.component.html',
    styleUrls: ['./contact-us-form.component.scss'],
})
export class ContactUsFormComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
    @Input('userData') userData: ContactUsUserProfile;
    @Input('cardArray') cards: CardInformation[];
    @Input('reasons') contactUsReasons: IContactUsReasons[];

    areBothInvalid = false;
    isEmailInvalid = false;
    isPhoneInvalid = false;
    contactusForm: FormGroup;
    CARD_NUMBER_LENGTH = 16;
    files: any[] = [];
    private telephoneSub: Subscription;
    errorMessage: string;
    submitted: boolean = false;
    selectedCard: CardInformation;
    formSubmissionSuccessful: boolean = false;
    public status: PageStatus;
    PageStatus = PageStatus;
    @ViewChild('contactUsFormDiv') contactUsFormDiv: ElementRef;

    constructor(
        private formBuilder: FormBuilder,
        private contactUsService: ContactusService,
        public credentials: CredentialsService,
        private rmsSessionService: RmsSessionService,
        private inputSanitizerService: InputSanitizerService,
        private codeRedemptionService: CodeRedemptionService,
        private featureService: FeatureService,
    ) {
        this.createForm();
    }

    async ngOnInit() {
        this.listenTelephoneNum();
        this.setSelectedCardIfAny();
    }

    setSelectedCardIfAny() {
        // set selected card if any and make dropdown disabled
        if (this.cards?.length === 1) {
            this.contactusForm.controls.cardNumber.setValue(this.cards[0].proxyCardNumber);
            this.selectedCard = this.cards[0];
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        // populating form data
        if (changes?.userData?.currentValue) {
            this.contactusForm.patchValue({
                firstname: this.userData?.firstName,
                lastname: this.userData?.lastName,
                emailAddress: this.userData?.email,
                telephoneNumber: this.userData?.phone,
            });
        }
        if (changes?.cards?.currentValue && !!this.contactUsService.getProxyNumber()) {
            this.setSelectedCardIfAny();
        }
        if (changes?.cards?.currentValue?.length > 1) {
            this.contactusForm.controls.cardNumber.setValidators(Validators.required);
            this.contactusForm.controls.cardNumber.updateValueAndValidity();
        }
    }

    ngAfterViewInit(): void {
        // this.contactUsFormDiv.nativeElement.scrollIntoView({ behavior: 'smooth' });
    }

    listenTelephoneNum() {
        this.telephoneSub = this.contactusForm.controls.telephoneNumber.valueChanges.subscribe((value) => {
            if (value && (isNaN(value) || value === ' ')) this.contactusForm.controls.telephoneNumber.setValue('');
        });
    }

    createForm() {
        this.contactusForm = this.formBuilder.group({
            firstname: ['', [Validators.required, Validators.pattern(new RegExp('^[a-zA-Z]+$'))]],
            lastname: ['', [Validators.required, Validators.pattern(new RegExp('^[a-zA-Z]+$'))]],
            emailAddress: [
                '',
                [Validators.required, Validators.email, Validators.pattern(new RegExp('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$'))],
            ],
            telephoneNumber: ['', [Validators.minLength(10), Validators.maxLength(15)]],
            cardNumber: ['', this.cards && this.cards?.length > 0 ? Validators.required : []],
            enquiryText: ['', [Validators.maxLength(2048), Validators.required]],
            reason: ['', Validators.required],
        });
    }

    cardNumberSelected(card: CardInformation) {
        this.contactusForm.controls.cardNumber.setValue(card.proxyCardNumber);
        this.selectedCard = card;
    }

    reasonSelected(reason: IContactUsReasons) {
        this.contactusForm.controls.reason.setValue(reason.reasonName);
    }

    maskCardNumber(proxyCardNumber: string) {
        let mask = Array(proxyCardNumber.length + 1).join('*');
        let maskedCardNumber = mask.split('');
        maskedCardNumber.push(proxyCardNumber.substring(proxyCardNumber.length - 4));
        return maskedCardNumber.join('');
    }

    telephoneNumberValidations() {
        let tn = this.contactusForm.controls.telephoneNumber;
        return !tn.valid && !tn.untouched && !isNaN(+tn.value) && (tn.errors?.minlength || tn.errors?.maxlength);
    }

    hideDropdown(dropdownRef: BsDropdownDirective): void {
        setTimeout(() => {
            dropdownRef.hide();
        }, 1);
    }

    onFilechange(event: any) {
        this.errorMessage = null;
        if (this.files.concat(Array.from(event.target.files)).length > 3) {
            this.errorMessage = 'contactusForm.uploadAdditionalFilesOverLimit';
            return;
        }

        for (let file of Array.from(event.target.files)) {
            if (file['size'] > 5000000) {
                this.errorMessage = 'contactusForm.uploadAdditionalFilesValidation';
                return;
            }
        }
        this.files = this.files.concat(Array.from(event.target.files));
    }

    removeFile(index) {
        this.files = this.files.filter((_, i) => i != index);
        this.errorMessage = null;
    }

    async submit() {
        this.errorMessage = null;
        this.isEmailInvalid = false;
        this.isPhoneInvalid = false;
        this.areBothInvalid = false;
        this.submitted = true;
        this.formSubmissionSuccessful = false;
        this.status = PageStatus.Processing;

        if (!this.contactusForm.valid) {
            this.contactusForm.markAllAsTouched();
            this.status = PageStatus.Error;
            return;
        }

        try {
            const flowType = {
                type: FlowType.OTHER,
                name: FlowName.CONTACT_US,
            };
            const RMS_ID =
                this.rmsSessionService?.rmsSessionData?.rmsId || (await this.rmsSessionService.getRmsData(flowType).catch((error) => error))?.rmsId;
            const rmsSessionId = RMS_ID
            const formData = new FormData();
            for (let file of this.files) {
                formData.append('files[]', file, file.name);
            }
            formData.append('contactUsData', JSON.stringify(this.contactusForm.value));
            formData.append('rmsSessionId', rmsSessionId);

            if (this.featureService.getfeatureLayout() !== 'fluid') {
                const validateCountryCodeModel: ValidateCountryCodeModel = {
                    email: this.contactusForm.value.emailAddress,
                    phone: this.contactusForm.value.telephoneNumber =
                        this.contactusForm.value.telephoneNumber === "" ? null
                            : this.contactusForm.value.telephoneNumber
                };
                const isCountryValid = await this.codeRedemptionService.validateCountryCode(validateCountryCodeModel);
                if (isCountryValid.isProhibited) {
                    throw new Error(isCountryValid.errors[0]);
                }
            }

            const response = await this.contactUsService.submitContactUsForm(formData);
            if (!response.success && JSON.stringify(response.errors[0]).includes('contactus-request-failed')) {
                this.errorMessage = 'contactusForm.formSubmissionUnSuccessful';
                this.status = PageStatus.Error;
                return;
            }
            this.formSubmissionSuccessful = true;
            this.status = PageStatus.Completed;
            this.contactusForm.controls.enquiryText.reset();
            this.contactusForm.controls.reason.reset();
            this.files = [];
        } catch (error) {
            this.status = PageStatus.Error;
            if (error.status == 413) {
                this.errorMessage = 'contactusForm.uploadAdditionalFilesValidation';
            } else if ((error.status === 500 && error.url?.includes('CheckProhibitedCountry')) || error.message === 'Email from prohibited country') {
                if (error.message === 'Email from prohibited country') {
                    this.isEmailInvalid = true
                } else if (error.error.errors[0] === 'Phone number from prohibited country') {
                    this.isPhoneInvalid = true;
                } else {
                    this.areBothInvalid = true;
                }
            }

        }
    }

    ngOnDestroy() {
        this.telephoneSub.unsubscribe();
    }
}
