import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CommonService } from './common.service';
declare global { interface Window { AdobeDC: any; } }

@Injectable({
  providedIn: 'root'
})
export class PdfViewerConfigService {
  adobeDCView: any;
  previewConfig = {
    showAnnotationTools: false
  };
  pdfReady$: Observable<void> = new Observable((subscriber) => {
    //@Sagar: If typeScript finds the window.AdobeDC from global object then subscribe immediately otherwise Wait for Adobe Document Services PDF Embed API to be ready
    if (window.AdobeDC) {
      subscriber.next();
    } else {
      document.addEventListener('adobe_dc_view_sdk.ready', () => {
        subscriber.next();
      });
    }
  });
  constructor(
    readonly commonService: CommonService,
  ) { }

  previewFile(divId: string, fileName: string, fileNameString: string){
    //@Sagar: adobe pdf viewer api configuration hooks(from adobe docs).
    const config: any = {
        clientId: this.commonService.appConfig.pdfViewerClientId //"b91a6658493d485c9f297090eba01d10"
        //clientId: "c770a512a8ab48dc855df6f0e79237d2" // @Sagar: keeping local host whitelisted client id for reference
      };
      if (divId) { 
        config.divId = divId;
      }
      this.adobeDCView = new window.AdobeDC.View(config);
      this.adobeDCView.previewFile({
        content: {
          //@Sagar: Location of file where it is hosted
          location: {
            url: fileName, // pdf file url,
            //headers:[{key: 'Authorization', value: `bearer ${authToken}`}]
          },
        },
        metaData: {
          fileName: fileNameString,
          //id: 'mmy file id'
        }
      }, this.previewConfig);
  }
}
