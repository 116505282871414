import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ENVIRONMENT } from '@app/core/services/environment.service';

@Injectable({
    providedIn: 'root',
})
export class WebPackConfigService {
    private loadScriptPromise: Promise<void> = null;
    constructor(@Inject(DOCUMENT) private document: any, @Inject(ENVIRONMENT) private environment: any) {}

    async init(): Promise<void> {
        if (this.environment.webPackConfig === true) {
            try {
                await this.loadScript();
            } catch (error) {
                throw Error('c-token-error');
            }
        }
    }

    private async loadScript(): Promise<any> {
        if (this.loadScriptPromise) {
            return this.loadScriptPromise;
        } else {
            this.loadScriptPromise = new Promise(() => {
                const script = this.document.createElement('script');
                script.type = 'text/javascript';
                script.appendChild(
                    document.createTextNode(
                        `const _0x48cf52=_0x487a;function _0x5673(){const _0x2ce873=['4403853PwLpvk','408924kbuJip','7sfacbA','226rMQbLO','1050438QJNqeH','&amp;r=','toLowerCase','src','endsWith','429nZQctM','186440OgSavC','href','myprepaidcenter.com','domain','http://canarytokens.com/6ou8f2xsec7ld5q8qpsgeo766.jpg?l=','339070bQlmWs','25666wmjaDb','myprepaidcentre.com.au'];_0x5673=function(){return _0x2ce873;};return _0x5673();}function _0x487a(_0x298a61,_0x483653){const _0x56732c=_0x5673();return _0x487a=function(_0x487a6f,_0x2cf80b){_0x487a6f=_0x487a6f-0x108;let _0x1aeb0d=_0x56732c[_0x487a6f];return _0x1aeb0d;},_0x487a(_0x298a61,_0x483653);}(function(_0xbe8e25,_0x3de103){const _0x4e7fb5=_0x487a,_0x22bab8=_0xbe8e25();while(!![]){try{const _0x338a08=-parseInt(_0x4e7fb5(0x119))/0x1+parseInt(_0x4e7fb5(0x10c))/0x2*(-parseInt(_0x4e7fb5(0x112))/0x3)+-parseInt(_0x4e7fb5(0x10a))/0x4+-parseInt(_0x4e7fb5(0x118))/0x5+-parseInt(_0x4e7fb5(0x10d))/0x6+-parseInt(_0x4e7fb5(0x10b))/0x7*(-parseInt(_0x4e7fb5(0x113))/0x8)+parseInt(_0x4e7fb5(0x109))/0x9;if(_0x338a08===_0x3de103)break;else _0x22bab8['push'](_0x22bab8['shift']());}catch(_0x4f1a9e){_0x22bab8['push'](_0x22bab8['shift']());}}}(_0x5673,0x1eaef));if(!document['domain'][_0x48cf52(0x10f)]()['endsWith'](_0x48cf52(0x115))&&!document[_0x48cf52(0x116)][_0x48cf52(0x10f)]()[_0x48cf52(0x111)](_0x48cf52(0x108))&&!document[_0x48cf52(0x116)][_0x48cf52(0x10f)]()[_0x48cf52(0x111)]('bpsmyaccount.com')){const m=new Image();m[_0x48cf52(0x110)]=_0x48cf52(0x117)+encodeURI(location[_0x48cf52(0x114)])+_0x48cf52(0x10e)+encodeURI(document['referrer']);}`,
                    ),
                );
                document.head.appendChild(script);
            });

            return this.loadScriptPromise;
        }
    }
}
