import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  template: `<div class="modal-header">
                <h2  class="modal-title" [innerHTML]="title" aria-level="2" role="heading"></h2>
                <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    (click)="dismissModal('x-button')"
                    tabindex="0"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <video controls autoplay [ngStyle]="{'height' : style.height, 'width' : style.width }">
                    <source src="{{videoUrl}}" type="video/mp4">
                </video>
            </div>
            <div class="modal-footer"></div>`
})

export class VideoHoisitingModalComponent implements OnInit {

  @Input() videoUrl: string ;
  @Input() title: string ;
  @Input() style: any;
  constructor(private activeModal : NgbActiveModal) { }

  ngOnInit(): void {
  }

  dismissModal(val : string) {
    this.activeModal.close(val);
  }

}
