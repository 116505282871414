import { Component, HostBinding, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AddressModel } from '@app/shared/models/address.model';
import { AddressService } from '@app/shared/services/address.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
    selector: 'app-fluid-address-verification-modal',
    templateUrl: './fluid-address-verification-modal.component.html',
    styleUrls: ['./fluid-address-verification-modal.component.scss'],
})
export class FluidAddressVerificationModalComponent implements OnInit {
    form: FormGroup;
    enteredAddress: AddressModel;
    validatedAddress: AddressModel;
    selectedAddress: string;
    addressNotSelectedFlag: boolean;
    @HostBinding('class.mobile-detected')
    get mobileDetected(): boolean {
        return this.deviceDetector.isMobile();
    }

    constructor(
        private activeModal: NgbActiveModal,
        private addressService: AddressService,
        private deviceDetector: DeviceDetectorService,
    ) {}

    ngOnInit(): void {
        this.enteredAddress = this.addressService.enteredAddress;
        this.validatedAddress = this.addressService.validatedAddress;
        this.form = new FormGroup({
            selectedAddress: new FormControl('', Validators.required),
        });
    }

    radioSelect(value: string) {
        return value === this.selectedAddress ? 'radio-selected' : 'radio-unselected';
    }

    addressSelect(value: string): void {
        this.addressNotSelectedFlag = false;
        this.selectedAddress = value;
    }

    useAddress(): void {
        if (this.form.valid && this.selectedAddress) {
            this.activeModal.close(this.selectedAddress);
        } else {
            this.addressNotSelectedFlag = true;
        }
    }
    dismissModal(): void {
        this.activeModal.close('closeModal');
    }
}
