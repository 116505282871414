import { ElementRef, Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Injectable({
    providedIn: 'root',
})
export class ReactiveFormService {
    public IdOfFormElement: string;
    constructor() {}

    validateAllFields(formGroup: FormGroup): void {
        Object.keys(formGroup.controls).forEach((field) => {
            const control = formGroup.get(field);
            if (control instanceof FormControl) {
                control.markAsTouched({ onlySelf: true });
            } else if (control instanceof FormGroup) {
                this.validateAllFields(control);
            }
        });
    }

    findInvalidControls(formGroup: FormGroup): number {
        const invalid = [];
        const controls = formGroup.controls;
        if (controls) {
            for (const name in controls) {
                if (controls[name].invalid) {
                    invalid.push(name);
                }
            }
            return invalid.length;
        }
    }

    firstErrorID(formGroup: FormGroup): string {
        for (const _ele of Object.keys(formGroup.controls)) {
            if (formGroup.controls[_ele].invalid) {
                const elementList = document.querySelectorAll('[formcontrolname="' + _ele + '"]');
                return elementList[0]?.getAttribute('id');
            }
        }
    }

    focusInvalidControl(formGroup: FormGroup): void {
        for (const _ele of Object.keys(formGroup.controls)) {
            if (formGroup.controls[_ele].invalid) {
                const elementList = document.querySelectorAll('[formcontrolname="' + _ele + '"]');
                this.IdOfFormElement = elementList[0]?.getAttribute('id');
                if (elementList.length > 0) {
                    const _setFocusElementID = elementList[0].getAttribute('id');
                    document.getElementById(_setFocusElementID).focus();
                    break;
                }

                const _setFocusElement = document.getElementById(_ele);
                if (_setFocusElement) {
                    _setFocusElement.focus();
                    break;
                }
            }
        }
    }
}
