import { Injectable, Injector } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AlertModalComponent, ModalButton } from '@app/shared/components/modals/alert-modal.component';

@Injectable({
    providedIn: 'root',
})
export class BHNModalService {
    constructor(private modalService: NgbModal) {}

    showAlert(
        message: string | [string, { [key: string]: string }],
        title: string | [string, { [key: string]: string }] = '',
        buttons: Array<ModalButton | string> = ['^modal.general.ok'],
        closeButton = true,
        modalOptions: NgbModalOptions = {},
    ): Promise<string> {
        const buttonDefs = buttons.map((button, index) => {
            if (typeof button === 'string') {
                return {
                    label: button,
                    className: index === 0 ? 'btn btn-primary' : 'btn btn-secondary',
                    returnValue: button,
                    focus: index === 0,
                } as ModalButton;
            } else {
                return button as ModalButton;
            }
        });

        modalOptions = {
            ...modalOptions,
            windowClass: `bhn-alert-modal ${modalOptions.windowClass}`,
        };

        const modalRef = this.modalService.open(AlertModalComponent, modalOptions);
        modalRef.componentInstance.message = message;
        modalRef.componentInstance.title = title;
        modalRef.componentInstance.buttons = buttonDefs;
        modalRef.componentInstance.closeButton = closeButton;
        modalRef.componentInstance.errType = (message == 'aws-waf-error');
        return modalRef.result.catch((err) => err);
    }
}
