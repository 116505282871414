import { Pipe, PipeTransform } from '@angular/core';
import { BHNTranslateService } from '../services/bhn-translate.service';

@Pipe({ name: 'numberCurrency' })
export class NumberCurrencyPipe implements PipeTransform {
    constructor(private translate: BHNTranslateService) {}

    transform(value: any, currency: string) {
        try {
            if (currency) {
                return new Intl.NumberFormat(this.translate.getStoredLanguage(), {
                    style: 'currency',
                    currency,
                }).format(value);
            } else {
                return new Intl.NumberFormat(this.translate.getStoredLanguage()).format(value);
            }
        } catch {
            return value; // just in case
        }
    }
}
