<div class="modal-header">
    <div class="modal-title">
        <span translate>shell.inactivity.inactivity.message</span>
    </div>
</div>
<div class="modal-body">
    <div class="alert-message">
        {{ 'shell.inactivity.logout-countdown' | translate : { timeLeft: loginExpiration$ | async } }}
    </div>
</div>
<div class="modal-footer">
    <button *ngIf="showLogoutBtn()" class="btn btn-primary" (click)="activeModal.dismiss('Cross click'); logout()">
        <span translate>shell.inactivity.logout.btn</span>
    </button>
    <button class="btn btn-secondary" (click)="activeModal.dismiss('Cross click'); reset_timer()">
        <span translate id="continueBtn">shell.inactivity.refreshLogin.btn</span>
    </button>
</div>
