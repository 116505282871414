import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {State} from 'app/shared/components/send-me-the-app/components/send-me-the-app.component'

@Component({
    selector: 'app-field-error',
    templateUrl: './field-error.component.html',
    styleUrls: ['./field-error.component.scss'],
})
export class FieldErrorComponent implements OnInit {
    @Input()
    public parentForm: FormGroup;

    @Input()
    public fieldName: string;

    @Input()
    public errorMessage: string;

    @Input()
    public type: string;

    @Input()
    public id: string;

    @Input()
    public state: State;

    State= State; 

    constructor() {}

    get formField(): FormControl {
        return this.parentForm?.get(this.fieldName) as FormControl;
    }

    ngOnInit(): void {}
}
