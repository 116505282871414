import { TranslateService } from '@ngx-translate/core';
import { ContentEditorLangChangeEvent, ContentEditorRouteChangeEvent } from './../services/content-editor.service';
import { ContentEditorModel } from '@app/shared/models/content-editor.model';
import { ContentEditorService } from '@app/shared/services/content-editor.service';
import { Directive, ElementRef, Renderer2, OnInit, OnDestroy, AfterContentInit } from '@angular/core';
import { Subscription } from 'rxjs';

@Directive({
    selector: '[editable]',
})
export class EditableDirective implements OnInit, AfterContentInit, OnDestroy {
    private tag: string;
    private wrapperHTML: string;
    private iconHTML: string;
    private isEditMode: boolean;
    private editModeSubscription: Subscription;
    private onContentEditorLanguageChangeSub: Subscription;
    private onContentEditorRouteChangeSub: Subscription;

    constructor(
        private el: ElementRef,
        private renderer: Renderer2,
        private ceService: ContentEditorService,
        private translate: TranslateService,
    ) {
        if (!this.onContentEditorLanguageChangeSub) {
            this.onContentEditorLanguageChangeSub = this.ceService.onContentEditorLanguageChange.subscribe(
                (event: ContentEditorLangChangeEvent) => {
                    this.ceService.resourceList = event.data;
                    if (event.data.length > 0) {
                        this.cleanUp();
                        this.replaceContent();
                    } else {
                        this.cleanUp();
                    }
                },
            );
        }

        if (!this.onContentEditorRouteChangeSub) {
            this.onContentEditorRouteChangeSub = this.ceService.onContentEditorRouteChangeSub.subscribe(
                (event: ContentEditorRouteChangeEvent) => {
                    if (event.changed) {
                        if (this.ceService.innerHtmlMap) {
                            this.ceService.innerHtmlMap = this.ceService.innerHtmlMap.filter(f => f.name.includes('shell'));
                        }
                        this.ceService.translateRoute = event.url;
                        this.replaceContent();
                    }
                },
            );
        }
        this.editModeSubscription = this.ceService.isInEditMode().subscribe(editing => (this.isEditMode = editing));
    }

    private cleanUp() {
        const elementToUpdate = this.el.nativeElement;
        const tag = elementToUpdate.nodeName.toLowerCase();
        const isModified: boolean = this.el.nativeElement.getAttribute('modified') as boolean;
        if (isModified) {
            switch (tag) {
                case 'img':
                    if (this.ceService.translationKeyMap.has(elementToUpdate.id)) {
                        if (elementToUpdate.getAttribute('alt')) {
                            this.translate
                                .get(this.ceService.translateRoute + this.ceService.translationKeyMap.get(elementToUpdate.id))
                                .subscribe(item => {
                                    this.renderer.setAttribute(elementToUpdate, 'alt', item);
                                    this.renderer.removeAttribute(elementToUpdate, 'modified');
                                });
                        }
                    }
                    break;
                case 'input':
                    const content = this.ceService.translationKeyMap.get(elementToUpdate.id);
                    this.renderer.setProperty(elementToUpdate, 'placeholder', content);
                    if (elementToUpdate.getAttribute('aria-label')) {
                        this.renderer.setAttribute(elementToUpdate, 'aria-label', content);
                    }
                    this.renderer.removeAttribute(elementToUpdate, 'modified');
                    break;

                default:
                    if (elementToUpdate.innerHTML !== '') {
                        const content = this.ceService.translationKeyMap.get(elementToUpdate.id);
                        elementToUpdate.innerHTML = content.trim();
                    }
                    this.renderer.removeAttribute(elementToUpdate, 'modified');
                    break;
            }
        }
    }

    ngOnInit(): void {
        this.edifyElement();
    }

    ngOnDestroy(): void {
        this.editModeSubscription.unsubscribe();
        if (this.onContentEditorLanguageChangeSub) {
            this.onContentEditorLanguageChangeSub.unsubscribe();
        }
        if (this.onContentEditorRouteChangeSub) {
            this.onContentEditorRouteChangeSub.unsubscribe();
        }
    }

    ngAfterContentInit(): void {
        this.replaceContent();
    }

    private edifyElement() {
        this.tag = this.el.nativeElement.nodeName.toLowerCase();
        this.validateElement();

        this.wrapperHTML = this.renderer.createElement('span');
        this.renderer.setProperty(this.wrapperHTML, 'id', this.el.nativeElement.id + 'Wrapper');

        this.iconHTML = this.renderer.createElement('i');
        this.renderer.setProperty(this.iconHTML, 'id', this.el.nativeElement.id + 'EditableIcon');
        this.renderer.setProperty(this.iconHTML, 'style', 'display:none; cursor:pointer');
        this.renderer.addClass(this.iconHTML, 'editableIcon');
        this.renderer.addClass(this.iconHTML, 'fa');
        this.renderer.addClass(this.iconHTML, 'fa-pencil');
        this.renderer.listen(this.iconHTML, 'click', () => {
            const foundElements = this.el.nativeElement.querySelectorAll('[editable]');
            for (let i = 0, len = foundElements.length; i < len; i++) {
                foundElements[i].dispatchEvent('hallodeactivated');
            }

            this.el.nativeElement.dispatchEvent('halloactivated');
        });

        switch (this.tag) {
            case 'img':
                const editableImageWrapperHTML = this.renderer.createElement('div');

                if (this.el.nativeElement.getAttribute('editable-container-item')) {
                    this.renderer.setProperty(editableImageWrapperHTML, 'id', this.el.nativeElement.id + 'EditableImageWrapper');
                    this.renderer.setAttribute(editableImageWrapperHTML, 'editable', '');
                    this.renderer.setAttribute(editableImageWrapperHTML, 'editable-container-item', '');
                    this.renderer.removeAttribute(this.el.nativeElement, 'editable-container-item');
                } else {
                    this.renderer.setProperty(editableImageWrapperHTML, 'id', this.el.nativeElement.id + 'EditableImageWrapper');
                    this.renderer.setAttribute(editableImageWrapperHTML, 'editable', '');
                }

                this.renderer.setAttribute(this.el.nativeElement, 'editable-image', '');
                this.renderer.insertBefore(this.el.nativeElement.parentNode, this.wrapperHTML, this.el.nativeElement);
                this.renderer.appendChild(this.wrapperHTML, this.iconHTML);
                this.renderer.appendChild(this.wrapperHTML, this.el.nativeElement);
                this.renderer.insertBefore(this.el.nativeElement.parentNode, editableImageWrapperHTML, this.el.nativeElement);
                this.renderer.appendChild(editableImageWrapperHTML, this.el.nativeElement);

                this.renderer.removeAttribute(this.el.nativeElement, 'editable');
                break;

            default:
                this.renderer.insertBefore(this.el.nativeElement.parentNode, this.wrapperHTML, this.el.nativeElement);
                this.renderer.appendChild(this.wrapperHTML, this.iconHTML);
                this.renderer.appendChild(this.wrapperHTML, this.el.nativeElement);
                break;
        }
    }

    private replaceContent() {
        if (this.isEditMode) {
            return;
        }

        if (this.ceService.resourceList !== undefined && this.ceService.resourceList.length >= 1) {
            const elementToUpdate = this.el.nativeElement;
            const tag = elementToUpdate.nodeName.toLowerCase();
            let contentEditorModel: ContentEditorModel;
            if (tag === 'img') {
                contentEditorModel = this.ceService.resourceList.find(
                    (model: ContentEditorModel) => model.name === elementToUpdate.parentElement.getAttribute('id'),
                );
            } else {
                contentEditorModel = this.ceService.resourceList.find(
                    (model: ContentEditorModel) => model.name === elementToUpdate.getAttribute('id'),
                );
            }

            if (contentEditorModel) {
                if (contentEditorModel.value) {
                    switch (tag) {
                        case 'img':
                            const imgObject = JSON.parse(contentEditorModel.value);
                            this.renderer.setProperty(elementToUpdate, 'src', imgObject.src);

                            if (imgObject.width && imgObject.width !== 'auto') {
                                this.renderer.setProperty(elementToUpdate, 'width', imgObject.width);
                            }
                            if (imgObject.height && imgObject.height !== 'auto') {
                                this.renderer.setProperty(elementToUpdate, 'height', imgObject.height);
                            }
                            if (imgObject.alt) {
                                this.renderer.setProperty(elementToUpdate, 'alt', imgObject.alt);
                            }
                            if (!this.ceService.translationKeyMap.has(contentEditorModel.name)) {
                                if (elementToUpdate.getAttribute('alt')) {
                                    this.ceService.translationKeyMap.set(contentEditorModel.name, elementToUpdate.getAttribute('alt'));
                                }
                            }
                            this.renderer.setAttribute(elementToUpdate, 'modified', 'true');
                            break;
                        case 'input':
                            if (!this.ceService.translationKeyMap.has(contentEditorModel.name)) {
                                this.ceService.translationKeyMap.set(contentEditorModel.name, elementToUpdate.innerHTML);
                            }
                            this.renderer.setProperty(elementToUpdate, 'placeholder', contentEditorModel.value);
                            if (elementToUpdate.getAttribute('aria-label')) {
                                this.renderer.setAttribute(elementToUpdate, 'aria-label', contentEditorModel.value);
                            }
                            this.renderer.setAttribute(elementToUpdate, 'modified', 'true');
                            break;
                        default:
                            if (!this.ceService.translationKeyMap.has(contentEditorModel.name)) {
                                this.ceService.translationKeyMap.set(contentEditorModel.name, elementToUpdate.innerHTML);
                            }
                            if (elementToUpdate.getAttribute('data-fee-key')) {
                                elementToUpdate.innerHTML = contentEditorModel.value.replace(elementToUpdate.getAttribute('data-fee-key'), elementToUpdate.getAttribute('data-fee-curr'));
                            }else{
                                elementToUpdate.innerHTML = contentEditorModel.value;
                            }
                            if (elementToUpdate.getAttribute('aria-label')) {
                                this.renderer.setAttribute(elementToUpdate, 'aria-label', contentEditorModel.value);
                            }
                            this.renderer.setAttribute(elementToUpdate, 'modified', 'true');
                            break;
                    }
                }
            }
            const idx = this.ceService.resourceList.findIndex(f => f === contentEditorModel);
            if (idx > -1) {
                if (!this.ceService.innerHtmlMap) {
                    this.ceService.innerHtmlMap = new Array<ContentEditorModel>();
                }

                const innerHTMLdata = this.ceService.innerHtmlMap.find(x => x.name === this.ceService.resourceList[idx].name);
                if (!innerHTMLdata) {
                    this.ceService.innerHtmlMap.push(this.ceService.resourceList[idx]);
                } else {
                    innerHTMLdata.value = this.ceService.resourceList[idx].value;
                    innerHTMLdata.cultureName = this.ceService.resourceList[idx].cultureName;
                }
                if(!this.ceService.resourceList[idx].name.toLowerCase().includes('error') && !this.ceService.resourceList[idx].name.includes('toolTip') && !this.ceService.resourceList[idx].name.includes('add-to-wallet-message')) {
                    this.ceService.resourceList.splice(idx, 1);
                }
            }
        }
    }

    private validateElement() {
        if (this.el.nativeElement.innerHTML.indexOf('editable') > -1) {
            console.error(this.el.nativeElement.id + " contains a nested 'editable' element. Editable elements may not be nested.");
        }

        // this.renderer.ha
        // if (this.el.nativeElement.hasAttribute('(click)')) {
        //     console.error(`An editable item should not have an click attribute.
        //     This element must be wrapped with a wrapping element. The wrapping element may then have a ng-click attribute.`);
        // }
    }
}