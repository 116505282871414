import { Component, ElementRef, EventEmitter, Input, Output, Renderer2 } from '@angular/core';
import { CustomBannerType } from '@app/shared/enum/custom-banner-type.enum';
import { CustomBannerConfig } from '@app/shared/models/custom-banner-config.model';
import { CustomTooltipConfigs } from '@app/shared/models/custom-tooltip-configs.model';
import { ContentEditorService } from '@app/shared/services/content-editor.service';
import { fromEvent } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { CardDataService } from '@app/shared/services/card-data.service';

@Component({
    selector: 'app-custom-banner',
    templateUrl: './custom-banner.component.html',
    styleUrls: ['./custom-banner.component.scss'],
})
export class CustomBannerComponent {
    @Input() public bannerInfo: CustomBannerConfig;
    @Output() public primaryButtonClicked = new EventEmitter();
    @Output() public additionalLinkClicked = new EventEmitter();
    @Output() public chaClicked = new EventEmitter();
    @Output() public onRegisterCard = new EventEmitter<string>();

    public showSpinnerActivation = false;
    public showSpinnerTips = false;
    dismissBanner = false;
    public toggleRegCardIcon = false;
    public tooltipConfigs: CustomTooltipConfigs;

    BANNER_TYPE = CustomBannerType;
    private clicks$: Observable<Event>;
    clickListenerSetup: any = false;
    cardExpiredError: string;

    constructor(
        private el: ElementRef,
        private renderer: Renderer2,
        private ceService: ContentEditorService,
        private cardData: CardDataService,
    ) {
        this.clicks$ = fromEvent(document, 'click');
        this.clicks$.subscribe((data: any) => {
            if (!data.target.className.includes('fa-info-circle')) this.onToggleRegCardHelp(true);
        });

        this.ceService.content.subscribe((content) => {
            content.forEach((element) => {
                if (element.name === 'cardExpiredError') {
                    this.cardExpiredError = element.value;
                }
            });
        });
    }

    public onPrimaryButtonClick() {
        this.showSpinnerActivation = true;
        this.primaryButtonClicked.emit();
    }

    public onAdditionalLinkClick() {
        this.showSpinnerTips = true;
        this.additionalLinkClicked.emit();
    }

    public onDismissBanner() {
        this.dismissBanner = true;
        this.showSpinnerActivation = false;
        this.showSpinnerTips = false;
        if (this.bannerInfo.module === 'cardactivationbanner') {
            this.cardData.isCardActivationBanner.next(false);
            sessionStorage.removeItem('notifyBannerPCN');
        }
        if (this.bannerInfo.module === 'rlSelfServiceConfirmationBanner') {
            this.cardData.isRLSelfServiceBanner.next(false);
            sessionStorage.removeItem('notifyRLBannerPCN');
        }
    }

    public onRegCardForShopping() {
        this.onRegisterCard.emit('register-card');
    }

    public onToggleRegCardHelp(_value: boolean) {
        this.toggleRegCardIcon = !_value;
        this.tooltipConfigs = {
            toggleToolTip: this.toggleRegCardIcon,
            toolText: '^card-details.registerCardToolTip',
            top: '85px',
            left: '590px',
        };
    }

    ngAfterViewChecked() {
        if (!this.cardExpiredError || !this.bannerInfo.isnotify || this.clickListenerSetup) {
            return;
        }
        this.onClickListenerSetup();
    }

    onClickListenerSetup() {
        const contactLink = this.el.nativeElement.querySelector('#contactLink');
        if (!contactLink) {
            return;
        }
        this.renderer.listen(contactLink, 'click', () => this.onClickOfCHA());
        this.clickListenerSetup = true;
    }

    public onClickOfCHA() {
        this.chaClicked.emit();
    }
}
