<div class="btn-hit-area" tabindex="-1">
    <button
        type="button"
        role="switch"
        class="switch"
        [class.checked]="checked"
        [class.disabled]="disabled"
        [attr.aria-disabled]="disabled"
        [attr.aria-checked]="checked"
        [attr.aria-label]="label"
        tabindex="0"
    >
        <small>
            <i class="fa"></i>
        </small>
    </button>
</div>
